import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { getPublicVideo } from 'widget/redux/server/actions/channel/video/get';
import { createAsyncProxy } from 'shared/worker/lib';

export const ensureVideoLoaded = createAsyncProxy(
  'ensureVideoLoaded',
  videoId => (dispatch, getState) => {
    const state = getState();
    const videoByIds = getVideosGroupedByIds(state);
    const channel = getChannelForWidget(state);

    return videoByIds[videoId]
      ? Promise.resolve()
      : dispatch(getPublicVideo(channel.id, videoId));
  },
);
