import {
  BASE_LOGGER,
  createLoggerActions,
} from 'shared/worker/actions/fedopsLogger';

function createLogger() {
  const { appLoaded } = createLoggerActions(BASE_LOGGER);
  return { appLoaded };
}

export default createLogger();
