import { handleActions } from 'redux-actions';
import {
  SET_COMMENTS_OPEN,
  SET_HAS_NEW_COMMENTS,
  SET_OPEN_ON_LOAD,
} from 'widget/redux/client/actions/comments';

const defaultState = {
  isOpen: false,
  hasNewComments: false,
  openOnLoad: false,
};

export default handleActions(
  {
    [SET_COMMENTS_OPEN]: (state, { payload }) => ({
      ...state,
      isOpen: payload,
      openOnLoad: payload ? false : state.openOnLoad,
    }),
    [SET_HAS_NEW_COMMENTS]: (state, { payload }) => ({
      ...state,
      hasNewComments: payload,
    }),
    [SET_OPEN_ON_LOAD]: (state, { payload }) => ({
      ...state,
      openOnLoad: payload,
    }),
  },
  defaultState,
);
