import React from 'react';
import { logWidgetSystem } from 'shared/worker/actions/bi';
import { connect } from 'react-redux';

import PaymentEvents from 'shared/components/payment-events/payment-events';

@connect(
  null,
  { logWidgetSystem },
)
export default class PaymentsBiHandler extends React.Component {
  logPaymentSucceeded = () => {
    this.props.logWidgetSystem('video.purchase.succeeded');
  };

  logSubscribeSucceeded = () => {
    this.props.logWidgetSystem('channel.subscribe.succeeded');
  };

  logUnsubscribeSucceeded = () => {
    this.props.logWidgetSystem('channel.unsubscribe.succeeded');
  };

  render() {
    return (
      <PaymentEvents
        onSale={this.logPaymentSucceeded}
        onRent={this.logPaymentSucceeded}
        onSubscription={this.logSubscribeSucceeded}
        onSubscriptionCancel={this.logUnsubscribeSucceeded}
      />
    );
  }
}
