import Lodash from 'lodash';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import biMiddleware from 'shared/bi/bi-middleware';
import lazyChannelVideosMiddleware from 'widget/redux/middleware/lazy-channel-videos/lazy-channel-videos-middleware';
import biHandlers from 'widget/redux/middleware/bi-middleware/bi-handlers';
import { initNotForPreview } from 'shared/utils/not-for-preview';

export const createConfigureStore = ({
  createRootReducer,
  createRootReducerPath,
}) => ({
  initialState = {},
  middlewares: additionalMiddlewares = [],
  history,
}) => {
  const withContext = fn => fn;
  const storage = {};
  const middlewares = [
    thunk.withExtraArgument({ withContext, storage }),
    routerMiddleware(history),
    lazyChannelVideosMiddleware,
    biMiddleware(biHandlers),
  ];

  if (__DEBUG__ && !__SERVER__) {
    const reduxLogger = require('redux-logger').createLogger({
      collapsed: true,
      diff: true,
    });
    middlewares.push(reduxLogger);
  }

  middlewares.push(...additionalMiddlewares);

  const middleware = !__SERVER__
    ? require('redux-devtools-extension').composeWithDevTools(
        applyMiddleware(...middlewares),
      )
    : applyMiddleware(...middlewares);

  // Create final store and subscribe router in debug env ie. for devtools
  const store = middleware(createStore)(
    createRootReducer({ history }),
    Lodash.omit(initialState, 'router'),
  );

  if (module.hot) {
    module.hot.accept(createRootReducerPath, () => {
      const createNextRootReducer = require(createRootReducerPath).default;
      store.replaceReducer(createNextRootReducer({ history }));
    });
  }

  initNotForPreview(store);

  return store;
};
