import { BI_EVENT } from 'shared/bi/constants';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import { pubSubPublish } from 'shared/worker/actions/pub-sub/publish';
import { createProxy } from 'shared/worker/lib';

export const sendBiEvent = createProxy(
  'sendBiEvent',
  (eventName, params) => (dispatch, getState) => {
    dispatch(
      pubSubPublish(BI_EVENT, {
        eventName,
        params,
        targetCompId: getCompId(getState()),
      }),
    );
  },
);
