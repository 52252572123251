import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { VODCSSModules } from 'shared/utils/wix-connect';

import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { logWidgetSystem } from 'shared/worker/actions/bi';

import ContentSlider from '@wix/wix-vod-shared/dist/src/widget/ui-components/content-slider/content-slider';
import PageList from '@wix/wix-vod-shared/dist/src/widget/ui-components/page-list/page-list';
import Nav from '@wix/wix-vod-shared/dist/src/widget/ui-components/nav/nav';
import VideoThumbnailOverlay from 'widget/layouts/compact/components/video-thumbnail-overlay/video-thumbnail-overlay';

import { withStyles } from 'shared/utils/withStyles';
import styles from './videos.styl';

@connect(
  state => ({
    currentSiteUser: getCurrentSiteUser(state),
    videoByIds: getVideosGroupedByIds(state),
  }),
  { logWidgetSystem },
)
@withStyles(styles)
@VODCSSModules(styles)
export default class Videos extends React.Component {
  static propTypes = {
    OverlayActionsCallbacks: PropTypes.func,

    currentSiteUser: PropTypes.object,
    channelData: PropTypes.object,

    containerWidth: PropTypes.number,

    videoByIds: PropTypes.object,
    videoIdsByPageNumber: PropTypes.array,
    isVisible: PropTypes.bool,
    isRTL: PropTypes.bool,

    thumbnailSize: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),

    onSlideToPrev: PropTypes.func,
    onSlideToNext: PropTypes.func,
    onBeforeClick: PropTypes.func,
    onBeforePlayRequested: PropTypes.func,
    onPlayRequestedBi: PropTypes.func,

    currentVideosPageNumber: PropTypes.number,
  };

  UNSAFE_componentWillReceiveProps({ currentVideosPageNumber }) {
    if (currentVideosPageNumber !== this.props.currentVideosPageNumber) {
      this.props.logWidgetSystem('videoList.changePage.rendered', {
        previousEventName: 'videoList.changePage.requested',
      });
    }
  }

  componentDidUpdate() {
    this.props.logWidgetSystem('videoList.show.rendered', {
      previousEventName: 'videoList.show.requested',
    });
  }

  renderVideoItem = (videoId, videoAtPageIndex, isVisiblePage) => {
    const {
      currentSiteUser,
      channelData,
      videoByIds,
      thumbnailSize,
      OverlayActionsCallbacks,
      onBeforeClick,
      onBeforePlayRequested,
      onPlayRequestedBi,
    } = this.props;

    const videoItem = videoByIds[videoId];

    return (
      <OverlayActionsCallbacks
        channelId={channelData.id}
        videoId={videoItem.id}
        videoSource={videoItem.videoSource}
        onBeforeClick={onBeforeClick}
        onBeforePlayRequested={onBeforePlayRequested}
        onPlayRequestedBi={_.partial(onPlayRequestedBi, videoItem)}
        key={videoId}
      >
        <VideoThumbnailOverlay
          {...thumbnailSize}
          currentSiteUser={currentSiteUser}
          channelData={channelData}
          videoItem={videoItem}
          isContentFocusable={isVisiblePage}
        />
      </OverlayActionsCallbacks>
    );
  };

  hasPage(pageNum, next) {
    const {
      videoIdsByPageNumber,
      channelData: { videosCount },
    } = this.props;

    const requestedPage = pageNum + next;

    if (pageNum === 0 && next > 0) {
      const videosPassed = videoIdsByPageNumber[0]
        ? videoIdsByPageNumber[0].length * requestedPage
        : 0;
      return (
        Boolean(videoIdsByPageNumber[requestedPage]) ||
        videosPassed < videosCount
      );
    }

    return Boolean(videoIdsByPageNumber[requestedPage]);
  }

  get isPrevPageVisible() {
    const { currentVideosPageNumber } = this.props;
    return this.hasPage(currentVideosPageNumber, -1);
  }

  get isNextPageVisible() {
    const { currentVideosPageNumber } = this.props;
    return this.hasPage(currentVideosPageNumber, 1);
  }

  render() {
    const {
      currentVideosPageNumber,
      videoIdsByPageNumber,
      containerWidth,
      onSlideToPrev,
      onSlideToNext,
      isVisible,
      isRTL,
    } = this.props;

    return (
      <div styleName="container">
        <ContentSlider
          width={containerWidth}
          currentPageNumber={currentVideosPageNumber}
          isRTL={isRTL}
        >
          <PageList
            pageWidth={containerWidth}
            currentPageNumber={currentVideosPageNumber}
            itemsByPageNumber={videoIdsByPageNumber}
            renderItem={this.renderVideoItem}
          />
        </ContentSlider>
        {isVisible && (
          <Nav
            prevButtonClassName={styles['prev-button']}
            nextButtonClassName={styles['next-button']}
            onPrevClick={onSlideToPrev}
            onNextClick={onSlideToNext}
            prevButtonAriaLabel={i18n.t('widget.accessibility.prev-videos')}
            nextButtonAriaLabel={i18n.t('widget.accessibility.next-videos')}
            isPrevVisible={this.isPrevPageVisible}
            isNextVisible={this.isNextPageVisible}
            isRTL={isRTL}
          />
        )}
      </div>
    );
  }
}
