import _ from 'lodash';
import { createSelector } from 'reselect';
import { getVideosByIds } from './videos';

export const isVideoDownloadEnabled = video =>
  _.get(video, 'allowDownload', false);
export const isChannelDownloadEnabled = videos =>
  _.some(videos, isVideoDownloadEnabled);

const _isDownloadEnabled = createSelector(
  getVideosByIds,
  _.nthArg(1),
  (videos, videoId) =>
    videoId
      ? isVideoDownloadEnabled(videos[videoId])
      : isChannelDownloadEnabled(videos),
);

export const isDownloadEnabled = (state, videoId = null) =>
  _isDownloadEnabled(state, videoId);
