import _ from 'lodash';
import getQueryParams from 'shared/utils/get-query-params';

export const parseExperimentsFromUrl = siteUrl => {
  const queryParams = getQueryParams(siteUrl);

  const { experiments = '' } = queryParams;
  return _.reduce(
    experiments.split(','),
    function(acc, name) {
      if (name) {
        return { ...acc, [name]: 'true' };
      }
      return acc;
    },
    {},
  );
};
