import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ContainerQuery } from 'react-container-query';
import formatMessageWithPrice from 'shared/utils/format-message-with-price';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import {
  canShowMainItemTitle,
  isCompactMainActionsOnlyIcons,
} from 'shared/selectors/app-settings';

import { createGetPlayButtonConfig } from './ui-selectors/video-overlay/play/button-config';
import { createGetPaidAccessButtonConfig } from './ui-selectors/video-overlay/paid-access/button-config';
import { getOpacityStyles } from './ui-selectors/opacity-styles';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';

import Title from './partials/title';
import PaidAccessButton from './partials/paid-access-button';
import PlayButton from './partials/play-button';
import VideoCover from './partials/video-cover';
import LiveLabel from 'widget/containers/live-label/live-label';

import { withStyles } from 'shared/utils/withStyles';
import styles from './overlay.styl';

const containerQuery = {
  [styles['element-max-1899']]: {
    maxWidth: 1899,
    minWidth: 550,
  },
  [styles['element-max-549']]: {
    maxWidth: 549,
    minWidth: 450,
  },
  [styles['element-max-449']]: {
    maxWidth: 449,
    minWidth: 300,
  },
  [styles['element-max-299']]: {
    maxWidth: 299,
  },
};

@connect(() => {
  const getPlayButtonConfig = createGetPlayButtonConfig();
  const getPaidAccessButtonConfig = createGetPaidAccessButtonConfig();

  return (state, ownProps) => ({
    playButtonConfig: getPlayButtonConfig(state, ownProps),
    paidAccessButtonConfig: getPaidAccessButtonConfig(state, ownProps),
    canShowMainItemTitle: canShowMainItemTitle(state),
    isMainActionsOnlyIcons: isCompactMainActionsOnlyIcons(state),
    opacityStyles: getOpacityStyles(state),
    isPlayerModuleLoaded: state.modules.player,
  });
})
@withStyles(styles)
export default class VideoOverlay extends React.Component {
  static propTypes = {
    videoItem: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,

    opacityStyles: PropTypes.object.isRequired,
    canShowMainItemTitle: PropTypes.bool.isRequired,
    isMainActionsOnlyIcons: PropTypes.bool.isRequired,

    onPlayRequest: PropTypes.func.isRequired,
    onPlayMemberOnlyRequest: PropTypes.func.isRequired,
    onPlayWithoutPreviewRequest: PropTypes.func.isRequired,
    onSubscriptionRequest: PropTypes.func.isRequired,
    onPurchaseRequest: PropTypes.func.isRequired,

    className: PropTypes.string,
    channelData: PropTypes.object,
    currentSiteUser: PropTypes.object,
    playButtonConfig: PropTypes.object,
    paidAccessButtonConfig: PropTypes.object,

    withFallbackColor: PropTypes.bool,
    isContentFocusable: PropTypes.bool,

    isPlayerModuleLoaded: PropTypes.bool,
  };

  static defaultProps = {
    isContentFocusable: true,
    showChannelCover: false,
    onPlayRequest: _.noop,
    onPlayMemberOnlyRequest: _.noop,
    onPlayWithoutPreviewRequest: _.noop,
    onPurchaseRequest: _.noop,
    onSubscriptionRequest: _.noop,
  };

  canPlayVideo() {
    const { channelData, videoItem, currentSiteUser } = this.props;
    return canPlayFullOrPreview(channelData, videoItem, currentSiteUser);
  }

  handleClick = () => {
    const { onPlayRequest } = this.props;

    if (!this.canPlayVideo()) {
      return;
    }

    onPlayRequest();
  };

  renderTitle() {
    const { videoItem, canShowMainItemTitle } = this.props;

    if (!canShowMainItemTitle) {
      return null;
    }

    return (
      <div className={styles['title-block']}>
        <Title>{videoItem.title}</Title>
      </div>
    );
  }

  renderLiveLabel() {
    const { videoItem } = this.props;

    return (
      <LiveLabel
        itemType={videoItem.itemType}
        mediaInfo={videoItem.mediaInfo}
        videoTitle={videoItem.title}
        startTime={videoItem.dateStartLive}
        className={styles['live-label']}
      />
    );
  }

  renderPlayButton() {
    const {
      playButtonConfig,
      isContentFocusable,
      isMainActionsOnlyIcons,
      isPlayerModuleLoaded,
    } = this.props;

    if (!playButtonConfig || !isPlayerModuleLoaded) {
      return null;
    }

    return (
      <PlayButton
        onClick={playButtonConfig.callback}
        isFocusable={isContentFocusable}
        isIconOnly={isMainActionsOnlyIcons}
      >
        {i18n.t(playButtonConfig.translationData.props)}
      </PlayButton>
    );
  }

  renderPaidAccessButton() {
    const {
      paidAccessButtonConfig,
      isContentFocusable,
      isMainActionsOnlyIcons,
    } = this.props;

    if (!paidAccessButtonConfig) {
      return null;
    }

    return (
      <PaidAccessButton
        onClick={paidAccessButtonConfig.callback}
        isFocusable={isContentFocusable}
        isIconOnly={isMainActionsOnlyIcons}
      >
        {formatMessageWithPrice(
          paidAccessButtonConfig.translationData.props,
          paidAccessButtonConfig.translationData.priceModel,
        )}
      </PaidAccessButton>
    );
  }

  render() {
    const {
      videoItem,
      className,
      width,
      height,
      withFallbackColor,
      opacityStyles,
      isContentFocusable,
    } = this.props;

    if (!videoItem) {
      return null;
    }

    const dataHook = classnames('player-overlay', {
      'player-overlay-visible': isContentFocusable,
    });

    return (
      <ContainerQuery query={containerQuery}>
        {queryClasses => (
          <div
            data-hook={dataHook}
            className={classnames(styles.container, className, {
              [styles.playable]: this.canPlayVideo(),
              ...queryClasses,
            })}
            onClick={this.handleClick}
          >
            <VideoCover
              videoItem={videoItem}
              width={width}
              height={height}
              withFallbackColor={withFallbackColor}
            />
            <div className={styles.content} style={opacityStyles}>
              <div className={styles['top-content']}>
                {this.renderTitle()}
                {this.renderLiveLabel()}
                <div className={styles['buttons-wrapper']}>
                  {this.renderPlayButton()}
                  {this.renderPaidAccessButton()}
                </div>
              </div>
            </div>
          </div>
        )}
      </ContainerQuery>
    );
  }
}
