import _ from 'lodash';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import {
  getAvailabilityStatus,
  FULL_ACCESS_ACTION_NAMES,
  OWNERSHIP_STATUSES,
} from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import formatMessageWithPrice from '../format-message-with-price';
import formatMessageWithDate from '../format-message-with-date';

// FIXME: these translations looks redundant, it should be enough to have two separate
// key-map translations for cover and player
export const DEFAULT_TRANSLATIONS = {
  [FULL_ACCESS_ACTION_NAMES.BUY]: 'widget.overlay-buttons.buy',
  [FULL_ACCESS_ACTION_NAMES.RENT]: 'widget.overlay-buttons.rent',
  [FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT]: 'widget.overlay-buttons.buy-from',
};

const COVER_TRANSLATIONS_MAP = {
  [FULL_ACCESS_ACTION_NAMES.SUBSCRIBE]: 'widget.channel-actions.subscribe',
  [FULL_ACCESS_ACTION_NAMES.BUY]: 'widget.overlay-buttons.buy',
  [FULL_ACCESS_ACTION_NAMES.RENT]: 'widget.overlay-buttons.rent',
  [FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT]: 'widget.overlay-buttons.buy-from',
};

const PLAYER_TRANSLATIONS_MAP = {
  ...COVER_TRANSLATIONS_MAP,
  [OWNERSHIP_STATUSES.YOU_BOUGHT_THIS]: 'actions.purchased',
  [OWNERSHIP_STATUSES.YOU_RENTED_THIS]: 'actions.rented',
};

const getBuyRentText = ({
  channelData,
  videoItem,
  currentSiteUser,
  translations,
}) => {
  const status = getAvailabilityStatus({
    channelData,
    videoItem,
    currentSiteUser,
  });
  const translationString = translations[status];

  if (!translationString) {
    return null;
  }

  let priceModel;
  let rentModel;

  switch (status) {
    case FULL_ACCESS_ACTION_NAMES.SUBSCRIBE:
      priceModel = dealInfoHelpers.getSubscriptionPriceInfo(
        channelData.dealInfo,
      );
      return formatMessageWithPrice(translationString, priceModel);

    case FULL_ACCESS_ACTION_NAMES.BUY:
      priceModel = dealInfoHelpers.getSalePriceInfo(videoItem.dealInfo);
      return formatMessageWithPrice(translationString, priceModel);

    case FULL_ACCESS_ACTION_NAMES.RENT:
      priceModel = dealInfoHelpers.getRentPriceInfo(videoItem.dealInfo);
      return formatMessageWithPrice(translationString, priceModel);

    case FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT:
      priceModel = dealInfoHelpers.getMinPriceInfo(videoItem.dealInfo);
      return formatMessageWithPrice(translationString, priceModel);

    case OWNERSHIP_STATUSES.YOU_RENTED_THIS:
      rentModel = _.get(videoItem.dgsInfo, 'rent');
      return formatMessageWithDate(
        translationString,
        rentModel.expirationTs * 1000,
      );

    default:
      return i18n.t(translationString);
  }
};

export const getBuyRentTextForPlayer = ({
  channelData,
  videoItem,
  currentSiteUser,
}) => {
  const params = {
    channelData,
    videoItem,
    currentSiteUser,
    translations: PLAYER_TRANSLATIONS_MAP,
  };
  return getBuyRentText(params);
};
