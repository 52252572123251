import _ from 'lodash';

export const getChannelId = source => {
  const channelId = _.get(source, 'fonts.channelId');
  return channelId ? channelId.replace(/\'/g, '') : channelId;
};

export const getVideoId = source => {
  const videoId = _.get(source, 'fonts.videoId');
  return videoId ? videoId.replace(/\'/g, '') : videoId;
};
