import _ from 'lodash';
import { PAYMENT_TYPE } from 'shared/constants/payments';
import CLOSE_REASONS from 'full-screen-modals/constants/close-reasons';
import {
  openFullScreenOverlay,
  openMobileOverlay,
} from 'shared/worker/actions/openOverlay';

export { openFullScreenOverlay, openMobileOverlay };

/**
 * Opens full-screen overlay with channel subscription info
 * @param channelId
 * @param onCloseByUser
 */
export function openFullScreenSubscribeOverlay(
  channelId,
  onCloseByUser = _.noop,
) {
  return openFullScreenOverlay(
    `#/payment/${PAYMENT_TYPE.SUBSCRIPTION}/${channelId}`,
    reason => {
      if (reason === CLOSE_REASONS.CLOSED_BY_USER) {
        onCloseByUser();
      }
    },
  );
}

/**
 * Opens full-screen overlay with video purchase info
 * @param channelId
 * @param videoId
 */
export function openFullScreenPurchaseOverlay(channelId, videoId) {
  return openFullScreenOverlay(
    `#/payment/${PAYMENT_TYPE.PURCHASE}/${channelId}/${videoId}`,
  );
}

/**
 * Opens full-screen overlay with video rent info
 * @param channelId
 * @param videoId
 */
export function openFullScreenRentOverlay(channelId, videoId) {
  return openFullScreenOverlay(
    `#/payment/${PAYMENT_TYPE.RENT}/${channelId}/${videoId}`,
  );
}

/**
 * Opens full-screen overlay with video purchase-complete
 * @param {string} paymentType
 * @param {string} channelId
 * @param {string} videoId
 */
export function openFullScreenPaymentCompleteOverlay(
  paymentType,
  channelId,
  videoId,
) {
  return openFullScreenOverlay(
    `#/payment-complete/${paymentType}/${channelId}/${videoId}`,
  );
}

/**
 * Opens full-screen overlay with member only video info
 * @param channelId
 * @param videoId
 * @param onClose
 */
export function openFullScreenMemberOnlyOverlay(channelId, videoId) {
  return openFullScreenOverlay(
    `#/payment/${PAYMENT_TYPE.MEMBER_ONLY}/${channelId}/${videoId}`,
  );
}

/**
 * Opens full-screen overlay with channel info
 * @param channelId
 */
export function openFullScreenChannelOverlay(channelId) {
  return openFullScreenOverlay(`#/channel/${channelId}/info`);
}

export function openFullScreenShareOverlay(channelId, videoId) {
  const baseUrl = `#/channel/${channelId}/share/`;
  return openFullScreenOverlay(videoId ? `${baseUrl}${videoId}` : baseUrl);
}
