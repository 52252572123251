import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import PropTypes from 'prop-types';
import { VODCSSModules } from 'shared/utils/wix-connect';

import { withStyles } from 'shared/utils/withStyles';
import styles from './scrollbar-wrapper.styl';

@withStyles(styles)
@VODCSSModules(styles)
class ScrollbarWrapper extends React.Component {
  static propTypes = {
    scrollX: PropTypes.bool,
    scrollY: PropTypes.bool,
    onlyOnHover: PropTypes.bool,
    getRef: PropTypes.func,
  };

  static defaultProps = {
    scrollX: false,
    scrollY: true,
    onlyOnHover: false,
    getRef: _.noop,
  };

  getRef = node => {
    if (!node) {
      return;
    }

    this.props.getRef(node);
  };

  render() {
    const { onlyOnHover, scrollX, scrollY } = this.props;
    const props = _.pick(this.props, 'className', 'children');

    const classNames = classnames('scrollbar-wrapper', {
      'only-on-hover': onlyOnHover,
      'scroll-x': scrollX,
      'scroll-y': scrollY,
    });

    return <div {...props} ref={this.getRef} styleName={classNames} />;
  }
}

@VODCSSModules(styles)
export class BackOfficeScrollbarWrapper extends React.Component {
  render() {
    const props = _.omit(this.props, 'styles');

    return <ScrollbarWrapper {...props} styleName="backoffice" />;
  }
}

@VODCSSModules(styles)
export class WidgetScrollbarWrapper extends React.Component {
  render() {
    const props = _.omit(this.props, 'styles');

    return <ScrollbarWrapper {...props} styleName="widget" />;
  }
}

export const WidgetDarkScrollbarWrapper = props => (
  <ScrollbarWrapper {...props} className={styles['widget-dark']} />
);
