import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropdown from '@wix/wix-vod-shared/dist/src/widget/ui-components/dropdown/dropdown';

import { updateSearch } from 'widget/redux/client/actions/search';
import { getCategory } from 'shared/selectors/search';
import { getChannelCategoryOptions } from 'widget/selectors/categories';
import { resetPlaybackStatus } from 'widget/redux/client/actions/player/change-playback-status';
import { isActionBarPreview, isRTL } from 'shared/selectors/app-settings';

import SEARCH_QUERY_FIELDS from 'shared/constants/search-query-fields';
import { logBi, logWidgetSystem } from 'shared/worker/actions/bi';

import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { withStyles } from 'shared/utils/withStyles';
import styles from './categories-dropdown.styl';

@connect(
  state => ({
    isOpen: isActionBarPreview(state),
    categoryOptions: [
      {
        label: i18n.t('widget.categories.all-videos'),
        value: '',
      },
      ...getChannelCategoryOptions(state),
    ],
    selectedCategory: getCategory(state),
    isRTL: isRTL(state),
  }),
  { updateSearch, resetPlaybackStatus, logBi, logWidgetSystem },
)
@withStyles(styles)
export default class CategoriesDropdown extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    className: PropTypes.string,
    selectedCategory: PropTypes.string,
    updateSearch: PropTypes.func.isRequired,
    resetPlaybackStatus: PropTypes.func.isRequired,
    categoryOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        key: PropTypes.string,
      }),
    ).isRequired,
  };

  getOptionByValue = value => _.find(this.props.categoryOptions, { value });

  handleChange = option => {
    const { updateSearch, selectedCategory, resetPlaybackStatus } = this.props;

    if (selectedCategory !== option.value) {
      updateSearch({
        searchQuery: option.value,
        searchQueryField: SEARCH_QUERY_FIELDS.CATEGORY,
      });

      resetPlaybackStatus();
    }

    this.props.logWidgetSystem('videoList.searchByCategory.requested');
    this.props.logBi('widget.categories.selected');
  };

  handleDropdownToggle = isOpen => {
    if (isOpen) {
      this.props.logBi('widget.categories.clicked');
    }
  };

  render() {
    const {
      className,
      selectedCategory,
      categoryOptions,
      isOpen,
      isRTL,
    } = this.props;

    return (
      <div className={className} data-hook="categories-dropdown">
        <Dropdown
          options={categoryOptions}
          isOpen={isOpen}
          openerClassName={styles.dropdownContainer}
          valueClassName={classnames(styles.value, {
            [styles.pristine]: !selectedCategory,
          })}
          ariaLabel={i18n.t('widget.accessibility.select-category')}
          arrowClassName={styles.arrow}
          optionClassName={styles.option}
          popoverClassName={styles.popoverMenu}
          onChange={this.handleChange}
          selectedOption={this.getOptionByValue(selectedCategory)}
          onToggle={this.handleDropdownToggle}
          isRTL={isRTL}
        />
      </div>
    );
  }
}
