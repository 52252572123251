import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import StatisticLogger from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/loggers/statistic-logger';
import ErrorLogger from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/loggers/error-logger';
import PlaybackSuccessLogger from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/loggers/playback-success-logger';
import { logBi } from 'shared/worker/actions/bi';

@connect(
  null,
  { logBi },
)
class Loggers extends React.Component {
  static propTypes = {
    isAutoplayed: PropTypes.bool,
    isEmbed: PropTypes.bool,
    engine: PropTypes.object,
    eventEmitter: PropTypes.object,
    channelData: PropTypes.object,
    videoItem: PropTypes.object,
    isVideoPlaying: PropTypes.bool,
    isLive: PropTypes.bool,
  };

  static defaultProps = {
    isEmbed: false,
    isLive: false,
  };

  logPlaybackTry = data => {
    this.props.logBi('player-play-requested', {
      ...data,
      isAutoplay: this.props.isAutoplayed,
    });
  };

  logPlaybackSuccess = data => {
    this.props.logBi('player-play-done', {
      ...data,
      isAutoplay: this.props.isAutoplayed,
    });
  };

  logError = data => {
    this.props.logBi('player-error', data);
  };

  logStatistic = data => {
    this.props.logBi('player.debug.info', {
      ...data,
      isAutoplay: this.props.isAutoplayed,
    });
  };

  render() {
    const {
      engine,
      eventEmitter,
      videoItem,
      channelData,
      isVideoPlaying,
      isEmbed,
      isLive,
    } = this.props;

    return (
      <section>
        <StatisticLogger
          videoID={videoItem.baseItemId}
          isEmbed={isEmbed}
          isLive={isLive}
          channelID={channelData.id}
          engine={engine}
          logFunction={this.logStatistic}
          shouldSendBI={isVideoPlaying}
        />
        <PlaybackSuccessLogger
          videoID={videoItem.baseItemId}
          engine={engine}
          eventEmitter={eventEmitter}
          logTryFunction={this.logPlaybackTry}
          logSuccessFunction={this.logPlaybackSuccess}
          channelID={channelData.id}
        />
        <ErrorLogger
          engine={engine}
          eventEmitter={eventEmitter}
          logFunction={this.logError}
          videoID={videoItem.baseItemId}
          isLive={isLive}
        />
      </section>
    );
  }
}

export default Loggers;
