import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  isScheduledLive,
  isPendingLiveStream,
  isReadyLiveStream,
} from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/live';
import { getLocale, isRTL } from 'shared/selectors/app-settings';
import LiveLabel from '@wix/wix-vod-shared/dist/src/widget/ui-components/live-label/live-label';

import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import ReminderIcon from './bell.svg';

@connect((state, { itemType, startTime, mediaInfo }) => ({
  startTime,
  isScheduled: isScheduledLive({ itemType }) && Boolean(startTime),
  isPendingLiveStream: isPendingLiveStream({ itemType, mediaInfo }),
  isReadyLiveStream: isReadyLiveStream({ itemType, mediaInfo }),
  locale: getLocale(state),
  isRTL: isRTL(state),
}))
export default class LiveLabelContainer extends React.Component {
  static propTypes = {
    isPendingLiveStream: PropTypes.bool,
    isReadyLiveStream: PropTypes.bool,
    mediaInfo: PropTypes.object,
    locale: PropTypes.string,
    startTime: PropTypes.number,
    videoTitle: PropTypes.string,
    isRTL: PropTypes.bool,
  };

  render() {
    return (
      <LiveLabel
        {...this.props}
        liveText={i18n.t('shared.live')}
        pendingLiveText={i18n.t('shared.pending-live')}
        scheduledSoonAriaLabel={i18n.t(
          'widget.accessibility.scheduled-soon-label',
        )}
        scheduledAriaLabel={i18n.t('widget.accessibility.scheduled-label')}
        startsInAriaLabel={i18n.t('widget.accessibility.starts-in')}
        reminderAriaLabel={i18n.t('widget.accessibility.reminder')}
        reminderIcon={<ReminderIcon />}
      />
    );
  }
}
