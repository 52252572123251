import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  isCommentsOpen,
  shouldOpenCommentsOnLoad,
  hasNewComments,
  canShowComments,
} from 'widget/selectors/comments';
import {
  setCommentsOpen,
  setHasNewComments,
  setOpenCommentsOnLoad,
} from 'widget/redux/client/actions/comments.js';
import { isSingleVideo, getLocale, isRTL } from 'shared/selectors/app-settings';
import {
  getSiteUrl,
  getMetaSiteId,
} from 'widget/redux/client/hydrated-data/hydrated-data';
import { logBi } from 'shared/worker/actions/bi';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { getAuthToken } from 'api/pix-wix-proxy/annoto/get-auth-token';
import { requestLogin, logOutCurrentMember } from 'shared/utils/auth';
import { notForPreview } from 'shared/utils/not-for-preview';
import { storeForReload } from 'widget/utils/reload';
import { isInFullScreen } from 'shared/selectors/player';
import { setSavedTime } from 'widget/redux/client/actions/player/set-saved-time';
import {
  playVideo,
  pauseVideo,
} from 'widget/redux/client/actions/player/change-playback-status';

@connect(
  (state, ownProps) => {
    const videoItem = _.get(ownProps, 'videoItem');
    const channelData = _.get(ownProps, 'channelData');
    const currentSiteUser = getCurrentSiteUser(state);
    const metaSiteId = getMetaSiteId(state);

    return {
      isCommentsOpen: isCommentsOpen(state),
      isSingleVideo: isSingleVideo(state),
      shouldOpenCommentsOnLoad: shouldOpenCommentsOnLoad(state),
      hasNewComments: hasNewComments(state),
      locale: getLocale(state),
      siteUrl: getSiteUrl(state),
      currentSiteUser: getCurrentSiteUser(state),
      isInFullScreen: isInFullScreen(state),
      canShowComments: canShowComments(
        state,
        videoItem,
        channelData,
        currentSiteUser,
      ),
      savedTime: state.player.savedTime,
      isRTL: isRTL(state),
      metaSiteId,
    };
  },
  {
    setCommentsOpen,
    setHasNewComments,
    playVideo,
    pauseVideo,
    setSavedTime,
    setOpenCommentsOnLoad,
    logBi,
    requestLogin,
    logOutCurrentMember,
    storeForReload,
  },
)
export default class AnnotoHandlers extends React.Component {
  static propTypes = {
    player: PropTypes.object.isRequired,
    isCommentsOpen: PropTypes.bool.isRequired,
    setCommentsOpen: PropTypes.func.isRequired,
    setHasNewComments: PropTypes.func.isRequired,
    isSingleVideo: PropTypes.bool.isRequired,
    shouldOpenCommentsOnLoad: PropTypes.bool.isRequired,
    hasNewComments: PropTypes.bool.isRequired,
    channelData: PropTypes.object.isRequired,
    videoItem: PropTypes.object.isRequired,
    mobileMode: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    currentSiteUser: PropTypes.object,
    isInFullScreen: PropTypes.bool.isRequired,
    canShowComments: PropTypes.bool.isRequired,
    playVideo: PropTypes.func.isRequired,
    pauseVideo: PropTypes.func.isRequired,
    setSavedTime: PropTypes.func.isRequired,
    setOpenCommentsOnLoad: PropTypes.func.isRequired,
    savedTime: PropTypes.number,
    isRTL: PropTypes.bool,
    metaSiteId: PropTypes.string,
  };

  componentDidMount() {
    const {
      player,
      mobileMode,
      locale,
      siteUrl,
      currentSiteUser,
      isRTL,
    } = this.props;

    player.configureAnnoto({
      clientId: ANNOTO_CLIENT_ID,
      locale,
      ssoAuthRequestHandle: this.handleLoginRequest,
      logoutRequestHandle: this.handleLogoutRequest,
      mediaDetailsHandle: this.getMediaDetails,
      metadataChangeCallback: this.handleMetadataChange,
      uxEventCallback: this.handleUxEvent,
      mediaIdGetter: this.getVideoId,
      rtl: isRTL,
      deviceDetector: {
        isSmallScreen: () => mobileMode,
        isPhone: () => mobileMode,
        isTablet: () => mobileMode,
        isDesktop: () => !mobileMode,
      },
      originProvider: {
        getPageUrl: () => siteUrl,
      },
    });

    if (currentSiteUser) {
      this.setAnnotoUser(currentSiteUser);
    }

    this.updatePlayerTime();
    this.changeAnnotoState();
  }

  componentDidUpdate(prevProps) {
    const { isCommentsOpen, player, canShowComments, savedTime } = this.props;

    if (prevProps.canShowComments !== canShowComments) {
      this.changeAnnotoState();
    }

    if (isCommentsOpen && !prevProps.isCommentsOpen) {
      player.showAnnoto();
      this.logShownBi(true);
    }

    if (prevProps.savedTime !== savedTime) {
      this.updatePlayerTime();
    }
  }

  changeAnnotoState() {
    const { player, canShowComments } = this.props;

    if (canShowComments) {
      player.enableAnnoto();
    } else {
      player.disableAnnoto();
    }
  }

  //show/hide events
  handleUxEvent = ({ name }) => {
    const { setCommentsOpen } = this.props;

    if (name === 'widget:hide') {
      setCommentsOpen(false);
      this.logShownBi(false);
    }

    if (name === 'widget:show') {
      setCommentsOpen(true);
      this.logShownBi(true);
    }
  };

  logShownBi = isShown => {
    const { isSingleVideo, videoItem, channelData } = this.props;
    this.props.logBi('widget.vid.showComment.shown', {
      isShown,
      isSingleVideo,
      channelID: channelData.id,
      videoID: videoItem.id,
      source: videoItem.videoSource,
    });
  };

  // metadata update contains info about unread messages
  handleMetadataChange = ({ comments }) => {
    const {
      videoItem,
      channelData,
      isSingleVideo,
      shouldOpenCommentsOnLoad,
      setCommentsOpen,
      hasNewComments,
      setHasNewComments,
    } = this.props;

    const isNewCommentsAdded = comments.countDiff > 0;

    if (isNewCommentsAdded && !hasNewComments) {
      this.props.logBi('widget.vid.newComment.added', {
        isSingleVideo,
        channelID: channelData.id,
        videoID: videoItem.id,
        source: videoItem.videoSource,
      });
    }

    setHasNewComments(isNewCommentsAdded);

    if (shouldOpenCommentsOnLoad) {
      setCommentsOpen(true);
    }
  };

  //used to uniquely identify video in Annoto
  getVideoId = () => this.props.videoItem.id;

  getMediaDetails = () => {
    const { videoItem, channelData } = this.props;

    return {
      title: _.get(videoItem, 'title', ''),
      description: _.get(videoItem, 'description', ''),
      group: {
        id: channelData.id,
        title: _.get(channelData, 'title', ''),
        description: _.get(channelData, 'description', ''),
      },
    };
  };

  async setAnnotoUser(currentSiteUser) {
    const { player, metaSiteId } = this.props;
    const token = await getAuthToken(currentSiteUser.id, metaSiteId);

    player.setAnnotoAuthToken(token);
  }

  handleLoginRequest = () => {
    this.makeLoginRequest();
    return Promise.resolve();
  };

  makeLoginRequest = notForPreview(() => {
    const {
      player,
      currentSiteUser,
      isSingleVideo,
      videoItem,
      channelData,
      isInFullScreen,
    } = this.props;

    if (currentSiteUser) {
      this.setAnnotoUser(currentSiteUser);
    } else {
      this.savePlayerTime();

      if (isInFullScreen) {
        player.exitFullScreen();
      }
      this.props.requestLogin();
    }

    this.props.logBi('widget.vid.commentsLogin.requested', {
      isSingleVideo,
      channelID: channelData.id,
      videoID: videoItem.id,
      source: videoItem.videoSource,
    });
  });

  handleLogoutRequest = () => {
    this.savePlayerTime();
    this.props.logOutCurrentMember();
    return Promise.resolve();
  };

  savePlayerTime() {
    const { player } = this.props;

    this.props.storeForReload({
      savedPlayerTime: player.getCurrentTime(),
    });
  }

  updatePlayerTime() {
    const {
      player,
      pauseVideo,
      playVideo,
      savedTime,
      videoItem,
      setSavedTime,
      setOpenCommentsOnLoad,
    } = this.props;

    if (!player || !savedTime) {
      return;
    }

    // restoring playback to where user stopped before login from Annoto
    player.seekTo(savedTime);
    setOpenCommentsOnLoad(true);
    setSavedTime(0);
    playVideo(videoItem.id);
    pauseVideo(videoItem.id);
  }

  render() {
    return null;
  }
}
