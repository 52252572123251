import _ from 'lodash';
import { APP_SETTINGS_VERSION_LATEST } from '@wix/wix-vod-constants/dist/app-settings/versions';
import { isDemoChannel } from 'shared/utils/channel-helpers';
import { getChannelId } from 'widget/utils/app-settings';

export const isLatestVersion = appSettings =>
  _.get(appSettings, 'numbers.settingsVersion') === APP_SETTINGS_VERSION_LATEST;

export const isNewInstallation = appSettings => {
  const channelId = getChannelId(appSettings);
  return !channelId || isDemoChannel(channelId);
};
