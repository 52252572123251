import _ from 'lodash';
import { createAction } from 'redux-actions';
import { getSearchQuery, getSearchQueryField } from 'shared/selectors/search';
import {
  isSingleVideo,
  isStripLayout,
  isVideoListAlwaysShow,
} from 'shared/selectors/app-settings';
import {
  getVideosPerPageCount,
  getCursor,
  getVideoIdsByPageNumber,
  getVideoIds,
  getCurrentPageIndex,
} from 'widget/redux/client/lazy-channel-videos/selectors';
import { listPublicChannelVideos } from 'shared/redux/server/actions/channel/videos/list-public';
import { createProxy, createAsyncProxy } from 'shared/worker/lib';
import { getCurrentChannelId } from 'widget/selectors/channel-info';

export const RESET_LAZY_CHANNEL_VIDEOS = 'CLIENT.LAZY_CHANNEL_VIDEOS.RESET';
export const SET_LAZY_CHANNEL_VIDEOS_PAGE_INDEX =
  'CLIENT.LAZY_CHANNEL_VIDEOS.PAGE_INDEX.SET';
export const SET_LAZY_CHANNEL_VIDEOS_VIDEO_INDEX =
  'CLIENT.LAZY_CHANNEL_VIDEOS.VIDEO_INDEX.SET';
export const PREPEND_LAZY_VIDEO_IDS =
  'CLIENT.LAZY_CHANNEL_VIDEOS.PREPEND_VIDEO_IDS';

export const setLazyChannelVideosPageIndex = createAction(
  SET_LAZY_CHANNEL_VIDEOS_PAGE_INDEX,
);
export const setLazyChannelVideosVideoIndex = createAction(
  SET_LAZY_CHANNEL_VIDEOS_VIDEO_INDEX,
);
export const resetLazyChannelVideos = createAction(RESET_LAZY_CHANNEL_VIDEOS);
export const prependVideoIds = createAction(PREPEND_LAZY_VIDEO_IDS);

const PAGES_PER_REQUEST_COUNT = 1;

export const loadMore = createAsyncProxy(
  'loadMore',
  (loadMoreNumber, { category, tag, query } = {}) => (
    dispatch,
    getState,
    { storage },
  ) => {
    const state = getState();
    const searchParams = _.omitBy({ query, tag, category }, _.isEmpty);

    storage.loadingPromise = (storage.loadingPromise || Promise.resolve()).then(
      () => {
        const cursor = getCursor(state);

        if (cursor && storage.lastCursor === cursor) {
          return Promise.resolve();
        }

        storage.lastCursor = cursor;

        return dispatch(
          listPublicChannelVideos(getCurrentChannelId(state), {
            paging: {
              size: loadMoreNumber,
              cursor,
            },
            ...searchParams,
          }),
        );
      },
    );

    return storage.loadingPromise;
  },
);

export const loadMoreVideoPages = createAsyncProxy(
  'loadMoreVideoPages',
  (pagesCount = PAGES_PER_REQUEST_COUNT) => (dispatch, getState) => {
    const state = getState();

    if (isSingleVideo(state)) {
      return Promise.resolve();
    }

    const searchQuery = getSearchQuery(state);
    const searchQueryField = getSearchQueryField(state);
    const videosPerPage = getVideosPerPageCount(state);

    if (isStripLayout(state) && isVideoListAlwaysShow(state)) {
      pagesCount = 2;
    }

    return dispatch(
      loadMore(videosPerPage * pagesCount, { [searchQueryField]: searchQuery }),
    );
  },
);

export const goToLazyVideosPageIndex = createProxy(
  'goToLazyVideosPageIndex',
  pageIndex => (dispatch, getState) => {
    const state = getState();
    const videoIdsByPageNumber = getVideoIdsByPageNumber(state);
    const cursor = getCursor(state);

    if (videoIdsByPageNumber.length === pageIndex + 1 && cursor) {
      dispatch(loadMoreVideoPages());
    }

    dispatch(setLazyChannelVideosPageIndex(pageIndex));
  },
);

export const loadMoreVideosForMobileVertical = createProxy(
  'loadMoreVideosForMobileVertical',
  () => (dispatch, getState) => {
    const state = getState();
    const cursor = getCursor(state);
    const currentPageIndex = getCurrentPageIndex(state);

    if (cursor) {
      dispatch(loadMoreVideoPages(1));
    }

    dispatch(setLazyChannelVideosPageIndex(currentPageIndex + 1));
  },
);

export const goToLazyVideoIndex = videoIndex => (dispatch, getState) => {
  const state = getState();
  const videoIds = getVideoIds(state);
  const cursor = getCursor(state);

  if (videoIds.length === videoIndex + 1 && cursor) {
    dispatch(loadMoreVideoPages());
  }

  dispatch(setLazyChannelVideosVideoIndex(videoIndex));
};
