import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { getWidth } from 'shared/utils/node';

export default class Measure extends React.Component {
  static propTypes = {
    shouldMeasure: PropTypes.bool,
    onMeasure: PropTypes.func,
    children: PropTypes.element,
  };

  static defaultProps = {
    shouldMeasure: true,
    onMeasure: _.noop,
  };

  constructor(props) {
    super(props);
    this._node = null;
  }

  componentDidMount() {
    this.measure();
  }

  componentDidUpdate() {
    if (this.props.shouldMeasure) {
      this.measure();
    }
  }

  measure() {
    if (!this.props.shouldMeasure) {
      return;
    }

    this.props.onMeasure({ width: getWidth(this._node) });
  }

  render() {
    const { children } = this.props;
    return React.cloneElement(React.Children.only(children), {
      ref: ref => (this._node = ref),
    });
  }
}
