import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import Tooltip from 'shared/components/tooltip/tooltip';
import { sides } from 'shared/constants/popout';

import AccountInfo from '../account-info/account-info';

import { withStyles } from 'shared/utils/withStyles';
import styles from './account-button-with-tooltip.styl';

@withStyles(styles)
export default class AccountButtonWithTooltip extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    popoutClassName: PropTypes.string,
    popoutTriangleClassName: PropTypes.string,
    logoutLabel: PropTypes.string,
    userEmail: PropTypes.string,
    tooltipSide: PropTypes.oneOf(_.values(sides)),

    isFocusable: PropTypes.bool,
    onLogoutClick: PropTypes.func,
  };

  static defaultProps = {
    tooltipSide: sides.TOP,
  };

  contentRef = null;

  saveContentRef = ref => {
    this.contentRef = ref;
  };

  focusContent = () => {
    // need to wait for popup rendering to focus
    // ref callback can`t be used because content will render once for popup
    const interval = setInterval(() => {
      if (this.contentRef) {
        focus(this.contentRef);
        clearInterval(interval);
      }
    }, 200);
  };

  render() {
    const {
      logoutLabel,
      userEmail,
      onLogoutClick,
      className,
      tooltipSide,
      popoutClassName,
      popoutTriangleClassName,
      isFocusable,
    } = this.props;

    return (
      <Tooltip
        preventHideOnHover
        popoutSide={tooltipSide}
        dataHook="channel-account-info"
        iconName="avatar"
        iconAriaLabel={i18n.t('widget.accessibility.my-account')}
        isButtonFocusable={isFocusable}
        className={classnames(styles['account-info-button'], className)}
        popoutClassName={classnames(styles.popout, popoutClassName)}
        popoutInnerClassName={styles['popout-inner']}
        popoutTriangleClassName={classnames(
          styles['popout-triangle'],
          popoutTriangleClassName,
        )}
        onTooltipShow={this.focusContent}
      >
        <section ref={this.saveContentRef}>
          <AccountInfo
            logoutLabel={logoutLabel}
            userEmail={userEmail}
            onLogoutClick={onLogoutClick}
          />
        </section>
      </Tooltip>
    );
  }
}
