import _ from 'lodash';
import { handleActions } from 'redux-actions';

import { NAMES as CHANNEL_PAYMENT } from 'widget/redux/server/actions/channel/payment.js';

const PATH_TO_CHANNELS = 'response.data.entities.channels';
const defaultState = {};

export default handleActions(
  {
    [CHANNEL_PAYMENT.SUCCESS]: (state, { payload }) => {
      const channels = _.get(payload, PATH_TO_CHANNELS);
      const channelPaymentData = _.values(channels)[0];

      return channelPaymentData || {};
    },
  },
  defaultState,
);
