import { createWorkerAction, createProxy } from '../lib';
import userTransactions from 'shared/bi/user-transactions';

export const logBi = createWorkerAction('LOG_BI');

export const logWidgetSystem = createProxy(
  'bi.logWidgetSystem',
  (eventName, { previousEventName } = {}) => dispatch => {
    if (userTransactions.isTransaction({ eventName, previousEventName })) {
      const shouldSendEvent = userTransactions.getTransactionResult({
        eventName,
      });
      if (!shouldSendEvent) {
        return;
      }
    }

    dispatch(
      logBi('widget-system', {
        eventName,
      }),
    );
  },
);
