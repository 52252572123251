import _ from 'lodash';

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { VIDEO_CARDS_BI_ORIGIN } from '@wix/wix-vod-constants/dist/bi/video-cards-origin';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { isOwned } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/pricing';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import Spinner from '@wix/wix-vod-shared/dist/src/widget/ui-components/loading-spinner/loading-spinner';
import {
  canAccessFullVideo,
  canPlayFullOrPreview,
  getOwnershipStatus,
  isDownloadAllowed,
  getFullAccessActionName,
  FULL_ACCESS_ACTION_NAMES,
} from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import {
  isRTL,
  areElementsRounded,
  isCommentsEnabled,
  isCommentsEnabledForLive,
  isSingleVideo,
} from 'shared/selectors/app-settings';
import { loadVODPlayer } from 'shared/utils/load-player';
import ActionsPanel from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/actions-panel/actions-panel';
import AllVideoCards from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/video-cards/all-cards/all-cards';
import VideoCards from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/video-cards/video-cards';
import { makeCancelable } from '@wix/wix-vod-shared/dist/src/widget/utils/make-cancelable-promise';
import { getDownloadUrl } from '@wix/wix-vod-api/dist/src/public/video/video';

import { logBi } from 'shared/worker/actions/bi';

import { getInstance } from 'widget/redux/client/hydrated-data/hydrated-data';
import { isDownloadEnabled } from 'shared/selectors/downloads';
import {
  isInFullScreen,
  isAutoplayed,
  isPlayerUIVisible,
  getOverlayContent,
} from 'shared/selectors/player';
import { getBuyRentTextForPlayer } from 'shared/utils/buy-button-texts/buy-button-texts';
import getPlayerTranslations from 'shared/utils/get-player-translations';
import getVidiUrls from 'shared/selectors/vidi-urls';
import { notForPreview } from 'shared/utils/not-for-preview';

import ShareOverlay from 'widget/containers/share-overlay/share-overlay';
import videoCardsBiLogger from 'widget/player-widget/components/new-player/bi-loggers/video-cards-bi-logger';
import {
  pauseVideo,
  playVideo,
  resetPlaybackStatus,
} from 'widget/redux/client/actions/player/change-playback-status';
import { setSavedTime } from 'widget/redux/client/actions/player/set-saved-time';
import {
  hidePlayerUI,
  showPlayerUI,
} from 'widget/redux/client/actions/player/ui';
import { setOverlayContent } from 'widget/redux/client/actions/player/set-overlay-content';
import { toggleShareVideoOverlay } from 'widget/redux/client/actions/share-overlay';
import { canShowComments, hasNewComments } from 'widget/selectors/comments';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { isShareOverlayOpen } from 'widget/selectors/share-overlay';
import { getVideoCardsList } from 'widget/selectors/video-cards';
import { setCommentsOpen } from 'widget/redux/client/actions/comments.js';

import VideoCardsWrapper from './components/video-cards-wrapper-desktop/video-cards-wrapper-desktop';
import AnnotoHandlers from './components/annoto-handlers/annoto-handlers';

import AnimatedOverlayContent from './containers/animated-overlay-content/animated-overlay-content';
import INFO_OVERLAY_CONTENT from './containers/animated-overlay-content/info-overlay-content-enums';
import { LiveVideoUpdaters } from './containers/live-video-updaters/live-video-updaters';
import Loggers from './containers/loggers/loggers';
import PlayerUIVisibilityManager from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/ui-visibility-manager/ui-visibility-manager';
import PlayableFullScreenManager from 'playable/dist/src/modules/full-screen-manager/full-screen-manager';
import * as viewModeSelectors from 'widget/selectors/view-mode';

const {
  logAllCardsButtonClick,
  logAllCardsCloseButtonClick,
} = videoCardsBiLogger;

class FullScreenManager extends PlayableFullScreenManager {
  enterFullScreen = notForPreview(() => {
    super.enterFullScreen();
  });
}

@connect(
  (state, ownProps) => {
    const cardsData = getVideoCardsList(state);
    const videoItem = _.get(ownProps, 'videoItem');
    const channelData = _.get(ownProps, 'channelData');
    const currentSiteUser = getCurrentSiteUser(state);
    return {
      vidiUrls: getVidiUrls(ownProps),
      cardsData,
      canShowAllCards: !_.isEmpty(cardsData),
      canShowComments: canShowComments(
        state,
        videoItem,
        channelData,
        currentSiteUser,
      ),
      isEditorViewMode: viewModeSelectors.isEditorMode(state),
      isSiteViewMode: viewModeSelectors.isSiteMode(state),
      isPlayerUIVisible: isPlayerUIVisible(state),
      isCommentsEnabled:
        isCommentsEnabled(state) || isCommentsEnabledForLive(state),
      areElementsRounded: areElementsRounded(state),
      isAutoplayed: isAutoplayed(state),
      isInFullScreen: isInFullScreen(state),
      isShareOverlayOpen: isShareOverlayOpen(state),
      isLive: isLiveVideo(videoItem),
      canAccessFullVideo: canAccessFullVideo(
        channelData,
        videoItem,
        currentSiteUser,
      ),
      isDownloadAllowed: isDownloadAllowed(
        channelData,
        videoItem,
        currentSiteUser,
      ),
      isPlayableVideo: canPlayFullOrPreview(
        channelData,
        videoItem,
        currentSiteUser,
      ),
      currentSiteUser,
      isSingleVideo: isSingleVideo(state),
      canDownloadVideo: isDownloadEnabled(state, videoItem.id),
      instance: getInstance(state),
      hasNewComments: hasNewComments(state),
      overlayContent: getOverlayContent(state),
      isRTL: isRTL(state),
    };
  },
  {
    toggleShareVideoOverlay,
    showPlayerUI,
    hidePlayerUI,
    playVideo,
    pauseVideo,
    resetPlaybackStatus,
    setSavedTime,
    setCommentsOpen,
    setOverlayContent,
    logBi,
    logAllCardsButtonClick,
    logAllCardsCloseButtonClick,
  },
)
export default class InternalPlayer extends React.Component {
  static propTypes = {
    appSettings: PropTypes.object,
    channelData: PropTypes.object,
    videoItem: PropTypes.object,
    instance: PropTypes.string,

    vidiUrls: PropTypes.array,
    className: PropTypes.string,
    mobileMode: PropTypes.bool,
    playAtFullScreen: PropTypes.bool,
    playInline: PropTypes.bool,

    width: PropTypes.number,
    height: PropTypes.number,

    preload: PropTypes.string,

    onPlayRequest: PropTypes.func,
    isEditorViewMode: PropTypes.bool.isRequired,
    isSiteViewMode: PropTypes.bool.isRequired,
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onEnded: PropTypes.func,

    onError: PropTypes.func,
    onSubscribeRequest: PropTypes.func,
    onPurchaseRequest: PropTypes.func,
    onRentRequest: PropTypes.func,
    onFullScreenChanged: PropTypes.func,
    onShareRequest: PropTypes.func,
    onVideoInfoShow: PropTypes.func,
    downloadVideo: PropTypes.func,
    onVolumeChange: PropTypes.func,
    onMuteChange: PropTypes.func,

    isAutoplayed: PropTypes.bool,
    isVideoPlayRequested: PropTypes.bool,
    isVideoPauseRequested: PropTypes.bool,
    isVideoPlaying: PropTypes.bool,
    isVideoPaused: PropTypes.bool,
    isShareOverlayOpen: PropTypes.bool,
    isLive: PropTypes.bool,
    isInFullScreen: PropTypes.bool,
    isPlayableVideo: PropTypes.bool,
    muted: PropTypes.bool,
    canAccessFullVideo: PropTypes.bool,
    isDownloadAllowed: PropTypes.bool,

    cardsData: PropTypes.array,

    // actions panel config
    areElementsRounded: PropTypes.bool,
    canShowFullInfo: PropTypes.bool,
    canShareVideo: PropTypes.bool,
    canShowAllCards: PropTypes.bool,
    canShowTitle: PropTypes.bool,
    canShowBuyRentButton: PropTypes.bool,
    canShowComments: PropTypes.bool,
    isCommentsEnabled: PropTypes.bool,
    canDownloadVideo: PropTypes.bool,

    toggleShareVideoOverlay: PropTypes.func,
    showPlayerUI: PropTypes.func,
    hidePlayerUI: PropTypes.func,
    playVideo: PropTypes.func,
    pauseVideo: PropTypes.func,
    resetPlaybackStatus: PropTypes.func,
    abortPlayVideo: PropTypes.func,
    currentSiteUser: PropTypes.object,
    isInLightbox: PropTypes.bool,
    isSingleVideo: PropTypes.bool,
    setCommentsOpen: PropTypes.func,
    hasNewComments: PropTypes.bool,
    setOverlayContent: PropTypes.func,
    overlayContent: PropTypes.string,
    isRTL: PropTypes.bool,
  };

  static defaultProps = {
    onPlay: _.noop,
    onPause: _.noop,
    onEnded: _.noop,

    onError: _.noop,
    onFullScreenChanged: _.noop,

    onShareRequest: _.noop,
    onVideoInfoShow: _.noop,
    onVolumeChange: _.noop,

    width: 640,
    height: 360,

    preload: 'none',

    onPlayRequest: _.noop,
    canShowTitle: true,
    canShowBuyRentButton: true,
    isPlayableVideo: true,
    muted: false,
  };

  state = {
    VideoPlayerWrapper: null,
    VODplayer: null,
    vidiUrls: null,
  };

  componentDidMount() {
    this.loadPlayerPromise = makeCancelable(loadVODPlayer());
    this.loadPlayerPromise.promise
      .then(VODplayerModule => {
        this.setState({ VODplayer: VODplayerModule.default });
      })
      .catch(e => {
        if (e.isCanceled) {
          return;
        }
        return Promise.reject(e);
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.state.vidiUrls ||
      !_.isEqual(this.props.vidiUrls, nextProps.vidiUrls)
    ) {
      this.setState({
        vidiUrls: nextProps.vidiUrls,
      });
    }

    this.shouldCloseOverlay(nextProps);
  }

  componentWillUnmount() {
    this.loadPlayerPromise.cancel();
    this.isUnmounted = true;
  }

  shouldCloseOverlay(nextProps) {
    const {
      videoItem: { id },
      isVideoPlaying,
      isShareOverlayOpen,
    } = nextProps;

    const isPlayStarted = isVideoPlaying && !this.props.isVideoPlaying;
    const isVideoIdChanged = this.props.videoItem.id !== id;
    const hasClosedOverlay =
      !isShareOverlayOpen && this.props.isShareOverlayOpen;

    if (isPlayStarted || isVideoIdChanged || hasClosedOverlay) {
      this.closeOpenOverlays();
    }
  }

  onEnded = (...args) => {
    this.closeOpenOverlays();
    this.props.onEnded(args);
  };

  onPlayerInit = player => {
    if (this.isUnmounted) {
      return;
    }

    this.setState({
      player,
      eventEmitter: player._defaultModules.eventEmitter,
      engine: player._defaultModules.engine,
    });
  };

  getBuyRentButtonText() {
    const { channelData, videoItem } = this.props;
    return getBuyRentTextForPlayer({ channelData, videoItem });
  }

  getVideoOwnershipStatus() {
    const { videoItem } = this.props;
    return getOwnershipStatus(videoItem);
  }

  reportAnomaly = () => {
    //getNewRelic();
  };

  handleOverlayClose = () => {
    if (this.props.overlayContent === INFO_OVERLAY_CONTENT.ALL_CARDS) {
      this.props.logAllCardsCloseButtonClick();
    }

    this.closeOpenOverlays();
  };

  closeOpenOverlays = () => {
    this.props.showPlayerUI();
    if (this.props.overlayContent) {
      this.props.setOverlayContent(null);
    }
  };

  openShareOverlay = () => {
    const { toggleShareVideoOverlay, hidePlayerUI } = this.props;
    hidePlayerUI();
    toggleShareVideoOverlay();
  };

  openVideoInfoOverlay = () => {
    this.props.hidePlayerUI();
    this.props.setOverlayContent(INFO_OVERLAY_CONTENT.VIDEO_INFO);
  };

  handleAllCardsButtonClick = () => {
    this.props.hidePlayerUI();
    this.props.setOverlayContent(INFO_OVERLAY_CONTENT.ALL_CARDS);

    this.props.logAllCardsButtonClick();
  };

  getBuyClickHandler = () => {
    const { videoItem, channelData } = this.props;

    const status = getFullAccessActionName(channelData, videoItem);

    switch (status) {
      case FULL_ACCESS_ACTION_NAMES.SUBSCRIBE:
        return this.proxySubscribeRequest;
      case FULL_ACCESS_ACTION_NAMES.RENT:
        return this.proxyRentRequest;
      default:
        return this.proxyPurchaseRequest;
    }
  };

  proxySubscribeRequest = () => {
    const { onSubscribeRequest } = this.props;
    onSubscribeRequest();
  };

  proxyPurchaseRequest = () => {
    const { onPurchaseRequest } = this.props;
    onPurchaseRequest();
  };

  proxyRentRequest = () => {
    const { onRentRequest } = this.props;
    onRentRequest();
  };

  onPlayAbort = () => {
    //todo add bi
    const {
      resetPlaybackStatus,
      abortPlayVideo,
      videoItem,
      isInLightbox,
      isLive,
    } = this.props;

    if (isInLightbox) {
      resetPlaybackStatus(videoItem.id);
    } else if (isLive) {
      abortPlayVideo(videoItem.id);
    }
  };

  handleDownloadVideo = () => {
    const { isLive, instance, videoItem, channelData } = this.props;
    this.props.logBi('widget.download.video.clicked', {
      channelId: channelData.id,
      videoId: videoItem.baseItemId,
      videoType: isOwned(videoItem) ? 'purchased' : 'free',
      isLive,
      duration: videoItem.durationSec * 1000,
    });

    const downloadUrl = getDownloadUrl(
      videoItem.itemId,
      channelData.id,
      instance,
    );
    window.open(downloadUrl, '_blank');
  };

  getFullScreenShareOverlay() {
    const { channelData, videoItem, mobileMode, isInFullScreen } = this.props;

    if (mobileMode) {
      return null;
    }

    if (!isInFullScreen) {
      return null;
    }

    return (
      <ShareOverlay
        key={`share-${videoItem.id}`}
        channelData={channelData}
        videoItem={videoItem}
      />
    );
  }

  getActionButtonLabels() {
    return {
      videoInfoFullText: i18n.t('widget.action-button-labels.video-info-full'),
      videoInfoShortText: i18n.t(
        'widget.action-button-labels.video-info-short',
      ),
      shareVideoFullText: i18n.t(
        'widget.action-button-labels.share-video-full',
      ),
      shareVideoShortText: i18n.t(
        'widget.action-button-labels.share-video-short',
      ),
      showAllCardsFullText: i18n.t(
        'widget.action-button-labels.show-all-cards-full',
      ),
      showAllCardsShortText: i18n.t(
        'widget.action-button-labels.show-all-cards-short',
      ),
      commentsFullText: i18n.t(
        'widget.action-button-labels.show-comments-full',
      ),
      commentsShortText: i18n.t(
        'widget.action-button-labels.show-comments-short',
      ),
    };
  }

  getVideoCardsAccessibilityLabels() {
    return {
      facebookButtonTitle: i18n.t(
        'widget.accessibility.action-cards.facebook-like-button',
      ),
      youtubeButtonTitle: i18n.t(
        'widget.accessibility.action-cards.youtube-subscribe-button',
      ),
      twitterButtonTitle: i18n.t(
        'widget.accessibility.action-cards.twitter-follow-button',
      ),
    };
  }

  handleCommentsButtonClick = () => {
    this.props.setCommentsOpen(true);
  };

  validateVideoElement() {
    // this is here to perform an action on video element on user action, so browser will allow further autoplay
    const { player } = this.state;

    if (this.isVideoElementValidated || !player || player._destroyed) {
      return;
    }

    const videoElement = player._defaultModules.engine.getElement();

    if (!videoElement) {
      return;
    }

    videoElement.load();
    this.isVideoElementValidated = true;
  }

  render() {
    const {
      // video player config
      mobileMode,
      width,
      height,
      isAutoplayed,
      isVideoPlayRequested,
      isVideoPlaying,
      isVideoPaused,
      isVideoPauseRequested,
      playAtFullScreen,
      playInline,
      onError,
      videoItem,
      channelData,
      onPlay,
      onPause,
      onFullScreenChanged,
      cardsData,
      preload,
      isLive,
      isPlayableVideo,
      isDownloadAllowed,
      canAccessFullVideo,
      canDownloadVideo,
      muted,
      overlayContent,
      onVolumeChange,
      onMuteChange,
      isRTL,

      // actions panel config
      areElementsRounded,
      canShowFullInfo,
      canShareVideo,
      canShowAllCards,
      canShowTitle,
      canShowBuyRentButton,
      canShowComments,
      isCommentsEnabled,
      hasNewComments,

      // animated content config
      onShareRequest,
      isEditorViewMode,
      isSiteViewMode,
    } = this.props;

    const vidiUrls = this.state.vidiUrls;

    const { engine, eventEmitter, player, VODplayer } = this.state;

    if (!isPlayableVideo) {
      return null;
    }
    if (!VODplayer) {
      return <Spinner />;
    }

    if (isVideoPlayRequested && _.isEmpty(vidiUrls)) {
      this.validateVideoElement();
    }

    const buyRentButtonText = this.getBuyRentButtonText();
    const prevButtonAriaLabel = i18n.t(
      'widget.accessibility.action-cards.all-cards.next-page',
    );
    const nextButtonAriaLabel = i18n.t(
      'widget.accessibility.action-cards.all-cards.previous-page',
    );
    const getSlideAriaLabel = variables =>
      i18n.t(
        'widget.accessibility.action-cards.all-cards.slide-contents',
        variables,
      );

    const isAnnotoModuleEnabled =
      (isCommentsEnabled && !mobileMode) || isEditorViewMode;

    return (
      <VODplayer
        width={width}
        height={height}
        preload={preload}
        mobileMode={mobileMode}
        vidiUrls={vidiUrls}
        videoItem={videoItem}
        channelData={channelData}
        isVideoPlayRequested={!_.isEmpty(vidiUrls) && isVideoPlayRequested}
        isVideoPauseRequested={isVideoPauseRequested}
        playAtFullScreen={playAtFullScreen}
        playInline={playInline}
        texts={getPlayerTranslations()}
        canShowTitle={canShowTitle}
        onPlay={onPlay}
        onPause={onPause}
        onError={onError}
        canDownload={
          canDownloadVideo && isDownloadAllowed && !mobileMode && !isLive
        }
        onDownloadClick={this.handleDownloadVideo}
        muted={muted}
        onPlayAbort={this.onPlayAbort}
        onEnded={this.onEnded}
        onAnomalyDetected={this.reportAnomaly}
        onFullScreenChanged={onFullScreenChanged}
        onInit={this.onPlayerInit}
        onMuteChange={onMuteChange}
        onVolumeChange={onVolumeChange}
        isAnnotoModuleEnabled={isAnnotoModuleEnabled}
        modules={{
          fullScreenManager: FullScreenManager,
        }}
        isRTL={isRTL}
      >
        {!mobileMode && (
          <ActionsPanel
            videoItem={videoItem}
            isFocusable={isVideoPlaying || isVideoPaused}
            areElementsRounded={areElementsRounded}
            canShowBuyRentButton={
              canShowBuyRentButton && Boolean(buyRentButtonText)
            }
            canShowTitle={canShowTitle}
            canShowAllCardsButton={canShowAllCards}
            canShowCommentsButton={canShowComments}
            shouldShowCommentsPimple={hasNewComments}
            canShowFullInfo={canShowFullInfo}
            canShareVideo={canShareVideo}
            onShareVideoShow={this.openShareOverlay}
            onBuyRentButtonClick={this.getBuyClickHandler()}
            onAllCardsButtonClick={this.handleAllCardsButtonClick}
            onCommentsButtonClick={this.handleCommentsButtonClick}
            videoOwnershipStatus={this.getVideoOwnershipStatus()}
            onVideoInfoShow={this.openVideoInfoOverlay}
            buyRentButtonText={buyRentButtonText}
            {...this.getActionButtonLabels()}
            infoButtonAriaLabel={i18n.t('widget.accessibility.show-video-info')}
            shareButtonAriaLabel={i18n.t('widget.accessibility.share')}
            allCardsButtonAriaLabel={i18n.t('widget.accessibility.all-cards')}
            commentsButtonAriaLabel={i18n.t('widget.accessibility.video-chat')}
            isRTL={isRTL}
          />
        )}

        {!mobileMode && (
          <AnimatedOverlayContent
            videoItem={videoItem}
            channelData={channelData}
            overlayContent={overlayContent}
            onShareRequest={onShareRequest}
            onClose={this.handleOverlayClose}
            allCardsOverlayContent={
              <VideoCardsWrapper
                thumbnailSize={300}
                biOrigin={VIDEO_CARDS_BI_ORIGIN.ALL_CARDS}
                cardsData={cardsData}
              >
                {({ cardsData, onCardClick }) => (
                  <AllVideoCards
                    cardsData={cardsData}
                    onCardClick={onCardClick}
                    getSlideAriaLabel={getSlideAriaLabel}
                    prevButtonAriaLabel={prevButtonAriaLabel}
                    nextButtonAriaLabel={nextButtonAriaLabel}
                    labels={this.getVideoCardsAccessibilityLabels()}
                  />
                )}
              </VideoCardsWrapper>
            }
          />
        )}

        {!mobileMode && player && !player._destroyed && (
          <VideoCardsWrapper
            cardsData={cardsData}
            player={player}
            biOrigin={VIDEO_CARDS_BI_ORIGIN.VIDEO}
          >
            {({ cardsData, onCardClick }) => (
              <VideoCards
                cardsData={cardsData}
                player={player}
                onCardClick={onCardClick}
                labels={this.getVideoCardsAccessibilityLabels()}
              />
            )}
          </VideoCardsWrapper>
        )}

        {!mobileMode && (
          <PlayerUIVisibilityManager
            isPlayerUIVisible={this.props.isPlayerUIVisible}
          />
        )}

        {player && !player._destroyed && (
          <Loggers
            engine={engine}
            eventEmitter={eventEmitter}
            videoItem={videoItem}
            channelData={channelData}
            isVideoPlaying={isVideoPlaying}
            isLive={isLive}
            isAutoplayed={isAutoplayed}
          />
        )}

        {player && !player._destroyed && isLive && (
          <LiveVideoUpdaters
            eventEmitter={eventEmitter}
            videoItem={videoItem}
            channelId={channelData.id}
            canAccessFullVideo={canAccessFullVideo}
            isSiteMode={isSiteViewMode}
          />
        )}

        {this.getFullScreenShareOverlay()}

        {player && isCommentsEnabled && !mobileMode && (
          <AnnotoHandlers
            player={player}
            videoItem={videoItem}
            channelData={channelData}
            mobileMode={mobileMode}
          />
        )}
      </VODplayer>
    );
  }
}
