import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from 'shared/components/button/button';

import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import { withStyles } from 'shared/utils/withStyles';
import styles from './account-info.styl';

@withStyles(styles)
export default class AccountInfo extends React.Component {
  static propTypes = {
    userEmail: PropTypes.string,
    logoutLabel: PropTypes.string,
    children: PropTypes.any,

    onLogoutClick: PropTypes.func,
  };

  render() {
    const { children, userEmail, logoutLabel, onLogoutClick } = this.props;

    return (
      <section
        aria-labelledby="account_btn_mobile_heading"
        className={styles.container}
      >
        <div
          className={`qa-account-button-user-email ${styles['user-email']}`}
          id="account_btn_mobile_heading"
          role="heading"
          aria-label={`${i18n.t('widget.account.signed-as')} ${userEmail}`}
        >
          {userEmail}
        </div>

        {children ? <div>{children}</div> : null}

        <div className={styles['logout-wrapper']} role="presentation">
          <LinkButton
            className={`qa-account-button-user-logout ${styles.logout}`}
            dataHook="account-info-logout-button"
            ariaLabel={logoutLabel}
            onClick={onLogoutClick}
          >
            {logoutLabel}
          </LinkButton>
        </div>
      </section>
    );
  }
}
