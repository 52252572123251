import {
  OPEN_SLIDE_MENU,
  OPEN_VIDEO_VIEW,
  OPEN_CHANNEL_VIEW,
} from './interactions';
import { createLoggerActions } from 'shared/worker/actions/fedopsLogger';

export function createBaseMobileLogger(loggerName) {
  const { appLoaded, createInteraction } = createLoggerActions(loggerName);
  return {
    appLoaded,
    openSlideMenu: createInteraction(OPEN_SLIDE_MENU),
    openVideoView: createInteraction(OPEN_VIDEO_VIEW),
    openChannelView: createInteraction(OPEN_CHANNEL_VIEW),
  };
}
