import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import classnames from 'classnames';

import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { logWidgetSystem } from 'shared/worker/actions/bi';

import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { isVideoPlayingOptimistic } from 'widget/selectors/video-playback-status';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import {
  getVideosInRowCount,
  isThumbnailsPreviewHover,
  isVideosListInfoAlwaysShown,
  isVideosListInfoShownOnHover,
  isVideosListInfoPositionBelow,
  isRTL,
} from 'shared/selectors/app-settings';
import { getSearchQuery } from 'shared/selectors/search';
import { getIsFetching } from 'widget/redux/client/lazy-channel-videos/selectors';
import ContentSlider from '@wix/wix-vod-shared/dist/src/widget/ui-components/content-slider/content-slider';
import PageList from '@wix/wix-vod-shared/dist/src/widget/ui-components/page-list/page-list';
import { NextButton, PrevButton } from './_nav-button/nav-button';
import VideoThumbnailOverlay from 'shared/components/video-thumbnail-overlay/video-thumbnail-overlay';

import { withStyles } from 'shared/utils/withStyles';
import styles from './videos.styl';

@connect(
  state => ({
    isFetching: getIsFetching(state),
    videoByIds: getVideosGroupedByIds(state),
    isVideoPlaying: isVideoPlayingOptimistic(state),
    selectedVideoId: getMainVideoId(state),
    videosInRowCount: getVideosInRowCount(state),
    searchQuery: getSearchQuery(state),
    currentSiteUser: getCurrentSiteUser(state),
    isThumbnailsPreviewHover: isThumbnailsPreviewHover(state),
    isInfoAlwaysShown: isVideosListInfoAlwaysShown(state),
    isInfoShownOnHover: isVideosListInfoShownOnHover(state),
    isDescriptionBelow: isVideosListInfoPositionBelow(state),
    isRTL: isRTL(state),
  }),
  { logWidgetSystem },
)
@withStyles(styles)
export default class Videos extends React.Component {
  static propTypes = {
    channelData: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    selectedVideoId: PropTypes.string.isRequired,
    isVideoPlaying: PropTypes.bool.isRequired,
    videoByIds: PropTypes.object.isRequired,
    videoIdsByPageNumber: PropTypes.array.isRequired,
    videosInRowCount: PropTypes.number.isRequired,
    videosPerPageCount: PropTypes.number.isRequired,
    currentVideosPageNumber: PropTypes.number.isRequired,

    containerWidth: PropTypes.number.isRequired,
    isSelectedVideoIndicationDisabled: PropTypes.bool.isRequired,
    isThumbnailsPreviewHover: PropTypes.bool.isRequired,
    isInfoAlwaysShown: PropTypes.bool.isRequired,
    isInfoShownOnHover: PropTypes.bool.isRequired,
    isDescriptionBelow: PropTypes.bool.isRequired,
    thumbnailSize: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,

    onSlideToPrev: PropTypes.func.isRequired,
    onSlideToNext: PropTypes.func.isRequired,
    onThumbnailClick: PropTypes.func.isRequired,
    onPlayRequest: PropTypes.func.isRequired,
    onPlayMemberOnlyRequest: PropTypes.func.isRequired,

    currentSiteUser: PropTypes.object,
    className: PropTypes.string,
    isRTL: PropTypes.bool,
  };

  UNSAFE_componentWillReceiveProps({ currentVideosPageNumber }) {
    if (currentVideosPageNumber !== this.props.currentVideosPageNumber) {
      this.props.logWidgetSystem('videoList.changePage.rendered', {
        previousEventName: 'videoList.changePage.requested',
      });
    }
  }

  componentDidUpdate() {
    this.props.logWidgetSystem('videoList.searchByQuery.rendered', {
      previousEventName: 'videoList.searchByQuery.requested',
    });
    this.props.logWidgetSystem('videoList.searchByTag.rendered', {
      previousEventName: 'videoList.searchByTag.requested',
    });
    this.props.logWidgetSystem('videoList.searchByCategory.rendered', {
      previousEventName: 'videoList.searchByCategory.requested',
    });
  }

  renderVideoItem = (videoId, videoAtPageIndex, isVisiblePage) => {
    const {
      channelData,
      videoByIds,
      thumbnailSize,
      onThumbnailClick,
      onPlayRequest,
      onPlayMemberOnlyRequest,
      selectedVideoId,
      isSelectedVideoIndicationDisabled,
      videosInRowCount,
      currentSiteUser,
      isThumbnailsPreviewHover,
      isInfoAlwaysShown,
      isDescriptionBelow,
      isInfoShownOnHover,
    } = this.props;

    const videoItem = videoByIds[videoId];
    const isVideoSelected =
      selectedVideoId === videoId && !isSelectedVideoIndicationDisabled;
    const isLastInRow = (videoAtPageIndex + 1) % videosInRowCount === 0;

    const classNames = classnames(styles['video-list-item'], {
      [styles['last-in-row']]: isLastInRow,
    });

    return (
      <VideoThumbnailOverlay
        {...thumbnailSize}
        key={videoId}
        className={classNames}
        currentSiteUser={currentSiteUser}
        channelData={channelData}
        videoItem={videoItem}
        isSelected={isVideoSelected}
        isContentFocusable={isVisiblePage}
        isThumbnailsPreviewHover={isThumbnailsPreviewHover}
        isInfoAlwaysShown={isInfoAlwaysShown}
        isDescriptionBelow={isDescriptionBelow}
        isInfoShownOnHover={isInfoShownOnHover}
        onPlayRequest={memoizedPartial(onPlayRequest, videoItem)}
        onPlayMemberOnlyRequest={memoizedPartial(
          onPlayMemberOnlyRequest,
          videoId,
        )}
        onClick={memoizedPartial(onThumbnailClick, videoItem)}
      />
    );
  };

  hasPage(pageNum) {
    const { videoIdsByPageNumber } = this.props;
    return Boolean(videoIdsByPageNumber[pageNum]);
  }

  get isPrevPageVisible() {
    const { currentVideosPageNumber } = this.props;
    return this.hasPage(currentVideosPageNumber - 1);
  }

  get isNextPageVisible() {
    const { currentVideosPageNumber } = this.props;
    return this.hasPage(currentVideosPageNumber + 1);
  }

  render() {
    const {
      currentVideosPageNumber,
      videoIdsByPageNumber,
      containerWidth,
      className,
      onSlideToNext,
      onSlideToPrev,
      isFetching,
      isRTL,
    } = this.props;

    return (
      <div className={className}>
        <ContentSlider
          className={styles['slider-container']}
          width={containerWidth}
          currentPageNumber={currentVideosPageNumber}
          isRTL={isRTL}
        >
          <PageList
            pageWidth={containerWidth}
            currentPageNumber={currentVideosPageNumber}
            itemsByPageNumber={videoIdsByPageNumber}
            renderItem={this.renderVideoItem}
            isRTL={isRTL}
          />
        </ContentSlider>
        {(this.isPrevPageVisible || this.isNextPageVisible || isFetching) && (
          <div className={styles.buttons}>
            <PrevButton
              disabled={!this.isPrevPageVisible}
              onClick={onSlideToPrev}
            />
            <NextButton
              disabled={!this.isNextPageVisible}
              onClick={onSlideToNext}
            />
          </div>
        )}
      </div>
    );
  }
}
