import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveResizedImage } from '@wix/wix-vod-shared/dist/src/widget/ui-components/responsive-resized-image/responsive-resized-image';
import ResizedImage from 'shared/components/resized-image/resized-image';

const _propTypes = {
  thumbnailUrl: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  isResponsive: PropTypes.bool,
};

const _defaultProps = {
  width: 320,
  height: 180,
};

class SimpleThumbnail extends React.Component {
  static propTypes = _propTypes;
  static defaultProps = _defaultProps;

  render() {
    const { thumbnailUrl, className, width, height, isResponsive } = this.props;

    if (isResponsive) {
      return (
        <ResponsiveResizedImage
          src={thumbnailUrl}
          className={className}
          withFallbackColor
          progressive
          animated
          width={width}
          height={height}
        />
      );
    }

    return (
      <ResizedImage
        src={thumbnailUrl}
        className={className}
        withFallbackColor
        width={width}
        height={height}
      />
    );
  }
}

export class SimpleVideoThumbnail extends React.Component {
  static propTypes = {
    video: PropTypes.object.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
  };
  static defaultProps = _defaultProps;

  render() {
    const {
      video: { coverUrl, customCoverUrl },
      width,
      height,
      className,
    } = this.props;

    return (
      <SimpleThumbnail
        thumbnailUrl={customCoverUrl || coverUrl}
        className={className}
        width={width}
        height={height}
      />
    );
  }
}

export class ResponsiveSimpleVideoThumbnail extends React.Component {
  static propTypes = {
    video: PropTypes.object.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
  };
  static defaultProps = _defaultProps;

  render() {
    const {
      video: { coverUrl, customCoverUrl },
      width,
      height,
      className,
    } = this.props;

    return (
      <SimpleThumbnail
        thumbnailUrl={customCoverUrl || coverUrl}
        className={className}
        width={width}
        height={height}
        isResponsive
      />
    );
  }
}
