import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import formatMessageWithPrice from 'shared/utils/format-message-with-price';
import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';

@connect((state, ownProps) => ({
  subscriptionPriceInfo: dealInfoHelpers.getSubscriptionPriceInfo(
    ownProps.dealInfo,
  ),
}))
export default class ChannelSubscriptionLabel extends React.Component {
  static propTypes = {
    subscriptionPriceInfo: PropTypes.object,
    dealInfo: PropTypes.array,
  };

  render() {
    const { subscriptionPriceInfo } = this.props;

    return formatMessageWithPrice(
      'widget.channel-actions.subscribe',
      subscriptionPriceInfo,
    );
  }
}
