import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getTitleText,
  getSubscribeButtonText,
  getPublisherText,
  getPlayButtonText,
  getPurchaseButtonText,
  getPlayNextButtonText,
  getOpacityStyle,
} from 'widget/components/player-overlay/selectors';
import { openSubscription } from 'widget/redux/client/player-overlay/actions/open-subscription';
import { openPlayerOverlay } from 'widget/redux/client/player-overlay/actions/open-player-overlay';
import { openPurchase } from 'widget/redux/client/player-overlay/actions/open-purchase';
import { playSelectedVideo } from 'widget/redux/client/player-overlay/actions/play-selected-video';
import ComingSoon from 'widget/components/player-overlay/partials/coming-soon';
import Button from 'widget/components/button/button';
import LiveLabel from 'widget/containers/live-label/live-label';

import { VODCSSModules } from 'shared/utils/wix-connect';
import Icon from 'shared/components/icon/icon';
import { hasVideos } from 'shared/utils/channel-helpers';

import Title from '../partials/title';

import classnames from 'classnames';
import { withStyles } from 'shared/utils/withStyles';
import styles from './classic.styl';

@connect(
  (state, ownProps) => ({
    titleText: getTitleText(state, ownProps),
    publisherText: getPublisherText(state, ownProps),
    subscribeButtonText: getSubscribeButtonText(state, ownProps),
    playButtonText: getPlayButtonText(state, ownProps),
    purchaseButtonText: getPurchaseButtonText(state, ownProps),
    playNextButtonText: getPlayNextButtonText(state, ownProps),
    opacityStyle: getOpacityStyle(state),
    isPlayerModuleLoaded: state.modules.player,
  }),
  {
    openSubscription,
    openPlayerOverlay,
    playSelectedVideo,
    openPurchase,
  },
)
@withStyles(styles)
@VODCSSModules(styles)
export default class ClassicPlayerOverlay extends React.Component {
  static propTypes = {
    titleText: PropTypes.node,
    publisherText: PropTypes.node,
    subscribeButtonText: PropTypes.node,
    playButtonText: PropTypes.node,
    purchaseButtonText: PropTypes.node,
    playNextButtonText: PropTypes.node,
    opacityStyle: PropTypes.object.isRequired,
    videoItem: PropTypes.object,
    channelData: PropTypes.object,
    className: PropTypes.string,
    openSubscription: PropTypes.func.isRequired,
    openPurchase: PropTypes.func.isRequired,
    openPlayerOverlay: PropTypes.func.isRequired,
    playSelectedVideo: PropTypes.func.isRequired,
    onPlaySelectedVideo: PropTypes.func,
    playNextVideo: PropTypes.func,
    saveRef: PropTypes.func,
    nextVideoId: PropTypes.string,
    isPlayerModuleLoaded: PropTypes.bool,
  };

  renderPublisher() {
    const { publisherText } = this.props;

    const styleName = classnames(
      styles.publisher,
      'qa-widget-overlay-publisher',
    );

    return (
      <div className={styleName} data-hook="overlay-publisher">
        <span>{publisherText}</span>
      </div>
    );
  }

  renderLiveLabel() {
    const { videoItem } = this.props;

    return (
      <LiveLabel
        itemType={videoItem.itemType}
        mediaInfo={videoItem.mediaInfo}
        videoTitle={videoItem.title}
        startTime={videoItem.dateStartLive}
        className={styles['live-label']}
      />
    );
  }

  renderPlayButton() {
    const { channelData, playButtonText, isPlayerModuleLoaded } = this.props;

    if (!playButtonText || !isPlayerModuleLoaded) {
      return null;
    }

    if (!hasVideos(channelData)) {
      return <ComingSoon className={styles['soon-info']} />;
    }

    return (
      <Button
        dataHook="overlay-play-button"
        styleName="button"
        onClick={this.handlePlaySelectedVideo}
      >
        <Icon styleName="play-icon" name="arrow-right-filled" />
        <span>{playButtonText}</span>
      </Button>
    );
  }

  handlePlayNextVideoClick = event => {
    event.preventDefault();
    event.stopPropagation();

    this.props.playNextVideo();
  };

  renderPlayNextButton() {
    const { playNextButtonText, isPlayerModuleLoaded } = this.props;

    if (!playNextButtonText || !isPlayerModuleLoaded) {
      return null;
    }

    return (
      <Button
        styleName="button"
        data-hook="overlay-next-video-button"
        onClick={this.handlePlayNextVideoClick}
      >
        {playNextButtonText}
      </Button>
    );
  }

  handleSubscriptionButtonClick = event => {
    event.preventDefault();
    event.stopPropagation();

    this.props.openSubscription();
  };

  renderSubscribeButton() {
    const { subscribeButtonText } = this.props;

    if (!subscribeButtonText) {
      return null;
    }

    return (
      <Button
        styleName="button"
        dataHook="subscribe-button"
        onClick={this.handleSubscriptionButtonClick}
      >
        {subscribeButtonText}
      </Button>
    );
  }

  handlePurchaseButtonClick = event => {
    event.preventDefault();
    event.stopPropagation();

    const { openPlayerOverlay, videoItem } = this.props;

    openPlayerOverlay(videoItem);
  };

  renderPurchaseButton() {
    const { purchaseButtonText } = this.props;

    if (!purchaseButtonText) {
      return null;
    }

    return (
      <Button
        styleName="button"
        dataHook="paid-access-button"
        onClick={this.handlePurchaseButtonClick}
      >
        {purchaseButtonText}
      </Button>
    );
  }

  renderButtons() {
    if (!this.props.videoItem) {
      return null;
    }

    return (
      <div styleName="buttons">
        {this.renderPlayButton()}
        {this.renderSubscribeButton() || this.renderPurchaseButton()}
        {this.renderPlayNextButton()}
      </div>
    );
  }

  handlePlaySelectedVideo = event => {
    event.preventDefault();
    event.stopPropagation();

    const { videoItem, playSelectedVideo, onPlaySelectedVideo } = this.props;
    playSelectedVideo({ videoItem, onPlaySelectedVideo });
  };

  render() {
    const {
      className,
      opacityStyle,
      publisherText,
      titleText,
      saveRef,
    } = this.props;

    return (
      <section className={styles['player-overlay-container']} ref={saveRef}>
        <div
          style={opacityStyle}
          styleName="overlay"
          className={className}
          data-hook="player-overlay player-overlay-visible"
          onClick={this.handlePlaySelectedVideo}
        >
          {titleText && (
            <div className={styles.title}>
              <Title text={titleText} />
            </div>
          )}

          {publisherText && this.renderPublisher()}

          {this.renderLiveLabel()}
          {this.renderButtons()}
        </div>
      </section>
    );
  }
}
