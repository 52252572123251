import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import decorate from 'widget/utils/decorate';
import { playVideo } from 'widget/redux/client/actions/player/change-playback-status';
import { selectVideo } from 'widget/redux/client/actions/select-video';

import InFrameActions from './in-frame';

@connect(
  null,
  {
    playVideo,
    selectVideo,
  },
)
@decorate(InFrameActions)
export default class InFrameMobileActionCallbacks extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    selectVideo: PropTypes.func.isRequired,
    playVideo: PropTypes.func.isRequired,
    onBeforeClick: PropTypes.func,
    onBeforePlayRequested: PropTypes.func,
    onPlayRequestedBi: PropTypes.func,
    channelId: PropTypes.string,
    videoId: PropTypes.string,
    videoItem: PropTypes.object,
    isMainVideo: PropTypes.bool,
  };

  selectVideo = () => {
    const { selectVideo, videoId } = this.props;
    selectVideo(videoId);
  };

  playVideo = () => {
    const { playVideo, videoId } = this.props;
    this.props.onPlayRequestedBi();
    playVideo(videoId);
  };

  handlePlayRequest = () => {
    const { isMainVideo, onBeforePlayRequested, onBeforeClick } = this.props;
    const handler = isMainVideo
      ? _.flow(
          onBeforePlayRequested,
          this.playVideo,
        )
      : _.flow(
          onBeforeClick,
          this.selectVideo,
        );

    handler();
  };

  render() {
    const { children } = this.props;

    return React.cloneElement(children, {
      ...this.props,
      onPlayRequest: this.handlePlayRequest,
    });
  }
}
