import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WixSDK from 'shared/utils/wix-sdk';
import { updateAppSettings } from 'widget/redux/client/actions/app-settings';
import { getChannelIdFromAppSettings } from 'shared/selectors/app-settings';
import { getChannelId } from 'widget/utils/app-settings';
import * as viewModeSelectors from 'widget/selectors/view-mode';

import WixEvents from 'shared/constants/wix-sdk';
import {
  withPubSubEvents,
  consumePubSubEvent,
} from 'widget/containers/pub-sub-events';

@withPubSubEvents
@connect(
  state => ({
    appSettings: state.appSettings,
    currentChannelId: getChannelIdFromAppSettings(state),
    isSiteMode: viewModeSelectors.isSiteMode(state),
  }),
  {
    updateAppSettings,
  },
)
export class AppSettingsObserver extends React.Component {
  static propTypes = {
    children: PropTypes.func,
    appSettings: PropTypes.object,
    isSiteMode: PropTypes.bool.isRequired,
    currentChannelId: PropTypes.string,
    updateAppSettings: PropTypes.func.isRequired,
  };

  handleEditModeChange = _.debounce(({ editMode }) => {
    this.props.updateAppSettings({ editMode });
  }, 100);

  handleStylesChange = _.debounce(styleParams => {
    const { currentChannelId, isSiteMode } = this.props;

    WixSDK.Styles.extendStylesWithInitialChannelId(styleParams).then(
      updatedStyles => {
        const isChanged = currentChannelId !== getChannelId(updatedStyles);

        if (isSiteMode) {
          return;
        }

        if (isChanged) {
          window.location.reload();
        } else {
          this.props.updateAppSettings(updatedStyles);
        }
      },
    );
  }, 100);

  componentDidUpdate(prevProps) {
    consumePubSubEvent(WixEvents.STYLE_PARAMS_CHANGE, this.handleStylesChange, {
      prevProps,
      nextProps: this.props,
    });
    consumePubSubEvent(WixEvents.EDIT_MODE_CHANGE, this.handleEditModeChange, {
      prevProps,
      nextProps: this.props,
    });
  }

  render() {
    const { children, appSettings } = this.props;

    if (children) {
      return children(appSettings);
    }

    return null;
  }
}
