import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import getResizedImageUrl from '@wix/wix-vod-shared/dist/src/common/utils/get-resized-image-url';

import { buildUrl } from '@wix/wix-vod-shared/dist/src/common/utils/url/build-url';
import socialNetworkNames, {
  shareMappingPath,
} from '@wix/wix-vod-shared/dist/src/widget/constants/social-networks';
import getUrlToWidget from '@wix/wix-vod-shared/dist/src/common/utils/get-url-to-widget';

export default function initSocialShare() {
  function getOrigin(siteUrl) {
    try {
      return new URL(siteUrl).origin;
    } catch (er) {
      const a = document.createElement('a');
      a.setAttribute('href', siteUrl);
      return `${a.protocol}//${a.host}`;
    }
  }

  function getShareProxyUrl(
    siteUrl,
    { instanceId, componentId, sitePageId, channelId, videoId, biToken },
  ) {
    const baseUrl = getOrigin(siteUrl);

    return buildUrl(`${baseUrl}/${shareMappingPath}/${sitePageId}`, {
      'instance-id': instanceId,
      'component-id': componentId,
      'channel-id': channelId,
      'video-id': videoId,
      'bi-token': biToken,
    });
  }

  return {
    facebook: {
      name: socialNetworkNames.FACEBOOK,
      getShareUrl(content, params) {
        const shareProxyUrl = getShareProxyUrl(content.siteUrl, params);

        return buildUrl('https://www.facebook.com/sharer/sharer.php', {
          ref: 'site',
          display: 'popup',
          u: shareProxyUrl,
        });
      },
    },

    twitter: {
      name: socialNetworkNames.TWITTER,
      getShareUrl(content, params) {
        const { siteUrl, title } = content;
        const shareProxyUrl = getShareProxyUrl(siteUrl, params);

        return buildUrl('https://twitter.com/intent/tweet', {
          original_referer: siteUrl,
          text: i18n.t('social-share.network.twitter', {
            title,
            siteUrl: shareProxyUrl,
          }),
        });
      },
    },

    pinterest: {
      name: socialNetworkNames.PINTEREST,
      getShareUrl(content, params) {
        const { siteUrl, coverUrl, title, description } = content;
        const shareProxyUrl = getShareProxyUrl(siteUrl, params);

        return buildUrl('https://www.pinterest.com/pin/create/button/', {
          url: shareProxyUrl,
          media: getResizedImageUrl({ url: coverUrl }),
          description: i18n.t('social-share.network.pinterest', {
            title,
            description,
          }),
        });
      },
    },

    tumblr: {
      name: socialNetworkNames.TUMBLR,
      getShareUrl(content, params) {
        const { title, siteUrl, coverUrl } = content;
        const shareProxyUrl = getShareProxyUrl(siteUrl, params);

        return buildUrl('https://www.tumblr.com/widgets/share/tool', {
          posttype: 'photo',
          // todo: need to find a way to resize image for tumblr
          content: getResizedImageUrl({ url: coverUrl }),
          caption: i18n.t('social-share.network.tumblr', { title, siteUrl }),
          url: shareProxyUrl,
        });
      },
    },

    link: {
      name: socialNetworkNames.LINK,
      getShareUrl(content, params) {
        return getUrlToWidget(content.siteUrl, params.componentId);
      },
    },
  };
}
