import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  isNavigationArrowsOutside,
  getStretchToFullWidth,
  canShowVideoListItemTitle,
  getVideoListPublisherVisibility,
  canShowVideoListItemDescription,
  canShowVideoListItemContentBelow,
  getUseOptimalFontSizes,
  getTitleFont,
  getTextFont,
} from 'shared/selectors/app-settings';

import { canShowActionBar } from 'widget/selectors/layout';

import { getSliderHorizontalMargin } from 'widget/layouts/slider/selectors';
import {
  getItemWidthForSliderHeight,
  getSliderHorizontalPadding,
  getActionBarHeightForWidgetHeight,
} from 'widget/layouts/slider/utils/layout-calculations';

import { SLIDER_PADDING } from 'widget/layouts/slider/constants';
import { WIDTH_CONSTRAINTS } from 'widget/constants/thumbnail-sizes';
import { VIDEOS_ASPECT_RATIO } from 'widget/constants/videos-aspect-ratio';
import * as viewModeSelectors from 'widget/selectors/view-mode';

import { setWidgetHeight } from 'shared/worker/actions/resize/set-widget-height';
import { resizeComponent } from 'shared/worker/actions/resize/resize-component';
import {
  getContentHeight,
  THUMBNAIL_WIDTH_BREAKPOINTS,
} from '@wix/wix-vod-shared/dist/src/widget/ui-components/thumbnail/content/content.config';

export default Target => {
  @connect(
    state => ({
      appSettings: state.appSettings,
      withTitle: canShowVideoListItemTitle(state),
      withPublisher: getVideoListPublisherVisibility(state),
      withDescription: canShowVideoListItemDescription(state),
      withAllInfo: canShowVideoListItemContentBelow(state),
      canShowActionBar: canShowActionBar(state),
      windowSize: state.windowSize,
      isArrowsOutside: isNavigationArrowsOutside(state),
      isFullWidth: getStretchToFullWidth(state),
      horizontalMargin: getSliderHorizontalMargin(state),
      isOptimalFontSizes: getUseOptimalFontSizes(state),
      titleFont: getTitleFont(state),
      textFont: getTextFont(state),
      isSiteMode: viewModeSelectors.isSiteMode(state),
      isEditorMode: viewModeSelectors.isEditorMode(state),
    }),
    {
      setWidgetHeight,
      resizeComponent,
    },
  )
  class WithResizer extends React.Component {
    static propTypes = {
      appSettings: PropTypes.object.isRequired,
      withTitle: PropTypes.bool.isRequired,
      withPublisher: PropTypes.bool.isRequired,
      withDescription: PropTypes.bool.isRequired,
      withAllInfo: PropTypes.bool.isRequired,
      windowSize: PropTypes.object.isRequired,
      isArrowsOutside: PropTypes.bool.isRequired,
      isFullWidth: PropTypes.bool.isRequired,
      horizontalMargin: PropTypes.number.isRequired,
      isOptimalFontSizes: PropTypes.bool.isRequired,
      titleFont: PropTypes.object.isRequired,
      textFont: PropTypes.object.isRequired,
      canShowActionBar: PropTypes.bool.isRequired,
      isSiteMode: PropTypes.bool.isRequired,
      isEditorMode: PropTypes.bool.isRequired,
      setWidgetHeight: PropTypes.func.isRequired,
      resizeComponent: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);

      this.state = {
        minSliderWidth: 0,
        minSliderHeight: 0,
        enforcedWidth: 0,
      };

      Object.assign(this.state, this.getSliderDimensions());

      this.skipSizeUpdate = false;
    }

    componentWillReceiveProps(nextProps, nextContext) {
      if (nextProps.isSiteMode) {
        return;
      }

      if (
        !_.isEqual(
          this.getThumbnailSettings(nextProps),
          this.getThumbnailSettings(this.props),
        )
      ) {
        this.setState(
          { enforcedWidth: this.getItemWidth() },
          this.resizeWidget,
        );
        this.skipSizeUpdate = true;
        return;
      }

      if (!_.isEqual(this.props.windowSize, nextProps.windowSize)) {
        if (this.skipSizeUpdate) {
          this.skipSizeUpdate = false;
          return;
        }
      }

      this.setState(
        {
          enforcedWidth: 0,
        },
        () => {
          this.setState(this.getSliderDimensions(), this.resizeWidget);
        },
      );
    }

    getThumbnailSettings = props =>
      _.pick(props, [
        'withTitle',
        'withPublisher',
        'withDescription',
        'withAllInfo',
        'isOptimalFontSizes',
        'titleFont',
        'textFont',
        'isArrowsOutside',
        'canShowActionBar',
      ]);

    resizeWidget = () => {
      const {
        isEditorMode,
        isFullWidth,
        setWidgetHeight,
        resizeComponent,
      } = this.props;

      if (isEditorMode) {
        const width = this.containerRef.clientWidth;
        const height = this.containerRef.clientHeight;

        if (isFullWidth) {
          setWidgetHeight(height, width);
          return;
        }

        resizeComponent({ width, height });
      }
    };

    getSizes() {
      const {
        titleFont,
        textFont,
        isOptimalFontSizes,
        withDescription,
        withPublisher,
        withTitle,
      } = this.props;

      return _.map(THUMBNAIL_WIDTH_BREAKPOINTS, width => {
        const contentHeight = getContentHeight({
          width,
          titleFont,
          textFont,
          isOptimalFontSizes,
          withDescription,
          withPublisher,
          withTitle,
        });

        return {
          height: width / VIDEOS_ASPECT_RATIO + contentHeight,
          width,
          contentHeight,
        };
      });
    }

    getSliderDimensions({ windowSize, canShowActionBar } = this.props) {
      const actionBarHeight = canShowActionBar
        ? getActionBarHeightForWidgetHeight(windowSize.height)
        : 0;

      const minSliderHeight = this.getNormalizedSliderHeight(
        windowSize.height - SLIDER_PADDING * 2 - actionBarHeight,
      );

      return {
        minSliderWidth:
          this.getItemWidth(minSliderHeight) + this.getAllMarginsAndPaddings(),
        minSliderHeight,
      };
    }

    getSliderPadding = () =>
      getSliderHorizontalPadding(
        this.getItemWidth(),
        this.props.isArrowsOutside,
      );

    getAllMarginsAndPaddings = () =>
      (this.props.horizontalMargin + this.getSliderPadding()) * 2;

    getItemHeight = () => this.getItemWidth() / VIDEOS_ASPECT_RATIO;

    getItemWidth = (sliderHeight = this.state.minSliderHeight) =>
      this.state.enforcedWidth ||
      this.getItemWidthForSliderHeight(sliderHeight);

    getSliderWidth = () =>
      this.props.windowSize.width - this.getAllMarginsAndPaddings();

    getItemWidthForSliderHeight = sliderHeight =>
      getItemWidthForSliderHeight(
        sliderHeight,
        this.getContentHeightForSliderHeight(sliderHeight),
      );

    getContentHeightForSliderHeight = sliderHeight => {
      const sizes = this.getSizes();

      if (!sizes.length) {
        return;
      }

      const [size275, size399, size400, size500, size700] = sizes;

      if (sliderHeight <= size275.height) {
        return size275.contentHeight;
      }

      if (sliderHeight <= size399.height) {
        return size399.contentHeight;
      }

      if (sliderHeight < size500.height) {
        return size400.contentHeight;
      }

      if (sliderHeight < size700.height) {
        return size500.contentHeight;
      }

      return size700.contentHeight;
    };

    getNormalizedSliderHeight = sliderHeight => {
      const [, size399, size400] = this.getSizes();

      sliderHeight = this.getClampedHeight(sliderHeight);

      if (sliderHeight > size399.height && sliderHeight < size400.height) {
        sliderHeight = size400.height;
      }

      return sliderHeight;
    };

    getClampedHeight = height =>
      _.clamp(height, ...this.getHeightConstraints());

    getHeightConstraints() {
      const [size275, , , , size700] = this.getSizes();
      const [min, max] = WIDTH_CONSTRAINTS;

      return [
        Math.floor(min / VIDEOS_ASPECT_RATIO + size275.contentHeight),
        Math.ceil(max / VIDEOS_ASPECT_RATIO + size700.contentHeight),
      ];
    }

    saveContainerRef = ref => {
      this.containerRef = ref;
    };

    render() {
      const { horizontalMargin } = this.props;
      let { minSliderWidth, minSliderHeight } = this.getSliderDimensions();
      const { enforcedWidth } = this.state;

      if (enforcedWidth) {
        minSliderWidth = this.state.minSliderWidth;
        minSliderHeight = this.state.minSliderHeight;
      }

      return (
        <Target
          {...this.props}
          sliderWidth={this.getSliderWidth()}
          minSliderWidth={minSliderWidth}
          minSliderHeight={enforcedWidth ? 0 : minSliderHeight}
          itemWidth={this.getItemWidth()}
          thumbnailHeight={this.getItemHeight()}
          sliderMargin={horizontalMargin}
          sliderPadding={this.getSliderPadding()}
          onContainerRef={this.saveContainerRef}
          isResized
        />
      );
    }
  }
  return WithResizer;
};
