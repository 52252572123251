import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ensureVideoLoaded } from 'widget/redux/server/actions/video/ensure-video-loaded';
import { selectVideo } from 'widget/redux/client/actions/select-video';
import {
  playVideo,
  pauseVideo,
} from 'widget/redux/client/actions/player/change-playback-status';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { getVideoCardsList } from 'widget/selectors/video-cards';
import VideoCardsWrapper from 'widget/components/video-cards-wrapper/video-cards-wrapper';

@connect(
  state => ({
    cardsData: getVideoCardsList(state),
    currentSiteUser: getCurrentSiteUser(state),
    videoByIds: getVideosGroupedByIds(state),
    channelData: getChannelForWidget(state),
  }),
  {
    selectVideo,
    playVideo,
    pauseVideo,
    ensureVideoLoaded,
  },
)
export default class VideoCardsWrapperDesktop extends React.PureComponent {
  static propTypes = {
    currentSiteUser: PropTypes.object,
    channelData: PropTypes.object,
    videoByIds: PropTypes.object,
    cardsData: PropTypes.array,

    children: PropTypes.func.isRequired,

    selectVideo: PropTypes.func,
    playVideo: PropTypes.func,
    pauseVideo: PropTypes.func,
    ensureVideoLoaded: PropTypes.func,

    thumbnailSize: PropTypes.number,
  };

  handlePlayRequest = ({ id }) => {
    const {
      channelData,
      currentSiteUser,
      selectVideo,
      playVideo,
      ensureVideoLoaded,
    } = this.props;

    ensureVideoLoaded(id).then(() => {
      const videoItem = this.props.videoByIds[id];
      const isPlayAllowed = canPlayFullOrPreview(
        channelData,
        videoItem,
        currentSiteUser,
      );

      if (isPlayAllowed) {
        playVideo(videoItem.id);
        return;
      }

      selectVideo(videoItem.id);
    });
  };

  render() {
    return (
      <VideoCardsWrapper
        {...this.props}
        handlePlayRequest={this.handlePlayRequest}
      />
    );
  }
}
