import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PUB_SUB_RECIPIENTS from 'shared/constants/pub-sub-recipients';
import EVENTS from 'shared/constants/events';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import {
  withPubSubEvents,
  consumePubSubEvent,
} from 'widget/containers/pub-sub-events';

@withPubSubEvents
@connect(state => ({ compId: getCompId(state) }))
export default class SubscriptionCancelHandler extends React.Component {
  static propTypes = {
    onSubscriptionCancel: PropTypes.func.isRequired,
    pubSubRecipientName: PropTypes.oneOf(_.values(PUB_SUB_RECIPIENTS)),
  };

  componentDidUpdate(prevProps) {
    consumePubSubEvent(
      EVENTS.PAYMENT.CANCEL_SUBSCRIPTION,
      this.handleSubscriptionCancelEvent,
      {
        prevProps,
        nextProps: this.props,
      },
    );
  }

  unsubscribeSubscriptionEvent() {
    this.disableConsumeEvents = true;
  }

  handleSubscriptionCancelEvent = ({ data = {} } = {}) => {
    if (this.disableConsumeEvents) {
      return;
    }
    const { rootCompId, recipients } = data;
    const { pubSubRecipientName } = this.props;

    if (recipients) {
      if (!_.includes(recipients, pubSubRecipientName)) {
        return;
      }
    }
    if (this.props.compId === rootCompId) {
      this.unsubscribeSubscriptionEvent();
      this.props.onSubscriptionCancel();
    }
  };

  render() {
    return null;
  }
}
