import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getVideoCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/cover';
import ResizedImage from 'shared/components/resized-image/resized-image';

import { withStyles } from 'shared/utils/withStyles';
import styles from '../overlay.styl';

@withStyles(styles)
export default class OverlayVideoCover extends React.Component {
  static propTypes = {
    videoItem: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,

    className: PropTypes.string,
    withFallbackColor: PropTypes.bool,
  };

  static defaultProps = {
    withFallbackColor: false,
  };

  render() {
    const {
      videoItem,
      className,
      width,
      height,
      withFallbackColor,
    } = this.props;

    return (
      <ResizedImage
        className={classnames(styles.cover, className)}
        src={getVideoCoverUrl(videoItem)}
        width={width}
        height={height}
        withFallbackColor={withFallbackColor}
      />
    );
  }
}
