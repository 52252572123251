import Lodash from 'lodash';
import { durationToISOString } from 'widget/utils/duration';
import getResizedImageUrl, {
  MODES,
} from '@wix/wix-vod-shared/dist/src/common/utils/get-resized-image-url';

const getVideoKeywords = data => {
  const keywords = [];

  if (data.categories && data.categories.length) {
    keywords.push(data.categories.join(', '));
  }
  if (data.tags && data.tags.length) {
    keywords.push(data.tags.join(', '));
  }
  if (data.crew) {
    data.crew.forEach(crewItem => {
      keywords.push(crewItem.role);
      keywords.push(crewItem.name);
    });
  }
  if (data.cast) {
    data.cast.forEach(castItem => {
      keywords.push(castItem.role);
      keywords.push(castItem.name);
    });
  }

  return keywords;
};

const getContentUrl = data => {
  const { fileOutput } = data.mediaInfo || {};
  if (!fileOutput) {
    return undefined;
  }
  const sortOrder = ['HD', 'High', 'Low'];

  const clip = Lodash.first(
    Lodash.sortBy(fileOutput.clip || [], cl => sortOrder.indexOf(cl.tag)),
  );
  return clip && clip.url;
};

const getVideoContentUrls = data => {
  return {
    embedUrl: data.mediaExternUrl,
    // contentUrl: getContentUrl(data),
  };
};

const getThumbnailUrl = url =>
  getResizedImageUrl({
    url,
    height: 330,
    width: 330,
    mode: MODES.fill,
  });

const getChannel = data => {
  const keywords = [];
  const { categories, tags } = data.statsInfo || {};
  if (categories && categories.length) {
    keywords.push(categories.map(c => c.value).join(', '));
  }
  if (tags && tags.length) {
    keywords.push(tags.map(c => c.value).join(', '));
  }
  return {
    '@context': 'http://schema.org',
    '@type': 'MediaObject',
    name: data.title,
    description: data.description || undefined,
    thumbnailUrl: getThumbnailUrl(data.customCoverUrl || data.coverUrl),
    keywords,
  };
};

const getVideo = data => {
  const { embedUrl, contentUrl } = getVideoContentUrls(data);
  const thumbnailUrl = getThumbnailUrl(
    data.customCoverUrl || data.posterUrl || data.coverUrl,
  );
  return {
    '@context': 'http://schema.org',
    '@type': 'VideoObject',
    name: data.title,
    thumbnailUrl,
    image: thumbnailUrl,
    height: data.height,
    width: data.width,
    embedUrl,
    contentUrl,
    uploadDate: data.datePublish,
    description: data.description || undefined,
    duration: data.durationSec
      ? durationToISOString(data.durationSec)
      : undefined,
    keywords: getVideoKeywords(data),
  };
};

export const getSeoData = ({ channels, videos, isSingleVideo }) => {
  if (isSingleVideo) {
    const video = videos[0];
    return video ? getVideo(video) : null;
  }
  const channelsSeo = channels.map(channel => {
    const channelSeo = getChannel(channel);
    const video = videos.map(getVideo);
    return { ...channelSeo, video };
  });
  return channelsSeo[0];
};
