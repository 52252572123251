import React from 'react';

const WidgetPropsContext = React.createContext();

export const WidgetPropsProvider = WidgetPropsContext.Provider;

export function withWidgetProps(selector) {
  return Component => props => (
    <WidgetPropsContext.Consumer>
      {widgetProps => <Component {...props} {...selector(widgetProps)} />}
    </WidgetPropsContext.Consumer>
  );
}
