import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveResizedImage } from '@wix/wix-vod-shared/dist/src/widget/ui-components/responsive-resized-image/responsive-resized-image';

const ImageCover = ({ width, height, posterUrl }) => (
  <ResponsiveResizedImage
    src={posterUrl}
    width={width}
    height={height}
    withFallbackColor={!posterUrl}
    progressive
    nonProportional
  />
);

ImageCover.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  posterUrl: PropTypes.string,
};

export default ImageCover;
