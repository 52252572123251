import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { withStyles } from 'shared/utils/withStyles';
import styles from './not-available-overlay.styl';

const NotAvailableOverlay = ({ width, height }) => {
  const style = { width, height };

  return (
    <div
      data-hook="video-not-avalilable-overlay"
      className={styles.overlay}
      style={style}
    >
      <div className={styles.title}>
        {i18n.t('widget.video-is-not-available')}
      </div>
      <div className={styles.text}>{i18n.t('widget.check-again-later')}</div>
    </div>
  );
};

NotAvailableOverlay.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default withStyles(styles)(NotAvailableOverlay);
