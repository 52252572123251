import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import classnames from 'classnames';

import { connect } from 'react-redux';
import SOCIAL_NETWORK_NAMES from '@wix/wix-vod-shared/dist/src/widget/constants/social-networks';

import { notForPreview } from 'shared/utils/not-for-preview';
import openPopup from '@wix/wix-vod-shared/dist/src/common/utils/open-popup';
import initSocialNetworks from '../social-networks';

import {
  getCompId,
  getCurrentPageId,
  getBiToken,
  getInstanceId,
} from 'widget/redux/client/hydrated-data/hydrated-data';

import FacebookIcon from '@wix/wix-vod-shared/dist/src/icons/compiled/components/facebook';
import TwitterIcon from '@wix/wix-vod-shared/dist/src/icons/compiled/components/twitter';
import PinterestIcon from '@wix/wix-vod-shared/dist/src/icons/compiled/components/pinterest';
import TumblrIcon from '@wix/wix-vod-shared/dist/src/icons/compiled/components/tumblr';
import LinkIcon from '@wix/wix-vod-shared/dist/src/icons/compiled/components/link';

import { withStyles } from 'shared/utils/withStyles';
import styles from './social-button.styl';

const SOCIAL_NETWORK_NAME_TO_ICON_MAP = {
  [SOCIAL_NETWORK_NAMES.FACEBOOK]: FacebookIcon,
  [SOCIAL_NETWORK_NAMES.TWITTER]: TwitterIcon,
  [SOCIAL_NETWORK_NAMES.PINTEREST]: PinterestIcon,
  [SOCIAL_NETWORK_NAMES.TUMBLR]: TumblrIcon,
  [SOCIAL_NETWORK_NAMES.LINK]: LinkIcon,
};

@connect(state => ({
  instanceId: getInstanceId(state),
  componentId: getCompId(state),
  sitePageId: getCurrentPageId(state),
  biToken: getBiToken(state),
}))
@withStyles(styles)
export default class SocialButton extends React.Component {
  static propTypes = {
    network: PropTypes.string.isRequired,
    content: PropTypes.shape({
      siteUrl: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    }).isRequired,
    instanceId: PropTypes.string,
    componentId: PropTypes.string,
    sitePageId: PropTypes.string,
    channelId: PropTypes.string,
    biToken: PropTypes.string,
    videoId: PropTypes.string,
    rounded: PropTypes.bool,
    className: PropTypes.string,
    ariaLabel: PropTypes.string,
    popoutOuterClassName: PropTypes.string,
    popoutInnerClassName: PropTypes.string,
    linkClassName: PropTypes.string,
    tooltip: PropTypes.bool,
    onClick: PropTypes.func,
    isFocusable: PropTypes.bool,
  };

  static defaultProps = {
    rounded: false,
    isFocusable: true,
    onClick: _.noop,
  };

  state = {
    showPopout: false,
  };

  UNSAFE_componentWillMount() {
    const { network } = this.props;
    this.socialNetwork = initSocialNetworks()[network];
  }

  componentDidMount() {
    const { link, popout, copyLink } = this;
    // We use addEventListener because react`s elements are destroyed before onClick is fired

    if (popout) {
      copyLink.addEventListener('click', this.handleCopyToClipboard);
      popout.addEventListener('click', this.stopPropagation);
      link.addEventListener('click', this.togglePopout);
    } else {
      link.addEventListener('click', this.handleShareIconClick);
    }
  }

  componentWillUnmount() {
    const { link, popout, copyLink } = this;

    if (popout) {
      copyLink.removeEventListener('click', this.handleCopyToClipboard);
      popout.removeEventListener('click', this.stopPropagation);
      link.removeEventListener('click', this.togglePopout);
    } else {
      link.removeEventListener('click', this.handleShareIconClick);
    }
  }

  handleShareIconClick = event => {
    event.preventDefault();
    event.stopPropagation();

    this.openShareWindow();

    const { onClick, network } = this.props;
    onClick(network);
  };

  openShareWindow = notForPreview(() => {
    openPopup(this.getShareUrl());
  });

  togglePopout = notForPreview(event => {
    event.preventDefault();
    event.stopPropagation();

    const { showPopout } = this.state;

    this.setState({
      showPopout: !showPopout,
    });
  });

  stopPropagation(event) {
    event.stopPropagation();
  }

  handleCopyToClipboard = notForPreview(event => {
    event.preventDefault();

    if (document.queryCommandSupported('copy')) {
      this.input.select();
      document.execCommand('copy');
    }
  });

  saveRef(name) {
    return element => {
      this[name] = element;
    };
  }

  get popoutContent() {
    const { network, popoutInnerClassName, popoutOuterClassName } = this.props;
    const { showPopout } = this.state;

    if (network !== 'link') {
      return null;
    }

    const styleOuter = classnames(styles.popout, popoutOuterClassName, {
      [styles.hide]: !showPopout,
    });

    const styleInner = classnames(styles['popout-inner'], popoutInnerClassName);

    return (
      <span className={styleOuter} ref={this.saveRef('popout')}>
        <span className={styleInner}>
          <input
            type="text"
            value={this.getShareUrl()}
            readOnly
            className={styles['copy-url-input']}
            ref={this.saveRef('input')}
          />

          <a
            href="#"
            className={styles['copy-url-link']}
            ref={this.saveRef('copyLink')}
          >
            {i18n.t('social-button.copy')}
          </a>
        </span>
      </span>
    );
  }

  getShareUrl() {
    const {
      content,
      instanceId,
      componentId,
      sitePageId,
      channelId,
      videoId,
      biToken,
    } = this.props;

    return this.socialNetwork.getShareUrl(content, {
      instanceId,
      componentId,
      sitePageId,
      channelId,
      videoId,
      biToken,
    });
  }

  render() {
    const {
      network,
      className,
      rounded,
      linkClassName,
      ariaLabel,
      isFocusable,
    } = this.props;

    const { name } = this.socialNetwork;
    const tooltipStyles = classnames(className, styles.tooltip);
    const linkStyles = classnames(
      styles['social-button'],
      network,
      linkClassName,
      {
        [styles.rounded]: rounded,
      },
    );

    const Icon = SOCIAL_NETWORK_NAME_TO_ICON_MAP[network];

    return (
      <span className={tooltipStyles}>
        <a
          href="#"
          role="button"
          data-hook={network}
          title={ariaLabel || i18n.t(`social-button.${name}`)}
          className={linkStyles}
          ref={this.saveRef('link')}
          tabIndex={isFocusable ? 0 : -1}
          aria-label={ariaLabel}
        >
          <Icon role="presentation" />
        </a>

        {this.popoutContent}
      </span>
    );
  }
}
