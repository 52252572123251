import _ from 'lodash';
import { REQUEST_LOGIN_MODES } from 'shared/constants/request-login-modes';
import { logWidgetSystem } from 'shared/worker/actions/bi';
import { getLocale } from 'widget/redux/client/hydrated-data/hydrated-data';
import { requestLogin as requestLoginAction } from 'shared/worker/actions/user/request-login';
import { logout } from 'shared/worker/actions/user/logout';
import { createProxy, createAsyncProxy } from 'shared/worker/lib';

const systemEventsPerLoginMode = {
  [REQUEST_LOGIN_MODES.SIGN_UP]: {
    requested: 'user.signup.requested',
    succeeded: 'user.signup.succeeded',
    cancelled: 'user.signup.cancelled',
  },
  [REQUEST_LOGIN_MODES.LOGIN]: {
    requested: 'user.logIn.requested',
    succeeded: 'user.logIn.succeeded',
    cancelled: 'user.logIn.cancelled',
  },
};

export const requestAuth = createAsyncProxy(
  'requestAuth',
  isLogin => (dispatch, getState) => {
    const mode = isLogin
      ? REQUEST_LOGIN_MODES.LOGIN
      : REQUEST_LOGIN_MODES.SIGN_UP;
    const state = getState();
    const locale = getLocale(state);
    const systemEvents = systemEventsPerLoginMode[mode];

    dispatch(logWidgetSystem(systemEvents.requested));

    return new Promise((resolve, reject) => {
      const onLogin = loggedInUserData => {
        loggedInUserData = loggedInUserData.data || loggedInUserData;

        if (loggedInUserData.id) {
          dispatch(
            logWidgetSystem(systemEvents.succeeded, {
              previousEventName: systemEvents.requested,
            }),
          );
          resolve(loggedInUserData);
        }
      };

      const onError = error => {
        if (_.get(error, 'wasCancelled')) {
          dispatch(
            logWidgetSystem(systemEvents.cancelled, {
              previousEventName: systemEvents.requested,
            }),
          );
          return;
        }
        reject(error);
      };

      dispatch(
        requestLoginAction(
          {
            language: locale,
            mode,
          },
          onLogin,
          onError,
        ),
      );
    });
  },
);

export const requestLogin = () => requestAuth(true);
export const requestSignUp = () => requestAuth(false);

export const logOutCurrentMember = createProxy(
  'logOutCurrentMember',
  () => dispatch => {
    dispatch(logWidgetSystem('user.logOut.requested'));
    dispatch(logout());
  },
);
