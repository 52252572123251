import axios from 'axios';
import { hosts } from '@wix/wix-vod-shared/dist/src/common/settings/settings';

const authenticatedAxios = axios.create({
  withCredentials: true,
  xsrfCookieName: null,
  xsrfHeaderName: null,
  baseURL: hosts.pixWix,
});

export default authenticatedAxios;
