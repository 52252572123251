import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'shared/components/icon/icon';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import { withStyles } from 'shared/utils/withStyles';
import styles from '../account-button.styl';

@withStyles(styles)
export default class AccountInfoButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    isFocusable: PropTypes.bool,
  };

  static defaultProps = {
    isFocusable: true,
  };

  render() {
    const { className, onClick, isFocusable } = this.props;

    return (
      <button
        className={classnames(styles['account-info-button'], className)}
        onClick={onClick}
        data-hook="channel-account-info"
        tabIndex={isFocusable ? 0 : -1}
        aria-label={i18n.t('widget.accessibility.my-account')}
      >
        <Icon name="avatar" />
      </button>
    );
  }
}
