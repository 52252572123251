import React from 'react';
import Lodash from 'lodash';
import { connect } from 'react-redux';
import { getAllChannelsGroupedByIds } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getSeoData } from './seoData';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { isSingleVideo } from 'shared/selectors/app-settings';
import { withWidgetProps } from 'widget/containers/widget-props';

class SeoComponent extends React.Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { channels, videos, isSingleVideo } = this.props;

    let serializedSeoData = null;

    try {
      const seoData = getSeoData({ channels, videos, isSingleVideo });
      serializedSeoData = seoData && JSON.stringify(seoData);
    } catch (err) {
      this.props.captureException(err);
    }

    if (!serializedSeoData) {
      return null;
    }

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: serializedSeoData }}
      />
    );
  }
}

export default withWidgetProps(({ captureException }) => ({
  captureException,
}))(
  connect(state => ({
    isSingleVideo: isSingleVideo(state),
    channels: Lodash.values(getAllChannelsGroupedByIds(state)),
    videos: Lodash.values(getVideosGroupedByIds(state)).filter(
      video => video.id,
    ),
  }))(SeoComponent),
);
