import _ from 'lodash';
import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { normalizeChannel } from 'shared/redux/helpers/normalized';
import { getPublic as getChannelPublic } from 'api/public/channel/channel';
import { getHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
import { createAsyncProxy } from 'shared/worker/lib';

import getDefaultChannelDealInfo from 'shared/constants/channel-deal-info';

const ACTIONS = createAsyncActions('SERVER.CHANNEL.GET');
export const NAMES = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

export const getPublic = createAsyncProxy(
  'channel.getPublic',
  id => (dispatch, getState, { withContext }) => {
    const params = { id };
    dispatch(START(params));

    return withContext(getChannelPublic)(id, {
      hydratedChannel: getHydratedData(getState()).channel,
    }).then(
      function(response) {
        const data = response.data;
        const { vodSettings } = getState();

        if (!_.get(data, 'dealInfo.length') && vodSettings) {
          data.dealInfo = getDefaultChannelDealInfo(vodSettings.currency);
        }

        response = {
          ...response,
          data: normalizeChannel(data),
        };

        dispatch(SUCCESS(params, response));
        return response;
      },

      function(response) {
        dispatch(
          FAIL(params, response, {
            analytics: { type: 'error', name: 'shared.channel.video.get' },
          }),
        );
      },
    );
  },
);
