import { createLoggerActions } from 'shared/worker/actions/fedopsLogger';
import { VIDEO_SOURCES } from '@wix/wix-vod-constants/dist/common/video-sources';

const SOURCES_INTERACTIONS_MAPPING = {
  [VIDEO_SOURCES.COMPUTER]: 'VIDEO_PLAY_COMPUTER',
  [VIDEO_SOURCES.YOUTUBE]: 'VIDEO_PLAY_YOUTUBE',
  [VIDEO_SOURCES.VIMEO]: 'VIDEO_PLAY_VIMEO',
  [VIDEO_SOURCES.FACEBOOK]: 'VIDEO_PLAY_FACEBOOK',
  // VIDEO_SOURCES.MIXED,
  // VIDEO_SOURCES.LIVE,
  // VIDEO_SOURCES.DROPBOX,
  // VIDEO_SOURCES.GOOGLE_DRIVE,
};

const noopActionCreator = () => () => {};

const noopInteraction = { start: noopActionCreator, end: noopActionCreator };

function createPlayVideoInteraction(createInteraction) {
  return ({ videoSource }) => {
    const interactionName = SOURCES_INTERACTIONS_MAPPING[videoSource];
    if (interactionName === undefined) {
      return noopInteraction;
    }

    return createInteraction(interactionName);
  };
}

function createNewFedopsLogger() {
  const { appLoaded, createInteraction } = createLoggerActions(undefined);
  return {
    appLoaded,
    playVideo: createPlayVideoInteraction(createInteraction),
  };
}

export default createNewFedopsLogger();
