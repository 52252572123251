import { Schema, arrayOf } from 'normalizr';

const channel = new Schema('channels');
const paymentInfoChannel = new Schema('channels', { idAttribute: 'listId' });
const arrayOfChannels = arrayOf(channel);

const video = new Schema('videos');
const paymentInfoVideo = new Schema('videos', { idAttribute: 'itemId' });
const arrayOfVideos = arrayOf(video);
const arrayOfPaymentInfoVideos = arrayOf(paymentInfoVideo);

channel.define({
  featuredItem: video,
});

video.define({
  usedInLists: arrayOfChannels,
});

export {
  channel,
  paymentInfoChannel,
  arrayOfChannels,
  video,
  paymentInfoVideo,
  arrayOfVideos,
  arrayOfPaymentInfoVideos,
};
