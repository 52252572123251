import { createAction } from 'redux-actions';
import { isVolumeChangedByUser } from 'shared/selectors/player';
import { createProxy } from 'shared/worker/lib';

export const NAMES = {
  SET_MUTED: 'CLIENT.VIDEO.SOUND.SET_MUTED',
  SET_VOLUME_CHANGED_BY_USER: 'CLIENT.VIDEO.SOUND.SET_VOLUME_CHANGED_BY_USER',
};

const setVolumeChangedByUserAction = createAction(
  NAMES.SET_VOLUME_CHANGED_BY_USER,
);

export const setMuted = createAction(NAMES.SET_MUTED);
export const setVolumeChangedByUser = createProxy(
  'setVolumeChangedByUser',
  () => (dispatch, getState) => {
    if (!isVolumeChangedByUser(getState())) {
      dispatch(setVolumeChangedByUserAction());
    }
  },
);
