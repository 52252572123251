import React from 'react';
import PropTypes from 'prop-types';
import EVENTS from 'shared/constants/events';
import { connect } from 'react-redux';
import {
  getCompId,
  setHydratedData,
} from 'widget/redux/client/hydrated-data/hydrated-data';
import {
  consumePubSubEvent,
  withPubSubEvents,
} from 'widget/containers/pub-sub-events';

@connect(
  state => ({ compId: getCompId(state) }),
  { setHydratedData },
)
@withPubSubEvents
export class LoginWatcher extends React.Component {
  static propTypes = {
    setHydratedData: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    consumePubSubEvent(EVENTS.SESSION.LOGIN_USER, this.handleUserLogin, {
      prevProps,
      nextProps: this.props,
    });
  }

  handleUserLogin = async event => {
    const { id, email } = event.data;

    this.props.setHydratedData({
      currentSiteUser: {
        id,
        email,
      },
    });
  };

  render() {
    return null;
  }
}
