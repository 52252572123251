import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import SearchImage from './images/search.svg';

import { withStyles } from 'shared/utils/withStyles';
import styles from './empty-search-results.styl';

@withStyles(styles)
export default class EmptySearchResults extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const { className } = this.props;

    return (
      <section className={classnames(styles['no-search-wrapper'], className)}>
        <SearchImage className={styles['no-search-icon']} />
        <span className={styles['no-search-text']}>
          {i18n.t('widget.home-view.no-search-results')}
        </span>
      </section>
    );
  }
}
