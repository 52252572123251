import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WixSDK from 'shared/utils/wix-sdk';
import { setWidgetHeight } from 'shared/worker/actions/resize/set-widget-height';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { updateWindowSize } from 'widget/redux/client/actions/window-size';

export default Target => {
  const mapStateToProps = state => ({
    isEditor: viewModeSelectors.isEditorMode(state),
    windowSize: state.windowSize,
  });

  class WithApplyForcedHeight extends React.Component {
    static propTypes = {
      forcedHeight: PropTypes.number,
      isEditor: PropTypes.bool.isRequired,
    };

    componentDidMount() {
      this.setHeight();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (
        nextProps.forcedHeight &&
        nextProps.forcedHeight !== this.props.forcedHeight
      ) {
        this.setHeight(nextProps.forcedHeight);
      }
    }

    setHeight(forcedHeight = this.props.forcedHeight) {
      const { isEditor, updateWindowSize, windowSize } = this.props;
      if (isEditor && forcedHeight) {
        updateWindowSize({ height: forcedHeight, width: windowSize.width });
        WixSDK.setHeight(forcedHeight);
      }
    }

    render() {
      return this.props.forcedHeight ? null : <Target {...this.props} />;
    }
  }

  return connect(
    mapStateToProps,
    { setWidgetHeight, updateWindowSize },
  )(WithApplyForcedHeight);
};
