import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { logBi } from 'shared/worker/actions/bi';
import {
  BI_ORIGIN,
  BI_VIDEO_TYPE,
} from '@wix/wix-vod-shared/dist/src/common/constants/bi';

import { updateLiveStatus } from 'widget/redux/client/actions/videos';
import fetchVideoUrls from 'widget/redux/client/actions/video-urls/fetch';

import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { selectVideo } from 'widget/redux/client/actions/select-video';
import { getChannelVideoById } from '@wix/wix-vod-api/dist/src/public/channel-videos/channel-videos';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import {
  isPendingLiveStream,
  isScheduledLive,
} from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/live';
import { canAccessFullVideo } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { isVideoOverlayOpened } from 'widget/selectors/full-screen-modal';
import {
  getLiveScheduledVideos,
  getLiveStreamingVideos,
  getLivePendingVideos,
} from 'widget/selectors/live-videos';
import LivePolling from '@wix/wix-vod-shared/dist/src/widget/components/live-polling/live-polling';
import * as viewModeSelectors from 'widget/selectors/view-mode';

const getVideoData = id => (dispatch, getState, { withContext }) => {
  const channel = getChannelForWidget(getState());

  return withContext(getChannelVideoById)(channel.id, id);
};

@connect(
  state => ({
    channel: getChannelForWidget(state),
    currentSiteUser: getCurrentSiteUser(state),
    isVideoOverlayOpened: isVideoOverlayOpened(state),
    scheduledVideos: getLiveScheduledVideos(state),
    pendingVideos: getLivePendingVideos(state),
    streamingVideos: getLiveStreamingVideos(state),
    isPreviewOrEditorMode:
      viewModeSelectors.isPreviewMode(state) ||
      viewModeSelectors.isEditorMode(state),
  }),
  {
    updateLiveStatus,
    fetchVideoUrls,
    selectVideo,
    logBi,
    getVideoData,
  },
)
export default class LiveStartHandler extends React.Component {
  static propTypes = {
    scheduledVideos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        dateStartLive: PropTypes.number,
      }),
    ),
    pendingVideos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    streamingVideos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    channel: PropTypes.object.isRequired,
    currentSiteUser: PropTypes.object,

    updateLiveStatus: PropTypes.func,
    fetchVideoUrls: PropTypes.func,
    playVideo: PropTypes.func,
    selectVideo: PropTypes.func,

    isVideoPlaying: PropTypes.bool,
    isVideoOverlayOpened: PropTypes.bool,
    isPreviewOrEditorMode: PropTypes.bool,

    selectedVideoId: PropTypes.string,
  };

  autoplayVideo = video => {
    const {
      playVideo,
      selectVideo,
      channel,
      currentSiteUser,
      isVideoOverlayOpened,
    } = this.props;
    if (this.isOtherVideoPlaying(video.id) || isVideoOverlayOpened) {
      return;
    }

    if (canAccessFullVideo(channel, video, currentSiteUser)) {
      this.logAutoplayBi(video);
      playVideo(video);
    } else {
      selectVideo(video.id);
    }
  };

  logAutoplayBi = video => {
    const { channel } = this.props;

    this.props.logBi('widget.video.autoplay.start', {
      videoType: BI_VIDEO_TYPE.LIVE,
      isEmbed: false,
      channelID: channel.id,
      videoID: video.id,
      origin: BI_ORIGIN.ON_SITE,
    });
  };

  logPollBi = (videoItem, error) => {
    this.props.logBi('widget.poll.request.sent', {
      videoType: this.getVideoType(videoItem),
      isError: Boolean(error),
      errorMessage: _.get(error, 'message', null),
      origin: BI_ORIGIN.ON_SITE,
    });
  };

  getVideoType({ itemType, mediaInfo }) {
    let videoType = null;
    if (isPendingLiveStream({ itemType, mediaInfo })) {
      videoType = BI_VIDEO_TYPE.LIVE;
    } else if (isScheduledLive({ itemType, mediaInfo })) {
      videoType = BI_VIDEO_TYPE.SCHEDULED;
    }
    return videoType;
  }

  isOtherVideoPlaying = videoId => {
    const { isVideoPlaying, selectedVideoId } = this.props;
    return isVideoPlaying && selectedVideoId !== videoId;
  };

  isOtherVideoSelected = videoId => {
    const { selectedVideoId } = this.props;
    return selectedVideoId && selectedVideoId !== videoId;
  };

  canInitiallyAutoplay = videoId => {
    return !this.isOtherVideoSelected(videoId);
  };

  onStreamChangePending = videoItem => {
    this.props.updateLiveStatus(videoItem);
  };

  onStreamChangeReady = videoItem => {
    this.props.updateLiveStatus(videoItem);
    this.props.fetchVideoUrls(videoItem, true);
  };

  render() {
    const {
      scheduledVideos,
      pendingVideos,
      streamingVideos,
      isPreviewOrEditorMode,
    } = this.props;

    if (isPreviewOrEditorMode) {
      return null;
    }

    return (
      <LivePolling
        scheduledVideos={scheduledVideos}
        pendingVideos={pendingVideos}
        streamingVideos={streamingVideos}
        autoplayVideo={this.autoplayVideo}
        canInitiallyAutoplay={this.canInitiallyAutoplay}
        onStreamChangePending={this.onStreamChangePending}
        onStreamChangeReady={this.onStreamChangeReady}
        onPoll={this.logPollBi}
        getVideoData={this.props.getVideoData}
      />
    );
  }
}
