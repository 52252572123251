import { isVideoInInitialState } from 'widget/selectors/video-playback-status';
import { requestPlayVideo } from './request-play-video';
import { requestPlay } from 'shared/redux/client/reducers/playback';
import { createProxy } from 'shared/worker/lib';

export const requestPlayVideoOnload = createProxy(
  'requestPlayVideoOnload',
  (id, isMobile) => (dispatch, getState) => {
    const state = getState();

    if (isMobile) {
      dispatch(requestPlay(id, true));
    } else if (isVideoInInitialState(state)) {
      dispatch(requestPlayVideo(id, true, true));
    }
  },
);
