import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Tag from './tag/tag';

import { withStyles } from 'shared/utils/withStyles';
import styles from './tags-cloud.styl';

@withStyles(styles)
export default class TagsCloud extends React.Component {
  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    onTagFocus: PropTypes.func,
    onTagClick: PropTypes.func,
    onTagBlur: PropTypes.func,
    className: PropTypes.string,
    tagClassName: PropTypes.string,
    roundedTagClassName: PropTypes.string,
    isRounded: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onTagClick: _.noop,
  };

  render() {
    const {
      tags,
      isRounded,
      className,
      tagClassName,
      roundedTagClassName,
      onTagClick,
      onTagFocus,
      onTagBlur,
    } = this.props;

    return (
      <div className={className} data-hook="tags-cloud">
        {_.map(tags, tag => (
          <Tag
            key={tag}
            className={classnames(styles.tag, tagClassName)}
            roundedClassName={roundedTagClassName}
            text={tag}
            rounded={isRounded}
            onClick={onTagClick}
            onFocus={onTagFocus}
            onBlur={onTagBlur}
          />
        ))}
      </div>
    );
  }
}
