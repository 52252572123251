import { isVODChannel } from 'shared/utils/channel-helpers';
import { isSubscriptionButtonVisible } from 'shared/components/overlay-texts/channel/subscribe-button-text-utils';

/*
  THIS FUNCTIONS IS DEPRECATED
  use app-settings selector instead
  TODO: move to app-settings selector
  https://jira.wixpress.com/browse/VOD-303
*/

export function isChannelInfoVisible(appSettings) {
  return appSettings.booleans.showChannelInfo;
}

export function isTitleVisible(appSettings) {
  return appSettings.booleans.showChannelTitle;
}

export function isChannelShareVisible(appSettings) {
  return appSettings.booleans.showChannelShare;
}

export function isVideoShareVisible(appSettings) {
  return appSettings.booleans.enableVideosSharing;
}

export function isSignInVisible(appSettings) {
  return appSettings.booleans.showSignIn;
}

export function isTagsVisible(appSettings) {
  return appSettings.booleans.showChannelTags;
}

export function isCategoriesDropdownVisible(appSettings, channelData = {}) {
  return (
    isVODChannel(channelData) && appSettings.booleans.showChannelCategories
  );
}

export function isSearchInputVisible(appSettings, channelData = {}) {
  return isVODChannel(channelData) && appSettings.booleans.showSearch;
}

// TODO: move to classic only selectors
export function isClassicLayoutSearchBarVisible(appSettings, channelData = {}) {
  return (
    isCategoriesDropdownVisible(appSettings, channelData) ||
    isSearchInputVisible(appSettings, channelData)
  );
}

// TODO: move to classic only selectors
export function isClassicLayoutHeaderVisible(appSettings, channelData = {}) {
  return (
    isTitleVisible(appSettings) ||
    isChannelShareVisible(appSettings) ||
    isChannelInfoVisible(appSettings) ||
    isSignInVisible(appSettings) ||
    isSubscriptionButtonVisible({ channel: channelData })
  );
}
