import _ from 'lodash';
import { createSelector } from 'reselect';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { canShowChannelCategories as isCategoriesSettingEnabled } from 'shared/selectors/app-settings';
import { isYouTubeFeed } from 'shared/utils/channel-helpers';

export const getChannelCategories = createSelector(
  getChannelForWidget,
  channel => _.get(channel, 'statsInfo.categories', []),
);

export const getChannelCategoryOptions = createSelector(
  getChannelCategories,
  categories => _.map(categories, ({ value }) => ({ value, label: value })),
);

export const canShowChannelCategories = createSelector(
  isCategoriesSettingEnabled,
  getChannelForWidget,
  (isEnabled, channel) => isEnabled && !isYouTubeFeed(channel),
);
