import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { withStyles } from 'shared/utils/withStyles';
import styles from './animate-crossfade.styl';

// Note: use mock for this component at tests, because of using onTransitionEnd
@withStyles(styles)
export default class AnimateCrossfade extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    currentIdIndex: PropTypes.number,
    onAnimationComplete: PropTypes.func,
    activeClassName: PropTypes.string,
    fadeInOnly: PropTypes.bool,
  };

  static defaultProps = {
    children: [],
    currentIdIndex: 0,
    onAnimationComplete: _.noop,
    fadeInOnly: false,
  };

  state = {
    nextIndex: 0,
    children: [],
  };

  UNSAFE_componentWillMount() {
    this.handlePropsUpdate(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    clearTimeout(this.timerId);
    this.handlePropsUpdate(nextProps);
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  handlePropsUpdate({ currentIdIndex, children: nextChildren, fadeInOnly }) {
    this.prevIndex = this.props.currentIdIndex;
    const { children: prevChildren } = this.props;

    if (fadeInOnly && currentIdIndex !== this.props.currentIdIndex) {
      this.props.onAnimationComplete();
    }

    // Because of canShowChannelCover stuff
    this.timerId = setTimeout(() => {
      const children =
        currentIdIndex === this.prevIndex ? nextChildren : prevChildren;
      this.setState({
        children,
        nextIndex: currentIdIndex,
      });
    });
  }

  prevIndex = null;

  isActive(slideIndex) {
    return slideIndex === this.state.nextIndex;
  }

  isPrevActive(slideIndex) {
    return slideIndex === this.prevIndex;
  }

  updateChildrenForRender = () => {
    // Because of canShowChannelCover stuff
    this.setState({
      children: this.props.children,
    });

    this.props.onAnimationComplete();
  };

  stopPropagation = event => {
    event.stopPropagation();
  };

  renderWrappedSlide = (slide, slideIndex) => {
    const { activeClassName } = this.props;
    const isActive = this.isActive(slideIndex);
    const className = classnames(styles.slide, {
      [styles.active]: isActive,
      [activeClassName]: isActive,
      [styles.prevActive]: this.isPrevActive(slideIndex),
    });

    return (
      <div
        className={className}
        onTransitionEnd={isActive ? this.updateChildrenForRender : _.noop}
        key={slideIndex}
      >
        <div className={styles.inner} onTransitionEnd={this.stopPropagation}>
          {slide}
        </div>
      </div>
    );
  };

  render() {
    const { fadeInOnly, children, currentIdIndex } = this.props;

    return (
      <div className={styles.container}>
        {fadeInOnly
          ? this.renderWrappedSlide(children[currentIdIndex], currentIdIndex)
          : _.map(children, this.renderWrappedSlide)}
      </div>
    );
  }
}
