import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import {
  getSubscribeButtonText,
  getPublisherText,
  getPlayButtonText,
  getPurchaseButtonText,
  getOpacityStyle,
} from 'widget/components/player-overlay/selectors';
import { openSubscription } from 'widget/redux/client/player-overlay/actions/open-subscription';
import { openPlayerOverlay } from 'widget/redux/client/player-overlay/actions/open-player-overlay';
import { openPurchase } from 'widget/redux/client/player-overlay/actions/open-purchase';
import { playSelectedVideo } from 'widget/redux/client/player-overlay/actions/play-selected-video';
import ElementSwitcher from 'widget/components/element-switcher/element-switcher';
import Title from 'widget/components/player-overlay/partials/title';
import ComingSoon from 'widget/components/player-overlay/partials/coming-soon';
import LiveLabel from 'widget/containers/live-label/live-label';

import { hasVideos } from 'shared/utils/channel-helpers';
import { RoundedIcon } from 'shared/components/icon/icon';
import { GenericButton } from 'shared/components/button/button';
import { VODCSSModules } from 'shared/utils/wix-connect';
import { notForPreview } from 'shared/utils/not-for-preview';
import {
  isThumbnailsPreviewHover,
  isVideosListInfoAlwaysShown,
  isVideosListInfoNeverShown,
} from 'shared/selectors/app-settings';

import { withStyles } from 'shared/utils/withStyles';
import styles from './thumbnail-overlay.styl';

const THUMB_PADDING = 24;

@connect(
  (state, ownProps) => ({
    isThumbnailsPreviewHover: isThumbnailsPreviewHover(state),
    isTitleAlwaysShown: isVideosListInfoAlwaysShown(state),
    isTitleNeverShown: isVideosListInfoNeverShown(state),
    subscribeButtonText: getSubscribeButtonText(state, ownProps),
    publisherText: getPublisherText(state, ownProps),
    playButtonText: getPlayButtonText(state, ownProps),
    purchaseButtonText: getPurchaseButtonText(state, ownProps),
    opacityStyle: getOpacityStyle(state),
  }),
  {
    openSubscription,
    openPlayerOverlay,
    playSelectedVideo,
    openPurchase,
  },
)
@withStyles(styles)
@VODCSSModules(styles)
export default class ThumbnailOverlayStrip extends React.Component {
  static propTypes = {
    isThumbnailsPreviewHover: PropTypes.bool.isRequired,
    isTitleAlwaysShown: PropTypes.bool.isRequired,
    isTitleNeverShown: PropTypes.bool.isRequired,

    currentSiteUser: PropTypes.object,
    channelData: PropTypes.object.isRequired,
    videoItem: PropTypes.object.isRequired,

    publisherText: PropTypes.node,
    subscribeButtonText: PropTypes.node,
    playButtonText: PropTypes.node,
    purchaseButtonText: PropTypes.node,
    opacityStyle: PropTypes.object.isRequired,

    showChannelCover: PropTypes.bool,

    onPlaySelectedVideo: PropTypes.func,

    openSubscription: PropTypes.func.isRequired,
    openPlayerOverlay: PropTypes.func.isRequired,
    openPurchase: PropTypes.func.isRequired,
    playSelectedVideo: PropTypes.func.isRequired,

    isContentFocusable: PropTypes.bool,
    width: PropTypes.number,
    isOnlySmallButtons: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    isContentFocusable: true,
  };

  constructor(props) {
    super(props);

    const { durationStr } = this.props.videoItem;

    this.clampOptions = {
      truncateText: ` / ${durationStr}`,
      textAlign: 'center',
    };
  }

  get titleBlock() {
    const { videoItem, isTitleNeverShown } = this.props;

    if (isTitleNeverShown) {
      return null;
    }

    return (
      <div className={styles['title-block']}>
        <div className={styles.title}>
          <Title
            text={videoItem.title}
            clampOptions={this.clampOptions}
            enforceJSClamp
          />
        </div>
      </div>
    );
  }

  playSelectedVideo = notForPreview(event => {
    event.preventDefault();
    event.stopPropagation();

    const {
      videoItem,
      playSelectedVideo,
      onPlaySelectedVideo,
      showChannelCover,
    } = this.props;
    playSelectedVideo({ videoItem, onPlaySelectedVideo, showChannelCover });
  });

  get smallPlayButton() {
    if (!this.props.playButtonText) {
      return null;
    }

    return (
      <GenericButton
        styleName="button play-button"
        onClick={this.playSelectedVideo}
        isFocusable={this.props.isContentFocusable}
      >
        <RoundedIcon styleName="icon play-icon" name="arrow-right-filled" />
      </GenericButton>
    );
  }

  handleSubscriptionClick = event => {
    event.preventDefault();
    event.stopPropagation();

    this.props.openSubscription();
  };

  handlePurchaseClick = event => {
    event.preventDefault();
    event.stopPropagation();

    const { videoItem, openPlayerOverlay } = this.props;

    openPlayerOverlay(videoItem);
  };

  get smallSubscribeButton() {
    if (!this.props.subscribeButtonText) {
      return null;
    }

    return (
      <GenericButton
        styleName="button small-subscribe-button"
        onClick={this.handleSubscriptionClick}
        isFocusable={this.props.isContentFocusable}
      >
        <RoundedIcon styleName="icon paid-icon" name="paid" />
      </GenericButton>
    );
  }

  get smallPurchaseButton() {
    if (!this.props.purchaseButtonText) {
      return null;
    }

    return (
      <GenericButton
        styleName="button small-buy-button"
        onClick={this.handlePurchaseClick}
        isFocusable={this.props.isContentFocusable}
      >
        <RoundedIcon styleName="icon paid-icon" name="paid" />
      </GenericButton>
    );
  }

  get normalButtons() {
    const play = this.renderPlayButton();
    const subscribe = this.renderSubscribeButton();
    const purchase = this.renderPurchaseButton();

    const key = `${play ? '1' : ''}${subscribe ? '2' : ''}${
      purchase ? '3' : ''
    }`;

    return (
      <div styleName="buttons-wrapper" key={key}>
        {play}
        {subscribe || purchase}
      </div>
    );
  }

  get smallButtons() {
    return (
      <div styleName="buttons-wrapper">
        {this.smallPlayButton}
        {this.smallSubscribeButton || this.smallPurchaseButton}
      </div>
    );
  }

  get buttons() {
    const { width, isOnlySmallButtons } = this.props;

    if (isOnlySmallButtons) {
      return this.smallButtons;
    }

    return (
      <ElementSwitcher maxWidth={width - 2 * THUMB_PADDING}>
        {this.normalButtons}
        {this.smallButtons}
      </ElementSwitcher>
    );
  }

  renderPlayButton() {
    const { channelData, isContentFocusable, playButtonText } = this.props;

    if (!hasVideos(channelData)) {
      return <ComingSoon className={styles['soon-info']} />;
    }

    if (!playButtonText) {
      return null;
    }

    const styleName = classnames(styles.button, styles['play-button']);
    const iconStyleName = classnames(styles.icon, styles['play-icon']);

    return (
      <a
        href=""
        className={styleName}
        data-hook="overlay-play-button"
        onClick={this.playSelectedVideo}
        tabIndex={isContentFocusable ? 0 : -1}
      >
        <RoundedIcon className={iconStyleName} name="arrow-right-filled" />
        <span className={styles.text}>{playButtonText}</span>
      </a>
    );
  }

  renderSubscribeButton() {
    const { subscribeButtonText } = this.props;

    if (!subscribeButtonText) {
      return null;
    }

    const styleName = classnames(
      styles.button,
      'qa-widget-overlay-subscribe-button',
    );

    return (
      <a
        href=""
        className={styleName}
        data-hook="subscribe-button"
        onClick={this.handleSubscriptionClick}
        tabIndex={this.props.isContentFocusable ? 0 : -1}
      >
        <span className={styles.text}>{subscribeButtonText}</span>
      </a>
    );
  }

  renderPurchaseButton() {
    const { purchaseButtonText } = this.props;

    if (!purchaseButtonText) {
      return null;
    }

    const styleName = classnames(
      'qa-widget-overlay-buy-from-button',
      styles.button,
    );

    return (
      <a
        href=""
        className={styleName}
        onClick={this.handlePurchaseClick}
        data-hook="paid-access-button"
        tabIndex={this.props.isContentFocusable ? 0 : -1}
      >
        <span className={styles.text}>{purchaseButtonText}</span>
      </a>
    );
  }

  renderLiveLabel() {
    const { videoItem } = this.props;

    return (
      <LiveLabel
        itemType={videoItem.itemType}
        mediaInfo={videoItem.mediaInfo}
        startTime={videoItem.dateStartLive}
        isSmall
        noBackground
        className={styles['live-label']}
        smallClassName={styles['shown-on-hover']}
      />
    );
  }

  render() {
    const {
      channelData,
      videoItem,
      className,
      isThumbnailsPreviewHover,
      isTitleAlwaysShown,
      isTitleNeverShown,
    } = this.props;

    if (!channelData || !videoItem) {
      return null;
    }

    const styleNames = classnames('overlay-wrapper', {
      'preview-hover': isThumbnailsPreviewHover,
      visible: isTitleAlwaysShown || isTitleNeverShown,
    });

    return (
      <div styleName={styleNames} className={className}>
        <div styleName="background" />
        <div styleName="content">
          <div styleName="top-content">
            {this.titleBlock}
            {this.buttons}
            {this.renderLiveLabel()}
          </div>
        </div>
      </div>
    );
  }
}
