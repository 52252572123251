import React from 'react';
import _ from 'lodash';

import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import { VODCSSModules } from 'shared/utils/wix-connect';

import { getAllSettings } from 'shared/selectors/app-settings';
import { getCurrentUserEmail } from 'shared/selectors/current-site-user';
import { isSubscriptionEnabled } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';

import EVENTS from 'shared/constants/events';

import { LinkButton } from 'shared/components/button/button';
import { sides } from 'shared/constants/popout';

import AccountInfoButton from './account-info-button/account-info-button';
import AccountInfoButtonWithTooltip from './account-info-button-with-tooltip/account-button-with-tooltip';
import SubscriptionCancelHandler from 'widget/components/account/account-button/subscription-cancel-handler';

import { withStyles } from 'shared/utils/withStyles';
import styles from './account-button.styl';

import PUB_SUB_RECIPIENTS from 'shared/constants/pub-sub-recipients';

import {
  withPubSubEvents,
  consumePubSubEvent,
} from 'widget/containers/pub-sub-events';

@withPubSubEvents
@connect((state, ownProps) => ({
  appSettings: getAllSettings(state),
  userEmail: getCurrentUserEmail(state),
  isSubscriptionEnabled: isSubscriptionEnabled(ownProps.channelData),
}))
@withStyles(styles)
@VODCSSModules(styles)
export default class AccountButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    infoButtonClassName: PropTypes.string,
    infoButtonTooltipClassName: PropTypes.string,
    infoButtonPopoutTriangleClassName: PropTypes.string,

    appSettings: PropTypes.object,
    userEmail: PropTypes.string,

    onLoginClick: PropTypes.func,
    onLogoutClick: PropTypes.func,
    //click on a simple logout button
    onClick: PropTypes.func,
    onSubscriptionCancelClick: PropTypes.func,
    onSubscribeClick: PropTypes.func,
    tooltipSide: PropTypes.oneOf(_.values(sides)),

    isSubscriptionEnabled: PropTypes.bool,
    isFocusable: PropTypes.bool,

    pubSubRecipientName: PropTypes.oneOf(_.values(PUB_SUB_RECIPIENTS)),
  };

  componentDidUpdate(prevProps) {
    consumePubSubEvent(EVENTS.SESSION.LOGOUT_USER, this.handleLogoutEvent, {
      prevProps,
      nextProps: this.props,
    });
  }

  handleLogoutEvent = () => {
    if (this.disableConsumeEvents) {
      return;
    }
    this.props.onLogoutClick();
    this.disableConsumeEvents = true;
  };

  handleAccountButtonClicked = () => {
    this.props.onClick();
  };

  renderLoginButton() {
    const { className, onLoginClick, isFocusable } = this.props;

    return (
      <div className={className}>
        <LinkButton
          styleName="account-button"
          isFocusable={isFocusable}
          onClick={onLoginClick}
          dataHook="login-button"
        >
          {i18n.t('widget.account.sign-in')}
        </LinkButton>
      </div>
    );
  }

  renderAccountButtonWithTooltip() {
    const {
      infoButtonClassName,
      infoButtonTooltipClassName,
      infoButtonPopoutTriangleClassName,
      userEmail,
      tooltipSide,
      onLogoutClick,
      isFocusable,
    } = this.props;

    return (
      <AccountInfoButtonWithTooltip
        className={infoButtonClassName}
        popoutClassName={infoButtonTooltipClassName}
        popoutTriangleClassName={infoButtonPopoutTriangleClassName}
        logoutLabel={i18n.t('widget.account.logout')}
        tooltipSide={tooltipSide}
        userEmail={userEmail}
        isFocusable={isFocusable}
        onLogoutClick={onLogoutClick}
        dataHook="info-button-with-tooltip"
      />
    );
  }

  renderAccountButtonSimple() {
    const { infoButtonClassName, isFocusable } = this.props;

    return (
      <AccountInfoButton
        className={infoButtonClassName}
        isFocusable={isFocusable}
        onClick={this.handleAccountButtonClicked}
      />
    );
  }

  renderAccountButton() {
    const { isSubscriptionEnabled } = this.props;

    return isSubscriptionEnabled
      ? this.renderAccountButtonSimple()
      : this.renderAccountButtonWithTooltip();
  }

  render() {
    const {
      className,
      userEmail,
      pubSubRecipientName,
      onSubscriptionCancelClick,
    } = this.props;

    if (!userEmail) {
      return this.renderLoginButton();
    }

    return (
      <div
        className={classnames('qa-account', className)}
        styleName="account-info-button-wrapper"
      >
        {onSubscriptionCancelClick && (
          <SubscriptionCancelHandler
            onSubscriptionCancel={onSubscriptionCancelClick}
            pubSubRecipientName={pubSubRecipientName}
          />
        )}

        {this.renderAccountButton()}
      </div>
    );
  }
}
