export function attempt(fn, defaultValue) {
  try {
    return fn();
  } catch (ex) {
    return defaultValue;
  }
}

export function invokeSafely(fn, defaultValue = null) {
  return function() {
    try {
      return fn.apply(this, arguments); // eslint-disable-line no-invalid-this
    } catch (ex) {
      return defaultValue;
    }
  };
}
