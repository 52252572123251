import _ from 'lodash';
import { createSelector } from 'reselect';

export function getAllSettings(state) {
  return state.vodSettings;
}

export function getCurrency(state) {
  return _.get(state, 'vodSettings.currency', 'USD');
}

export const getDealInfo = createSelector(
  getAllSettings,
  _.property('dealInfo'),
);
export const getAllowDownload = createSelector(
  getAllSettings,
  _.property('allowDownload'),
);
export const getMemberOnly = createSelector(
  getAllSettings,
  _.property('memberOnly'),
);
