import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import { VODCSSModules } from 'shared/utils/wix-connect';
import { LinkButton } from 'shared/components/button/button';
import Icon from 'shared/components/icon/icon';
import { isChannelInfoVisible } from 'shared/utils/layout-helpers';
import { sendBiEvent } from 'shared/bi/send-bi-event';

import { withStyles } from 'shared/utils/withStyles';
import styles from './channel-actions.styl';

@connect(
  state => ({ isVisible: isChannelInfoVisible(state.appSettings) }),
  { sendBiEvent },
)
@withStyles(styles)
@VODCSSModules(styles)
export default class ChannelInfoButton extends React.Component {
  static propTypes = {
    isVisible: PropTypes.bool,
    isFocusable: PropTypes.bool,
    channelData: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  componentDidMount() {
    this.sendAboutInfoDisplayedBI();
  }

  componentDidUpdate() {
    this.sendAboutInfoDisplayedBI();
  }

  sendAboutInfoDisplayedBI() {
    if (this.props.isVisible) {
      this.doSendAboutInfoDisplayedBI();
    }
  }

  doSendAboutInfoDisplayedBI = _.once(() => {
    this.props.sendBiEvent('widget.aboutCh.displayed');
  });

  handleClick = event => {
    this.props.sendBiEvent('widget.aboutCh.clicked');
    this.props.onClick(event);
  };

  render() {
    const { className, isVisible, isFocusable } = this.props;

    if (!isVisible) {
      return null;
    }

    return (
      <LinkButton
        className={className}
        styleName="action info"
        dataHook="channel-info-button"
        isFocusable={isFocusable}
        ariaLabel={i18n.t('widget.accessibility.show-channel-info')}
        onClick={this.handleClick}
      >
        <Icon name="info-1" />
      </LinkButton>
    );
  }
}
