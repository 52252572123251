/* eslint-disable camelcase */

import { PublicChannel } from '@wix/wix-vod-api-site-based/dist/src/public/services/channel/channel';
import { toError } from 'api/parsers/transport-error';
import { keysToCamelCase } from '@wix/wix-vod-shared/dist/src/common/utils/object';
import { publicCatalogServiceConfig } from 'api/config/catalog-service';
import { CHANNEL_TYPES } from '@wix/wix-vod-constants/dist/common/channel-types';
import { parseChannel } from './parsers';
import { createCtx } from '@wix/wix-vod-shared/dist/src/common/utils/context';

export const createService = () =>
  new PublicChannel({
    privateServiceConfig: publicCatalogServiceConfig,
  });
export const service = createService();

export const ctx = createCtx();

export const getVideoPreviewUrls = (channelId, instance) => {
  // const service = ctx.getValue();
  return service
    .getVideoPreviewUrls(channelId, instance)
    .then(response => response.data);
};

export const getMemberInfo = async (
  channelId,
  instance,
  { hydratedMemberInfo } = {},
) => {
  // const service = ctx.getValue();

  try {
    return keysToCamelCase(
      hydratedMemberInfo || (await service.getMemberInfo(channelId, instance)),
    );
  } catch (error) {
    return toError(error.response);
  }
};

export const getPublic = async (channelId, { hydratedChannel } = {}) => {
  // const service = ctx.getValue();

  const response = hydratedChannel || (await service.getInfo(channelId));

  try {
    const channel = response.data;
    const {
      custom_cover_url,
      cover_url,
      featured_item,
      channel_type,
    } = channel;

    if (
      !custom_cover_url &&
      !cover_url &&
      featured_item &&
      channel_type !== CHANNEL_TYPES.YOUTUBE_FEED
    ) {
      channel.cover_url = extractCoverFromVideoItem(channel.featured_item);
    }

    return { data: parseChannel(channel) };
  } catch ({ response }) {
    return toError(response);
  }
};

const extractCoverFromVideoItem = videoItem =>
  videoItem.custom_cover_url || videoItem.cover_url;
