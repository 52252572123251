import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import classnames from 'classnames';

import { areElementsRounded } from 'shared/selectors/app-settings';
import socialNetworkNames from '@wix/wix-vod-shared/dist/src/widget/constants/social-networks';

import SocialButton from './social-button/social-button';

import { withStyles } from 'shared/utils/withStyles';
import styles from './social-share.styl';

@connect(state => ({ areElementsRounded: areElementsRounded(state) }))
@withStyles(styles)
export default class SocialShare extends React.Component {
  static propTypes = {
    areElementsRounded: PropTypes.bool,
    networks: PropTypes.arrayOf(PropTypes.string),
    content: PropTypes.shape({
      siteUrl: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    }),

    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    linkClassName: PropTypes.string,
    popoutOuterClassName: PropTypes.string,
    popoutInnerClassName: PropTypes.string,
    networksClassNames: PropTypes.object,
    onShare: PropTypes.func,
    children: PropTypes.node,
    channelId: PropTypes.string,
    videoId: PropTypes.string,
    isButtonsFocusable: PropTypes.bool,
  };

  static defaultProps = {
    networks: _.values(socialNetworkNames),
    networksClassNames: {},
    onShare: _.noop,
  };

  render() {
    const {
      areElementsRounded,
      networks,
      content,
      className,
      popoutOuterClassName,
      popoutInnerClassName,
      buttonClassName,
      networksClassNames,
      linkClassName,
      onShare,
      children,
      channelId,
      videoId,
      isButtonsFocusable,
    } = this.props;

    return (
      <div
        className={classnames(styles['social-share'], className)}
        data-hook="share-options"
      >
        {_.map(networks, network => {
          const ariaLabel =
            network === socialNetworkNames.LINK
              ? i18n.t('widget.accessibility.get-link')
              : i18n.t('widget.accessibility.share-on', { network });

          return (
            <SocialButton
              popoutOuterClassName={popoutOuterClassName}
              popoutInnerClassName={popoutInnerClassName}
              className={classnames(
                buttonClassName,
                networksClassNames[network],
              )}
              rounded={areElementsRounded}
              key={network}
              network={network}
              content={content}
              linkClassName={linkClassName}
              onClick={onShare}
              ariaLabel={ariaLabel}
              isFocusable={isButtonsFocusable}
              channelId={channelId}
              videoId={videoId}
            />
          );
        })}

        {children}
      </div>
    );
  }
}
