import { getBuyButtonDisplayTypeWhenInOverlay } from '@wix/wix-vod-shared/dist/src/common/utils/overlay-buttons-utils';
import { isVideoOverQuota } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/quota';
import { canAccessFullVideo } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { getPlayButtonTypeString } from 'shared/utils/bi/video-overlay-events-params';
import { logWidgetVidClick } from 'shared/utils/bi/widget-common-events';
import { logBi } from 'shared/worker/actions/bi';
import { createProxy } from 'shared/worker/lib';

export const logChannelCoverButtonClicked = button => {
  return logBi('widget.chCover.btn.clicked', { button });
};

export const logSubscriptionPopupClosed = () => {
  return logBi('widget.subscriptionPopUp.Completed', {
    result: 'canceled',
    errorMsg: '',
  });
};

export const logVideoButtonClicked = (videoItem, button) => {
  return logBi('widget.VidSelected.btn.clicked', {
    button,
    buttonType: 'main',
    videoID: videoItem.id,
    overQuoted: isVideoOverQuota(videoItem),
  });
};

export const logPlayButtonClicked = createProxy(
  'logPlayButtonClicked',
  ({ channelData, videoItem, currentSiteUser, isChannelCover }) => dispatch => {
    if (isChannelCover) {
      const button = canAccessFullVideo(channelData, videoItem, currentSiteUser)
        ? 'startWatching'
        : 'watchPreview';

      dispatch(logChannelCoverButtonClicked(button));
    } else {
      dispatch(
        logVideoButtonClicked(
          videoItem,
          getPlayButtonTypeString(channelData, videoItem, currentSiteUser),
        ),
      );
    }

    dispatch(logWidgetVidClick({ videoItem, channelData }));
  },
);

export const logPurchaseButtonClicked = ({
  appSettings,
  videoItem,
  channelData,
  isChannelCover,
}) => dispatch => {
  const buttonDisplayType = getBuyButtonDisplayTypeWhenInOverlay({
    settings: appSettings,
    videoItem,
    channelData,
    showChannelCover: isChannelCover,
  });

  dispatch(logVideoButtonClicked(videoItem, buttonDisplayType));
};
