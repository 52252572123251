import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import { connect } from 'react-redux';
import fedopsLogger from 'shared/utils/fedops-logger';
import mobileFedopsLogger from 'loggers/fedops/mobile-widget';

import { addSingleMeasurement } from 'shared/utils/performance/index';
import { getOpenedEventParams } from 'shared/utils/bi/video-overlay-events-params';
import { logBi } from 'shared/worker/actions/bi';
import { performanceApplicationLoaded } from 'shared/worker/actions/performanceLogger';
import PERFORMANCE_TYPES from 'shared/utils/performance/types';
import { CHANNEL_LAYOUT_VALUES } from '@wix/wix-vod-constants/dist/app-settings/channel-layout-values';
import {
  getAllSettings,
  getChannelLayout,
} from 'shared/selectors/app-settings';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getMainVideo } from 'widget/selectors/get-video';
import { shouldShowChannelCover } from '@wix/wix-vod-shared/dist/src/common/utils/overlay-buttons-utils';
import { sendLoadComplete } from 'widget/utils/load-complete';
import { withWidgetProps } from '../widget-props';

const LAYOUTS_WITH_CHANNEL_COVER = [
  CHANNEL_LAYOUT_VALUES.CLASSIC,
  CHANNEL_LAYOUT_VALUES.COMPACT,
  CHANNEL_LAYOUT_VALUES.STRIP,
];

@connect(
  state => ({
    appSettings: getAllSettings(state),
    channel: getChannelForWidget(state),
    mainVideo: getMainVideo(state),
    isSiteMode: viewModeSelectors.isSiteMode(state),
    viewMode: viewModeSelectors.getViewMode(state),
  }),
  {
    logBi,
    sendLoadComplete,
    logAppLoaded: fedopsLogger.appLoaded,
    performanceApplicationLoaded,
  },
)
@withWidgetProps(widgetProps => ({
  registerToComponentDidLayout: widgetProps.host.registerToComponentDidLayout,
}))
class PerformanceLoggers extends React.Component {
  static propTypes = {
    appSettings: PropTypes.object.isRequired,
    channel: PropTypes.object.isRequired,
    mainVideo: PropTypes.object,
    isSiteMode: PropTypes.bool.isRequired,
    viewMode: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.props.logAppLoaded();
    this.logWidgetOpenedBi();
  }

  logWidgetOpenedBi = () => {
    const {
      appSettings,
      channel,
      mainVideo,
      isSiteMode,
      viewMode,
      showChannelCover,
    } = this.props;

    addSingleMeasurement(PERFORMANCE_TYPES.APP_FULLY_RENDERED);

    this.props.registerToComponentDidLayout(() => {
      this.props.performanceApplicationLoaded();
    });

    this.props.logBi(
      'widget.opened',
      getOpenedEventParams(
        appSettings,
        channel,
        mainVideo,
        showChannelCover,
        viewMode,
      ),
    );

    if (isSiteMode) {
      this.props.sendLoadComplete();
    }
  };

  render() {
    return null;
  }
}

function showChannelCoverForWidget(state) {
  const channelLayout = getChannelLayout(state);
  const canShowChannelCover = includes(
    LAYOUTS_WITH_CHANNEL_COVER,
    channelLayout,
  );
  const appSettings = getAllSettings(state);
  return shouldShowChannelCover(appSettings, canShowChannelCover);
}

export const WidgetPerformanceLoggers = connect(
  state => ({
    showChannelCover: showChannelCoverForWidget(state),
  }),
  {
    logAppLoaded: fedopsLogger.appLoaded,
  },
)(PerformanceLoggers);

export const MobilePerformanceLoggers = connect(
  state => ({
    showChannelCover: !state.isVideosTouched,
  }),
  {
    logAppLoaded: mobileFedopsLogger.appLoaded,
  },
)(PerformanceLoggers);
