import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import classnames from 'classnames';

import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import AnimateCrossfade from 'widget/components/animate-crossfade/animate-crossfade';

import { withStyles } from 'shared/utils/withStyles';
import styles from './main-item-slider.styl';

@withStyles(styles)
export default class MainItemSlider extends React.Component {
  static propTypes = {
    loadedVideoIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    mainVideoId: PropTypes.string.isRequired,
    renderItem: PropTypes.func.isRequired,
    onAnimationComplete: PropTypes.func,

    isMainVideoAtChannelCover: PropTypes.bool,
    isPlayerVisible: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    isMainVideoAtChannelCover: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      wasPlayerVisible: false,
    };
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ wasPlayerVisible: this.props.isPlayerVisible });
  }

  componentDidUpdate(prevProps) {
    const { mainVideoId, isMainVideoAtChannelCover } = this.props;
    const isMainVideoIdChanged = prevProps.mainVideoId !== mainVideoId;
    const isMainVideoAtChannelCoverChanged =
      prevProps.isMainVideoAtChannelCover !== isMainVideoAtChannelCover;

    if (isMainVideoIdChanged || isMainVideoAtChannelCoverChanged) {
      // Because of animation for 0.3s. Needed to read updated player overlay
      // onTransitionEnd can`t be used because selected video can be updated to not rendered yet
      setTimeout(() => {
        // To read just current overlay instead of all rendered
        const visibleOverlayRef =
          this.containerRef &&
          this.containerRef.querySelector(
            '[data-is-visible-overlay="visible"]',
          );
        focus(visibleOverlayRef);
      }, 400);
    }
  }

  containerRef = null;

  saveContainerRef = ref => {
    this.containerRef = ref;
  };

  renderItem = (videoId, index, mainVideoIdIndex) => {
    const isCurrentOrPrevOrNext = Math.abs(mainVideoIdIndex - index) <= 1;

    if (!isCurrentOrPrevOrNext) {
      return <div key={videoId} />;
    }

    const isVisible = index === mainVideoIdIndex;
    return (
      <section
        className={styles.item}
        data-hook="main-item-slider-item"
        data-is-visible-overlay={isVisible ? 'visible' : null}
        aria-hidden={!isVisible}
        key={videoId}
      >
        {this.props.renderItem(videoId, isVisible)}
      </section>
    );
  };

  render() {
    const {
      loadedVideoIds,
      mainVideoId,
      className,
      onAnimationComplete,
      isPlayerVisible,
    } = this.props;
    const videoIdsToRender = loadedVideoIds.length
      ? loadedVideoIds
      : [mainVideoId];
    const mainVideoIdIndex = loadedVideoIds.length
      ? loadedVideoIds.indexOf(mainVideoId)
      : 0;

    return (
      <div
        className={classnames(styles.container, className)}
        ref={memoizedPartial(this.saveContainerRef)}
      >
        <AnimateCrossfade
          activeClassName={isPlayerVisible ? styles.active : ''}
          fadeInOnly={this.state.wasPlayerVisible}
          onAnimationComplete={onAnimationComplete}
          currentIdIndex={mainVideoIdIndex}
        >
          {_.map(videoIdsToRender, (videoId, index) =>
            this.renderItem(videoId, index, mainVideoIdIndex),
          )}
        </AnimateCrossfade>
      </div>
    );
  }
}
