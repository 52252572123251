import React from 'react';

import PropTypes from 'prop-types';
import { VODCSSModules } from 'shared/utils/wix-connect';

import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import MenuButton from 'widget/layouts/compact/components/menu-button/menu-button';
import Animate from '@wix/wix-vod-shared/dist/src/widget/ui-components/animate/animate';

import { withStyles } from 'shared/utils/withStyles';
import styles from './action-bar.styl';

@withStyles(styles)
@VODCSSModules(styles)
export default class ActionBar extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    height: PropTypes.number,
    areActionsVisible: PropTypes.bool,

    onActionsContainerClick: PropTypes.func,

    onMenuClick: PropTypes.func,
    onButtonsClick: PropTypes.func,
  };

  handleButtonsClick = event => {
    event.stopPropagation();
    this.props.onButtonsClick();
  };

  renderButtons() {
    const { onActionsContainerClick, children } = this.props;

    return (
      <div
        onClick={onActionsContainerClick}
        className={styles['clickable-container']}
      >
        <div styleName="buttons" onClick={this.handleButtonsClick}>
          {children}
        </div>
      </div>
    );
  }

  renderMenuButton() {
    const { onMenuClick } = this.props;

    return (
      <div className={styles['clickable-container']} onClick={onMenuClick}>
        <MenuButton
          styleName="menu-button"
          ariaLabel={i18n.t('widget.accessibility.show-all-videos')}
        />
      </div>
    );
  }

  render() {
    const { areActionsVisible, height } = this.props;

    return (
      <div style={{ height }} styleName="actions">
        <Animate
          activeClassName={styles['actions-wrapper']}
          dataHook="compact-actions"
          type="fade-in"
          when={areActionsVisible}
        >
          {this.renderButtons()}
        </Animate>
        <Animate
          type="fade-in"
          activeClassName={styles['menu-button-wrapper']}
          dataHook="menu-button-wrapper"
          when={!areActionsVisible}
        >
          {this.renderMenuButton()}
        </Animate>
      </div>
    );
  }
}
