import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { GenericButton } from '../button';
import PropTypes from 'prop-types';
import Icon from 'shared/components/icon/icon';

import { withStyles } from 'shared/utils/withStyles';
import styles from './icon.styl';

@withStyles(styles)
export default class IconButton extends React.Component {
  static propTypes = {
    Button: PropTypes.func,
    name: PropTypes.string,
    size: PropTypes.number,
    label: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.string,
    dataHook: PropTypes.string,
    iconClassName: PropTypes.string,
  };

  static defaultProps = {
    Button: GenericButton,
  };

  get label() {
    const { label, children } = this.props;
    const text = _.isUndefined(label) ? children : label;

    if (!text) {
      return null;
    }

    return <span className={styles.text}>{text}</span>;
  }

  render() {
    const {
      Button,
      name,
      size,
      iconClassName,
      className,
      dataHook,
      ariaLabel,
    } = this.props;
    const props = _.omit(
      this.props,
      'intl',
      'styles',
      'settings',
      'Button',
      'name',
      'size',
      'label',
      'children',
      'className',
      'dataHook',
      'iconClassName',
    );

    const label = this.label;

    const classNames = classnames(
      {
        [styles.generic]: Button === GenericButton,
      },
      className,
    );

    return (
      <Button
        {...props}
        ariaLabel={ariaLabel}
        dataHook={dataHook}
        className={classNames}
      >
        <Icon
          name={name}
          size={size}
          className={classnames(styles.icon, iconClassName)}
        />
        {label}
      </Button>
    );
  }
}
