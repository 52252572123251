import React from 'react';
import PropTypes from 'prop-types';

import Select from '@wix/wix-vod-shared/dist/src/widget/ui-components/select/select';
import { withStyles } from 'shared/utils/withStyles';
import styles from './embed-styles.styl';

@withStyles(styles)
class EmbedSelect extends React.Component {
  static propTypes = {
    dataHook: PropTypes.string,
    ariaLabel: PropTypes.string,
    selected: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    isRTL: PropTypes.bool,
  };

  handleChange = e => {
    this.props.onChange(e.target.value);
  };

  render() {
    const { options, selected, dataHook, ariaLabel, isRTL } = this.props;

    return (
      <Select
        dataHook={dataHook}
        ariaLabel={ariaLabel}
        className={styles.select}
        selected={selected}
        onChange={this.handleChange}
        options={options}
        isRTL={isRTL}
      />
    );
  }
}

export default EmbedSelect;
