import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { ContainerQuery } from 'react-container-query';

import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { callOnEnterPressExactOnElement } from 'shared/utils/call-on-keyboard-press';

import { createGetPlayButtonConfig } from 'widget/layouts/compact/components/player-overlay/ui-selectors/video-overlay/play/button-config';
import { createGetPaidAccessButtonConfig } from 'widget/layouts/compact/components/player-overlay/ui-selectors/video-overlay/paid-access/button-config';
import { isVideoPlayingOptimistic } from 'widget/selectors/video-playback-status';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import {
  canShowVideoListItemTitle,
  canShowVideoListItemPublisher,
  isClassicLayout,
} from 'shared/selectors/app-settings';

import LiveLabel from 'widget/containers/live-label/live-label';
import Icon from 'shared/components/icon/icon';
import Title from 'widget/layouts/compact/components/player-overlay/partials/title';
import Publisher from 'widget/layouts/compact/components/player-overlay/partials/publisher';
import PlayButton from 'widget/layouts/compact/components/player-overlay/partials/play-button';
import VideoCover from 'widget/layouts/compact/components/player-overlay/partials/video-cover';

import { withStyles } from 'shared/utils/withStyles';
import styles from './video-thumbnail-overlay.styl';

// TODO: replace `getPlayButtonConfig` and `getPaidAccessButtonConfig` with `getPlayButtonText` approach
// TODO: add `currentSiteUser` selector to `@connect` and remove it from parent components props
// see https://github.com/wix-private/wix-vod/pull/2003#discussion_r148538134 for details

const containerQuery = {
  [styles['element-max-199']]: {
    maxWidth: 199,
  },
  [styles['element-max-249']]: {
    minWidth: 200,
    maxWidth: 249,
  },
};

@connect(() => {
  const getPlayButtonConfig = createGetPlayButtonConfig();
  const getPaidAccessButtonConfig = createGetPaidAccessButtonConfig();
  return (state, ownProps) => ({
    playButtonConfig: getPlayButtonConfig(state, ownProps),
    paidAccessButtonConfig: getPaidAccessButtonConfig(state, ownProps),
    isLive: isLiveVideo(ownProps.videoItem),
    isPlaying: ownProps.isSelected && isVideoPlayingOptimistic(state),
    isShowVideoListItemTitle: canShowVideoListItemTitle(state),
    isShowVideoListItemPublisher: canShowVideoListItemPublisher(state),
    isClassicLayout: isClassicLayout(state),
  });
})
@withStyles(styles)
export default class VideoThumbnailOverlay extends React.Component {
  static propTypes = {
    videoItem: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,

    isThumbnailsPreviewHover: PropTypes.bool.isRequired,
    isInfoAlwaysShown: PropTypes.bool.isRequired,
    isInfoShownOnHover: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    isLive: PropTypes.bool,

    onClick: PropTypes.func.isRequired,
    onPlayRequest: PropTypes.func.isRequired,
    onPlayMemberOnlyRequest: PropTypes.func.isRequired,
    onPlayWithoutPreviewRequest: PropTypes.func.isRequired,
    onPurchaseRequest: PropTypes.func.isRequired,
    onRentRequest: PropTypes.func.isRequired,
    onSubscriptionRequest: PropTypes.func.isRequired,

    className: PropTypes.string,
    currentSiteUser: PropTypes.object,
    playButtonConfig: PropTypes.object,
    paidAccessButtonConfig: PropTypes.object,

    withFallbackColor: PropTypes.bool,
    isContentFocusable: PropTypes.bool,
    isDescriptionBelow: PropTypes.bool,
    isShowVideoListItemTitle: PropTypes.bool,
    isShowVideoListItemPublisher: PropTypes.bool,
    isClassicLayout: PropTypes.bool,
  };

  static defaultProps = {
    isContentFocusable: true,
    isDescriptionBelow: false,
    isShowVideoListItemTitle: true,
    isShowVideoListItemPublisher: true,
  };

  renderTitle() {
    const {
      videoItem,
      isPlaying,
      isDescriptionBelow,
      isShowVideoListItemTitle,
      isClassicLayout,
    } = this.props;

    return isClassicLayout && !isShowVideoListItemTitle ? null : (
      <Title
        className={styles.title}
        maxLinesCount={isDescriptionBelow ? 1 : 2}
        ariaHidden={isPlaying}
      >
        {videoItem.title}
      </Title>
    );
  }

  renderPublisher() {
    const {
      videoItem,
      isPlaying,
      isClassicLayout,
      isShowVideoListItemPublisher,
    } = this.props;

    return isClassicLayout && !isShowVideoListItemPublisher ? null : (
      <Publisher className={styles.publisher} ariaHidden={isPlaying}>
        {videoItem.publisher}
      </Publisher>
    );
  }

  renderDescription() {
    const title = this.renderTitle();
    const publisher = this.renderPublisher();
    if (!title && !publisher) {
      return null;
    }

    return (
      <div className={styles.description}>
        {title}
        {publisher}
      </div>
    );
  }

  renderPlayButton() {
    const { playButtonConfig, isContentFocusable, isPlaying } = this.props;

    if (!playButtonConfig) {
      return null;
    }

    return (
      <PlayButton
        className={styles['play-button']}
        iconClassName={styles['play-icon']}
        onClick={playButtonConfig.callback}
        isFocusable={isContentFocusable}
        ariaHidden={isPlaying}
        isIconOnly
      >
        {/*for accessibility only*/}
        {i18n.t(playButtonConfig.translationData.props)}
      </PlayButton>
    );
  }

  renderPaidAccessIcon() {
    const { paidAccessButtonConfig, isPlaying } = this.props;

    if (!paidAccessButtonConfig) {
      return null;
    }

    return (
      <Icon
        className={styles['paid-access-icon']}
        aria-hidden={isPlaying}
        dataHook="paid-icon"
        name="paid"
      />
    );
  }

  renderPlayingIndication() {
    const { isPlaying } = this.props;

    return (
      <div
        className={styles['now-playing']}
        tabIndex={-1}
        aria-hidden={!isPlaying}
      >
        {i18n.t('thumbnail.now-playing')}
      </div>
    );
  }

  renderDuration() {
    const {
      isLive,
      videoItem: { durationStr, isPlaying },
    } = this.props;

    if (!durationStr || isLive) {
      return null;
    }

    return (
      <div
        className={styles.duration}
        role="timer"
        aria-hidden={isPlaying}
        aria-label={i18n.t('widget.accessibility.duration', { durationStr })}
      >
        {durationStr}
      </div>
    );
  }

  renderLiveLabel() {
    const { videoItem } = this.props;

    return (
      <LiveLabel
        itemType={videoItem.itemType}
        mediaInfo={videoItem.mediaInfo}
        startTime={videoItem.dateStartLive}
        isSmall
        className={styles['live-label']}
        smallClassName={styles['live-scheduled-label']}
      />
    );
  }

  renderOverlay = queryClasses => {
    const {
      videoItem,
      className,
      isInfoAlwaysShown,
      isDescriptionBelow,
      isInfoShownOnHover,
      isThumbnailsPreviewHover,
      isSelected,
      isPlaying,
      width,
      height,
      isContentFocusable,
      onClick,
      isLive,
    } = this.props;

    if (!videoItem) {
      return null;
    }

    const classNames = classnames(styles.container, className, {
      [styles['info-visible']]: isInfoAlwaysShown,
      [styles['info-when-hover']]: isInfoShownOnHover,
      [styles['description-below']]: isDescriptionBelow,
      [styles['preview-hover']]: isThumbnailsPreviewHover,
      [styles.selected]: isSelected,
      [styles.playing]: isPlaying,
      ...queryClasses,
    });

    const thumbClassNames = classnames(styles.thumbnail, {
      [styles.live]: isLive,
    });

    const dataHook = classnames('video-list-thumb-wrapper', {
      'video-list-thumb-wrapper-selected': isSelected,
    });

    return (
      <section
        className={classNames}
        onClick={onClick}
        onKeyDown={memoizedPartial(callOnEnterPressExactOnElement, onClick)}
        data-hook={dataHook}
        style={{ width }}
        aria-hidden={!isContentFocusable}
        tabIndex={isContentFocusable ? 0 : -1}
      >
        <div className={thumbClassNames} style={{ height, width }}>
          <VideoCover
            className={styles.cover}
            videoItem={videoItem}
            width={width}
            height={height}
            withFallbackColor
          />
          <div className={styles.content}>
            {this.renderPlayingIndication()}
            {/* without publisher - is by design */}
            {!isDescriptionBelow && this.renderTitle()}
            {this.renderPaidAccessIcon()}
            {this.renderDuration()}
            {this.renderPlayButton()}
            {this.renderLiveLabel()}
          </div>
        </div>
        {isDescriptionBelow && this.renderDescription()}
      </section>
    );
  };

  render() {
    return (
      <ContainerQuery query={containerQuery}>
        {this.renderOverlay}
      </ContainerQuery>
    );
  }
}
