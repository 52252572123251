import { createAction } from 'redux-actions';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { notForPreview } from 'shared/utils/not-for-preview';
import { sendBiEvent } from 'shared/bi/send-bi-event';
import { openFullScreenSubscribeOverlay } from 'widget/utils/open-overlay';
import {
  logChannelCoverButtonClicked,
  logSubscriptionPopupClosed,
} from 'widget/components/player-overlay/bi';
import { createProxy } from 'shared/worker/lib';

const OPEN_SUBSCRIPTION = 'CLIENT.PLAYER_OVERLAY.OPEN_SUBSCRIPTION';
const openSubscriptionAction = createAction(OPEN_SUBSCRIPTION);

export const openSubscription = createProxy(
  'openSubscription',
  () => (dispatch, getState) => {
    const state = getState();
    const channelData = getChannelForWidget(state);

    notForPreview(() => {
      dispatch(sendBiEvent('widget.subscription.clicked'));
      dispatch(logChannelCoverButtonClicked('subscribe'));

      dispatch(
        openFullScreenSubscribeOverlay(channelData.id, () =>
          dispatch(logSubscriptionPopupClosed()),
        ),
      );
    })();

    dispatch(openSubscriptionAction());
  },
);
