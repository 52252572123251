import { handleActions } from 'redux-actions';

import { increment, decrement } from 'shared/redux/helpers/counter';

import { NAMES as SERVER_CHANNEL_VIDEOS_LIST } from 'shared/redux/server/actions/channel/videos/list-public';

export default handleActions(
  {
    [SERVER_CHANNEL_VIDEOS_LIST.START]: increment,
    [SERVER_CHANNEL_VIDEOS_LIST.SUCCESS]: decrement,
    [SERVER_CHANNEL_VIDEOS_LIST.FAIL]: decrement,
  },
  0,
);
