import _ from 'lodash';
import { createSelector } from 'reselect';
import { LIVE_STATUSES as LIVE_ITEM_TYPES } from '@wix/wix-vod-constants/dist/common/live-statuses';
import {
  isScheduledLive,
  isReadyLiveStream,
  isPendingLiveStream,
} from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/live';

const getVideos = state => _.get(state, 'entities.videos');

export const getLiveVideos = createSelector(
  getVideos,
  videos =>
    _.filter(videos, video => _.includes(LIVE_ITEM_TYPES, video.itemType)),
);

export const getLiveScheduledVideos = createSelector(
  getLiveVideos,
  videos => _.filter(videos, ({ itemType }) => isScheduledLive({ itemType })),
);

export const getLivePendingVideos = createSelector(
  getLiveVideos,
  videos =>
    _.filter(videos, ({ itemType, mediaInfo }) =>
      isPendingLiveStream({ itemType, mediaInfo }),
    ),
);

export const getLiveStreamingVideos = createSelector(
  getLiveVideos,
  videos =>
    _.filter(videos, ({ itemType, mediaInfo }) =>
      isReadyLiveStream({ itemType, mediaInfo }),
    ),
);
