import { PublicVideoCards } from '@wix/wix-vod-api-site-based/dist/src/public/services/video-cards/video-cards';
import { parseCards } from '@wix/wix-vod-shared/dist/src/common/parsers/cards/cards';
import { toError } from 'api/parsers/transport-error';
import { publicCatalogServiceConfig } from 'api/config/catalog-service';
import { createCtx } from '@wix/wix-vod-shared/dist/src/common/utils/context';

export const createService = () =>
  new PublicVideoCards(publicCatalogServiceConfig);
export const service = createService();

export const ctx = createCtx();

export const getVideoCards = (channelId, videoId) => {
  // const service = ctx.getValue();

  return service
    .getList(channelId, videoId)
    .then(response => ({
      ...response,
      data: parseCards(response.data),
    }))
    .catch(toError);
};
