import { openPreviewPrompt } from 'shared/redux/client/actions/modal';

import * as viewModeSelectors from 'widget/selectors/view-mode';

let store;

export const initNotForPreview = _store => {
  store = _store;
};

export const notForPreview = target => (...args) => {
  if (store) {
    const state = store.getState();
    const isPreview = viewModeSelectors.isPreviewMode(state);
    const isEditor = viewModeSelectors.isEditorMode(state);

    if (isPreview || isEditor) {
      store.dispatch(openPreviewPrompt());
      return;
    }
  }

  return target(...args);
};
