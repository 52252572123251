import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  sides,
  trianglePositions,
  popoutPositions,
  openOn,
} from 'shared/constants/popout';

export const sideShape = PropTypes.oneOf(_.values(sides));
export const trianglePositionShape = PropTypes.oneOf(
  _.values(trianglePositions),
);
export const popoutPositionShape = PropTypes.oneOf(_.values(popoutPositions));
export const openOnShape = PropTypes.oneOf(_.values(openOn));
