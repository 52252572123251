import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { withStyles } from 'shared/utils/withStyles';
import styles from './no-results.styl';

const NoResults = ({ className, dataHook, message, onButtonClick }) => (
  <section
    data-hook={dataHook}
    tabIndex="0"
    className={classnames(styles.container, className)}
  >
    <div>
      <div className={styles.title}>{message}</div>
      <button
        data-hook="reset-search-button"
        className={styles.button}
        onClick={onButtonClick}
      >
        {i18n.t('widget.search.clear-search')}
      </button>
    </div>
  </section>
);

NoResults.propTypes = {
  className: PropTypes.string,
  dataHook: PropTypes.string,
  message: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(NoResults);
