import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { VIDEO_PLAYBACK_STATUSES } from '@wix/wix-vod-constants/dist/common/video-playback-statuses';
import PlayerWrapper from './player-wrapper';
import {
  abortPlayVideo,
  pauseVideo,
  setPlaybackStatus,
} from 'widget/redux/client/actions/player/change-playback-status';
import {
  isVideoPaused,
  isVideoPausedOptimistic,
  isVideoPauseRequested,
  isVideoPlayAborted,
  isVideoPlaying,
  isVideoPlayingOptimistic,
  isVideoPlayRequested,
} from 'widget/selectors/video-playback-status';
import { isMuted } from 'shared/selectors/player';
import { setAutoPlayed } from 'widget/redux/client/actions/player/set-autoplayed';
import PropTypes from 'prop-types';
import { isMobile } from 'shared/selectors/form-factor';

function getDependentProps(paused, isInFullscreen) {
  return {
    showInitialOverlay: !isInFullscreen && paused,
  };
}

@connect(
  state => ({
    isVideoPlayRequested: isVideoPlayRequested(state),
    isVideoPauseRequested: isVideoPauseRequested(state),
    isVideoPlaying: isVideoPlaying(state),
    isVideoPaused: isVideoPaused(state),
    isVideoPlayingOptimistic: isVideoPlayingOptimistic(state),
    isVideoPausedOptimistic: isVideoPausedOptimistic(state),
    isVideoPlayAborted: isVideoPlayAborted(state),
    isMuted: isMuted(state),
    playAtFullScreen: !isMobile(state),
  }),
  {
    pauseVideo,
    setAutoPlayed,
    setPlaybackStatus,
    abortPlayVideo,
  },
)
export default class TabletPlayer extends React.Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    videoUrls: PropTypes.object,
    videoItem: PropTypes.object,
    channelData: PropTypes.object,
    size: PropTypes.object,
    simple: PropTypes.bool,
    overlay: PropTypes.any,
    onFullScreenChanged: PropTypes.func,
    onPlayStart: PropTypes.func,
    onEnded: PropTypes.func,
    preload: PropTypes.string,
    reportStats: PropTypes.func,
    fetchVideoUrls: PropTypes.func,
    appSettings: PropTypes.object,
    canShowChannelCover: PropTypes.bool,
    mainOrThumbnailLocation: PropTypes.string,
    muted: PropTypes.bool,
    loop: PropTypes.bool,
    currentSiteUser: PropTypes.object,
    onMemberSignUp: PropTypes.func,
    currentTime: PropTypes.number,
    setAutoPlayed: PropTypes.func,
    setPlaybackStatus: PropTypes.func,

    abortPlayVideo: PropTypes.func,
    onPause: PropTypes.func,
    onResume: PropTypes.func,
    isVideoCardsEnabled: PropTypes.bool,
    fetchVideoCards: PropTypes.func,
    onPlayRequest: PropTypes.func,
    shouldLoadVideo: PropTypes.bool,
    isOverQuota: PropTypes.bool,
    isLive: PropTypes.bool,
    cleanupVideoCards: PropTypes.func,
    isInLightbox: PropTypes.bool,
    isAutoplayed: PropTypes.bool,
    isVideoPlayRequested: PropTypes.bool,
    isVideoPauseRequested: PropTypes.bool,
    isVideoPlaying: PropTypes.bool,
    isVideoPaused: PropTypes.bool,
    isVideoPlayingOptimistic: PropTypes.bool,
    isVideoPausedOptimistic: PropTypes.bool,
    isVideoPlayAborted: PropTypes.bool,
  };

  static defaultProps = {
    onFullScreenChanged: _.noop,
    onPlayStart: _.noop,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...getDependentProps(props.paused, false),
      paused: props.paused,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { paused } = nextProps;
    const { isInFullScreen } = this.state;

    if (this.props.paused !== paused) {
      const newState = {
        ...getDependentProps(paused, isInFullScreen),
        paused,
      };

      // do not show overlay when in full screen (android case)
      if (paused && isInFullScreen) {
        newState.showInitialOverlay = false;
      }

      this.setState(newState);
    }
  }

  onPlayStart = () => {
    const { setPlaybackStatus, onPlayStart } = this.props;

    setPlaybackStatus(VIDEO_PLAYBACK_STATUSES.PLAYING);
    onPlayStart();

    this.setState(getDependentProps(false, true));
  };

  onFullScreenChanged = isInFullScreen => {
    this.props.onFullScreenChanged(isInFullScreen);

    let state = {
      isInFullScreen,
    };

    if (!isInFullScreen) {
      this.props.pauseVideo();
      const newPaused = true;

      state = {
        ...state,
        paused: newPaused,
        ...getDependentProps(newPaused, isInFullScreen),
      };
    }

    this.setState(state);
  };

  onPause = () => {
    const { setPlaybackStatus, isVideoPaused, setAutoPlayed } = this.props;

    if (!isVideoPaused) {
      setAutoPlayed(false);
      setPlaybackStatus(VIDEO_PLAYBACK_STATUSES.PAUSED);
    }
  };

  onResume = () => {
    const { setPlaybackStatus } = this.props;

    setPlaybackStatus(VIDEO_PLAYBACK_STATUSES.PLAYING);
  };

  onEnded = () => {
    const { setPlaybackStatus } = this.props;

    setPlaybackStatus(VIDEO_PLAYBACK_STATUSES.ENDED);
  };

  render() {
    const { playAtFullScreen } = this.props;
    const { paused, showInitialOverlay } = this.state;

    const props = _.omit(
      this.props,
      'paused',
      'mobileMode',
      'playAtFullScreen',
      'onFullScreenChanged',
      'onPurchaseClick',
      'onSubscribeClick',
      'onRentClick',
    );

    return (
      <PlayerWrapper
        {...props}
        showInitialOverlayOnPause
        mobileMode
        paused={paused}
        playAtFullScreen={playAtFullScreen}
        showInitialOverlay={showInitialOverlay}
        onPlayStart={this.onPlayStart}
        onPause={this.onPause}
        onResume={this.onResume}
        onEnded={this.onEnded}
        onFullScreenChanged={this.onFullScreenChanged}
      />
    );
  }
}
