import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addLocaleData, IntlProvider } from 'react-intl';
import translations from '@wix/wix-vod-shared/dist/src/common/i18n/translations';
import { getExperiments, experimentsFetched } from '../selectors/experiments';
import { getHydratedData } from '../../widget/redux/client/hydrated-data/hydrated-data';
import { getLocale } from 'shared/selectors/app-settings';
import reactIntlData from 'locales/react-intl-locale-data';
import { withWidgetProps } from 'widget/containers/widget-props';

@connect(state => {
  const { locale, translations, experiments } = getHydratedData(state);

  return {
    experiments: getExperiments(state),
    experimentsFetched: experimentsFetched(state),
    hydratedExperiments: experiments,
    hydratedLocaleData:
      locale && translations ? { locale, translations } : null,
    locale: getLocale(state),
  };
})
@withWidgetProps(({ captureException }) => ({ captureException }))
export default class LocaleProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    experiments: PropTypes.object,
    experimentsFetched: PropTypes.bool,

    hydratedExperiments: PropTypes.object,
    hydratedLocaleData: PropTypes.object,

    locale: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      locale: null,
      translationsReady: false,
      experimentsReady: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setLocaleData();
  }

  componentDidMount() {
    this.setLocaleData();
  }

  setLocaleData() {
    const { hydratedLocaleData } = this.props;

    if (!hydratedLocaleData) {
      return;
    }

    addLocaleData(reactIntlData[hydratedLocaleData.locale]);

    translations.set(hydratedLocaleData.translations);

    this.setState({
      locale: hydratedLocaleData.locale,
      translationsReady: true,
    });
    this.setExperiments();
  }

  componentDidUpdate() {
    this.setExperiments();
  }

  setExperiments = () => {
    if (!this.state.experimentsReady && this.props.experimentsFetched) {
      translations.setExperiments(this.props.experiments);
      this.setState({
        experimentsReady: true,
      });
    }
  };

  render() {
    const { locale, translationsReady } = this.state;
    const { children } = this.props;

    if (!translationsReady || !children) {
      return null;
    }

    return (
      <IntlProvider messages={translations.getAll()} locale={locale}>
        {children}
      </IntlProvider>
    );
  }
}
