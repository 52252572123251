import { createSelector } from 'reselect';
import {
  getCanShareVideo,
  canShowExpandedVideoShare,
  isStripLayout,
  isSliderLayout,
  isGridLayout,
  isPlayInFrame,
} from 'shared/selectors/app-settings';

export const canShareVideo = createSelector(
  getCanShareVideo,
  canShowExpandedVideoShare,
  isStripLayout,
  isSliderLayout,
  isGridLayout,
  isPlayInFrame,
  (
    isVideoShare,
    isExpandedVideoShare,
    isStrip,
    isSlider,
    isGrid,
    inFramePlay,
  ) => {
    if (isStrip) {
      return isExpandedVideoShare;
    }

    if (isSlider) {
      return isExpandedVideoShare;
    }

    if (inFramePlay) {
      return isVideoShare;
    }

    return isExpandedVideoShare;
  },
);
