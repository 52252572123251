import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  getVideoIdFromAppSettings,
  isSingleVideo,
} from 'shared/selectors/app-settings';
import { getVideoIdFromUrl } from './query-params';
import { getVideosGroupedByIds } from 'shared/selectors/videos';

export const getVideoIdToSelect = createSelector(
  [isSingleVideo, getVideoIdFromAppSettings, getVideoIdFromUrl],
  (isSingleVideo, singleVideoId, videoIdFromUrl) => {
    const videoIdToSelect = isSingleVideo ? singleVideoId : videoIdFromUrl;
    return videoIdToSelect;
  },
);

export const videoToSelectExists = createSelector(
  [getVideoIdToSelect, getVideosGroupedByIds],
  (videoIdToSelect, videos) => {
    return !_.isEmpty(videos[videoIdToSelect]);
  },
);
