import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { notForPreview } from 'shared/utils/not-for-preview';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isShareOverlayOpen } from 'widget/selectors/share-overlay';
import { openFullScreenShareOverlay } from 'shared/utils/open-overlay';
import { isInlineShareVisible } from 'widget/selectors/inline-share';
import { pauseVideo } from 'widget/redux/client/actions/player/change-playback-status';
import { closeShareOverlay } from 'widget/redux/client/actions/share-overlay';
import { showPlayerUI } from 'widget/redux/client/actions/player/ui';

@connect(
  (state, props) => ({
    isShareOpen: isShareOverlayOpen(state),
    mainVideoId: getMainVideoId(state),
    channel: getChannelForWidget(state),
    shouldOpenFullscreenModal: !isInlineShareVisible(state, props),
  }),
  {
    pauseVideo,
    closeShareOverlay,
    showPlayerUI,
    openFullScreenShareOverlay,
  },
)
export default class OpenFullscreenModalShare extends React.Component {
  static propTypes = {
    pauseVideo: PropTypes.func.isRequired,
    closeShareOverlay: PropTypes.func.isRequired,
    showPlayerUI: PropTypes.func.isRequired,
    isShareOpen: PropTypes.bool.isRequired,
    shouldOpenFullscreenModal: PropTypes.bool.isRequired,
    mainVideoId: PropTypes.string,
    channel: PropTypes.object,
    itemWidth: PropTypes.number.isRequired,
  };

  UNSAFE_componentWillReceiveProps({
    pauseVideo,
    isShareOpen,
    shouldOpenFullscreenModal,
    channel,
    mainVideoId,
    closeShareOverlay,
    showPlayerUI,
  }) {
    if (shouldOpenFullscreenModal && isShareOpen && !this.props.isShareOpen) {
      pauseVideo();
      closeShareOverlay();
      showPlayerUI();
      this.openFullScreenShareOverlay(channel.id, mainVideoId);
    }
  }

  openFullScreenShareOverlay = notForPreview((channelId, mainVideoId) => {
    this.props.openFullScreenShareOverlay(channelId, mainVideoId);
  });

  render() {
    return null;
  }
}
