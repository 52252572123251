import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isExternalVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import ExternalPlayer from '@wix/wix-vod-shared/dist/src/widget/player-external/player-external';
import NotAvailableOverlay from './components/not-available-overlay/not-available-overlay';
import { VODCSSModules } from 'shared/utils/wix-connect';

import { MAIN_ITEM_TYPE_VALUES } from '@wix/wix-vod-constants/dist/app-settings/main-item-type-values';
import { setFullScreenStatus } from 'widget/redux/client/actions/player/set-fullscreen-status';
import { setVolumeChangedByUser } from 'widget/redux/client/actions/player/sound';
import { isAllCardsOverlayVisible } from 'widget/selectors/layout';
import { isInFullScreen } from 'shared/selectors/player';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';

import InternalPlayer from './player-internal';
import ChannelCover from 'widget/containers/channel-cover/channel-cover';
import { withStyles } from 'shared/utils/withStyles';
import styles from './player.styl';
import { getLocale } from 'shared/selectors/app-settings';
import { ResponsiveResizedImage } from '@wix/wix-vod-shared/dist/src/widget/ui-components/responsive-resized-image/responsive-resized-image';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';

@connect(
  (state, ownProps) => ({
    isExternalVideo: isExternalVideo(ownProps.videoItem),
    isInFullScreen: isInFullScreen(state),
    shouldRenderInternalPlayer: !(
      ownProps.mobileMode && isExternalVideo(ownProps.videoItem)
    ),
    isAllCardsOverlayVisible: isAllCardsOverlayVisible(state),
    locale: getLocale(state),
    compId: getCompId(state),
  }),
  {
    setFullScreenStatus,
    setVolumeChangedByUser,
  },
)
@withStyles(styles)
@VODCSSModules(styles)
export default class Player extends React.Component {
  static propTypes = {
    appSettings: PropTypes.object,
    channelData: PropTypes.object,
    videoItem: PropTypes.object.isRequired,
    compId: PropTypes.string.isRequired,

    canShowChannelCover: PropTypes.bool,

    isVideoPlayRequested: PropTypes.bool,
    isVideoPauseRequested: PropTypes.bool,
    isVideoPlaying: PropTypes.bool,
    isVideoPaused: PropTypes.bool,
    isVideoPlayingOptimistic: PropTypes.bool,
    isVideoPausedOptimistic: PropTypes.bool,
    isVideoPlayAborted: PropTypes.bool,
    isInFullScreen: PropTypes.bool,
    isMuted: PropTypes.bool,
    shouldRenderInternalPlayer: PropTypes.bool,
    showPlayer: PropTypes.bool,

    showInitialOverlayOnPause: PropTypes.bool,
    showPoster: PropTypes.bool,
    simple: PropTypes.bool,
    mobileMode: PropTypes.bool,
    isExternalVideo: PropTypes.bool,

    children: PropTypes.any,

    width: PropTypes.number,
    height: PropTypes.number,

    abortPlayVideo: PropTypes.func,
    onPause: PropTypes.func,
    onPlayStart: PropTypes.func,
    onResume: PropTypes.func,
    onEnded: PropTypes.func,
    onFullScreenChanged: PropTypes.func,

    setFullScreenStatus: PropTypes.func,
    setVolumeChangedByUser: PropTypes.func,

    videoUrls: PropTypes.object,
    isOverQuota: PropTypes.bool,
    isInLightbox: PropTypes.bool,
    isAllCardsOverlayVisible: PropTypes.bool,
    dataHook: PropTypes.string,
  };

  static defaultProps = {
    onPause: _.noop,
    onPlayStart: _.noop,
    onResume: _.noop,
    onEnded: _.noop,

    showPlayer: true,
    showPoster: true,
    dataHook: 'player',
  };

  constructor(props) {
    super(props);

    this.playerWrapperRef = null;
    this.iframeRef = null;
    this.state = {
      beenPlayed: false,
      ended: false,
      mounted: false,
    };

    this.playerRef = React.createRef();
    this.overlayRef = React.createRef();
  }

  componentDidMount() {
    const playerRef = this.playerRef.current;
    const overlayRef = this.overlayRef.current;

    [playerRef, overlayRef].forEach(this.setStylesForRef);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.isVideoIdChanged(newProps)) {
      this.setState({ beenPlayed: false, ended: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { isVideoPlaying, isExternalVideo } = this.props;
    const isVideoPlayingChanged = prevProps.isVideoPlaying !== isVideoPlaying;

    if (isVideoPlayingChanged && isVideoPlaying) {
      focus(isExternalVideo ? this.iframeRef : this.playerWrapperRef);
    }
  }

  setStylesForRef = ref => {
    if (!ref) {
      return;
    }

    const { width, height } = this.getStyles();

    ref.style.width = `${width}px`;
    ref.style.height = `${height}px`;
  };

  isVideoIdChanged(newProps) {
    return newProps.videoItem.id !== this.props.videoItem.id;
  }

  saveIframeRef = ref => {
    this.iframeRef = ref;
  };

  savePlayerWrapperRef = ref => {
    this.playerWrapperRef = ref;
  };

  setPlayStatus = () => {
    const { isVideoPlaying } = this.props;

    if (!isVideoPlaying) {
      const { beenPlayed } = this.state;
      if (beenPlayed) {
        this.onResume();
      } else {
        this.onPlayStart();
      }
    }
  };

  get showChannelCover() {
    const { appSettings, canShowChannelCover } = this.props;
    return (
      canShowChannelCover &&
      appSettings.numbers.mainItemType === MAIN_ITEM_TYPE_VALUES.CHANNEL_COVER
    );
  }

  showVideoTitle() {
    return _.get(this.props, 'appSettings.booleans.showVideoTitle', true);
  }

  shouldShowInitialOverlay() {
    const {
      isVideoPlayingOptimistic,
      isVideoPausedOptimistic,
      isVideoPlayAborted,
      isInFullScreen,
      showInitialOverlayOnPause,
      isAllCardsOverlayVisible,
    } = this.props;

    if (isAllCardsOverlayVisible) {
      return false;
    }

    if (showInitialOverlayOnPause) {
      return isInFullScreen ? false : !isVideoPlayingOptimistic;
    }

    return (
      isVideoPlayAborted ||
      (!isVideoPlayingOptimistic && !isVideoPausedOptimistic)
    );
  }

  get posterUrl() {
    const { videoItem, channelData } = this.props;

    const { customCoverUrl } = videoItem;
    let { coverUrl } = videoItem;

    coverUrl = customCoverUrl || coverUrl;

    if (this.showChannelCover) {
      coverUrl = channelData.customCoverUrl || channelData.coverUrl || coverUrl;
    }

    return coverUrl;
  }

  get initialOverlay() {
    const { children } = this.props;
    const { beenPlayed, ended } = this.state;

    if (!children) {
      return null;
    }

    const { channelData } = this.props;
    const originProps = _.pick(
      this.props,
      'showChannelCover',
      'appSettings',
      'videoItem',
    );

    return React.cloneElement(
      children,
      _.assign(
        {
          channelData,
          beenPlayed,
          ended,
          showChannelCover: this.showChannelCover,
        },
        originProps,
      ),
    );
  }

  get poster() {
    const { simple, isExternalVideo, showPoster, width, height } = this.props;

    if (!showPoster) {
      return null;
    }

    if (simple && isExternalVideo) {
      return null;
    }

    const posterUrl = this.posterUrl;

    if (!posterUrl) {
      return null;
    }

    const { channelData } = this.props;

    // TODO: use separated video players for dashboard and widget !!!
    if (channelData) {
      return (
        <ChannelCover
          posterUrl={posterUrl}
          mediaInfo={channelData.mediaInfo}
          channelId={channelData.id}
          showVideoCover={this.showChannelCover}
          width={width}
          height={height}
        />
      );
    }

    return (
      <ResponsiveResizedImage
        styleName="cover-image"
        src={posterUrl}
        width={width}
        height={height}
        nonProportional
        progressive
      />
    );
  }

  get overlay() {
    if (!this.shouldShowInitialOverlay()) {
      return null;
    }

    return (
      <div styleName="overlay" ref={this.overlayRef} style={this.getStyles()}>
        {this.initialOverlay}
        {this.poster}
      </div>
    );
  }

  getStyles() {
    const { width, height } = this.props;
    return { width, height };
  }

  onPlayStart = () => {
    this.props.onPlayStart();
    this.setState({ beenPlayed: true });
  };

  onEnded = () => {
    this.setState({ ended: true, beenPlayed: false });
    this.props.onEnded();
  };

  onResume = () => {
    this.props.onResume();
    this.setState({ ended: false });
  };

  onFullScreenChanged = isInFullScreen => {
    const { setFullScreenStatus, onFullScreenChanged } = this.props;

    setFullScreenStatus(isInFullScreen);
    onFullScreenChanged(isInFullScreen);
  };

  onVolumeChange = () => {
    this.props.setVolumeChangedByUser();
  };

  onMuteChange = () => {
    this.props.setVolumeChangedByUser();
  };

  get player() {
    const {
      isOverQuota,
      width,
      height,
      shouldRenderInternalPlayer,
      isMuted,
      onPause,
    } = this.props;
    const props = _.omit(this.props, 'intl', 'styles');

    // NOTE: we need to put share overlay inside VideoPlayer
    // to be sure player would be shown in full screen mode
    if (isOverQuota) {
      return <NotAvailableOverlay width={width} height={height} />;
    }

    return (
      <div>
        <div
          className={classnames({
            [styles.hidden]: this.props.isExternalVideo,
          })}
        >
          {shouldRenderInternalPlayer && (
            <InternalPlayer
              {...props}
              muted={isMuted}
              canShowTitle={this.showVideoTitle()}
              onFullScreenChanged={this.onFullScreenChanged}
              onPlay={this.setPlayStatus}
              onPause={onPause}
              onEnded={this.onEnded}
              onVolumeChange={this.onVolumeChange}
              onMuteChange={this.onMuteChange}
            />
          )}
        </div>
        {this.props.isExternalVideo && (
          <ExternalPlayer
            {...props}
            locale={this.props.locale}
            muted={isMuted}
            onFullScreenChanged={this.onFullScreenChanged}
            onPlay={this.setPlayStatus}
            onPause={onPause}
            onEnded={this.onEnded}
            onIframeRef={this.saveIframeRef}
            onVolumeChange={this.onVolumeChange}
          />
        )}
      </div>
    );
  }

  render() {
    const { dataHook, showPlayer } = this.props;

    return (
      <div
        styleName="player"
        ref={this.playerRef}
        data-hook={dataHook}
        style={this.getStyles()}
      >
        {this.overlay}
        {showPlayer && (
          <div
            styleName="player-wrapper"
            dir="ltr"
            ref={this.savePlayerWrapperRef}
            aria-hidden={this.shouldShowInitialOverlay()}
          >
            {this.player}
          </div>
        )}
      </div>
    );
  }
}
