import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EDITOR_TYPE } from '@wix/wix-vod-constants/dist/common/editor-type';

import {
  getSliderHeight,
  isSliderLayout,
  getChannelLayout,
} from 'shared/selectors/app-settings';
import { getHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';

@connect(state => ({
  height: getSliderHeight(state),
  windowSize: state.windowSize,
  isSliderLayout: isSliderLayout(state),
  layout: getChannelLayout(state),
  hydratedEditorType: getHydratedData(state).editorType,
}))
export default class ForceSliderHeight extends React.Component {
  static propTypes = {
    height: PropTypes.number.isRequired,
    windowSize: PropTypes.object,
    children: PropTypes.node,
    isSliderLayout: PropTypes.bool,
    layout: PropTypes.number,
    hydratedEditorType: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      height: null,
    };
  }

  componentDidMount() {
    const { height, hydratedEditorType } = this.props;

    /**
     * ADI ignores default height of our widget saved in numbers.sliderHeight,
     * and sets it's own default height.
     * So we need to change size of parent container after mounting of slider
     * by our own.
     */

    if (
      hydratedEditorType === EDITOR_TYPE.ADI &&
      window.innerHeight !== height
    ) {
      this.setState({ height });
    }
  }

  UNSAFE_componentWillReceiveProps({
    height,
    isSliderLayout,
    layout,
    windowSize,
  }) {
    const isHeightChanged = height !== this.props.height;
    const isNextSlider = isSliderLayout && layout !== this.props.layout;

    if (isNextSlider || isHeightChanged) {
      this.setState({ height });
    }

    if (windowSize.height === this.state.height) {
      this.setState({ height: null });
    }
  }

  render() {
    return React.cloneElement(this.props.children, {
      forcedHeight: this.state.height,
    });
  }
}
