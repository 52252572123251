import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { getVideoIds } from 'widget/redux/client/lazy-channel-videos/selectors';
import { getIsSearching } from 'shared/selectors/search';

export default Target => {
  @connect(state => ({
    videoIds: getVideoIds(state),
    videoByIds: getVideosGroupedByIds(state),
    isSearching: getIsSearching(state),
  }))
  class WithPreviousVideos extends React.Component {
    static propTypes = {
      videoIds: PropTypes.array.isRequired,
      videoByIds: PropTypes.object.isRequired,
      isSearching: PropTypes.bool.isRequired,
    };

    constructor(props) {
      super(props);

      this.state = {
        prevVideoIds: props.videoIds,
        prevVideoByIds: props.videoByIds,
      };
    }

    UNSAFE_componentWillReceiveProps({ videoIds, videoByIds }) {
      if (
        this.props.videoIds.length !== videoIds.length &&
        this.props.videoIds.length
      ) {
        this.setState({ prevVideoIds: this.props.videoIds });
      }

      if (!_.isEqual(videoByIds, this.state.prevVideoByIds)) {
        this.setState({ prevVideoByIds: videoByIds });
      }
    }

    getVideoByIds() {
      const { isSearching, videoByIds } = this.props;
      return isSearching ? this.state.prevVideoByIds : videoByIds;
    }

    getVideoIds() {
      const { isSearching, videoIds } = this.props;
      return isSearching ? this.state.prevVideoIds : videoIds;
    }

    render() {
      return (
        <Target
          {...this.props}
          videoByIds={this.getVideoByIds()}
          videoIds={this.getVideoIds()}
        />
      );
    }
  }
  return WithPreviousVideos;
};
