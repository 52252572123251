import { createAction } from 'redux-actions';
import { openFullScreenOverlay } from 'shared/utils/open-overlay';
import { createProxy } from 'shared/worker/lib';

export const NAMES = {
  OPEN_FULL_SCREEN_MODAL_VIDEO: 'CLIENT.FULL_SCREEN_MODAL.VIDEO.OPEN',
  CLOSE_FULL_SCREEN_MODAL_VIDEO: 'CLIENT.FULL_SCREEN_MODAL.VIDEO.CLOSE',
};

const openFullScreenModalVideo = createAction(
  NAMES.OPEN_FULL_SCREEN_MODAL_VIDEO,
);

export const closeFullScreenVideoOverlay = createAction(
  NAMES.CLOSE_FULL_SCREEN_MODAL_VIDEO,
);

export const openFullScreenVideoOverlay = createProxy(
  'openFullScreenVideoOverlay',
  (channelId, videoId, autoplay = false, onClose) => dispatch => {
    dispatch(
      openFullScreenOverlay(
        `#/channel/${channelId}/video/${videoId}?autoplay=${autoplay}`,
        onClose,
      ),
    );
    dispatch(openFullScreenModalVideo());
  },
);
