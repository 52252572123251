import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import decorate from 'widget/utils/decorate';
import { notForPreview } from 'shared/utils/not-for-preview';

import { selectVideo } from 'widget/redux/client/actions/select-video';
import {
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
} from 'widget/redux/client/actions/full-screen-modal';

import InFrameActions from './in-frame';

// In Expanded View mode click on a thumbnail should select video in widget, not show overlay.
// Overlay is only used to play videos in compact layout.
@connect(
  null,
  {
    selectVideo,
    openFullScreenVideoOverlay,
    closeFullScreenVideoOverlay,
  },
)
@decorate(InFrameActions)
export default class InOverlayActionCallbacks extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    channelId: PropTypes.string,
    videoId: PropTypes.string,
    selectVideo: PropTypes.func,
    onSelectOrPlay: PropTypes.func,
    onPlayRequestedBi: PropTypes.func,
    openFullScreenVideoOverlay: PropTypes.func,
    closeFullScreenVideoOverlay: PropTypes.func,
    videoItem: PropTypes.object,
  };

  static defaultProps = {
    onPlayRequestedBi: _.noop,
  };

  showVideoAtOverlay = notForPreview(() => {
    const {
      channelId,
      videoId,
      openFullScreenVideoOverlay,
      closeFullScreenVideoOverlay,
    } = this.props;
    openFullScreenVideoOverlay(
      channelId,
      videoId,
      null,
      closeFullScreenVideoOverlay,
    );
  });

  playVideoAtOverlay = notForPreview(() => {
    const {
      channelId,
      videoId,
      openFullScreenVideoOverlay,
      closeFullScreenVideoOverlay,
    } = this.props;
    this.props.onPlayRequestedBi();
    openFullScreenVideoOverlay(
      channelId,
      videoId,
      true,
      closeFullScreenVideoOverlay,
    );
  });

  render() {
    return React.cloneElement(this.props.children, {
      ...this.props,
      onPlayRequest: this.playVideoAtOverlay,
      onPlayWithoutPreviewRequest: this.showVideoAtOverlay,
    });
  }
}
