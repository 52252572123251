import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { updateDeeplink } from 'widget/utils/widget-info/widget-info';
import { getPublic as getChannel } from 'widget/redux/server/actions/channel/get-public';
import { getPaymentInfo } from 'widget/redux/server/actions/channel/payment';
import { resetSearch } from 'widget/redux/client/actions/search';
import { resetSelectedVideo } from 'widget/redux/client/actions/select-video';
import { pauseVideo } from 'widget/redux/client/actions/player/change-playback-status';
import { closeModal } from 'shared/components/modal/utils';
import { ViewModeObserver } from 'widget/containers/view-mode-observer';

import { loadMoreVideoPages } from 'widget/redux/client/lazy-channel-videos/actions';
import {
  getAllSettings,
  getVideoIdFromAppSettings,
  getChannelIdFromAppSettings,
} from 'shared/selectors/app-settings';
import events from 'shared/constants/events';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import {
  consumePubSubEvent,
  withPubSubEvents,
} from 'widget/containers/pub-sub-events';
import WixEvents from 'shared/constants/wix-sdk';

const IGNORED_KEYS = ['previewType'];

const getChangedKeys = (target, source) => {
  const keys = _.union(_.keys(target), _.keys(source));
  return _.filter(
    keys,
    key => !_.includes(IGNORED_KEYS, key) && target[key] !== source[key],
  );
};

const getAppSettingsDifference = (props, nextProps) => {
  const {
    booleans: nextBooleans,
    numbers: nextNumbers,
  } = nextProps.appSettings;
  const { booleans: thisBooleans, numbers: thisNumbers } = props.appSettings;

  return {
    booleans: getChangedKeys(nextBooleans, thisBooleans),
    numbers: getChangedKeys(nextNumbers, thisNumbers),
  };
};

const hasChangesInAppSettings = (props, nextProps) => {
  const { booleans, numbers } = getAppSettingsDifference(props, nextProps);
  return booleans.length || numbers.length;
};

@withPubSubEvents
@connect(
  state => ({
    currentChannelId: getChannelIdFromAppSettings(state),
    currentVideoId: getVideoIdFromAppSettings(state),
    appSettings: getAllSettings(state),
    isEditor: viewModeSelectors.isEditorMode(state),
  }),
  {
    getChannel,
    getPaymentInfo,
    resetSearch,
    resetSelectedVideo,
    pauseVideo,
    fetchVideos: loadMoreVideoPages,
  },
)
export class EditorObserver extends React.Component {
  static propTypes = {
    currentChannelId: PropTypes.string.isRequired,
    currentVideoId: PropTypes.string,
    appSettings: PropTypes.object.isRequired,
    fetchVideos: PropTypes.func.isRequired,
    getChannel: PropTypes.func.isRequired,
    getPaymentInfo: PropTypes.func.isRequired,
    resetSearch: PropTypes.func.isRequired,
    resetSelectedVideo: PropTypes.func.isRequired,
    pauseVideo: PropTypes.func.isRequired,
    isEditor: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps) {
    consumePubSubEvent(
      WixEvents.SETTINGS_UPDATED,
      this.reloadOnDashboardClosing,
      {
        prevProps,
        nextProps: this.props,
      },
    );
    consumePubSubEvent(WixEvents.SITE_PUBLISHED, this.updateWidgetChannel, {
      prevProps,
      nextProps: this.props,
    });

    const { currentChannelId, currentVideoId } = this.props;

    if (
      currentChannelId !== prevProps.currentChannelId ||
      currentVideoId !== prevProps.currentVideoId
    ) {
      this.updateWidgetChannel();
    }
  }
  componentDidMount() {
    this.updateWidgetChannel();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isEditor && hasChangesInAppSettings(this.props, nextProps)) {
      this.reset();
    }
  }

  handleEditModeChange = () => {
    this.reset();
    closeModal();
  };

  reloadOnDashboardClosing = event => {
    if (event === events.DASHBOARD.CLOSED) {
      this.reloadData();
      this.reset();
    }
  };

  updateWidgetChannel = () => {
    const { currentChannelId, currentVideoId, isEditor } = this.props;

    if (isEditor) {
      updateDeeplink(currentChannelId, currentVideoId);
    }
  };

  reset = () => {
    const { resetSearch, resetSelectedVideo, pauseVideo } = this.props;

    pauseVideo();
    resetSearch();
    resetSelectedVideo();
  };

  reloadData() {
    const { currentChannelId } = this.props;
    const { getChannel, getPaymentInfo, fetchVideos } = this.props;

    getChannel(currentChannelId);
    getPaymentInfo(currentChannelId);
    fetchVideos();
  }

  render() {
    return <ViewModeObserver onChange={this.handleEditModeChange} />;
  }
}
