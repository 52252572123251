import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import formatMessageWithPrice from 'shared/utils/format-message-with-price';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { callOnEnterPressExactOnElement } from 'shared/utils/call-on-keyboard-press';

import {
  isThumbnailsPreviewHover,
  isVideosListInfoAlwaysShown,
  isVideosListInfoNeverShown,
} from 'shared/selectors/app-settings';
import { createGetPlayButtonConfig } from 'widget/layouts/compact/components/player-overlay/ui-selectors/video-overlay/play/button-config';
import { createGetPaidAccessButtonConfig } from 'widget/layouts/compact/components/player-overlay/ui-selectors/video-overlay/paid-access/button-config';
import { isShareOverlayOpen } from 'widget/selectors/share-overlay';
import Title from 'widget/layouts/compact/components/player-overlay/partials/title';
import PaidAccessButton from 'widget/layouts/compact/components/player-overlay/partials/paid-access-button';
import PlayButton from 'widget/layouts/compact/components/player-overlay/partials/play-button';
import VideoCover from 'widget/layouts/compact/components/player-overlay/partials/video-cover';
import LiveLabel from 'widget/containers/live-label/live-label';

import { withStyles } from 'shared/utils/withStyles';
import styles from './video-thumbnail-overlay.styl';

// TODO: replace `getPlayButtonConfig` and `getPaidAccessButtonConfig` with `getPlayButtonText` approach
// TODO: add `currentSiteUser` selector to `@connect` and remove it from parent components props
// see https://github.com/wix-private/wix-vod/pull/2003#discussion_r148538134 for details
@connect(() => {
  const getPlayButtonConfig = createGetPlayButtonConfig();
  const getPaidAccessButtonConfig = createGetPaidAccessButtonConfig();

  return (state, ownProps) => ({
    playButtonConfig: getPlayButtonConfig(state, ownProps),
    paidAccessButtonConfig: getPaidAccessButtonConfig(state, ownProps),

    isThumbnailsPreviewHover: isThumbnailsPreviewHover(state),
    isOverlayAlwaysShown: isVideosListInfoAlwaysShown(state),
    isOverlayNeverShown: isVideosListInfoNeverShown(state),

    isShareOverlayOpen: isShareOverlayOpen(state),
  });
})
@withStyles(styles)
export default class VideoThumbnailOverlay extends React.Component {
  static propTypes = {
    videoItem: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,

    isThumbnailsPreviewHover: PropTypes.bool.isRequired,
    isOverlayAlwaysShown: PropTypes.bool.isRequired,
    isOverlayNeverShown: PropTypes.bool.isRequired,

    onClick: PropTypes.func.isRequired,
    onPlayRequest: PropTypes.func.isRequired,
    onPlayMemberOnlyRequest: PropTypes.func.isRequired,
    onPlayWithoutPreviewRequest: PropTypes.func.isRequired,
    onSubscriptionRequest: PropTypes.func.isRequired,
    onPurchaseRequest: PropTypes.func.isRequired,

    className: PropTypes.string,
    currentSiteUser: PropTypes.object,
    playButtonConfig: PropTypes.object,
    paidAccessButtonConfig: PropTypes.object,

    withFallbackColor: PropTypes.bool,
    isContentFocusable: PropTypes.bool,

    isShareOverlayOpen: PropTypes.bool,
  };

  static defaultProps = {
    isContentFocusable: true,
    showChannelCover: false,
    onPlayRequest: _.noop,
    onPlayMemberOnlyRequest: _.noop,
    onPlayWithoutPreviewRequest: _.noop,
    onPurchaseRequest: _.noop,
    onSubscriptionRequest: _.noop,
  };

  renderTitle() {
    const { videoItem } = this.props;

    return (
      <div className={styles['title-block']}>
        <Title className={styles.title}>{videoItem.title}</Title>
      </div>
    );
  }

  renderPlayButton() {
    const { playButtonConfig, isContentFocusable } = this.props;

    if (!playButtonConfig) {
      return null;
    }

    return (
      <PlayButton
        className={styles.button}
        iconClassName={styles.icon}
        onClick={playButtonConfig.callback}
        isFocusable={isContentFocusable}
        isIconOnly
      >
        {/*for accessibility only*/}
        {i18n.t(playButtonConfig.translationData.props)}
      </PlayButton>
    );
  }

  renderPaidAccessButton() {
    const { paidAccessButtonConfig, isContentFocusable } = this.props;

    if (!paidAccessButtonConfig) {
      return null;
    }

    return (
      <PaidAccessButton
        className={styles.button}
        iconClassName={styles.icon}
        onClick={paidAccessButtonConfig.callback}
        isFocusable={isContentFocusable}
        isIconOnly
      >
        {/*for accessibility only*/}
        {formatMessageWithPrice(
          paidAccessButtonConfig.translationData.props,
          paidAccessButtonConfig.translationData.priceModel,
        )}
      </PaidAccessButton>
    );
  }

  renderLiveLabel() {
    const { videoItem } = this.props;

    return (
      <LiveLabel
        itemType={videoItem.itemType}
        mediaInfo={videoItem.mediaInfo}
        startTime={videoItem.dateStartLive}
        isSmall
        noBackground
        className={styles['live-label']}
        smallClassName={styles['shown-on-hover']}
      />
    );
  }

  render() {
    const {
      videoItem,
      className,
      isOverlayNeverShown,
      isOverlayAlwaysShown,
      isThumbnailsPreviewHover,
      width,
      height,
      isContentFocusable,
      onClick,
      isShareOverlayOpen,
    } = this.props;

    if (!videoItem) {
      return null;
    }

    const containerClassNames = classnames(styles.container, {
      [styles['always-hidden']]: isOverlayNeverShown,
      [styles['always-visible']]: isOverlayAlwaysShown,
      [styles['preview-hover']]: isThumbnailsPreviewHover,
      [styles['share-overlay-open']]: isShareOverlayOpen,
    });

    return (
      <section
        className={classnames(styles.wrapper, className)}
        onClick={onClick}
        onKeyDown={memoizedPartial(callOnEnterPressExactOnElement, onClick)}
        data-hook="video-list-thumb-wrapper"
        style={{ height, width }}
        aria-hidden={!isContentFocusable}
        tabIndex={isContentFocusable ? 0 : -1}
      >
        <VideoCover
          videoItem={videoItem}
          width={width}
          height={height}
          withFallbackColor
        />
        <div data-hook="player-overlay" className={containerClassNames}>
          <div className={styles.content}>
            <div className={styles['top-content']}>
              {this.renderTitle()}
              <div className={styles['buttons-wrapper']}>
                {this.renderPlayButton()}
                {this.renderPaidAccessButton()}
                {this.renderLiveLabel()}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
