export const DIMENSIONS = 16 / 9;
export const MIN_WIDTH = 200;
export const MIN_HEIGHT = Math.round(MIN_WIDTH / DIMENSIONS);

const EMBED_DEV_API_ENDPOINT = 'http://localhost:4000';
const EMBED_PROD_API_ENDPOINT = 'https://embed.wix.com/video';

export const EMBED_API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? EMBED_PROD_API_ENDPOINT
    : EMBED_DEV_API_ENDPOINT;
export const calculateHeight = width => Math.round(Number(width) / DIMENSIONS);
export const calculateWidth = height => Math.round(Number(height) * DIMENSIONS);
