import { noop, get } from 'lodash';
import { WixSDK as _BaseSDK } from '@wix/wix-vod-shared/dist/src/common/wix-sdk';
import { getWidgetSettingsByInstanceId } from '@wix/wix-vod-api/dist/src/private/widgets/widgets';
import isDefaultADIChannel from '@wix/wix-vod-shared/dist/src/common/utils/is-default-adi-channel';
import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';

const WixSDKOriginal = global.Wix;

const BaseSDK = _BaseSDK || {
  addEventListener: noop,
  removeEventListener: noop,
  Events: {},
  Utils: {},
  PubSub: { subscribe: noop, unsubscribe: noop },
  Performance: {
    applicationLoadingStep: noop,
    applicationLoaded: noop,
  },
};

const wrapWithCache = fn => {
  let cache = null;
  return (...args) => {
    if (cache) {
      return Promise.resolve(cache);
    }
    return fn(...args).then(response => {
      // once channel id is received it should not be changed
      cache = response;
      return cache;
    });
  };
};

const getChannelIdFromServer = wrapWithCache(({ instanceId }) =>
  getWidgetSettingsByInstanceId(instanceId)
    .then(data => data.items[0])
    .then(firstWidget => get(firstWidget, 'settings.listId'))
    .catch(() => Promise.resolve(null)),
);

const WixSDK = {
  ...BaseSDK,

  resizeComponent({ width = 0, height = 0 }, onSuccess = noop) {
    // Either width or height is mandatory
    if (width > 0 || height > 0) {
      WixSDKOriginal.resizeComponent({ width, height }, onSuccess);
    }
  },

  Utils: {
    ...BaseSDK.Utils,

    getViewMode() {
      const viewMode = WixSDKOriginal.Utils.getViewMode();
      return viewMode === VIEW_MODES.STANDALONE ? VIEW_MODES.SITE : viewMode;
    },
  },

  Styles: {
    ...BaseSDK.Styles,

    _getInitialChannelId() {
      const originInstanceId = WixSDK.Utils.getInstanceValue(
        'originInstanceId',
      );

      // handle case with multiple categories in ADI
      // for now they are taken from deeplinks service
      if (originInstanceId) {
        return getChannelIdFromServer({
          instanceId: originInstanceId,
        });
      }

      return WixSDK.Data.Public.get('channelId', {
        scope: 'COMPONENT',
      }).then(({ channelId }) => channelId);
    },

    /**
     * This is tmp workaround to allow retrieve channel id in ADI while switching between different design presets
     * @param params
     * @returns {*}
     * @private
     */
    extendStylesWithInitialChannelId(params) {
      const appSettingsChannelId = get(params, 'fonts.channelId');

      if (!isDefaultADIChannel(appSettingsChannelId)) {
        return Promise.resolve(params);
      }

      return this._getInitialChannelId()
        .then(channelId => {
          return {
            ...params,
            fonts: {
              ...params.fonts,
              channelId: channelId || appSettingsChannelId,
            },
          };
        })
        .catch(() => params);
    },

    getStyleParams() {
      return new Promise(WixSDKOriginal.Styles.getStyleParams).then(params =>
        WixSDK.Styles.extendStylesWithInitialChannelId(params),
      );
    },
  },
};

export default WixSDK;
