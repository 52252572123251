import { handleActions } from 'redux-actions';
import { NAMES } from 'widget/redux/client/actions/modules';

const defaultState = {
  player: true,
};

export default handleActions(
  {
    [NAMES.PLAYER_MODULE_LOADED]: state => ({
      ...state,
      player: true,
    }),
  },
  defaultState,
);
