import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Slider from 'shared/components/slider/slider';
import { withStyles } from 'shared/utils/withStyles';
import styles from './video-list.styl';
import VideoThumbnail from 'shared/components/video-thumbnail/video-thumbnail';
import ActionCallbacks from 'widget/containers/action-callbacks/action-callbacks';
import { loadMore } from 'widget/redux/client/lazy-channel-videos/actions';
import {
  getIsFetching,
  getCursor,
} from 'widget/redux/client/lazy-channel-videos/selectors';
import ClampText from '@wix/wix-vod-shared/dist/src/common/components/clamp-text/clamp-text';
import { ALIGNMENT } from '@wix/wix-vod-shared/dist/src/widget/ui-components/thumbnail/content/content';
import { getThumbnailSpacing } from 'shared/selectors/app-settings';

const BREAKPOINTS = [
  {
    max: 639,
    width: 560,
    height: 315,
  },
  {
    max: 1279,
    width: 592,
    height: 333,
  },
  {
    min: 1280,
    width: 608,
    height: 342,
  },
];

@connect(
  state => ({
    thumbnailSpacing: getThumbnailSpacing(state),
    nextVideosCursor: getCursor(state),
    isFetching: getIsFetching(state),
  }),
  {
    loadMore,
  },
)
@withStyles(styles)
export default class VideoList extends React.Component {
  static propTypes = {
    thumbnailSpacing: PropTypes.number.isRequired,
    channel: PropTypes.object.isRequired,
    videoIds: PropTypes.array.isRequired,
    videoByIds: PropTypes.object.isRequired,
    loadMore: PropTypes.func.isRequired,
    onPlayRequestedBi: PropTypes.func,
    isFetching: PropTypes.bool.isRequired,
    nextVideosCursor: PropTypes.string,
  };

  loadMore = itemsToLoadCount => {
    const { nextVideosCursor, isFetching, loadMore } = this.props;

    if (nextVideosCursor && !isFetching) {
      loadMore(itemsToLoadCount);
    }
  };

  renderThumbnailContent = ({
    title,
    description,
    publisher,
    alignment,
    onClick,
  }) => {
    const classes = classNames(styles.content, {
      [styles['align-left']]: alignment === ALIGNMENT.LEFT,
      [styles['align-center']]: alignment === ALIGNMENT.CENTER,
      [styles['align-right']]: alignment === ALIGNMENT.RIGHT,
    });

    return (
      <div className={classes} onClick={onClick}>
        <div className={styles.publisher}>{publisher}</div>
        <ClampText text={title} className={styles.title} />
        <ClampText text={description} className={styles.description} />
      </div>
    );
  };

  renderItem = videoId => {
    const {
      videoByIds,
      channel,
      currentSiteUser,
      onPlayRequestedBi,
      thumbnailSpacing,
    } = this.props;

    const isLoading = !videoId;
    const video = isLoading ? {} : videoByIds[videoId];

    return (
      <div style={{ paddingRight: thumbnailSpacing }}>
        <ActionCallbacks
          channelId={channel.id}
          videoItem={video}
          onPlayRequestedBi={onPlayRequestedBi}
          key={videoId}
        >
          <VideoThumbnail
            isLoading={isLoading}
            videoItem={video}
            key={videoId}
            channelData={channel}
            isContentFocusable
            currentSiteUser={currentSiteUser}
            breakpoints={BREAKPOINTS}
            renderThumbnailContent={this.renderThumbnailContent}
          />
        </ActionCallbacks>
      </div>
    );
  };

  render() {
    const { videoIds, channel } = this.props;

    return (
      <div className={styles.container}>
        <Slider
          items={videoIds}
          itemsCount={channel.videosCount}
          renderItem={this.renderItem}
          arrowClassName={styles.arrow}
          itemClassName={classNames(styles.item)}
          loadMore={this.loadMore}
        />
      </div>
    );
  }
}
