import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import Nav from '@wix/wix-vod-shared/dist/src/widget/ui-components/nav/nav';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { isExternalVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getChannelCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/cover';
import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import { notForPreview } from 'shared/utils/not-for-preview';

import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { VODCSSModules } from 'shared/utils/wix-connect';
import { openFullScreenChannelOverlay } from 'shared/utils/open-overlay';
import { openFullScreenMemberOnlyOverlay } from 'widget/utils/open-overlay';
import { storeForReload } from 'widget/utils/reload';
import {
  MAX_WIDGET_WIDTH,
  stripLayoutThumbnailSize,
} from 'widget/utils/videos-sizes/videos-sizes';
import {
  isChannelInfoVisible,
  isSignInVisible,
  isSearchInputVisible,
  isCategoriesDropdownVisible,
} from 'shared/utils/layout-helpers';
import { isSubscriptionButtonVisible } from 'shared/components/overlay-texts/channel/subscribe-button-text-utils';
import {
  selectVideo,
  resetSelectedVideo,
} from 'widget/redux/client/actions/select-video';
import {
  searchByString,
  searchByCategory,
} from 'widget/redux/client/actions/search';
import {
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
} from 'widget/redux/client/actions/full-screen-modal';
import { requestPlayVideo } from 'widget/redux/client/actions/request-play-video';

import {
  getVideoIdsByPageNumber,
  getCurrentPageIndex,
  getCurrentVideoIndex,
  getIsFetching,
  getVideoIds,
  hasPrevVideo,
  hasNextVideo,
} from 'widget/redux/client/lazy-channel-videos/selectors';

import { goToLazyVideosPageIndex } from 'widget/redux/client/lazy-channel-videos/actions';
import { playSelectedVideo } from 'widget/redux/client/player-overlay/actions/play-selected-video';

import { getMainVideo } from 'widget/selectors/get-video';
import { isVideoPlayingOptimistic } from 'widget/selectors/video-playback-status';
import {
  isThumbnailsPreviewHover,
  getAllSettings,
  isSingleVideo,
  canShowExpandedVideoShare,
  isMainItemChannelCover,
  canShowChannelShare,
  isVideoListAlwaysShow,
  isVideoListExpandOnClick,
  isVideoListNeverShow,
  isRTL,
} from 'shared/selectors/app-settings';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { getSearchQuery } from 'shared/selectors/search';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { getIsSearchResultEmpty } from 'widget/selectors/search-results';
import PlayerBlocksVisibility from 'widget/containers/player-blocks-visibility';
import { logWidgetSystem } from 'shared/worker/actions/bi';
import { sendBiEvent } from 'shared/bi/send-bi-event';

import Icon from 'shared/components/icon/icon';
import { LinkButton } from 'shared/components/button/button';
import { StripWidgetDropdown } from 'shared/components/dropdown/dropdown';
import AccountButton from 'widget/components/account/account-button/account-button';
import ChannelInfoButton from 'widget/components/channel-actions/channel-info-button';
import ChannelSubscriptionButton from 'widget/components/channel-actions/channel-subscription-button';
import StripPlayerWrapper from 'widget/layouts/strip/components/strip-player-wrapper/strip-player-wrapper';
import Categories from 'widget/components/categories/categories';
import SearchBar from 'widget/components/search-bar/search-bar';
import Videos from 'widget/layouts/strip/components/videos/videos';
import OverlayStrip from 'widget/components/player-overlay/strip/strip';
import EmptySearchResults from 'widget/components/empty-search-results/empty-search-results';
import Button from 'widget/components/button/button';
import DeeplinkPopups from 'widget/components/deeplink-popups/deeplink-popups';
import AccessibleVideosContainer from 'widget/components/accessible-videos-container/accessible-videos-container';
import ShareButton from 'widget/components/share-button/share-button';
import NoVideosContent from 'widget/components/no-content/no-videos-content/no-videos-content';
import { getCurrentChannelId } from 'widget/selectors/channel-info';

/* containers */
import { WidgetPerformanceLoggers } from 'widget/containers/performance-loggers/performance-loggers';
import PaymentEvents from 'shared/components/payment-events/payment-events';
import LiveStartHandler from 'widget/components/live-start-handler/live-start-handler';
import ChannelActionsContainer from 'shared/containers/channel-actions/channel-actions';
import { withPlayerModuleLoader } from 'widget/data-components/player-module-loader';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { setWidgetHeight } from 'shared/worker/actions/resize/set-widget-height';

import { withStyles } from 'shared/utils/withStyles';
import styles from './strip.styl';

const SEARCH_LINE_HEIGHT = 42;
const FIXED_NO_SEARCH_RESULT_MIN_HEIGHT = 350;
const RESIZE_INTERVAL = 300;
const DEFAULT_STATE = {
  isSearchBarExpanded: false,
  currentCategory: null,
  areAllVideosVisible: false,
};

@connect(
  state => ({
    windowSize: state.windowSize,
    mainVideoId: getMainVideoId(state),
    mainVideo: getMainVideo(state),
    selectedVideoId: state.selectedVideoId,
    isVideoPlaying: isVideoPlayingOptimistic(state),
    currentChannelId: getCurrentChannelId(state),
    canShowChannelCover: !state.isVideosTouched,
    appSettings: getAllSettings(state),
    isThumbnailsPreviewHover: isThumbnailsPreviewHover(state),
    firstChannelVideoId: state.firstChannelVideoId,
    isVideoShareVisible: canShowExpandedVideoShare(state),
    videoIdsByPageNumber: getVideoIdsByPageNumber(state),
    currentVideosPageNumber: getCurrentPageIndex(state),
    lazyLoadedVideoIds: getVideoIds(state),
    videoByIds: getVideosGroupedByIds(state),
    isVideosFetching: getIsFetching(state),
    currentSiteUser: getCurrentSiteUser(state),
    currentIdIndex: getCurrentVideoIndex(state),
    hasPrevVideo: hasPrevVideo(state),
    hasNextVideo: hasNextVideo(state),
    isSignInVisible: isSignInVisible(state.appSettings),
    isSearchInputVisible: isSearchInputVisible(
      state.appSettings,
      getChannelForWidget(state),
    ),
    isCategoriesDropdownVisible: isCategoriesDropdownVisible(
      state.appSettings,
      getChannelForWidget(state),
    ),
    isChannelShareVisible: canShowChannelShare(state),
    searchQuery: getSearchQuery(state),
    channelData: getChannelForWidget(state),
    isSingleVideo: isSingleVideo(state),
    isSearchResultEmpty: getIsSearchResultEmpty(state),
    isMainItemChannelCover: isMainItemChannelCover(state),
    isVideoListAlwaysShow: isVideoListAlwaysShow(state),
    isVideoListExpandOnClick: isVideoListExpandOnClick(state),
    isVideoListNeverShow: isVideoListNeverShow(state),
    isRTL: isRTL(state),
    isEditor: viewModeSelectors.isEditorMode(state),
  }),
  {
    selectVideo,
    resetSelectedVideo,
    requestPlayVideo,
    searchByString,
    searchByCategory,
    goToLazyVideosPageIndex,
    openFullScreenVideoOverlay,
    closeFullScreenVideoOverlay,
    playSelectedVideo,
    logWidgetSystem,
    storeForReload,
    sendBiEvent,
    openFullScreenMemberOnlyOverlay,
    setWidgetHeight,
    openFullScreenChannelOverlay,
  },
)
@withStyles(styles)
@VODCSSModules(styles)
export class StripView extends React.Component {
  static displayName = 'StripView';

  static propTypes = {
    currentSiteUser: PropTypes.object,
    currentChannelId: PropTypes.string,
    mainVideoId: PropTypes.string,
    mainVideo: PropTypes.object,
    isVideosFetching: PropTypes.bool,
    selectVideo: PropTypes.func.isRequired,
    canShowChannelCover: PropTypes.bool.isRequired,
    nextVideoId: PropTypes.string,
    channelData: PropTypes.object.isRequired,
    videoByIds: PropTypes.object.isRequired,
    videoIdsByPageNumber: PropTypes.array,
    appSettings: PropTypes.object.isRequired,
    windowSize: PropTypes.object.isRequired,
    searchQuery: PropTypes.string,
    openFullScreenVideoOverlay: PropTypes.func,
    closeFullScreenVideoOverlay: PropTypes.func,
    isSearchInputVisible: PropTypes.bool,
    isChannelShareVisible: PropTypes.bool.isRequired,
    isCategoriesDropdownVisible: PropTypes.bool,
    isThumbnailsPreviewHover: PropTypes.bool.isRequired,
    isSingleVideo: PropTypes.bool,
    isVideoShareVisible: PropTypes.bool,
    goToLazyVideosPageIndex: PropTypes.func.isRequired,
    lazyLoadedVideoIds: PropTypes.array.isRequired,
    currentIdIndex: PropTypes.number.isRequired,
    hasPrevVideo: PropTypes.bool.isRequired,
    hasNextVideo: PropTypes.bool.isRequired,
    searchByString: PropTypes.func.isRequired,
    searchByCategory: PropTypes.func.isRequired,
    isSearchResultEmpty: PropTypes.bool.isRequired,
    isMainItemChannelCover: PropTypes.bool.isRequired,
    requestPlayVideo: PropTypes.func.isRequired,
    selectedVideoId: PropTypes.string,
    isVideoPlaying: PropTypes.bool.isRequired,
    currentVideosPageNumber: PropTypes.number.isRequired,
    isSignInVisible: PropTypes.bool.isRequired,
    resetSelectedVideo: PropTypes.func.isRequired,
    isVideoListAlwaysShow: PropTypes.bool.isRequired,
    isVideoListExpandOnClick: PropTypes.bool.isRequired,
    isVideoListNeverShow: PropTypes.bool.isRequired,
    setWidgetHeight: PropTypes.func.isRequired,

    playSelectedVideo: PropTypes.func.isRequired,
    PlayerComponent: PropTypes.func,
    isPortableDevice: PropTypes.bool,
    isRTL: PropTypes.bool,
    isEditor: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchBarValue: props.searchQuery,
      isInitialVideosLoaded: false,
      ...DEFAULT_STATE,
      // TODO: move common logic to separate component
      ...this.getDefaultPlayerConfig(props),
    };
  }

  componentDidMount() {
    const { currentVideosPageNumber, goToLazyVideosPageIndex } = this.props;

    this.sendSubscriptionDisplayedOnWidget();
    this.resizeByContentInterval = setInterval(
      this.resizeByContent,
      RESIZE_INTERVAL,
    );

    if (this.shouldRenderVideos && !currentVideosPageNumber) {
      goToLazyVideosPageIndex(0);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isEditor && this.hasPreviewBecameActive(nextProps)) {
      this.reset(nextProps);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedVideoId, setWidgetHeight } = this.props;

    const isSelectedVideoChanged =
      prevProps.selectedVideoId !== selectedVideoId;
    if (isSelectedVideoChanged) {
      focus(this.playerOverlayContainerRef);
    }

    if (
      this.props.windowSize.height !== prevProps.windowSize.height &&
      this.currentContentHeight !== this.props.windowSize.height
    ) {
      setWidgetHeight(this.currentContentHeight, this.props.windowSize.width, {
        overflow: false,
      });
    }

    const isAllVideosOpened =
      prevState.areAllVideosVisible !== this.state.areAllVideosVisible &&
      this.shouldRenderVideos;
    if (isAllVideosOpened) {
      // because of scroll position will be updated after widget resize
      window.addEventListener('resize', this.focusVideosContainer);
    }
  }

  componentWillUnmount() {
    clearInterval(this.resizeByContentInterval);
  }

  videosContainerRef = null;
  playerOverlayContainerRef = null;
  containerRef = null;

  saveRef = (name, ref) => {
    this[name] = ref;
  };

  sendSubscriptionDisplayedOnWidget() {
    const { channelData } = this.props;
    if (isSubscriptionButtonVisible({ channel: channelData })) {
      this.props.sendBiEvent('widget.subscription.displayed', {
        whereDisplayed: 'widget',
      });
    }
  }

  focusVideosContainer = () => {
    focus(this.videosContainerRef);

    window.removeEventListener('resize', this.focusVideosContainer);
  };

  get shouldRenderVideos() {
    const {
      isThumbnailsPreviewHover,
      isVideoListAlwaysShow,
      isVideoListExpandOnClick,
    } = this.props;

    if (isThumbnailsPreviewHover) {
      return true;
    }

    return isVideoListExpandOnClick
      ? this.state.areAllVideosVisible
      : isVideoListAlwaysShow;
  }

  get shouldRenderAllVideosButton() {
    const {
      isVideoListExpandOnClick,
      isSingleVideo,
      isThumbnailsPreviewHover,
    } = this.props;

    if (isThumbnailsPreviewHover) {
      return false;
    }

    if (isSingleVideo) {
      return false;
    }

    return isVideoListExpandOnClick && !this.state.areAllVideosVisible;
  }

  get listThumbnailSize() {
    if (typeof window === 'undefined') {
      return {};
    }

    const widgetWidth = this.props.windowSize.width;
    const { videosInRow } = this.props.appSettings.numbers;
    return stripLayoutThumbnailSize(widgetWidth, videosInRow);
  }

  get videosListHeight() {
    const { appSettings, channelData } = this.props;
    const { videosInRow, numberOfRows } = appSettings.numbers;
    const videosCount = Math.min(
      channelData.videosCount,
      videosInRow * numberOfRows,
    );

    return this.listThumbnailSize.height * Math.ceil(videosCount / videosInRow);
  }

  isVideoPreviewMode() {
    // TODO: clean up video preview
    return false;
  }

  getDefaultPlayerConfig() {
    return {
      // TODO: move to selector or method. Probably will be used with isWidgetTouched (old canShowChannelCover)
      isVideoPreview: false,
    };
  }

  handleSlideToPrev = () => {
    this.props.logWidgetSystem('videoList.changePage.requested');
    const { currentVideosPageNumber, goToLazyVideosPageIndex } = this.props;
    goToLazyVideosPageIndex(currentVideosPageNumber - 1);
  };

  handleSlideToNext = () => {
    this.props.logWidgetSystem('videoList.changePage.requested');
    const { currentVideosPageNumber, goToLazyVideosPageIndex } = this.props;
    goToLazyVideosPageIndex(currentVideosPageNumber + 1);
  };

  handleThumbnailClick = video => {
    if (video.id !== this.props.mainVideoId || this.props.canShowChannelCover) {
      this.props.selectVideo(video.id);
    }
  };

  reset = (props = this.props) => {
    this.setState({
      searchBarValue: '',
      ...DEFAULT_STATE,
      ...this.getDefaultPlayerConfig(props),
      areAllVideosVisible: props.isThumbnailsPreviewHover,
    });

    this.props.goToLazyVideosPageIndex(0);
  };

  get contentHeight() {
    if (!this.containerRef) {
      return 0;
    }
    return this.containerRef.clientHeight;
  }

  resizeByContentInterval = null;
  currentContentHeight = 0;

  resizeByContent = () => {
    const { setWidgetHeight, windowSize } = this.props;

    const height = Math.ceil(this.contentHeight);

    if (!height || height === this.currentContentHeight) {
      return;
    }

    this.currentContentHeight = height;

    setWidgetHeight(height, windowSize.width, { overflow: false });
  };

  hasPreviewBecameActive(nextProps) {
    // TODO: rename to videoAsBackground
    return !this.state.isVideoPreview && this.isVideoPreviewMode(nextProps);
  }

  get categoriesList() {
    const categoriesStats =
      _.get(this.props, 'channelData.statsInfo.categories') || [];
    return _.map(categoriesStats, 'value');
  }

  get noSearchResults() {
    const styleNames = classnames('no-search-results', {
      stretched: this.videosListHeight < FIXED_NO_SEARCH_RESULT_MIN_HEIGHT,
    });

    return <EmptySearchResults styleName={styleNames} />;
  }

  get videosList() {
    const {
      appSettings,
      channelData,
      videoIdsByPageNumber,
      currentVideosPageNumber,
      isVideosFetching,
      mainVideo,
      currentSiteUser,
    } = this.props;

    const { videosInRow, numberOfRows } = appSettings.numbers;

    const noVideosLoaded = !videoIdsByPageNumber.length;

    if ((isVideosFetching && noVideosLoaded) || !mainVideo) {
      return null;
    }

    return (
      <Videos
        styleName="videos"
        containerWidth={this.playerSize.width}
        videoIdsByPageNumber={videoIdsByPageNumber}
        currentVideosPageNumber={currentVideosPageNumber}
        onSlideToNext={this.handleSlideToNext}
        onSlideToPrev={this.handleSlideToPrev}
        channelData={channelData}
        currentSiteUser={currentSiteUser}
        onPlayRequest={this.playVideo}
        onThumbnailClick={this.handleThumbnailClick}
        thumbnailSize={this.listThumbnailSize}
        numberOfRows={numberOfRows}
        numberOfColumns={videosInRow}
      />
    );
  }

  get closeVideosButton() {
    const { appSettings, isVideoListAlwaysShow } = this.props;
    const {
      showChannelShare,
      showChannelInfo,
      showSignIn,
    } = appSettings.booleans;
    const isAllActionsDisabled =
      !showChannelShare &&
      !showChannelInfo &&
      !this.canSubscribe &&
      !showSignIn;

    if (!this.shouldRenderVideos || isVideoListAlwaysShow) {
      return null;
    }

    return (
      <LinkButton
        className={styles.action}
        isFocusable={this.isChannelActionsFocusable()}
        onClick={this.handleUserToggleVisibilityAllVideos}
        dataHook="close-action-bar"
        ariaLabel={i18n.t('widget.accessibility.close-video-list')}
      >
        {isAllActionsDisabled && i18n.t('widget.strip-view.close')}
        <Icon
          className={
            styles[
              isAllActionsDisabled ? 'close-icon-with-label' : 'close-icon'
            ]
          }
          name="add"
        />
      </LinkButton>
    );
  }

  handleCategorySelect = category => {
    this.props.searchByCategory(category);
    this.props.logWidgetSystem('videoList.searchByCategory.requested');
    this.setState({
      currentCategory: category,
      searchBarValue: '',
    });
  };

  get categories() {
    const {
      isCategoriesDropdownVisible,
      appSettings,
      searchQuery,
      isVideoListNeverShow,
    } = this.props;
    const { isSearchBarExpanded, currentCategory, searchBarValue } = this.state;
    const { showChannelCategories } = appSettings.booleans;

    if (
      !isCategoriesDropdownVisible ||
      isVideoListNeverShow ||
      !showChannelCategories
    ) {
      return null;
    }

    const isHidden =
      isSearchBarExpanded ||
      (!currentCategory && (searchQuery || searchBarValue));
    const className = classnames(styles['categories-container'], {
      [styles.hidden]: isHidden,
    });

    return (
      // wrapper needed for focusing when switching from search bar to categories by tab
      <div className={className}>
        <Categories
          className={styles.categories}
          DropdownClass={StripWidgetDropdown}
          isFocusable={this.isChannelActionsFocusable()}
          height={SEARCH_LINE_HEIGHT}
          list={this.categoriesList}
          onCategorySelect={this.handleCategorySelect}
          selectedCategory={currentCategory}
        />
      </div>
    );
  }

  get canSubscribe() {
    const { channelData } = this.props;
    const subscriptionPriceInfo = dealInfoHelpers.getSubscriptionPriceInfo(
      channelData.dealInfo,
    );

    return !_.get(channelData, 'dgsInfo.subscription') && subscriptionPriceInfo;
  }

  storeDataForReload = () => {
    this.props.storeForReload({
      selectedVideoId: this.props.mainVideoId,
    });
  };

  setCurrentVideoFromPayment = paymentEvent => {
    if (paymentEvent.itemId) {
      this.props.selectVideo(paymentEvent.itemId);
    }
  };

  isChannelActionsFocusable() {
    return !this.props.searchQuery || this.state.currentCategory;
  }

  get signInButton() {
    const { isSignInVisible, channelData } = this.props;

    if (!isSignInVisible) {
      return null;
    }

    return (
      <ChannelActionsContainer
        channelId={channelData.id}
        onPageRefresh={this.storeDataForReload}
      >
        {channelActions => (
          <AccountButton
            className={classnames('qa-widget-channel-account', styles.action)}
            infoButtonClassName={styles['account-button']}
            isFocusable={this.isChannelActionsFocusable()}
            channelData={channelData}
            onClick={channelActions.showAccountInfo}
            onLoginClick={channelActions.logIn}
            onLogoutClick={channelActions.logOut}
            onSubscribeClick={channelActions.subscribe}
            onSubscriptionCancelClick={channelActions.cancelSubscription}
          />
        )}
      </ChannelActionsContainer>
    );
  }

  get channelActions() {
    const {
      channelData,
      searchQuery,
      appSettings,
      mainVideoId,
      isChannelShareVisible,
    } = this.props;
    const { currentCategory } = this.state;

    const closeVideoButton = this.closeVideosButton;

    if (
      !isChannelShareVisible &&
      !isChannelInfoVisible(appSettings) &&
      !isSubscriptionButtonVisible({ channel: channelData }) &&
      !isSignInVisible(appSettings) &&
      !closeVideoButton
    ) {
      return null;
    }

    const { isSearchBarExpanded, searchBarValue } = this.state;
    const submittedSearchValue = currentCategory ? '' : searchQuery;
    const isHidden =
      isSearchBarExpanded || submittedSearchValue || searchBarValue;
    const className = classnames(styles['channel-actions'], {
      [styles.hidden]: isHidden,
    });

    const isFocusable = this.isChannelActionsFocusable();

    return (
      <ChannelActionsContainer
        channelId={channelData.id}
        videoId={mainVideoId}
        onPageRefresh={this.storeDataForReload}
      >
        {channelActions => (
          <div className={className} data-hook="channel-actions">
            {isChannelShareVisible && (
              <ShareButton
                className={classnames(styles.action, styles['action-share'])}
                ariaLabel={i18n.t('widget.accessibility.share')}
                tabIndex={isFocusable ? 0 : -1}
                onClick={channelActions.showShare}
              />
            )}

            <ChannelInfoButton
              className={styles.action}
              channelData={channelData}
              isFocusable={isFocusable}
              onClick={this.showChannelInfo}
            />

            <ChannelSubscriptionButton
              className={styles.action}
              channelData={channelData}
              isFocusable={isFocusable}
              onClick={channelActions.subscribe}
            />

            {this.signInButton}
            {closeVideoButton}
          </div>
        )}
      </ChannelActionsContainer>
    );
  }

  showChannelInfo = event => {
    event.preventDefault();
    this.props.openFullScreenChannelOverlay(this.props.channelData.id);
  };

  openMembership = () => {
    this.props.openFullScreenMemberOnlyOverlay(
      this.props.channelData.id,
      this.props.mainVideoId,
    );
  };

  handleSearchInputFocus = () => {
    this.setState({ isSearchBarExpanded: true });
  };

  handleSearchInputBlur = () => {
    this.setState({ isSearchBarExpanded: false });
  };

  clearSearch = () => {
    this.setState({ searchBarValue: '', isSearchBarExpanded: false });

    if (!this.state.currentCategory) {
      //if category is selected nothing to reset
      this.props.searchByString('');
    }
  };

  searchByQuery = value => {
    this.props.searchByString(value);
    this.props.logWidgetSystem('videoList.searchByQuery.requested');
    this.setState({ currentCategory: null });
  };

  handleSearchInputChange = searchBarValue => {
    this.setState({ searchBarValue });
  };

  get searchBar() {
    const {
      isSearchInputVisible,
      appSettings,
      searchQuery,
      isVideoListNeverShow,
    } = this.props;
    const { currentCategory } = this.state;

    if (!isSearchInputVisible) {
      return null;
    }

    const { isSearchBarExpanded, searchBarValue } = this.state;
    const value = currentCategory ? '' : searchQuery;
    const isExpanded = isSearchBarExpanded || value || searchBarValue;
    const { booleans } = appSettings;
    const { showSearch } = booleans;

    if (isVideoListNeverShow || !showSearch) {
      return null;
    }

    return (
      <SearchBar
        layout="strip"
        className={classnames('qa-widget-searchbar', styles.search, {
          [styles.expanded]: isExpanded,
        })}
        value={value}
        onFocus={this.handleSearchInputFocus}
        onBlur={this.handleSearchInputBlur}
        onChange={this.handleSearchInputChange}
        onClose={this.clearSearch}
        onSearch={this.searchByQuery}
      />
    );
  }

  get actionsLine() {
    const {
      isVideoPlaying,
      isSingleVideo,
      isVideoListNeverShow,
      isPortableDevice,
    } = this.props;

    if (isSingleVideo) {
      return this.renderSingleVideoActionsLine();
    }

    const searchBar = this.searchBar;
    const categories = this.categories;
    const channelActions = this.channelActions;

    if (
      (!this.shouldRenderVideos && !isVideoListNeverShow) ||
      (isPortableDevice && isVideoPlaying) ||
      (!searchBar && !categories && !channelActions)
    ) {
      return null;
    }

    return (
      <div className={styles['search-line']} data-hook="widget-search-line">
        {channelActions}
        {searchBar}
        {categories}
      </div>
    );
  }

  renderSingleVideoActionsLine() {
    const { appSettings } = this.props;

    if (!isSignInVisible(appSettings)) {
      return null;
    }

    return (
      <div
        className={styles['search-line']}
        data-hook="single-video-actions-line"
      >
        <div
          className={styles['channel-actions']}
          data-hook="single-video-actions"
        >
          {this.signInButton}
        </div>
      </div>
    );
  }

  get allVideosButton() {
    const { isVideoPlaying, isPortableDevice } = this.props;
    if (
      !this.shouldRenderAllVideosButton ||
      (isPortableDevice && isVideoPlaying)
    ) {
      return null;
    }

    return (
      <div styleName="all-videos">
        <Button
          styleName="button"
          disableTheme
          dataHook="all-videos-button"
          onClick={this.handleUserToggleVisibilityAllVideos}
        >
          {i18n.t('widget.strip-view.all-videos')}
          <Icon name="add" styleName="icon" />
        </Button>
      </div>
    );
  }

  get videos() {
    const {
      channelData,
      isVideosFetching,
      videoIdsByPageNumber,
      searchQuery,
      currentVideosPageNumber,
      isSingleVideo,
      isSearchResultEmpty,
    } = this.props;

    if (!channelData || !this.shouldRenderVideos || isSingleVideo) {
      return null;
    }

    const videosContainerProps = {
      channelTitle: channelData.title,
      isChannelHasVideos: Boolean(channelData.videosCount),
      dataHook: 'video-list',
      isVideosFetching,
      videoIdsByPageNumber,
      searchQuery,
      currentVideosPageNumber,
    };

    if (!channelData.videosCount) {
      return (
        <AccessibleVideosContainer
          {...videosContainerProps}
          className={styles['coming-soon']}
          style={{ minHeight: this.listThumbnailSize.height }}
          onRef={memoizedPartial(this.saveRef, 'videosContainerRef')}
        >
          {i18n.t('widget.this-channel-is-coming-soon')}
        </AccessibleVideosContainer>
      );
    }

    return (
      <AccessibleVideosContainer
        {...videosContainerProps}
        className={styles['videos-container']}
        style={{ height: this.videosListHeight }}
        onRef={memoizedPartial(this.saveRef, 'videosContainerRef')}
      >
        {isSearchResultEmpty ? this.noSearchResults : this.videosList}
      </AccessibleVideosContainer>
    );
  }

  get playerSize() {
    const { width } = this.props.windowSize;

    if (!width) {
      return {};
    }

    return {
      width: Math.max(MAX_WIDGET_WIDTH, width),
      height: Math.round(Math.max(MAX_WIDGET_WIDTH, width) / 2.39),
    };
  }

  playVideo = notForPreview(videoItem => {
    const {
      currentSiteUser,
      channelData,
      mainVideoId,
      openFullScreenVideoOverlay,
      closeFullScreenVideoOverlay,
      requestPlayVideo,
      isPortableDevice,
    } = this.props;

    const { id, memberOnly } = videoItem;

    if (isPortableDevice) {
      // we can`t start playing for not rendered player because of browser restriction
      if (id === mainVideoId) {
        return requestPlayVideo(id);
      }
      return this.handleThumbnailClick(videoItem);
    }

    if (!memberOnly || (memberOnly && currentSiteUser)) {
      this.props.playSelectedVideo({ videoItem });
      openFullScreenVideoOverlay(
        channelData.id,
        id,
        true,
        closeFullScreenVideoOverlay,
      );
    } else {
      this.props.openFullScreenMemberOnlyOverlay(channelData.id, id);
    }
  });

  get hasBottomContent() {
    // TODO move hasBottomContent to selector|method
    const allVideosButton = this.allVideosButton;
    const searchLine = this.actionsLine;
    return Boolean(allVideosButton || searchLine);
  }

  renderNoVideoPlayerOverlay() {
    const { appSettings, channelData } = this.props;
    const hasBottomContent = this.hasBottomContent;

    const buttonsWrapperClassName = classnames({
      [styles['buttons-wrapper-no-margin']]: !hasBottomContent,
    });

    const props = {
      appSettings,
      channelData,
      buttonsWrapperClassName,
      styleName: 'player-overlay',
      showChannelCover: true,
      areAllVideosVisible: false,
      videoItem: {},
      onPlaySelectedVideo: _.noop,
    };

    return <OverlayStrip {...props} />;
  }

  selectNextVideo = () => {
    const { selectVideo, lazyLoadedVideoIds, currentIdIndex } = this.props;
    selectVideo(lazyLoadedVideoIds[currentIdIndex + 1]);
  };

  selectPrevVideo = () => {
    const { selectVideo, lazyLoadedVideoIds, currentIdIndex } = this.props;
    selectVideo(lazyLoadedVideoIds[currentIdIndex - 1]);
  };

  get playerOverlayNav() {
    const { hasNextVideo, hasPrevVideo, isRTL } = this.props;

    return (
      <Nav
        prevButtonClassName={styles['prev-button']}
        nextButtonClassName={styles['next-button']}
        prevButtonAriaLabel={i18n.t('widget.accessibility.prev-video')}
        nextButtonAriaLabel={i18n.t('widget.accessibility.next-video')}
        onPrevClick={this.selectPrevVideo}
        onNextClick={this.selectNextVideo}
        isPrevVisible={hasPrevVideo}
        isNextVisible={hasNextVideo}
        isRTL={isRTL}
      />
    );
  }

  getCurrentlyPlayingVideo() {
    const { videoByIds, mainVideoId, isVideoPlaying } = this.props;
    return isVideoPlaying ? _.get(videoByIds, mainVideoId, null) : null;
  }

  renderPlayerOverlay() {
    const {
      appSettings,
      channelData,
      currentSiteUser,
      mainVideo,
      canShowChannelCover,
    } = this.props;

    if (
      this.getCurrentlyPlayingVideo() &&
      isExternalVideo(this.getCurrentlyPlayingVideo())
    ) {
      return null;
    }

    const hasBottomContent = this.hasBottomContent;

    const buttonsWrapperClassName = classnames({
      [styles['buttons-wrapper-no-margin']]: !hasBottomContent,
    });

    return (
      <section
        className={styles['player-overlay-container']}
        ref={memoizedPartial(this.saveRef, 'playerOverlayContainerRef')}
      >
        <OverlayStrip
          styleName="player-overlay"
          buttonsWrapperClassName={buttonsWrapperClassName}
          currentSiteUser={currentSiteUser}
          channelData={channelData}
          appSettings={appSettings}
          videoItem={mainVideo}
          showChannelCover={canShowChannelCover}
          onPlaySelectedVideo={this.playVideo}
          areAllVideosVisible={this.shouldRenderVideos}
          toggleVisibilityAllVideos={this.handleUserToggleVisibilityAllVideos}
          showChannelInfo={this.showChannelInfo}
        />
      </section>
    );
  }

  get player() {
    const { PlayerComponent, isPortableDevice } = this.props;

    if (!PlayerComponent) {
      return null;
    }

    const { width, height } = this.playerSize;

    const {
      channelData,
      isVideoPlaying,
      mainVideo,
      canShowChannelCover,
    } = this.props;

    const playerProps = {
      videoItem: mainVideo,
      channelData,
      width,
      height,
      canShowChannelCover,
      styleName: 'player',
      className: 'qa-widget-player',
      onMemberSignUp: this.openMembership,
    };

    if (isPortableDevice) {
      return (
        <PlayerBlocksVisibility>
          {({ canShowVideoInfoButton, canShowShareButton }) => (
            <PlayerComponent
              {...playerProps}
              paused={!isVideoPlaying}
              canShowFullInfo={canShowVideoInfoButton}
              canShareVideo={canShowShareButton}
            />
          )}
        </PlayerBlocksVisibility>
      );
    }

    return (
      <PlayerBlocksVisibility>
        {({ canShowVideoInfoButton, canShowShareButton }) => (
          <StripPlayerWrapper
            {...playerProps}
            PlayerComponent={PlayerComponent}
            onMemberSignUp={this.openMembership}
            canShowFullInfo={canShowVideoInfoButton}
            canShareVideo={canShowShareButton}
          />
        )}
      </PlayerBlocksVisibility>
    );
  }

  renderNoVideosPlayerContent() {
    const { channelData, isMainItemChannelCover } = this.props;
    const { width, height } = this.playerSize;

    return (
      <NoVideosContent
        channelCoverUrl={getChannelCoverUrl(channelData)}
        width={width}
        height={height}
        isMainItemChannelCover={isMainItemChannelCover}
      >
        {this.renderNoVideoPlayerOverlay()}
      </NoVideosContent>
    );
  }

  get playerContainer() {
    const { mainVideo } = this.props;

    if (!mainVideo) {
      return (
        <div styleName="player-container">
          {this.renderNoVideosPlayerContent()}
          {this.allVideosButton}
          {this.actionsLine}
        </div>
      );
    }

    const hasBottomContent = this.hasBottomContent;

    return (
      <div
        styleName={classnames('player-container', {
          'with-bottom-padding': hasBottomContent,
        })}
        data-channel-layout="strip"
      >
        {this.player}
        {this.renderPlayerOverlay()}
        {this.playerOverlayNav}
        {this.allVideosButton}
        {this.actionsLine}
        <DeeplinkPopups />
      </div>
    );
  }

  handleUserToggleVisibilityAllVideos = () => {
    if (!this.state.areAllVideosVisible) {
      this.props.logWidgetSystem('videoList.show.requested');
    }
    this.toggleVisibilityAllVideos();
  };

  toggleVisibilityAllVideos = () => {
    const { currentVideosPageNumber, goToLazyVideosPageIndex } = this.props;
    const nextAreAllVideosVisible = !this.state.areAllVideosVisible;

    this.setState({
      areAllVideosVisible: nextAreAllVideosVisible,
    });

    if (nextAreAllVideosVisible && !currentVideosPageNumber) {
      goToLazyVideosPageIndex(0);
    }
  };

  render() {
    const {
      channelData,
      isVideoPlaying,
      selectedVideoId,
      isSingleVideo,
    } = this.props;

    return (
      <section
        className={classnames(styles.container, {
          [styles['with-videos']]: this.shouldRenderVideos,
          [styles['single-video']]: isSingleVideo,
        })}
        ref={memoizedPartial(this.saveRef, 'containerRef')}
        data-channel-layout="strip"
        data-hook="widget-container"
        aria-label={i18n.t('widget.accessibility.channel-videos-widget', {
          channelTitle: channelData.title,
        })}
        tabIndex="0"
      >
        {this.playerContainer}
        {this.videos}

        <PaymentEvents
          onRent={this.setCurrentVideoFromPayment}
          onSale={this.setCurrentVideoFromPayment}
          onSubscription={this.reset}
          onSubscriptionCancel={this.reset}
        />

        <LiveStartHandler
          playVideo={this.playVideo}
          isVideoPlaying={isVideoPlaying}
          selectedVideoId={selectedVideoId}
        />

        <WidgetPerformanceLoggers />
      </section>
    );
  }
}

export default withPlayerModuleLoader(StripView);
