import { buildUrl } from '@wix/wix-vod-shared/dist/src/common/utils/url/build-url';
import { hosts } from '@wix/wix-vod-shared/dist/src/common/settings/settings';
import openPopup from '@wix/wix-vod-shared/dist/src/common/utils/open-popup';

export default {
  cancelSubscription(channelId, siteUrl, instance) {
    openPopup(
      buildUrl(`${hosts.paypal}/vod/pp/subscribe/cancel_profile`, {
        type: 'subscription',
        channelId,
        ref: siteUrl,
        instance,
      }),
    );
  },
};
