import _ from 'lodash';
import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { getVideoCards } from 'api/public/video-cards/video-cards';
import { VIDEO_CARD_TYPES } from '@wix/wix-vod-constants/dist/video-cards/types';
import {
  getFullSiteUrl,
  getInstance,
} from 'widget/redux/client/hydrated-data/hydrated-data';
import {
  buildChannelCardsDeeplinkUrl,
  buildVideoCardsDeeplinkUrl,
} from '@wix/wix-vod-api/dist/src/private/widgets/widgets';
import { createAsyncProxy } from 'shared/worker/lib';

const ACTIONS = createAsyncActions('SERVER.VIDEO-CARDS.GET');
const { START, SUCCESS, FAIL } = ACTIONS;

const deeplinkCardTypes = [
  VIDEO_CARD_TYPES.CHANNEL,
  VIDEO_CARD_TYPES.VIDEO,
  VIDEO_CARD_TYPES.RECENT_VIDEO,
];

const hasDeeplink = card => _.includes(deeplinkCardTypes, card.type);

const appendDeeplinks = ({ cardsData, fallbackUrl, instance }) =>
  _.map(cardsData, card =>
    hasDeeplink(card)
      ? {
          ...card,
          url: card.actionItemId
            ? buildVideoCardsDeeplinkUrl({
                channelId: card.actionListId,
                videoId: card.actionItemId,
                instance,
                fallbackUrl,
              })
            : buildChannelCardsDeeplinkUrl({
                channelId: card.actionListId,
                instance,
                fallbackUrl,
              }),
        }
      : card,
  );

export const NAMES = ACTIONS.NAMES;

export const fetchVideoCards = createAsyncProxy('fetchVideoCards', function(
  listId,
  itemId,
) {
  return function(dispatch, getState, { withContext }) {
    const params = { id: itemId };
    const state = getState();
    const instance = getInstance(state);
    const fullSiteUrl = getFullSiteUrl(state);

    dispatch(START(params));

    return withContext(getVideoCards)(listId, itemId)
      .then(response =>
        appendDeeplinks({
          cardsData: response.data,
          fallbackUrl: fullSiteUrl,
          instance,
        }),
      )
      .then(data => {
        dispatch(SUCCESS(params, data));
        return data;
      })
      .catch(errorResponse => {
        dispatch(
          FAIL(params, errorResponse, {
            analytics: {
              type: 'error',
              name: 'shared.video-cards.get',
            },
          }),
        );
      });
  };
});
