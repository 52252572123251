import _ from 'lodash';
import replaceArrays from 'widget/utils/replace-arrays';

export default (state = {}, { payload }) => {
  const channels = _.get(payload, 'response.data.entities.channels');
  if (channels) {
    return _.mergeWith({}, state, channels, replaceArrays);
  }

  return state;
};
