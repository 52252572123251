import { selectVideo } from 'widget/redux/client/actions/select-video';
import { playVideo } from 'widget/redux/client/actions/player/change-playback-status';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { createProxy } from 'shared/worker/lib';

export const requestPlayVideo = createProxy(
  'requestPlayVideo',
  (id, muted = false, autoPlayed = false) => (dispatch, getState) => {
    const state = getState();
    const videoByIds = getVideosGroupedByIds(state);
    const channelData = getChannelForWidget(state);
    const currentSiteUser = getCurrentSiteUser(state);
    const videoItem = videoByIds[id];
    const isPlayAllowed = canPlayFullOrPreview(
      channelData,
      videoItem,
      currentSiteUser,
    );

    if (isPlayAllowed) {
      dispatch(playVideo(videoItem.id, muted, autoPlayed));
    } else {
      dispatch(selectVideo(videoItem.id));
    }
  },
);
