import fedopsLogger from 'loggers/fedops/desktop-widget';

import {
  openFullScreenPurchaseOverlay as openPurchaseOverlay,
  openFullScreenMemberOnlyOverlay as openMemberOnlyOverlay,
  openFullScreenSubscribeOverlay as openSubscribeOverlay,
  openFullScreenRentOverlay as openRentOverlay,
} from 'shared/utils/open-overlay';
import { createProxy } from 'shared/worker/lib';

export const openFullScreenPurchaseOverlay = createProxy(
  'openFullScreenPurchaseOverlay',
  (...args) => dispatch => {
    dispatch(fedopsLogger.loadCashierModal.start());
    dispatch(openPurchaseOverlay(...args));
  },
);

export const openFullScreenMemberOnlyOverlay = createProxy(
  'openFullScreenMemberOnlyOverlay',
  (...args) => dispatch => {
    dispatch(fedopsLogger.loadCashierModal.start());
    dispatch(openMemberOnlyOverlay(...args));
  },
);

export const openFullScreenSubscribeOverlay = createProxy(
  'openFullScreenSubscribeOverlay',
  (...args) => dispatch => {
    dispatch(fedopsLogger.loadCashierModal.start());
    dispatch(openSubscribeOverlay(...args));
  },
);

export const openFullScreenRentOverlay = createProxy(
  'openFullScreenRentOverlay',
  (...args) => dispatch => {
    dispatch(fedopsLogger.loadCashierModal.start());
    dispatch(openRentOverlay(...args));
  },
);
