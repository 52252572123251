import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { notForPreview } from 'shared/utils/not-for-preview';

import { logBi } from 'shared/worker/actions/bi';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import {
  openFullScreenSubscribeOverlay,
  openFullScreenPurchaseOverlay,
  openFullScreenRentOverlay,
} from 'widget/utils/open-overlay';

@connect(
  state => ({
    currentSiteUser: getCurrentSiteUser(state),
  }),
  {
    logBi,
    openFullScreenSubscribeOverlay,
    openFullScreenPurchaseOverlay,
    openFullScreenRentOverlay,
  },
)
export default class StripPlayerWrapper extends React.Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    videoUrls: PropTypes.object,
    size: PropTypes.object,
    simple: PropTypes.bool,
    overlay: PropTypes.any,
    onPlayStart: PropTypes.func,
    onEnded: PropTypes.func,
    preload: PropTypes.string,
    reportStats: PropTypes.func,
    fetchVideoUrls: PropTypes.func,
    appSettings: PropTypes.object,
    mainOrThumbnailLocation: PropTypes.string,
    loop: PropTypes.bool,

    onSubscribeClick: PropTypes.func,
    onPurchaseClick: PropTypes.func,
    isVideoCardsEnabled: PropTypes.bool,
    fetchVideoCards: PropTypes.func,
    onPlayRequest: PropTypes.func,
    shouldLoadVideo: PropTypes.bool,
    isOverQuota: PropTypes.bool,
    isLive: PropTypes.bool,
    cleanupVideoCards: PropTypes.func,
    isInLightbox: PropTypes.bool,
    isAutoplayed: PropTypes.bool,
    handleVideoEnd: PropTypes.func,
    canShowChannelCover: PropTypes.bool,
    onMemberSignUp: PropTypes.func,
    isUserLogged: PropTypes.bool,
    videoItem: PropTypes.object,
    channelData: PropTypes.object,
    currentSiteUser: PropTypes.object,

    PlayerComponent: PropTypes.func,
  };

  openPurchase = notForPreview(() => {
    const { videoItem, channelData } = this.props;
    this.props.openFullScreenPurchaseOverlay(channelData.id, videoItem.id);
  });

  openRent = notForPreview(() => {
    const { videoItem, channelData } = this.props;
    this.props.openFullScreenRentOverlay(channelData.id, videoItem.id);
  });

  openSubscription = notForPreview(() => {
    const { channelData } = this.props;

    this.props.openFullScreenSubscribeOverlay(channelData.id, () => {
      this.props.logBi('widget.subscriptionPopUp.Completed', {
        channelID: channelData.id,
        result: 'canceled',
        errorMsg: '',
      });
    });
  });

  render() {
    const props = _.omit(
      this.props,
      'mobileMode', // can't do this
      'playAtFullScreen', // can't do this
      'showInitialOverlay', // can't do this
      'showControls', // can't do this
      'showPoster', // can't do this
      'currentTime', // can't do this
      'paused', // can't do this
      'muted', // can't do this
      'onPurchaseClick',
      'onSubscribeClick',
      'onRentClick',
    );

    const { PlayerComponent } = this.props;

    return (
      <PlayerComponent
        {...props}
        playAtFullScreen={false}
        showPlayer={false}
        showInitialOverlay
        showControls
        showPoster
        paused
        shouldLoadVideo={false}
        onPurchaseClick={this.openPurchase}
        onSubscribeClick={this.openSubscription}
        onRentClick={this.openRent}
      />
    );
  }
}
