import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { NAMES as PLAYER_UI_VISIBILITY_ACTIONS } from 'widget/redux/client/actions/player/ui';

const defaultState = {
  isVisible: null,
};

export default handleActions(
  {
    [PLAYER_UI_VISIBILITY_ACTIONS.SHOW]: _.constant({ isVisible: true }),
    [PLAYER_UI_VISIBILITY_ACTIONS.HIDE]: _.constant({ isVisible: false }),
  },
  defaultState,
);
