import React from 'react';

import { Route } from 'react-router';

import LayoutSwitcher from 'widget/layouts/layout-switcher';
import { Direction } from 'shared/containers/direction';
import { widgetWrapper } from 'shared/worker/widgetWrapper';
import { createStore } from './widget.store';

function Component() {
  return (
    <Direction>
      <Route path="/" component={LayoutSwitcher} />
    </Direction>
  );
}

export const WidgetComponent = widgetWrapper({
  createStore,
  Component,
});

export default {
  component: WidgetComponent,
};
