import {
  CASHIER_MODAL_LOAD,
  CASHIER_PAYMENT_PAGE_LOAD,
  CASHIER_PAYMENT_COMPLETE_PAGE_LOAD,
} from './interactions';

import { createLoggerActions } from 'shared/worker/actions/fedopsLogger';

export function createBaseDesktopLogger(loggerName) {
  const { appLoaded, createInteraction } = createLoggerActions(loggerName);
  return {
    appLoaded,
    loadCashierModal: createInteraction(CASHIER_MODAL_LOAD),
    loadCashierPaymentPage: createInteraction(CASHIER_PAYMENT_PAGE_LOAD),
    loadCashierPaymentCompletePage: createInteraction(
      CASHIER_PAYMENT_COMPLETE_PAGE_LOAD,
    ),
  };
}
