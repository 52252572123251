import React from 'react';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { closeModal } from 'shared/components/modal/utils';
import { PrimaryButton } from 'shared/components/button/button';

import { withStyles } from 'shared/utils/withStyles';
import styles from './preview-prompt.styl';

const PreviewModePrompt = withStyles(styles)(() => (
  <div className={styles['prompt-wrapper']} data-hook="preview-mode-prompt">
    <div>{i18n.t('widget.preview-prompt.you-in-preview.line1')}</div>

    <div className={styles['second-message']}>
      {i18n.t('widget.preview-prompt.you-in-preview.line2')}
    </div>

    <PrimaryButton
      dataHook="close-preview-prompt"
      className={styles['close-button']}
      onClick={closeModal}
    >
      {i18n.t('widget.preview-prompt.got-it')}
    </PrimaryButton>
  </div>
));

export const getOpenPreviewPromptModalConfig = () => ({
  contentClassName: styles['modal-content'],
  className: styles.modal,
  closeOnOverlayClick: true,
  title: i18n.t('widget.preview-prompt.preview-mode'),
  content: <PreviewModePrompt />,
});
