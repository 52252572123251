import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PaymentEvents from 'shared/components/payment-events/payment-events';

import { getChannelIdFromAppSettings } from 'shared/selectors/app-settings';
import { getPaymentInfo } from 'widget/redux/server/actions/channel/payment';

@connect(
  state => ({
    channelId: getChannelIdFromAppSettings(state),
  }),
  {
    getPaymentInfo,
  },
)
export default class ReloadChannelPaymentDataOnPayment extends React.Component {
  static propTypes = {
    getPaymentInfo: PropTypes.func,
    channelId: PropTypes.string,
  };

  fetchChannelPaymentData = _.debounce(() => {
    this.props.getPaymentInfo(this.props.channelId);
  }, 300);

  render() {
    return <PaymentEvents onAnyEvent={this.fetchChannelPaymentData} />;
  }
}
