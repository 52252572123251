import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TabletPlayer from 'widget/components/players/tablet-player';
import DesktopPlayer from 'widget/components/players/desktop-player';

import { isPortableDevice } from 'shared/selectors/form-factor';

export const PlayerModuleContext = React.createContext({
  PlayerComponent: null,
  isPortableDevice: false,
});

@connect(state => ({
  isPortableDevice: isPortableDevice(state),
}))
class PlayerModuleLoader extends React.PureComponent {
  static propTypes = {
    children: PropTypes.object,
  };

  render() {
    const { children, isPortableDevice } = this.props;
    const PlayerComponent = isPortableDevice ? TabletPlayer : DesktopPlayer;

    return (
      <PlayerModuleContext.Provider
        value={{
          PlayerComponent,
          isPortableDevice,
        }}
      >
        {children}
      </PlayerModuleContext.Provider>
    );
  }
}

export const withPlayerModuleLoader = WrappedComponent =>
  class extends React.Component {
    render() {
      return (
        <PlayerModuleContext.Consumer>
          {({ PlayerComponent, isPortableDevice }) => {
            return (
              <WrappedComponent
                {...this.props}
                PlayerComponent={PlayerComponent}
                isPortableDevice={isPortableDevice}
              />
            );
          }}
        </PlayerModuleContext.Consumer>
      );
    }
  };

export default PlayerModuleLoader;
