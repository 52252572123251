import { isVideoOverQuota } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/quota';
import { convertSToMS } from '@wix/wix-vod-shared/dist/src/widget/utils/time';
import { logBi } from 'shared/worker/actions/bi';
import fedopsLogger from 'loggers/fedops/new-fedops-logger';
import { isAutoplayed } from 'shared/selectors/player';

export const logWidgetVidClick = ({
  videoItem,
  channelData,
  buttonName = 'play_video',
}) => dispatch => {
  const { durationSec, videoSource } = videoItem;

  dispatch(
    logBi('widget.vid.clicked', {
      duration: convertSToMS(durationSec),
      channelID: channelData.id,
      videoType: videoSource,
      videoID: videoItem.id,
      overQuoted: isVideoOverQuota(videoItem),
      buttonName,
    }),
  );

  dispatch(fedopsLogger.playVideo({ videoSource }).start());
};

export const logWidgetVidPlayStart = ({ channelData, videoItem, button }) => (
  dispatch,
  getState,
) => {
  const isAutoplay = isAutoplayed(getState());

  const { baseItemId, id, durationSec, videoSource } = videoItem;

  dispatch(
    logBi('widget.vid.start.play', {
      duration: convertSToMS(durationSec),
      channelID: channelData.id,
      videoType: videoSource,
      videoID: baseItemId || id,
      isAutoplay,
      button,
    }),
  );

  if (!isAutoplay) {
    dispatch(fedopsLogger.playVideo({ videoSource }).end());
  }
};
