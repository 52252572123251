import { createAction } from 'redux-actions';
import { getHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
import { updateWidgetSingleVideo } from 'widget/redux/initial-data-desktop';

export const APP_SETTINGS_UPDATE = 'CLIENT.APP_SETTINGS.SET';
export const setAppSettings = createAction(APP_SETTINGS_UPDATE);

export const updateAppSettings = styleParams => async (dispatch, getState) => {
  dispatch(setAppSettings(styleParams));
  await dispatch(updateWidgetSingleVideo());
};

export const setInitialAppSettings = styleParams => (dispatch, getState) => {
  const state = getState();
  const { siteUrl, locale } = getHydratedData(state);

  const settings = {
    ...styleParams,
    siteUrl,
    locale,
  };

  dispatch(setAppSettings(settings));
};
