import _ from 'lodash';
import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { getVODExperiments } from 'api/public/experiments/experiments';
import { parseExperimentsFromUrl } from 'shared/utils/experiments';
import { getFullSiteUrl } from 'widget/redux/client/hydrated-data/hydrated-data';
import { createAsyncProxy } from 'shared/worker/lib';

const ACTIONS = createAsyncActions('SERVER.EXPERIMENTS');
export const NAMES = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

export const getExperiments = createAsyncProxy('experiments.get', function() {
  return function(dispatch, getState) {
    dispatch(START());
    const state = getState();
    return Promise.all([
      getVODExperiments(state),
      __SERVER__ ? null : parseExperimentsFromUrl(getFullSiteUrl(state)),
    ])
      .then(args => _.merge(...args))
      .then(
        function(experiments) {
          dispatch(SUCCESS({}, experiments));
          return experiments;
        },

        function(response) {
          dispatch(
            FAIL({}, response, {
              analytics: { type: 'error', name: 'shared.experiments.get' },
            }),
          );
        },
      );
  };
});
