import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { VODCSSModules } from 'shared/utils/wix-connect';
import { isSubscriptionButtonVisible } from 'shared/components/overlay-texts/channel/subscribe-button-text-utils';
import ChannelSubscriptionLabel from 'shared/components/channel-subscription-label/channel-subscription-label';

import { LinkButton } from 'shared/components/button/button';

import { getDealInfo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';

import { withStyles } from 'shared/utils/withStyles';
import styles from './channel-actions.styl';

@connect((state, ownProps) => ({
  dealInfo: getDealInfo(ownProps),
  isVisible: isSubscriptionButtonVisible({ channel: ownProps.channelData }),
}))
@withStyles(styles)
@VODCSSModules(styles)
export default class ChannelSubscriptionButton extends React.Component {
  static propTypes = {
    channelData: PropTypes.object,
    dealInfo: PropTypes.array,
    className: PropTypes.string,
    onClick: PropTypes.func,
    isVisible: PropTypes.bool,
    isFocusable: PropTypes.bool,
  };

  render() {
    const { isVisible, dealInfo, className, onClick, isFocusable } = this.props;

    if (!isVisible) {
      return null;
    }

    return (
      <LinkButton
        className={className}
        styleName="action subscribe"
        dataHook="channel-subscription-button"
        isFocusable={isFocusable}
        onClick={onClick}
      >
        <ChannelSubscriptionLabel dealInfo={dealInfo} isVisible={isVisible} />
      </LinkButton>
    );
  }
}
