import React from 'react';
import PropTypes from 'prop-types';
import { VODCSSModules } from 'shared/utils/wix-connect';

import { withStyles } from 'shared/utils/withStyles';
import styles from './with-tooltip.styl';

@withStyles(styles)
@VODCSSModules(styles)
export default class WithTooltip extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    tooltip: PropTypes.any,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
  };

  renderTooltip() {
    const { tooltip, innerClassName } = this.props;

    return (
      <span styleName="tooltip">
        <span className={innerClassName} styleName="tooltip-inner">
          {tooltip}
        </span>
      </span>
    );
  }

  render() {
    const { tooltip, children, className } = this.props;

    if (!children) {
      return null;
    }

    return (
      <span className={className} styleName="with-tooltip">
        {tooltip && this.renderTooltip()}
        {children}
      </span>
    );
  }
}
