import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  getRentPriceInfo,
  getSalePriceInfo,
  getSubscriptionPriceInfo,
} from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';

import { getVideoById } from 'shared/selectors/videos';

export function isPayed(entity) {
  return Boolean(_.get(entity, 'dgsInfo'));
}

export const getChannelDealInfo = createSelector(
  getChannelForWidget,
  _.property('dealInfo'),
);

export const getSubscribeInfo = createSelector(
  getChannelDealInfo,
  getSubscriptionPriceInfo,
);

export const getVideoDealInfo = createSelector(
  getVideoById,
  _.property('dealInfo'),
);

export const getRentInfo = createSelector(
  getVideoDealInfo,
  getRentPriceInfo,
);

export const getSaleInfo = createSelector(
  getVideoDealInfo,
  getSalePriceInfo,
);
