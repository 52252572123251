import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { pubSubPublish } from 'shared/worker/actions/pub-sub/publish';
import EVENTS from 'shared/constants/events';

@connect(
  null,
  { pubSubPublish },
)
export default class PaymentEventProxy extends React.Component {
  //at least, handling unsubscribe payment success event, maybe paypal too.
  componentDidMount() {
    window.addEventListener('message', this.proxyEvent, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.proxyEvent, false);
  }

  proxyEvent = event => {
    if (_.get(event, 'data.completed', false)) {
      this.props.pubSubPublish(EVENTS.PAYMENT.PAYMENT_EVENT, {
        message: event.data,
      });
    }
  };

  render() {
    return null;
  }
}
