import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

const ComingSoon = ({ className }) => (
  <span className={className}>
    {i18n.t('widget.overlay-buttons.coming-soon')}
  </span>
);

ComingSoon.propTypes = {
  className: PropTypes.string,
};

export default ComingSoon;
