import { createAction } from 'redux-actions';
import { createProxy } from 'shared/worker/lib';

export const SELECTED_VIDEO_ACTIONS = {
  SET: 'CLIENT.SELECTED_VIDEO.SET',
  RESET: 'CLIENT.SELECTED_VIDEO.RESET',
};

const selectVideoAction = createAction(SELECTED_VIDEO_ACTIONS.SET);

export const selectVideo = createProxy(
  'selectVideo',
  videoId => (dispatch, getState) => {
    const selectedVideoId = getState().selectedVideoId;

    if (selectedVideoId !== videoId) {
      dispatch(selectVideoAction(videoId));
    }
  },
);

export const resetSelectedVideo = createAction(SELECTED_VIDEO_ACTIONS.RESET);
