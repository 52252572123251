import React from 'react';
import _ from 'lodash';

import classnames from 'classnames';
import { connect } from 'react-redux';
import { VODCSSModules } from 'shared/utils/wix-connect';
import pubsub from 'shared/utils/pubsub';
import invertSide from 'shared/utils/popout/invert-side';
import { isPortableDevice } from 'shared/selectors/form-factor';

import PropTypes from 'prop-types';

import events from 'shared/constants/events';

import {
  sides,
  popoutPositions,
  trianglePositions,
} from 'shared/constants/popout';
import {
  sideShape,
  popoutPositionShape,
  trianglePositionShape,
} from 'shared/shapes/popout';

import { withStyles } from 'shared/utils/withStyles';
import styles from './popout.styl';

const _propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  innerClassName: PropTypes.string,
  children: PropTypes.any,
  popoutSide: sideShape,
  popoutPosition: popoutPositionShape,
  trianglePosition: trianglePositionShape,
  triangleClassName: PropTypes.string,
  getRef: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClickOutside: PropTypes.func,
  height: PropTypes.number,
  isActive: PropTypes.bool,
};

const _defaultProps = {
  popoutSide: sides.TOP,
  popoutPosition: popoutPositions.CENTER,
  trianglePosition: trianglePositions.CENTER,
  getRef: _.noop,
  onMouseEnter: _.noop,
  onMouseLeave: _.noop,
  onClickOutside: _.noop,
};

export function showPopout(popout) {
  pubsub.publish(events.POPOUT.SHOW, popout);
}

@connect(state => ({ isPortableDevice: isPortableDevice(state) }))
@withStyles(styles)
@VODCSSModules(styles)
class PopoutBase extends React.Component {
  static propTypes = _propTypes;
  static defaultProps = _defaultProps;

  componentDidMount() {
    window.addEventListener('blur', this.handleClickOutside);

    if (this.props.isPortableDevice) {
      document.addEventListener('touchstart', this.handleClickOutside);
    } else {
      document.addEventListener('click', this.handleClickOutside);
    }

    this.unsubscribeHide = pubsub.subscribe(
      events.POPOUT.SHOW,
      this.hideAllButOnePopout,
    );
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.handleClickOutside);
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);

    if (this.unsubscribeHide) {
      this.unsubscribeHide();
    }
  }

  get styleNames() {
    const { popoutSide, trianglePosition } = this.props;
    return classnames(
      'content',
      `triangle-${invertSide(popoutSide)}`,
      `triangle-${trianglePosition}`,
    );
  }

  handleClickOutside = event => {
    const { target } = event;
    if (target !== window && this.popout && this.popout.contains(target)) {
      return;
    }

    this.props.onClickOutside({ event });
  };

  hideAllButOnePopout = popoutToShow => {
    if (popoutToShow && this.popout && !this.popout.contains(popoutToShow)) {
      this.props.onClickOutside();
    }
  };

  getRef = node => {
    this.popout = node;
    this.props.getRef(node);
  };

  render() {
    const {
      children,
      className,
      onMouseEnter,
      onMouseLeave,
      triangleClassName,
      height,
      isActive,
    } = this.props;
    let { style } = this.props;

    if (height) {
      style = { ...style, height };
    }

    return (
      <div
        ref={this.getRef}
        styleName={this.styleNames}
        className={className}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={triangleClassName} styleName="triangle before" />
        <div
          className={styles['tooltip-content']}
          data-hook={isActive ? 'tooltip-content' : ''}
        >
          {children}
        </div>
        <div className={triangleClassName} styleName="triangle after" />
      </div>
    );
  }
}

export default class Popout extends React.Component {
  static propTypes = _propTypes;
  static defaultProps = _defaultProps;

  render() {
    const { innerClassName, children } = this.props;
    const props = _.omit(this.props, 'styles', 'innerClassName', 'children');

    return (
      <PopoutBase {...props}>
        <div className={classnames(styles.inner, innerClassName)}>
          {children}
        </div>
      </PopoutBase>
    );
  }
}
