import {
  videoToSelectExists,
  getVideoIdToSelect,
} from 'widget/selectors/video-id-to-select';
import { selectVideo } from 'widget/redux/client/actions/select-video';
import { getPublicVideo } from './server/actions/channel/video/get';
import { getPublic as getChannel } from './server/actions/channel/get-public';
import { getPaymentInfo } from './server/actions/channel/payment';
import { getExperiments } from 'shared/redux/server/actions/experiments/get';
import {
  loadMoreVideoPages,
  prependVideoIds,
} from './client/lazy-channel-videos/actions';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { clearHydratedData } from './client/hydrated-data/hydrated-data';
import { getVideoById } from 'shared/selectors/videos';
import { getCurrentChannelId } from 'widget/selectors/channel-info';

const getVideo = () => async (dispatch, getState) => {
  const state = getState();
  const videoId = getVideoIdToSelect(state);

  if (!videoId) {
    return;
  }
  const videoFetched = Boolean(getVideoById(state, videoId));
  if (videoFetched) {
    return;
  }
  const currentChannelId = getCurrentChannelId(state);
  await dispatch(getPublicVideo(currentChannelId, videoId));
};

const selectVideoIfNeeded = () => (dispatch, getState) => {
  const state = getState();
  if (videoToSelectExists(state)) {
    // TODO: this might be useless already, video should be loaded 100%
    const videoIdToSelect = getVideoIdToSelect(state);
    dispatch(selectVideo(videoIdToSelect));
  }
};

export const setInitialState = () => async (dispatch, getState) => {
  const state = getState();
  const currentChannelId = getCurrentChannelId(state);

  await Promise.all(
    [
      getChannel(currentChannelId),
      getExperiments(state),
      loadMoreVideoPages(),
      getPaymentInfo(currentChannelId),
      getVideo(),
    ].map(action => dispatch(action)),
  );

  dispatch(clearHydratedData());

  const channelData = getChannelForWidget(getState());

  if (channelData.featuredItem) {
    dispatch(prependVideoIds(channelData.featuredItem));
  }
  dispatch(selectVideoIfNeeded());
};

export const updateWidgetSingleVideo = () => async (dispatch, getState) => {
  await dispatch(getVideo());
  dispatch(selectVideoIfNeeded());
};
