import Experiments from '@wix/wix-experiments';
import { getHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';

const config = {
  baseUrl: 'https://www.wix.com',
};

export const fetchVODExperiments = async () => {
  const service = new Experiments(config);
  await service.load('vod');
  return service.all();
};

export const getVODExperiments = state => {
  const experiments = getHydratedData(state).experiments;
  if (experiments) {
    return experiments;
  }
  return fetchVODExperiments();
};
