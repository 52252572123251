import { handleActions } from 'redux-actions';

import { getResult } from 'shared/redux/helpers/normalized';
import { clear } from 'shared/redux/helpers/ids';

import { NAMES as SERVER_CHANNEL_VIDEOS_LIST } from 'shared/redux/server/actions/channel/videos/list-public';

export default handleActions(
  {
    [SERVER_CHANNEL_VIDEOS_LIST.START]: clear,
    [SERVER_CHANNEL_VIDEOS_LIST.SUCCESS]: getResult,
  },
  [],
);
