import React from 'react';
import _ from 'lodash';

import classnames from 'classnames';

import PropTypes from 'prop-types';

import { withStyles } from 'shared/utils/withStyles';
import styles from './tag.styl';

@withStyles(styles)
export default class Tag extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    roundedClassName: PropTypes.string,
    onFocus: PropTypes.func,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    rounded: PropTypes.bool,
  };

  static defaultProps = {
    onFocus: _.noop,
    onClick: _.noop,
    onBlur: _.noop,
    rounded: false,
  };

  handleClick = event => {
    event.preventDefault();

    const { onClick, text } = this.props;

    onClick(text);
  };

  render() {
    const {
      text,
      className,
      roundedClassName,
      rounded,
      onFocus,
      onBlur,
    } = this.props;

    const classNames = classnames(className, styles.tag, {
      [styles.rounded]: rounded,
      [roundedClassName]: rounded && roundedClassName,
    });

    return (
      <a
        href="#"
        className={classNames}
        onClick={this.handleClick}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <span className={styles.inner}>{text}</span>
      </a>
    );
  }
}
