import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { DEFAULT_VOD_SETTINGS } from '@wix/wix-vod-constants/dist/common/default-vod-settings';
import { NAMES as SERVER_VOD_SETTINGS_GET } from 'shared/redux/server/actions/vod-settings/get';
import withReplace from 'shared/redux/helpers/vod-settings-replace';

function mergeResponseData(state, { payload }) {
  return _.mergeWith(
    {},
    DEFAULT_VOD_SETTINGS,
    state,
    payload.response,
    withReplace,
  );
}

export default handleActions(
  {
    [SERVER_VOD_SETTINGS_GET.SUCCESS]: mergeResponseData,
  },
  null,
);
