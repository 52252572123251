import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Provider as ReduxProvider, connect } from 'react-redux';
import { createHashHistory, createMemoryHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { StaticRouter } from 'react-router';

import {
  createFunctionProxy,
  createWorkerHandlerMiddleware,
  consumeEvents,
} from './lib';

import { getAllSettings } from 'shared/selectors/app-settings';
import { updateWindowSize } from 'widget/redux/client/actions/window-size';
import { WidgetPropsProvider } from 'widget/containers/widget-props';
import { setInitialAppSettings } from 'widget/redux/client/actions/app-settings';
import { withStypesWrap } from '@wix/wix-vod-shared/dist/src/widget/utils/styles-processing/container';
import cssVars from 'shared/styles/core.styl';
import { subscribeGlobalStyles } from '../../../build/webpack-environments/loaders/styles/myCssLoader/runtime.js';
import { configureAxios } from 'shared/configure-axios';
import { setHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
import { logBi } from 'shared/worker/actions/bi';
// import { createClientStore } from './clientStore';

const {
  parseStyleParams,
} = require('@wix/wix-vod-shared/dist/src/widget/data/style-params/parse');

function withSSRDebug(Comp) {
  if (
    __DEV__ &&
    typeof location !== 'undefined' &&
    location.search.toLowerCase().includes('debugssrfast')
  ) {
    return class SSRWidgetComponent extends React.Component {
      state = {
        content: '',
      };
      async componentDidMount() {
        const res = await fetch('https://localhost:3333/ssr', {
          method: 'POST',
          body: JSON.stringify(this.props),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const content = await res.text();
        this.setState({ content });
      }
      render() {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.content,
            }}
          ></div>
        );
      }
    };
  }
  return Comp;
}

class IsomorphicRouter extends React.Component {
  render() {
    if (__SERVER__) {
      return (
        <StaticRouter location={this.props.url}>
          {this.props.children}
        </StaticRouter>
      );
    }

    return (
      <ConnectedRouter history={this.props.history}>
        {this.props.children}
      </ConnectedRouter>
    );
  }
}

function createIsomorphicHistory({ url }) {
  if (__SERVER__ || __OOI__) {
    return createMemoryHistory({ initialEntries: [url] });
  } else {
    return createHashHistory();
  }
}

const withErrorBoundary = Comp => {
  return class BoundaryWrapComponent extends React.Component {
    state = { error: false };
    componentDidCatch(err, errorInfo) {
      this.props.captureException(err, { extra: { reactInfo: errorInfo } });
      this.setState({ error: true });
    }
    render() {
      if (this.state.error) {
        return null;
      }
      return <Comp {...this.props} />;
    }
  };
};

export function widgetWrapper({ createStore, Component }) {
  const ComponentWithStyles = withStypesWrap(Component, {
    subscribeGlobalStyles,
    cssVars,
  });
  const withSettings = connect(state => ({
    styleParams: getAllSettings(state),
  }));

  const StyledComponent = withSettings(({ styleParams, ...props }) => {
    const mergedProps = {
      ...props,
      host: {
        ...props.host,
        style: {
          ...props.host.style,
          styleParams: styleParams || props.style.styleParams,
        },
        isScrollExperimentOpen: true,
      },
    };

    return <ComponentWithStyles {...mergedProps} />;
  });

  class StoreWrapper extends React.Component {
    static propTypes = {
      hydratedSource: PropTypes.object,
      pubSubEvents: PropTypes.object,
      style: PropTypes.object,
    };

    getDimensions(props) {
      return props.dimensions || props.host.dimensions;
    }

    constructor(props) {
      super(props);

      const functionsProxy = (this.functionsProxy = createFunctionProxy());
      const isBackend = props.renderingEnv === 'backend';

      this.resolve = _.noop;

      if (isBackend) {
        this.promise = new Promise(_resolve => {
          this.resolve = _resolve;
        });
      }

      const dispatch = action =>
        props.dispatchEv(functionsProxy.serializeAction(action));

      const dispatchOnPageReady = async action => {
        await this.promise;
        return dispatch(action);
      };

      const middlewares = [
        createWorkerHandlerMiddleware(
          isBackend ? dispatchOnPageReady : dispatch,
        ),
      ];

      const { configData, appState, host } = this.props;

      this.history = createIsomorphicHistory({ url: configData.url });

      // this.store = createClientStore({
      //   appState: props.appState,
      //   functionsProxy,
      //   dispatchEv: props.dispatchEv,
      // });

      this.store = createStore({
        configData: this.props.configData,
        middlewares,
        initialState: appState,
        history: this.history,
      });

      if (__OOI__) {
        if (isBackend) {
          this.promise.then(this.logWidgetLoaded);
        } else {
          this.logWidgetLoaded();
        }
      }

      this.configureAxios();

      this.store.dispatch(setInitialAppSettings(parseStyleParams(host.style)));

      this.updateWindowSize();
    }
    configureAxios() {
      configureAxios(this.props.configData);
    }
    componentDidUpdate(prevProps) {
      if (
        prevProps.renderingEnv === 'backend' &&
        this.props.renderingEnv === 'browser'
      ) {
        this.resolve();
      }

      if (!_.isEqual(prevProps.configData, this.props.configData)) {
        this.configureAxios();
        this.store.dispatch(
          setHydratedData({
            instance: this.props.configData.instance,
          }),
        );
      }
      if (
        !_.isEqual(
          this.getDimensions(prevProps),
          this.getDimensions(this.props),
        )
      ) {
        this.updateWindowSize();
      }
    }

    logWidgetLoaded = () => {
      this.store.dispatch(
        logBi('widget.loaded', {
          channelID: '00000000-0000-0000-0000-000000000000',
        }),
      );
    };

    updateWindowSize() {
      const dimensions = this.getDimensions(this.props);

      let width = 0;
      let height = 0;

      if (dimensions) {
        width = dimensions.width || width;
        height = dimensions.height || height;

        if (typeof window !== 'undefined') {
          width = width || window.innerWidth; // isFullWidth
        }
      } else {
        if (typeof window !== 'undefined') {
          width = window.innerWidth;
          height = window.innerHeight;
        }
      }

      this.store.dispatch(updateWindowSize({ width, height }));
    }

    UNSAFE_componentWillReceiveProps(newProps) {
      // this.store.dispatch({
      //   type: '@ORIG_DISPATCH/setState',
      //   payload: newProps.appState,
      // });

      consumeEvents(newProps, event => {
        this.functionsProxy.callFunction(event);
      });
    }

    render() {
      if (__DEBUG__) {
        console.log('widget props', this.props);
      }

      const { store } = this;

      return (
        <ReduxProvider store={store}>
          <WidgetPropsProvider value={this.props}>
            <IsomorphicRouter url="/" history={this.history}>
              <StyledComponent {...this.props} />
            </IsomorphicRouter>
          </WidgetPropsProvider>
        </ReduxProvider>
      );
    }
  }

  return withSSRDebug(withErrorBoundary(StoreWrapper));
}
