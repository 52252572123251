import { getVideoUrls } from '@wix/wix-vod-api/dist/src/public/video/video';
import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { getInstance } from 'widget/redux/client/hydrated-data/hydrated-data';
import { createAsyncProxy } from 'shared/worker/lib';

const ACTIONS = createAsyncActions('CLIENT.VIDEO_URLS.GET');
export const NAMES = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

const fetchVideoUrls = createAsyncProxy(
  'fetchVideoUrls',
  (videoItem, force = false) => (dispatch, getState, { withContext }) => {
    const state = getState();
    const { videoUrlsCache } = state;
    const instance = getInstance(state);

    if (!force && (!videoItem || videoUrlsCache[videoItem.id])) {
      return;
    }

    const params = { videoItem };

    dispatch(START(params));

    /*
     * When videoItem is a bought video response from member/info and original video is too far in list
     * and wasn't loaded yet
     * */
    if (videoItem.dgsInfo && !videoItem.itemId && !videoItem.listId) {
      return Promise.resolve();
    }

    return withContext(getVideoUrls)(
      videoItem.itemId,
      videoItem.listId,
      instance,
    ).then(
      function(response) {
        dispatch(SUCCESS(params, response));
        return response;
      },

      function(response) {
        dispatch(FAIL(params, response));
      },
    );
  },
);

export default fetchVideoUrls;
