import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import SocialShare from 'widget/player-widget/components/social-share/social-share';
import LinkIcon from 'static/images/link.svg';
import CheckedMarkIcon from 'static/images/check-mark.svg';
import classnames from 'classnames';
import { withStyles } from 'shared/utils/withStyles';
import styles from './popup-content.styl';

@withStyles(styles)
export default class ShareMobileContent extends React.Component {
  static propTypes = {
    inputLabel: PropTypes.string.isRequired,
    shareParams: PropTypes.object,
    onShare: PropTypes.func,
    channelId: PropTypes.string,
    videoId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.timeout = null;
    this.inputRef = null;
    this.state = {
      copied: false,
    };
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleInputRef = ref => (this.inputRef = ref);

  handleCopyClick = () => {
    const input = this.inputRef;

    input.select();
    input.setSelectionRange(0, input.value.length);
    document.execCommand('copy');
    input.blur();
    this.setState({ copied: true });
    this.timeout = setTimeout(() => this.setState({ copied: false }), 3000);
  };

  render() {
    const { shareParams, onShare, inputLabel, channelId, videoId } = this.props;

    return (
      <div data-hook="share-popup">
        <div
          className={classnames(styles.url, {
            [styles.copied]: this.state.copied,
          })}
        >
          <input
            type="text"
            className={styles['hidden-input']}
            ref={this.handleInputRef}
            value={shareParams.siteUrl}
            onChange={_.noop}
          />
          <input
            type="text"
            data-hook="share-url-input"
            aria-label={inputLabel}
            className={styles['url-input']}
            value={shareParams.siteUrl}
            onChange={_.noop}
          />
          <div className={styles['copy-button']} onClick={this.handleCopyClick}>
            <LinkIcon className={styles['link-icon']} />
          </div>
          <div className={styles.notification}>
            <CheckedMarkIcon className={styles['checked-icon']} />
            <span>{i18n.t('share-overlay.social.link-copied')}</span>
          </div>
        </div>
        <SocialShare
          networks={['facebook', 'twitter', 'pinterest', 'tumblr']}
          className={styles['share-container']}
          buttonClassName={styles['share-button']}
          content={shareParams}
          onShare={onShare}
          channelId={channelId}
          videoId={videoId}
        />
      </div>
    );
  }
}
