import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import Input from '@wix/wix-vod-shared/dist/src/widget/ui-components/input/input';

import { withStyles } from 'shared/utils/withStyles';
import styles from './copy-content.styl';

const HIDE_MESSAGE_TIMEOUT = 3000;

@withStyles(styles)
class CopyContent extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onInit: PropTypes.func,
    onFocus: PropTypes.func,
    onContentCopied: PropTypes.func,
    isRTL: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      showSuccessMessage: false,
    };
  }

  componentDidMount() {
    this.props.onInit(this.input);
  }

  handleCopyClick = () => {
    const { showSuccessMessage } = this.state;
    if (!showSuccessMessage) {
      this.copyToClipboard();
    }
  };

  copyToClipboard = () => {
    if (document.queryCommandSupported('copy')) {
      this.input.select();
      document.execCommand('copy');
      this.setState({ showSuccessMessage: true });
      this.hideMessages();
      this.props.onContentCopied();
    }
  };

  hideMessages() {
    setTimeout(() => {
      this.setState({ showSuccessMessage: false });
    }, HIDE_MESSAGE_TIMEOUT);
  }

  setRef = node => {
    this.input = node;
  };

  render() {
    const { onFocus, value, isRTL } = this.props;
    const { showSuccessMessage } = this.state;
    return (
      <div className={styles.copy}>
        <Input
          dataHook="iframe-code"
          className={styles['iframe-code']}
          setRef={this.setRef}
          onFocus={onFocus}
          value={value}
        />

        <button
          className={classNames(styles['copy-button'], {
            [styles.success]: showSuccessMessage,
            [styles.rtl]: isRTL,
            [styles.ltr]: !isRTL,
          })}
          tabIndex="0"
          onClick={this.handleCopyClick}
        >
          <i className={styles.checkmark} />
          <span className={styles.text}>
            {i18n.t('share-overlay.embed.copy')}
          </span>
        </button>
      </div>
    );
  }
}

export default CopyContent;
