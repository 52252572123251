import _ from 'lodash';
import classnames from 'classnames';
import cxs from 'cxs';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* containers */
import withApplyForcedHeight from 'widget/layouts/slider/containers/apply-forced-height/apply-forced-height';
import withResizer from 'widget/layouts/slider/containers/resizer/resizer';
import ActionCallbacks from 'widget/containers/action-callbacks/action-callbacks';
import { WidgetPerformanceLoggers } from 'widget/containers/performance-loggers/performance-loggers';
import PaymentEvents from 'shared/components/payment-events/payment-events';
import ShareOverlay from 'widget/containers/share-overlay/share-overlay';
import OpenFullscreenModalShare from 'widget/containers/open-fullscreen-modal-share/open-fullscreen-modal-share';
import LiveStartHandler from 'widget/components/live-start-handler/live-start-handler';
import VideoList from 'widget/components/video-list/video-list';
import Player from 'widget/containers/player/player';
import { withPlayerModuleLoader } from 'widget/data-components/player-module-loader';

/* components */
import Slider from '@wix/wix-vod-shared/dist/src/widget/ui-components/slider/slider';
import ActionBar from 'widget/components/action-bar/action-bar';
import VideoThumbnail from 'shared/components/video-thumbnail/video-thumbnail';
import NoResults from 'widget/layouts/components/no-results/no-results';
import AutoPlayVideo from 'shared/components/autoplay-video/autoplay-video';

/* utils */
import getThumbnailMinWidthAttribute from '@wix/wix-vod-shared/dist/src/widget/ui-components/slider/get-thumbnail-min-width-attribute';
import { appStatus } from 'services/app-status';

/* bi */
import { logWidgetSystem } from 'shared/worker/actions/bi';
import { logWidgetVidClick } from 'shared/utils/bi/widget-common-events';

/* selectors */
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import {
  isVideoPlayingOptimistic,
  isVideoPausedOptimistic,
} from 'widget/selectors/video-playback-status';
import { isResponsiveEnabled } from 'shared/selectors/experiments';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import {
  getVideoIds,
  getCursor,
  getIsFetching,
} from 'widget/redux/client/lazy-channel-videos/selectors';
import { getCategory } from 'shared/selectors/search';
import {
  isThumbnailsPreviewHover,
  isSliderNavAlwaysVisible,
  getThumbnailSpacing,
  getContainerMargins,
  getChannelLayout,
  isPlayInFrame,
  isRTL,
} from 'shared/selectors/app-settings';
import { getMainVideo } from 'widget/selectors/get-video';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { canRenderPlayer } from 'widget/layouts/slider/selectors';
import { isInlineShareVisible } from 'widget/selectors/inline-share';
import { showAutoPlay } from 'widget/selectors/layout';

/* actions */
import {
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
} from 'widget/redux/client/actions/full-screen-modal';
import {
  loadMore,
  loadMoreVideoPages,
} from 'widget/redux/client/lazy-channel-videos/actions';
import { selectVideo } from 'widget/redux/client/actions/select-video';
import { pauseVideo } from 'widget/redux/client/actions/player/change-playback-status';
import { resetSearch } from 'widget/redux/client/actions/search';
import { requestPlayVideo } from 'widget/redux/client/actions/request-play-video';

/* constants */
import { SLIDER_PADDING } from 'widget/layouts/slider/constants';
import { CHANNEL_LAYOUT_VALUES } from '@wix/wix-vod-constants/dist/app-settings/channel-layout-values';

/* translations */

import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import { withStyles } from 'shared/utils/withStyles';
import styles from './slider.styl';
import * as viewModeSelectors from 'widget/selectors/view-mode';

const channelLayoutToNameMap = {
  [CHANNEL_LAYOUT_VALUES.SLIDER_SMALL]: 'sliderSmall',
  [CHANNEL_LAYOUT_VALUES.SLIDER_BIG]: 'sliderBig',
};

@connect(
  (state, props) => {
    const mainVideo = getMainVideo(state);
    const videoIds = getVideoIds(state);
    const channelLayout = getChannelLayout(state);
    const mainVideoId = _.get(mainVideo, 'id');
    return {
      styleId: getCompId(state),
      isEditorMode: viewModeSelectors.isEditorMode(state),
      isResponsive: isResponsiveEnabled(state),
      channel: getChannelForWidget(state),
      channelLayout,
      layoutName: _.get(channelLayoutToNameMap, channelLayout, ''),
      currentSiteUser: getCurrentSiteUser(state),
      mainVideo,
      mainVideoId,
      mainVideoIndex: _.indexOf(videoIds, mainVideoId),
      videoIds,
      videoByIds: getVideosGroupedByIds(state),
      nextVideosCursor: getCursor(state),
      isFetching: getIsFetching(state),
      isThumbnailsPreviewHover: isThumbnailsPreviewHover(state),
      isNavAlwaysVisible: isSliderNavAlwaysVisible(state),
      isVideoPlaying: isVideoPlayingOptimistic(state),
      isVideoPaused: isVideoPausedOptimistic(state),
      thumbnailSpacing: getThumbnailSpacing(state),
      containerMargins: getContainerMargins(state),
      selectedCategory: getCategory(state),
      isPlayerVisible: canRenderPlayer(state),
      isInlineShareVisible: isInlineShareVisible(state, props),
      isPlayInFrame: isPlayInFrame(state),
      showAutoPlay: showAutoPlay(state),
      isRTL: isRTL(state),
    };
  },
  {
    loadMore,
    loadMoreVideoPages,
    selectVideo,
    pauseVideo,
    resetSearch,
    requestPlayVideo,
    openFullScreenVideoOverlay,
    closeFullScreenVideoOverlay,
    logWidgetSystem,
    logWidgetVidClick,
  },
)
@withStyles(styles)
export class SliderLayout extends React.Component {
  static propTypes = {
    styleId: PropTypes.string,
    channel: PropTypes.object.isRequired,
    layoutName: PropTypes.string.isRequired,
    currentSiteUser: PropTypes.object,
    isEditorMode: PropTypes.bool.isRequired,

    mainVideo: PropTypes.object,
    mainVideoId: PropTypes.string,
    mainVideoIndex: PropTypes.number,

    videoIds: PropTypes.array.isRequired,
    videoByIds: PropTypes.object.isRequired,
    nextVideosCursor: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    loadMore: PropTypes.func.isRequired,
    loadMoreVideoPages: PropTypes.func.isRequired,
    selectVideo: PropTypes.func.isRequired,
    pauseVideo: PropTypes.func.isRequired,
    resetSearch: PropTypes.func.isRequired,
    requestPlayVideo: PropTypes.func.isRequired,
    channelLayout: PropTypes.number.isRequired,

    isThumbnailsPreviewHover: PropTypes.bool.isRequired,
    isNavAlwaysVisible: PropTypes.bool.isRequired,
    isVideoPlaying: PropTypes.bool.isRequired,
    isVideoPaused: PropTypes.bool.isRequired,
    isResponsive: PropTypes.bool.isRequired,

    containerMargins: PropTypes.number.isRequired,
    thumbnailSpacing: PropTypes.number.isRequired,
    isArrowsOutside: PropTypes.bool.isRequired,
    itemWidth: PropTypes.number.isRequired,
    thumbnailHeight: PropTypes.number.isRequired,
    sliderMargin: PropTypes.number.isRequired,
    sliderPadding: PropTypes.number.isRequired,
    minSliderWidth: PropTypes.number.isRequired,
    minSliderHeight: PropTypes.number.isRequired,
    isResized: PropTypes.bool.isRequired,
    isPlayerVisible: PropTypes.bool.isRequired,
    isInlineShareVisible: PropTypes.bool.isRequired,
    isPlayInFrame: PropTypes.bool.isRequired,
    showAutoPlay: PropTypes.bool,

    selectedCategory: PropTypes.string,

    onContainerRef: PropTypes.func.isRequired,

    sliderWidth: PropTypes.number.isRequired,

    openFullScreenVideoOverlay: PropTypes.func.isRequired,
    closeFullScreenVideoOverlay: PropTypes.func.isRequired,

    PlayerComponent: PropTypes.func,
    isPortableDevice: PropTypes.bool,
    isRTL: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    cxs.prefix(props.styleId);
  }

  componentDidMount() {
    appStatus.setMarkerReady(this.props.channelLayout);
  }

  componentDidUpdate() {
    this.props.logWidgetSystem('videoList.searchByCategory.rendered', {
      previousEventName: 'videoList.searchByCategory.requested',
    });
  }

  logVideoPlayRequested = videoItem => {
    const { channel } = this.props;
    this.props.logWidgetVidClick({ videoItem, channelData: channel });
  };

  renderItem = (videoId, checkIsVisible) => {
    const {
      channel,
      videoByIds,
      currentSiteUser,
      videoIds,
      itemWidth,
      mainVideo,
    } = this.props;

    if (!videoId) {
      return null;
    }

    const video = videoByIds[videoId];
    const index = videoIds.indexOf(videoId);

    const dataHook = classnames('video-list-thumb-wrapper', {
      'video-list-thumb-wrapper-selected': mainVideo.id === videoId,
    });

    return (
      <ActionCallbacks
        channelId={channel.id}
        videoItem={video}
        onPlayRequestedBi={this.logVideoPlayRequested}
        key={videoId}
      >
        <VideoThumbnail
          videoItem={video}
          key={videoId}
          dataHook={dataHook}
          channelData={channel}
          isContentFocusable={checkIsVisible(index)}
          currentSiteUser={currentSiteUser}
          width={itemWidth}
        />
      </ActionCallbacks>
    );
  };

  getSliderAreaStyles = () => {
    const { sliderMargin, sliderPadding } = this.props;
    return {
      width: `calc(100% - ${(sliderMargin + sliderPadding) * 2}px)`,
    };
  };

  loadMoreItems = itemsToLoadCount => {
    const { loadMore, loadMoreVideoPages, selectedCategory } = this.props;

    if (selectedCategory) {
      loadMoreVideoPages();
      return;
    }

    loadMore(itemsToLoadCount, { category: selectedCategory });
  };

  getNavButtonClassName() {
    const {
      isArrowsOutside,
      sliderMargin,
      sliderPadding,
      thumbnailHeight,
    } = this.props;

    const buttonWidthClassName = isArrowsOutside
      ? cxs({ minWidth: `${sliderMargin + sliderPadding}px` })
      : null;

    return classnames(
      styles.navButton,
      cxs({ height: `${thumbnailHeight}px` }),
      buttonWidthClassName,
    );
  }

  getPublishedChannelItemsCount() {
    const { channel, videoIds, nextVideosCursor } = this.props;

    return !nextVideosCursor && videoIds.length
      ? videoIds.length
      : channel.videosCount;
  }

  getMaxItemsCount() {
    const {
      videoIds,
      selectedCategory,
      nextVideosCursor,
      sliderWidth,
      itemWidth,
    } = this.props;

    const itemsPerPage = Math.ceil(sliderWidth / itemWidth);

    if (!selectedCategory || !nextVideosCursor) {
      return this.getPublishedChannelItemsCount();
    }

    return Math.max(videoIds.length + (nextVideosCursor ? 1 : 0), itemsPerPage);
  }

  handleActiveItemLeftFrame = () => {
    const { isVideoPlaying, pauseVideo } = this.props;

    if (isVideoPlaying) {
      pauseVideo();
    }
  };

  renderSlider() {
    const {
      channel,
      videoIds,
      isFetching,
      selectedCategory,
      mainVideoIndex,
      nextVideosCursor,
      isArrowsOutside,
      isNavAlwaysVisible,
      thumbnailSpacing,
      sliderWidth,
      itemWidth,
      thumbnailHeight,
      isRTL,
      isEditorMode,
    } = this.props;

    const navButtonClassName = this.getNavButtonClassName();

    return (
      <Slider
        width={sliderWidth}
        reset={isEditorMode}
        containerStyle={isArrowsOutside ? null : this.getSliderAreaStyles()}
        navButtonClassName={navButtonClassName}
        shadyNavButtonClassName={styles.shadyNav}
        thumbnailHeight={thumbnailHeight}
        itemsPerPageCount={1}
        itemIds={videoIds}
        horizontalSpacing={thumbnailSpacing}
        itemsCount={this.getMaxItemsCount()}
        itemWidth={itemWidth}
        activeItemOverlay={this.renderActiveItemContent()}
        onActiveItemLeftFrame={this.handleActiveItemLeftFrame}
        renderItem={this.renderItem}
        canLoadMoreItems={Boolean(nextVideosCursor && !isFetching)}
        mainItemIndex={mainVideoIndex}
        loadMoreItems={this.loadMoreItems}
        accessibilityLabels={{
          containerAriaLabel: i18n.t('widget.accessibility.channel-videos', {
            channelTitle: channel.title,
          }),
          prevButtonAriaLabel: i18n.t('widget.accessibility.prev-videos'),
          nextButtonAriaLabel: i18n.t('widget.accessibility.next-videos'),
        }}
        withDisabledNav={Boolean(selectedCategory) && isFetching}
        withArrowsOutside={isArrowsOutside}
        withNavAlwaysVisible={isNavAlwaysVisible}
        withKeyboardNavigation
        withShadyNavigation
        isRTL={isRTL}
      />
    );
  }

  renderEmptyState() {
    return (
      <div
        data-hook="slider-empty"
        style={this.getSliderAreaStyles()}
        className={styles.empty}
      >
        {!this.props.isFetching && (
          <div className={styles.emptyContent}>
            {i18n.t('widget.this-channel-is-coming-soon')}
          </div>
        )}
      </div>
    );
  }

  renderContent() {
    const {
      mainVideo,
      selectedCategory,
      nextVideosCursor,
      videoIds,
      isFetching,
    } = this.props;

    if (
      !isFetching &&
      selectedCategory &&
      !nextVideosCursor &&
      !videoIds.length
    ) {
      return this.renderEmptySearchState(
        i18n.t('widget.categories.no-videos-in-category'),
      );
    }

    return mainVideo ? this.renderSlider() : this.renderEmptyState();
  }

  renderEmptySearchState(message) {
    return (
      <div style={this.getSliderAreaStyles()}>
        <NoResults
          dataHook="slider-empty-search-results"
          message={message}
          onButtonClick={this.props.resetSearch}
        />
      </div>
    );
  }

  renderActions() {
    const {
      containerMargins,
      isArrowsOutside,
      channel,
      isResponsive,
    } = this.props;

    const style = isResponsive
      ? null
      : {
          padding: !containerMargins && !isArrowsOutside ? '0 15px' : 0,
          ...this.getSliderAreaStyles(),
        };

    return (
      <ActionBar
        onPageRefresh={_.noop}
        style={style}
        channelData={channel}
        isResponsive={isResponsive}
      />
    );
  }

  renderSliderContent() {
    const { selectedCategory, minSliderHeight } = this.props;

    return (
      <section
        className={styles.slider}
        key={`slider-${selectedCategory}`}
        data-hook="slider-container"
        style={{ minHeight: '100%', height: minSliderHeight || '100%' }}
      >
        {this.renderContent()}
      </section>
    );
  }

  renderResponsiveSliderContent() {
    const { videoIds, videoByIds, channel, currentSiteUser } = this.props;

    return (
      <VideoList
        videoIds={videoIds}
        videoByIds={videoByIds}
        channel={channel}
        currentSiteUser={currentSiteUser}
        onPlayRequestedBi={this.logVideoPlayRequested}
      />
    );
  }

  setCurrentVideoFromPayment = ({ itemId } = {}) => {
    if (itemId) {
      this.props.selectVideo(itemId);
    }
  };

  renderActiveItemContent() {
    const {
      channel,
      mainVideo,
      itemWidth,
      thumbnailHeight,
      isPlayerVisible,
      isVideoPlaying,
      isVideoPaused,
      isInlineShareVisible,
      showAutoPlay,
      PlayerComponent,
    } = this.props;

    if (!isPlayerVisible || !PlayerComponent) {
      return null;
    }

    return (
      <div
        data-hook="player-wrapper"
        className={classnames(styles.player, {
          [styles.active]: isVideoPlaying || isVideoPaused,
        })}
      >
        <Player
          width={itemWidth}
          height={thumbnailHeight}
          PlayerComponent={PlayerComponent}
        />
        {isInlineShareVisible && (
          <ShareOverlay
            key={channel.id}
            channelData={channel}
            videoItem={mainVideo}
          />
        )}
        {showAutoPlay && <AutoPlayVideo />}
      </div>
    );
  }

  playVideo = ({ id }) => {
    const {
      channel,
      requestPlayVideo,
      openFullScreenVideoOverlay,
      closeFullScreenVideoOverlay,
      isPlayInFrame,
      isPortableDevice,
    } = this.props;

    if (isPlayInFrame || isPortableDevice) {
      requestPlayVideo(id);
      return;
    }

    openFullScreenVideoOverlay(
      channel.id,
      id,
      true,
      closeFullScreenVideoOverlay,
    );
  };

  render() {
    const {
      minSliderWidth,
      onContainerRef,
      itemWidth,
      isResized,
      channel,
      layoutName,
      isVideoPlaying,
      mainVideoId,
      isResponsive,
    } = this.props;

    const style = isResponsive
      ? {}
      : {
          minWidth: minSliderWidth,
          paddingTop: SLIDER_PADDING,
          paddingBottom: SLIDER_PADDING,
        };

    return (
      <main
        className={classnames(styles.container, {
          [styles.isResized]: isResized,
        })}
        ref={onContainerRef}
        data-thumbnail-min-width={getThumbnailMinWidthAttribute(itemWidth)}
        data-hook="widget-container"
        data-channel-layout={layoutName}
        aria-label={i18n.t('widget.accessibility.channel-videos-widget', {
          channelTitle: channel.title,
        })}
        tabIndex="0"
        style={style}
      >
        {this.renderActions()}
        {isResponsive
          ? this.renderResponsiveSliderContent()
          : this.renderSliderContent()}

        <OpenFullscreenModalShare itemWidth={itemWidth} />
        <PaymentEvents
          onRent={this.setCurrentVideoFromPayment}
          onSale={this.setCurrentVideoFromPayment}
        />
        <LiveStartHandler
          playVideo={this.playVideo}
          isVideoPlaying={isVideoPlaying}
          selectedVideoId={mainVideoId}
        />
        <WidgetPerformanceLoggers />
        {__SERVER__ && <style>{cxs.css()}</style>}
      </main>
    );
  }
}

export default _.flow(
  withResizer,
  withApplyForcedHeight,
  withPlayerModuleLoader,
)(SliderLayout);
