import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import formatMessageWithPrice from 'shared/utils/format-message-with-price';
import {
  getFullAccessActionName,
  FULL_ACCESS_ACTION_NAMES,
} from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';

/**
 * Is channel subscribe button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @return {Boolean}
 */
export function isVisible({ channel, video }) {
  return (
    getFullAccessActionName(channel, video) ===
    FULL_ACCESS_ACTION_NAMES.SUBSCRIBE
  );
}

/**
 * Channel subscribe button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @param props.translation {Object} default is 'widget.overlay-buttons.get-subscription'
 * @return {React.Component}
 */
export default function renderSubscribeButtonText(props) {
  if (!isVisible(props)) {
    return null;
  }

  return formatMessageWithPrice(
    props.translation || 'widget.overlay-buttons.get-subscription',
    dealInfoHelpers.getSubscriptionPriceInfo(props.channel.dealInfo),
  );
}
