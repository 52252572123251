import _ from 'lodash';
import { createAction } from 'redux-actions';

export const DEFAULT_NAMES = ['START', 'SUCCESS', 'FAIL'];

function toPayload(params = {}, response = null) {
  const payload = { params };
  if (response) {
    payload.response = response;
  }
  return payload;
}

function toMeta(params, response, meta = null) {
  if (meta && _.isArray(meta.analytics)) {
    return meta;
  }
  if (meta && meta.analytics && !meta.analytics.payload) {
    const { type, ...payload } = meta.analytics;
    meta.analytics = { type, payload };
  }
  return meta;
}

export default function(namespace, actionsNames = DEFAULT_NAMES) {
  const NAMES = _.reduce(
    actionsNames,
    function(res, name) {
      res[name] = `${namespace}.${name}`;
      return res;
    },
    {},
  );

  return _.reduce(
    actionsNames,
    function(res, name) {
      res[name] = createAction(NAMES[name], toPayload, toMeta);
      return res;
    },
    { NAMES },
  );
}
