import React from 'react';
import PropTypes from 'prop-types';
import WixEvents from 'shared/constants/wix-sdk';
import {
  withPubSubEvents,
  consumePubSubEvent,
} from 'widget/containers/pub-sub-events';

@withPubSubEvents
export class ViewerObserver extends React.Component {
  static propTypes = {
    isVideoPlaying: PropTypes.bool.isRequired,
    pauseVideo: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    consumePubSubEvent(
      WixEvents.PAGE_NAVIGATION,
      this.stopPlaybackOnPageChange,
      {
        prevProps,
        nextProps: this.props,
      },
    );
  }

  stopPlaybackOnPageChange = ({ wasAppOnPage }) => {
    if (wasAppOnPage && this.props.isVideoPlaying) {
      this.props.pauseVideo();
    }
  };

  render() {
    return null;
  }
}
