import React from 'react';
import { withStyles } from 'shared/utils/withStyles';
import styles from './balls-loader.styl';

export default withStyles(styles)(() => (
  <div className={styles.container}>
    <div className={styles.ballOne} />
    <div className={styles.ballTwo} />
    <div className={styles.ballThree} />
  </div>
));
