import { createSelector } from 'reselect';

import { getSearchQuery } from 'shared/selectors/search';
import {
  getVideoIdsByPageNumber,
  getIsFetching,
} from 'widget/redux/client/lazy-channel-videos/selectors';

export const getIsSearchResultEmpty = createSelector(
  [getIsFetching, getSearchQuery, getVideoIdsByPageNumber],
  (isVideosFetching, searchQuery, videoIdsByPageNumber) =>
    Boolean(!isVideosFetching && searchQuery && !videoIdsByPageNumber.length),
);
