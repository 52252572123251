import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestPlayVideoOnload } from 'widget/redux/client/actions/request-play-video-onload';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getAutoplay } from 'shared/selectors/app-settings';
import { getMainVideo } from 'widget/selectors/get-video';
import { getVideoIdFromUrl } from 'widget/selectors/query-params';
import * as viewModeSelectors from 'widget/selectors/view-mode';

@connect(
  state => ({
    mainVideo: getMainVideo(state),
    mainVideoId: getMainVideoId(state),
    isAutoPlay: getAutoplay(state),
    videoIdFromUrl: getVideoIdFromUrl(state),
    isSite: viewModeSelectors.isSiteMode(state),
    isStandalone: viewModeSelectors.isStandalone(state),
  }),
  {
    requestPlayVideoOnload,
  },
)
export default class AutoPlayVideo extends React.PureComponent {
  static propTypes = {
    dataHook: PropTypes.string,
    isAutoPlay: PropTypes.bool,
    isMobile: PropTypes.bool,
    mainVideoId: PropTypes.string.isRequired,
    requestPlayVideoOnload: PropTypes.func.isRequired,
    videoIdFromUrl: PropTypes.string,
    isSite: PropTypes.bool.isRequired,
    isStandalone: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    dataHook: 'autoplay-video',
    isMobile: false,
  };

  UNSAFE_componentWillMount() {
    const {
      videoIdFromUrl,
      isAutoPlay,
      isMobile,
      requestPlayVideoOnload,
      mainVideoId,
      isSite,
      isStandalone,
    } = this.props;

    if (isAutoPlay && !videoIdFromUrl && (isSite || isStandalone)) {
      requestPlayVideoOnload(mainVideoId, isMobile);
    }
  }

  render() {
    return null;
  }
}
