import { createAction } from 'redux-actions';
import SEARCH_QUERY_FIELDS from 'shared/constants/search-query-fields';

export const SEARCH_ACTIONS = {
  UPDATE_SEARCH: 'CLIENT.SEARCH.UPDATE_SEARCH',
  RESET_SEARCH: 'CLIENT.SEARCH.RESET_SEARCH',
};

export const updateSearch = createAction(SEARCH_ACTIONS.UPDATE_SEARCH);
export const resetSearch = createAction(SEARCH_ACTIONS.RESET_SEARCH);

export const searchByString = value =>
  updateSearch({
    searchQuery: value,
    searchQueryField: SEARCH_QUERY_FIELDS.QUERY,
  });
export const searchByTag = value =>
  updateSearch({
    searchQuery: value,
    searchQueryField: SEARCH_QUERY_FIELDS.TAG,
  });
export const searchByCategory = value =>
  updateSearch({
    searchQuery: value,
    searchQueryField: SEARCH_QUERY_FIELDS.CATEGORY,
  });
