import _ from 'lodash';
import { createSelector } from 'reselect';
import { isNumberOfRowsForClassicEnabled } from './app-settings';

export const getExperiments = state => state.experiments;
export const experimentsFetched = createSelector(
  getExperiments,
  _.negate(_.isNil),
);

/**
 * @param {string} experimentName
 * @returns {Reselect.Selector} isEnabledSelector
 */
const createIsEnabledSelector = experimentName =>
  createSelector(
    getExperiments,
    experiments => _.get(experiments, experimentName) === 'true',
  );

export const isNumberOfRowsSpecEnabled = createIsEnabledSelector(
  'specs.vod.numbersOfRowsClassic',
);

export const isNumberOfRowsEnabled = createSelector(
  isNumberOfRowsForClassicEnabled,
  isNumberOfRowsSpecEnabled,
  (isAppSettingsFTenabled, isSpecEnabled) =>
    isAppSettingsFTenabled && isSpecEnabled,
);

export const isResponsiveEnabled = createIsEnabledSelector(
  'specs.vod.responsive',
);

export const isResponsivePaymentViewsEnabled = createIsEnabledSelector(
  'specs.vod.responsivePaymentViews',
);
