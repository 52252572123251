import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hosts } from '@wix/wix-vod-shared/dist/src/common/settings/settings';
import { pauseVideo } from 'widget/redux/client/actions/player/change-playback-status';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { getWidgetVideoCards } from 'widget/selectors/video-cards';
import { VIDEO_CARD_TYPES } from '@wix/wix-vod-constants/dist/video-cards/types';
import videoCardsBiLogger from 'widget/player-widget/components/new-player/bi-loggers/video-cards-bi-logger';

const { logCardClick, logCardCloseButtonClick } = videoCardsBiLogger;

const isVideoCard = card =>
  card.type === VIDEO_CARD_TYPES.VIDEO ||
  card.type === VIDEO_CARD_TYPES.RECENT_VIDEO;
const isCustomContentCard = card =>
  card.type === VIDEO_CARD_TYPES.PRODUCT ||
  card.type === VIDEO_CARD_TYPES.CUSTOM;
const DEFAULT_THUMBNAIL_SIZE = 110;

@connect(
  (state, { thumbnailSize }) => ({
    cardsData: getWidgetVideoCards({ thumbnailSize, imageHost: hosts.image })(
      state,
    ),
    videoByIds: getVideosGroupedByIds(state),
    channelData: getChannelForWidget(state),
  }),
  {
    pauseVideo,
    logCardClick,
    logCardCloseButtonClick,
  },
)
export default class VideoCardsWrapper extends React.PureComponent {
  static propTypes = {
    handlePlayRequest: PropTypes.func.isRequired,

    channelData: PropTypes.object,
    videoByIds: PropTypes.object,
    cardsData: PropTypes.array,

    player: PropTypes.object,

    children: PropTypes.func.isRequired,
    pauseVideo: PropTypes.func,

    thumbnailSize: PropTypes.number,

    biOrigin: PropTypes.string,
  };

  static defaultProps = {
    thumbnailSize: DEFAULT_THUMBNAIL_SIZE,
  };

  componentDidMount() {
    const { player } = this.props;
    if (player && _.isFunction(player.onCardClose)) {
      player.onCardClose(this.logCardClose);
    }
  }

  logCardClose = ({ id }) => {
    this.props.logCardCloseButtonClick({
      card: _.find(this.props.cardsData, { clientId: id }),
    });
  };

  handleCardClick = card => {
    const { channelData, pauseVideo, biOrigin, handlePlayRequest } = this.props;
    const { actionListId, actionItemId } = card;

    this.props.logCardClick({ card, biOrigin });

    if (isVideoCard(card) && channelData.id === actionListId) {
      handlePlayRequest({ id: actionItemId });
      return;
    }

    if (isCustomContentCard(card) && !card.url) {
      return;
    }

    pauseVideo();
  };

  render() {
    const { cardsData, children } = this.props;

    return children({
      cardsData,
      onCardClick: this.handleCardClick,
    });
  }
}
