import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getInstance } from 'widget/redux/client/hydrated-data/hydrated-data';
import VideoPreview from './video-preview/video-preview';
import ImageCover from './image-cover/image-cover';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { isPortableDevice } from 'shared/selectors/form-factor';

@connect(state => ({
  instance: getInstance(state),
  isEditorViewMode: viewModeSelectors.isEditorMode(state),
  isMobileDevice: isPortableDevice(state),
}))
class ChannelCover extends React.Component {
  static propTypes = {
    channelId: PropTypes.string,
    instance: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    posterUrl: PropTypes.string,
    mediaInfo: PropTypes.object,
    isEditorViewMode: PropTypes.bool,
    isMobileDevice: PropTypes.bool,
    showVideoCover: PropTypes.bool,
  };

  shouldRenderVideoPreview() {
    const { isEditorViewMode, isMobileDevice } = this.props;

    return this.isVideoPreview() && !isEditorViewMode && !isMobileDevice;
  }

  isVideoPreview() {
    const { showVideoCover, mediaInfo } = this.props;
    return Boolean(showVideoCover && mediaInfo);
  }

  getImageCover() {
    const { width, height, posterUrl } = this.props;

    return (
      <ImageCover
        key={posterUrl}
        posterUrl={posterUrl}
        dataHook="image-cover"
        width={width}
        height={height}
      />
    );
  }

  render() {
    const { width, height, channelId, instance } = this.props;

    if (this.shouldRenderVideoPreview()) {
      return (
        <VideoPreview
          dataHook="video-preview"
          channelId={channelId}
          instance={instance}
          poster={this.getImageCover()}
          width={width}
          height={height}
        />
      );
    }

    return this.getImageCover();
  }
}

export default ChannelCover;
