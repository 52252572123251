import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import BackIcon from './back.svg';

import { withStyles } from 'shared/utils/withStyles';
import styles from './header.styl';

const Header = ({ dataHook, onBackButtonClick }) => (
  <div data-hook={dataHook} className={styles.title} dir="ltr">
    <a
      href="#"
      data-hook="back-button"
      className={styles['back-button']}
      onClick={onBackButtonClick}
      aria-label={i18n.t('widget.accessibility.close-embed-form')}
    >
      <BackIcon />
    </a>

    <span className={styles['title-text']}>
      {i18n.t('share-overlay.embed.video.title')}
    </span>
  </div>
);

Header.propTypes = {
  dataHook: PropTypes.string,
  onBackButtonClick: PropTypes.func,
};

export default withStyles(styles)(Header);
