import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WixEvents from 'shared/constants/wix-sdk';
import {
  withPubSubEvents,
  consumePubSubEvent,
} from 'widget/containers/pub-sub-events';

import { setHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
import * as viewModeSelectors from 'widget/selectors/view-mode';

@withPubSubEvents
@connect(
  state => ({
    viewMode: viewModeSelectors.getViewMode(state),
  }),
  { setHydratedData },
)
export class ViewModeObserver extends React.Component {
  static propTypes = {
    setHydratedData: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    viewMode: PropTypes.string,
  };

  static defaultProps = {
    onChange: _.noop,
  };

  componentDidUpdate(prevProps) {
    consumePubSubEvent(WixEvents.EDIT_MODE_CHANGE, this.handleEditModeChange, {
      prevProps,
      nextProps: this.props,
    });
  }

  handleEditModeChange = ({ editMode }) => {
    const { viewMode, setHydratedData, onChange } = this.props;

    onChange({ prevViewMode: viewMode, viewMode: editMode });
    setHydratedData({ viewMode: editMode });
  };

  render() {
    return null;
  }
}
