import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import { isShareOverlayOpen } from 'widget/selectors/share-overlay';
import {
  toggleShareChannelOverlay,
  toggleShareVideoOverlay,
} from 'widget/redux/client/actions/share-overlay';
import { canShowChannelShare } from 'shared/selectors/app-settings';
import { canShareVideo } from 'shared/selectors/video-share';

@connect(
  state => ({
    isOpen: isShareOverlayOpen(state),
    canShowVideoShare: canShareVideo(state),
    canShowChannelShare: canShowChannelShare(state),
  }),

  {
    toggleShareChannelOverlay,
    toggleShareVideoOverlay,
  },
)
class OpenShareOverlay extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    isFocusable: PropTypes.bool,
    canShowVideoShare: PropTypes.bool,
    canShowChannelShare: PropTypes.bool,
    toggleShareChannelOverlay: PropTypes.func,
    toggleShareVideoOverlay: PropTypes.func,
    children: PropTypes.func,
  };

  static defaultProps = {
    isFocusable: true,
  };

  toggleShare = () => {
    const { canShowChannelShare } = this.props;
    if (canShowChannelShare) {
      this.props.toggleShareChannelOverlay();
    } else {
      this.props.toggleShareVideoOverlay();
    }
  };

  render() {
    const { isFocusable, isOpen, children, canShowChannelShare } = this.props;

    // TODO: ask Yuriy, do we really need to hide tabindex

    if (!canShowChannelShare) {
      return null;
    }

    return (
      children &&
      children({
        toggleShare: this.toggleShare,
        tabIndex: !isFocusable || isOpen ? -1 : 0,
        ariaLabel: i18n.t('widget.accessibility.share'),
      })
    );
  }
}

export default OpenShareOverlay;
