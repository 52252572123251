import { INFO_POSITION_VALUES } from '@wix/wix-vod-constants/dist/app-settings/info-position-values';
import {
  isClassicLayoutHeaderVisible,
  isClassicLayoutSearchBarVisible,
} from 'shared/utils/layout-helpers';

const WIDGET_PADDING = 20;
const HEADER_HEIGHT = 55;
const SEARCH_BAR_HEIGHT = 72;
const VIDEOS_LIST_DESCRIPTION_LINE_HEIGHT = 18;
const VIDEOS_LIST_DESCRIPTION_SPACING = 8;
const VIDEOS_LIST_SPACING = 10;
const VIDEOS_LIST_PADDING_BOTTOM = 10;
const VIDEOS_LIST_PAGINATION_HEIGHT = 57;
export const MIN_WIDGET_WIDTH = 680;
export const MAX_WIDGET_WIDTH = 980;
export const VIDEO_HEIGHT_RATIO = 9 / 16;
const MOBILE_OVERLAY_HEIGHT_RATIO = 250 / 320;
const MOBILE_VIDEO_HEIGHT_RATIO = 180 / 320;

function roundAsBrowser(number) {
  return Math.floor(number * 100) / 100;
}

// TODO: move to classic only selectors
function classicLayoutHeaderHeight(appSettings, channelData) {
  return isClassicLayoutHeaderVisible(appSettings, channelData)
    ? HEADER_HEIGHT
    : WIDGET_PADDING;
}

// TODO: move to classic only selectors
function classicLayoutSearchLineHeight(appSettings, channelData) {
  return isClassicLayoutSearchBarVisible(appSettings, channelData)
    ? SEARCH_BAR_HEIGHT
    : WIDGET_PADDING;
}

function videosListDescriptionHeight(appSettings) {
  if (
    appSettings.numbers.videoInfoPosition !== INFO_POSITION_VALUES.BELOW_VIDEOS
  ) {
    return 0;
  }

  let height = 0;
  if (
    appSettings.booleans.showVideoTitleInList ||
    appSettings.booleans.showVideoPublisherInList
  ) {
    height += VIDEOS_LIST_DESCRIPTION_SPACING;
  }

  if (appSettings.booleans.showVideoTitleInList) {
    height += VIDEOS_LIST_DESCRIPTION_LINE_HEIGHT;
  }
  if (appSettings.booleans.showVideoPublisherInList) {
    height += VIDEOS_LIST_DESCRIPTION_LINE_HEIGHT;
  }

  return height;
}

function videosListHeight(appSettings, widgetWidth, videosInRow, rowsCount) {
  const descriptionHeight = videosListDescriptionHeight(appSettings);
  const thumbnailHeight = listThumbnailSize(widgetWidth, videosInRow).height;
  return (
    (thumbnailHeight + descriptionHeight + VIDEOS_LIST_SPACING) * rowsCount
  );
}

function videosListPaginationHeight(allVideosCount, rowsCount, videosInRow) {
  return allVideosCount > rowsCount * videosInRow
    ? VIDEOS_LIST_PAGINATION_HEIGHT
    : WIDGET_PADDING - VIDEOS_LIST_PADDING_BOTTOM;
}

// TODO: move to classic only selectors
export function classicLayoutWidgetHeight(
  widgetWidth,
  videosInRow,
  allVideosCount,
  rowsCount,
  appSettings,
  channelData,
) {
  return (
    classicLayoutHeaderHeight(appSettings, channelData) +
    playerSize(widgetWidth).height +
    classicLayoutSearchLineHeight(appSettings, channelData) +
    videosListHeight(appSettings, widgetWidth, videosInRow, rowsCount) +
    videosListPaginationHeight(allVideosCount, rowsCount, videosInRow)
  );
}

function playerHeightWithRatio(width) {
  return width * VIDEO_HEIGHT_RATIO;
}

export function playerSize(widgetWidth, widgetPadding = WIDGET_PADDING) {
  const width = widgetWidth - widgetPadding * 2;
  return {
    width: roundAsBrowser(width),
    height: roundAsBrowser(playerHeightWithRatio(width)),
  };
}

// TODO: move to mobile only selectors
export function playerSizeMobile(widgetWidth) {
  return playerSize(widgetWidth, 0);
}

// TODO: move to mobile only selectors
export function overlaySizeMobileMainItem(widgetWidth) {
  return {
    width: roundAsBrowser(widgetWidth),
    height: roundAsBrowser(widgetWidth * MOBILE_OVERLAY_HEIGHT_RATIO),
  };
}

export function playerSizeMobileMainItem(widgetWidth) {
  return {
    width: roundAsBrowser(widgetWidth),
    height: roundAsBrowser(widgetWidth * MOBILE_VIDEO_HEIGHT_RATIO),
  };
}

// TODO: move to strip only selectors
export function stripLayoutThumbnailSize(widgetWidth, videosInRow) {
  const width = widgetWidth / videosInRow;

  return {
    width: roundAsBrowser(width),
    height: roundAsBrowser(playerHeightWithRatio(width)),
  };
}

export function listThumbnailSize(widgetWidth, videosInRow) {
  const listWidth = widgetWidth - WIDGET_PADDING * 2;
  const width =
    (listWidth - (videosInRow - 1) * VIDEOS_LIST_SPACING) / videosInRow;

  return {
    width: roundAsBrowser(width),
    height: roundAsBrowser(playerHeightWithRatio(width)),
  };
}

function maxVideosAtHeight(appSettings, height, widgetWidth, videosInRow) {
  const videosHeight = videosListHeight(
    appSettings,
    widgetWidth,
    videosInRow,
    1,
  );
  let rowsCount = Math.floor(height / videosHeight);

  if (rowsCount <= 0) {
    rowsCount = 1;
  }

  return rowsCount * videosInRow;
}

export function videosPerPage(
  widgetSize,
  videosInRow,
  allVideosCount,
  appSettings,
  channelData,
) {
  const ocuppiedWidgetHeight =
    classicLayoutHeaderHeight(appSettings, channelData) +
    playerSize(widgetSize.width).height +
    classicLayoutSearchLineHeight(appSettings, channelData) +
    WIDGET_PADDING -
    VIDEOS_LIST_PADDING_BOTTOM;

  const singlePageListHeight = widgetSize.height - ocuppiedWidgetHeight;

  // paginated list includes navigation arrows height
  const paginatedListHeight =
    singlePageListHeight +
    WIDGET_PADDING +
    VIDEOS_LIST_PADDING_BOTTOM -
    VIDEOS_LIST_PAGINATION_HEIGHT;

  const singlePageVideosCount = maxVideosAtHeight(
    appSettings,
    singlePageListHeight,
    widgetSize.width,
    videosInRow,
  );

  const paginatedPerPageVideosCount = maxVideosAtHeight(
    appSettings,
    paginatedListHeight,
    widgetSize.width,
    videosInRow,
  );

  return allVideosCount && allVideosCount > singlePageVideosCount
    ? paginatedPerPageVideosCount
    : singlePageVideosCount;
}
