import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { get } from '@wix/wix-vod-api/dist/src/private/settings/settings';
import { createAsyncProxy } from 'shared/worker/lib';

const ACTIONS = createAsyncActions('SERVER.VOD-SETTINGS.GET');
export const NAMES = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

export const getVodSettings = createAsyncProxy('getVodSettings', function() {
  return function(dispatch) {
    dispatch(START());

    return get()
      .then(result => {
        dispatch(SUCCESS({}, result));
        return result;
      })
      .catch(response => {
        // NOTE: new site-based API responds with 404 error code if settings are missing (new user scenario)
        // temporary we are fixing this on the client and simulating empty object response, like it was done in old VOD
        if (response.status === 404) {
          const result = {};
          dispatch(SUCCESS({}, result));
          return Promise.resolve(result);
        }
        dispatch(
          FAIL(null, null, {
            analytics: {
              type: 'error',
              payload: { name: 'shared.vod-settings.get' },
            },
          }),
        );
        return Promise.reject(response);
      });
  };
});
