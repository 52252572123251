import { createAction } from 'redux-actions';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getAllSettings } from 'shared/selectors/app-settings';
import { canShowChannelCover } from 'widget/components/player-overlay/selectors';
import { notForPreview } from 'shared/utils/not-for-preview';
import {
  openFullScreenPurchaseOverlay,
  openFullScreenRentOverlay,
} from 'widget/utils/open-overlay';
import { logPurchaseButtonClicked } from 'widget/components/player-overlay/bi';
import { createProxy } from 'shared/worker/lib';

import {
  getFullAccessActionName,
  FULL_ACCESS_ACTION_NAMES,
} from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';

const OPEN_FULL_SCREEN_OVERLAY =
  'CLIENT.PLAYER_OVERLAY.OPEN_FULL_SCREEN_OVERLAY';
const openOverlayAction = createAction(OPEN_FULL_SCREEN_OVERLAY);

const openOverlay = ({ channelData, videoItem }, actionName) => dispatch => {
  if (actionName === FULL_ACCESS_ACTION_NAMES.RENT) {
    dispatch(openFullScreenRentOverlay(channelData.id, videoItem.id));
  } else {
    dispatch(openFullScreenPurchaseOverlay(channelData.id, videoItem.id));
  }
};

export const openPlayerOverlay = createProxy(
  'openPlayerOverlay',
  (videoItem, showChannelCover) => (dispatch, getState) => {
    const state = getState();
    const channelData = getChannelForWidget(state);
    const appSettings = getAllSettings(state);
    const isChannelCover = canShowChannelCover(state, { showChannelCover });
    const actionName = getFullAccessActionName(channelData, videoItem);
    const props = {
      channelData,
      appSettings,
      videoItem,
      isChannelCover,
    };

    notForPreview(() => {
      dispatch(logPurchaseButtonClicked(props));
      dispatch(openOverlay(props, actionName));
    })();

    dispatch(openOverlayAction());
  },
);
