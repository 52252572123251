import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isRTL } from 'shared/selectors/app-settings';

@connect(state => ({
  isRTL: isRTL(state),
}))
export class Direction extends React.Component {
  static propTypes = {
    isRTL: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { isRTL, children } = this.props;
    const dir = isRTL ? 'rtl' : 'ltr';

    return (
      <div
        data-hook="wix-vod-widget-direction-container"
        dir={dir}
        style={{ height: '100%', position: 'relative', zIndex: 0 }}
      >
        {children}
      </div>
    );
  }
}
