import _ from 'lodash';
import { TRANSCODING_STATUSES } from '@wix/wix-vod-constants/dist/common/transcoding-statuses';
import { CHANNEL_TYPES } from '@wix/wix-vod-constants/dist/common/channel-types';
import { keysToCamelCase } from '@wix/wix-vod-shared/dist/src/common/utils/object';
import { normalizeId } from 'api/normalizers/entity-id';
import { parseVideoItem } from '@wix/wix-vod-api/dist/src/parsers/video-item';

export const parseCatalogChannel = channel => {
  channel = keysToCamelCase(channel);

  const {
    itemId: id,
    customCoverUrl,
    usedCount: videosCount,
    publishChanges: hasChangesToPublish,
    urlType = '',
    channelType = CHANNEL_TYPES.CHANNEL,
  } = channel;

  let {
    createdTs,
    externalCreatedTs,
    updatedTs,
    publishedTs = createdTs,
    statsInfo,
  } = channel;

  createdTs = new Date(createdTs * 1000);
  externalCreatedTs = new Date(externalCreatedTs * 1000);
  updatedTs = new Date(updatedTs * 1000);
  if (publishedTs) {
    publishedTs = new Date(publishedTs * 1000);
  }

  // WHY ?!?!
  if (statsInfo) {
    statsInfo = _.mapValues(statsInfo, list => {
      // get rid of empty categories & tags
      const nonEmptyList = _.filter(list, item => Boolean(item.value));
      return _.uniqBy(nonEmptyList, 'value');
    });
  }

  // TODO: USE SERVER SIDE MAPPING
  channel = _({})
    .assign(channel, {
      // TODO: either move to wix-vod or normalize on server side
      id: normalizeId(id),
      customCoverUrl: customCoverUrl || null,
      videosCount,
      createdTs,
      externalCreatedTs,
      updatedTs,
      publishedTs,
      hasChangesToPublish,
      urlType,
      channelType,
      statsInfo,
    })
    .omit('itemId', 'usedCount', 'publishChanges')
    .value();

  channel = parseFeaturedItem(channel);
  channel = cleanupVideoCountsForYoutubeFeed(channel);
  return channel;
};

function parseFeaturedItem(channel) {
  if (channel.featuredItem) {
    // TODO: remove after add filtering on server side. Fix failed video, marked as featured
    if (
      !channel.featuredItem.mediaStatus ||
      channel.featuredItem.mediaStatus === TRANSCODING_STATUSES.NONE
    ) {
      channel = _.omit(channel, 'featuredItem');
      if (channel.videosCount) {
        channel.videosCount += -1;
      }
    } else if (channel.featuredItem.itemId) {
      // cleanup in new VOD backend version
      channel.featuredItem = parseVideoItem(channel.featuredItem);
    }
  }
  return channel;
}

function cleanupVideoCountsForYoutubeFeed(channel) {
  // skip videosCount for youtube feed, should be done on BE
  if (channel.channelType !== CHANNEL_TYPES.CHANNEL) {
    channel = _.omit(channel, 'videosCount');
  }
  return channel;
}
