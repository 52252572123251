import { handleActions } from 'redux-actions';
import { UPDATE_WINDOW_SIZE } from 'widget/redux/client/actions/window-size';

const defaultState = {
  width: 0,
  height: 0,
};

export default handleActions(
  {
    [UPDATE_WINDOW_SIZE]: (state, { payload }) => ({ ...state, ...payload }),
  },
  defaultState,
);
