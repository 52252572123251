import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { notForPreview } from 'shared/utils/not-for-preview';
import { isPlayInFrame } from 'shared/selectors/app-settings';
import { handleLogOutPaymentModal } from 'shared/utils/handle-logout-payment-modal';

import { isVideoPlayingOptimistic } from 'widget/selectors/video-playback-status';
import {
  playVideo,
  pauseVideo,
} from 'widget/redux/client/actions/player/change-playback-status';
import { selectVideo } from 'widget/redux/client/actions/select-video';
import {
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
} from 'widget/redux/client/actions/full-screen-modal';

import {
  openFullScreenPurchaseOverlay,
  openFullScreenMemberOnlyOverlay,
  openFullScreenSubscribeOverlay,
  openFullScreenRentOverlay,
} from 'widget/utils/open-overlay';
import { isPortableDevice } from 'shared/selectors/form-factor';

@connect(
  state => ({
    isPlayInFrame: isPlayInFrame(state),
    isVideoPlaying: isVideoPlayingOptimistic(state),
    isPortableDevice: isPortableDevice(state),
  }),
  {
    playVideo,
    pauseVideo,
    selectVideo,
    openFullScreenVideoOverlay,
    closeFullScreenVideoOverlay,
    openFullScreenPurchaseOverlay,
    openFullScreenMemberOnlyOverlay,
    openFullScreenSubscribeOverlay,
    openFullScreenRentOverlay,
  },
)
export default class ActionCallbacks extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    selectVideo: PropTypes.func.isRequired,
    playVideo: PropTypes.func.isRequired,
    pauseVideo: PropTypes.func.isRequired,
    onPlayRequestedBi: PropTypes.func,
    openFullScreenVideoOverlay: PropTypes.func,
    closeFullScreenVideoOverlay: PropTypes.func,
    channelId: PropTypes.string.isRequired,
    videoItem: PropTypes.object.isRequired,
    isPlayInFrame: PropTypes.bool.isRequired,
    isVideoPlaying: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onPlayRequestedBi: _.noop,
  };

  componentDidMount() {
    handleLogOutPaymentModal({
      onRent: this.showVideoRentInfo,
      onPurchase: this.showVideoPurchaseInfo,
      onSubscription: this.showChannelSubscriptionInfoAtOverlay,
    });
  }

  selectVideo = () => {
    const { selectVideo, videoItem } = this.props;
    selectVideo(videoItem.id);
  };

  playVideo() {
    const { playVideo, videoItem } = this.props;
    this.selectVideo();
    setTimeout(() => {
      // TODO: get rid of it, might not work in some cases
      playVideo(videoItem.id);
    });
  }

  pausePlayingVideo() {
    const { isVideoPlaying, pauseVideo } = this.props;
    if (isVideoPlaying) {
      pauseVideo();
    }
  }

  showVideoAtOverlay = notForPreview(() => {
    const {
      channelId,
      videoItem,
      openFullScreenVideoOverlay,
      closeFullScreenVideoOverlay,
    } = this.props;
    this.pausePlayingVideo();
    openFullScreenVideoOverlay(
      channelId,
      videoItem.id,
      false,
      closeFullScreenVideoOverlay,
    );
  });

  showVideoMemberOnlyInfo = notForPreview(() => {
    const { channelId, videoItem } = this.props;
    this.pausePlayingVideo();
    this.props.openFullScreenMemberOnlyOverlay(channelId, videoItem.id);
  });

  showVideoPurchaseInfo = notForPreview(() => {
    const { channelId, videoItem } = this.props;
    this.pausePlayingVideo();
    this.props.openFullScreenPurchaseOverlay(channelId, videoItem.id);
  });

  showVideoRentInfo = notForPreview(() => {
    const { channelId, videoItem } = this.props;
    this.pausePlayingVideo();
    this.props.openFullScreenRentOverlay(channelId, videoItem.id);
  });

  showChannelSubscriptionInfoAtOverlay = notForPreview(() => {
    this.pausePlayingVideo();
    this.props.openFullScreenSubscribeOverlay(this.props.channelId);
  });

  playVideoAtOverlay = notForPreview(() => {
    const {
      channelId,
      videoItem,
      openFullScreenVideoOverlay,
      closeFullScreenVideoOverlay,
    } = this.props;
    this.pausePlayingVideo();
    openFullScreenVideoOverlay(
      channelId,
      videoItem.id,
      true,
      closeFullScreenVideoOverlay,
    );
  });

  handlePlayVideo = () => {
    const {
      isPlayInFrame,
      onPlayRequestedBi,
      videoItem,
      isPortableDevice,
    } = this.props;

    onPlayRequestedBi(videoItem);

    if (isPlayInFrame) {
      this.playVideo();
      return;
    }

    if (isPortableDevice) {
      this.showVideoAtOverlay();
    } else {
      this.playVideoAtOverlay();
    }
  };

  render() {
    const { children } = this.props;

    return React.cloneElement(children, {
      onClick: this.handlePlayVideo,
      onPlayRequest: this.handlePlayVideo,
      onPlayMemberOnlyRequest: this.showVideoMemberOnlyInfo,
      onPlayWithoutPreviewRequest: this.showVideoAtOverlay,
      onPurchaseRequest: this.showVideoPurchaseInfo,
      onSubscriptionRequest: this.showChannelSubscriptionInfoAtOverlay,
      onRentRequest: this.showVideoRentInfo,
    });
  }
}
