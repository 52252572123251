import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import { reportStats } from 'widget/redux/server/actions/stats/report';
import STATS_EVENTS from 'shared/constants/stats/events';
import { openModal } from 'shared/redux/client/actions/modal';
import getUrlToVideo from '@wix/wix-vod-shared/dist/src/common/utils/get-url-to-video';
import {
  openBuyVideoOverlay,
  openSubscribeOverlay,
  openPaymentCompleteOverlay,
} from 'widget/redux/client/actions/mobile-overlay-openners/payment-pages-actions';
import { openFullScreenPaymentCompleteOverlay } from 'shared/utils/open-overlay';
import {
  openFullScreenPurchaseOverlay,
  openFullScreenSubscribeOverlay,
} from 'widget/utils/open-overlay';

import { getVideosGroupedByIds } from 'shared/selectors/videos';
import {
  getVideoIdFromUrl,
  getUrlParam,
} from 'widget/selectors/query-params.js';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { areElementsRounded } from 'shared/selectors/app-settings';
import { toggleShareVideoOverlay } from 'widget/redux/client/actions/share-overlay';
import { isPayed } from 'shared/selectors/payments';

import ShareMobileContent from 'mobile/components/share-mobile/popup-content/popup-content';
import {
  URL_PARAM_STATE,
  URL_PARAM_PAYMENT_TYPE,
  URL_STATE_COMPLETE_PURCHASE,
  URL_STATE_PURCHASE,
  URL_STATE_SHARE,
  URL_STATE_SUBSCRIBE,
} from '@wix/wix-vod-shared/dist/src/common/constants/url';

import {
  setCookie,
  hasCookie,
  removeCookie,
} from '@wix/wix-vod-shared/dist/src/common/utils/cookie';
import {
  getCompId,
  getFullSiteUrl,
} from 'widget/redux/client/hydrated-data/hydrated-data';
import { isMobile } from 'shared/selectors/form-factor';

const DISABLING_COOKIE_NAME = 'vod-disable-deeplink-popups';

@connect(
  state => {
    const videos = getVideosGroupedByIds(state) || {};
    return {
      channelData: getChannelForWidget(state),
      videoData: videos[getVideoIdFromUrl(state)],
      urlState: getUrlParam(state, URL_PARAM_STATE),
      paymentType: getUrlParam(state, URL_PARAM_PAYMENT_TYPE),
      areElementsRounded: areElementsRounded(state),
      siteUrl: getFullSiteUrl(state),
      compId: getCompId(state),
      isMobile: isMobile(state),
    };
  },
  {
    reportStats,
    openBuyVideoOverlay,
    openSubscribeOverlay,
    openPaymentCompleteOverlay,
    toggleShareVideoOverlay,
    openFullScreenPurchaseOverlay,
    openFullScreenSubscribeOverlay,
    openFullScreenPaymentCompleteOverlay,
    openModal,
  },
)
export default class DeeplinkPopups extends React.Component {
  static propTypes = {
    channelData: PropTypes.object,
    videoData: PropTypes.object,
    isMobile: PropTypes.bool,
    areElementsRounded: PropTypes.bool,
    openModal: PropTypes.func.isRequired,
    reportStats: PropTypes.func,
    urlState: PropTypes.string,
    paymentType: PropTypes.string,
    siteUrl: PropTypes.string,
    compId: PropTypes.string,
    openPaymentCompleteOverlay: PropTypes.func,
    openBuyVideoOverlay: PropTypes.func,
    openSubscribeOverlay: PropTypes.func,
    toggleShareVideoOverlay: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      isShareOverlayOpened: false,
    };

    if (__SERVER__) {
      this.hasOpenedPopup = true;
    } else {
      this.hasOpenedPopup = hasCookie(DISABLING_COOKIE_NAME);

      window.onbeforeunload = () => {
        removeCookie(DISABLING_COOKIE_NAME);
      };
    }
  }

  componentDidMount() {
    this.openDeeplinkPopups(this.props);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    this.openDeeplinkPopups(nextProps);
  }

  openDeeplinkPopups(props) {
    const { hasOpenedPopup } = this;
    const { channelData, urlState, videoData } = props;

    if (!urlState || hasOpenedPopup || !videoData || _.isEmpty(channelData)) {
      return;
    }

    switch (urlState) {
      case URL_STATE_SHARE:
        this.openSharePopup();
        break;
      case URL_STATE_COMPLETE_PURCHASE:
        this.openPurchaseCompletePopup();
        break;
      case URL_STATE_PURCHASE:
        this.openPurchasePopup(channelData, videoData);
        break;
      case URL_STATE_SUBSCRIBE:
        this.openSubscribePopup(channelData);
        break;
      default:
        break;
    }

    this.hasOpenedPopup = true;
    setCookie(DISABLING_COOKIE_NAME, true);
  }

  openSharePopup() {
    return this.props.isMobile
      ? this.openMobileSharePopup()
      : this.openShareOverlay();
  }

  openPurchasePopup(channel, video) {
    const { openBuyVideoOverlay } = this.props;

    if (isPayed(video)) {
      return;
    }

    return this.props.isMobile
      ? openBuyVideoOverlay(video.id)
      : this.props.openFullScreenPurchaseOverlay(channel.id, video.id);
  }

  openPurchaseCompletePopup() {
    const { channelData, paymentType, videoData } = this.props;

    if (isPayed(videoData)) {
      return;
    }

    return this.props.isMobile
      ? this.props.openPaymentCompleteOverlay(paymentType, videoData.id)
      : this.props.openFullScreenPaymentCompleteOverlay(
          paymentType,
          channelData.id,
          videoData.id,
        );
  }

  openSubscribePopup(channel) {
    const { openSubscribeOverlay } = this.props;

    if (isPayed(channel)) {
      return;
    }

    return this.props.isMobile
      ? openSubscribeOverlay(channel.id)
      : this.props.openFullScreenSubscribeOverlay(channel.id);
  }

  openShareOverlay() {
    this.props.toggleShareVideoOverlay();
  }

  sendStatsReport = name => {
    const { reportStats, channelData, videoData } = this.props;

    reportStats(STATS_EVENTS.VIDEO_SHARE, {
      channel: channelData,
      videoItem: videoData,
      network: {
        name,
      },
    });
  };

  openMobileSharePopup() {
    const { siteUrl, compId, videoData, openModal } = this.props;
    const { title, customCoverUrl, coverUrl, description } = videoData;

    const shareParams = {
      siteUrl: getUrlToVideo(videoData, {
        siteUrl,
        compId,
      }),
      title,
      description,
      coverUrl: customCoverUrl || coverUrl,
    };

    openModal({
      title: i18n.t('widget.share-video.share'),
      content: (
        <ShareMobileContent
          shareParams={shareParams}
          inputLabel="Accessible label"
          onShare={this.sendStatsReport}
        />
      ),
    });
  }

  render() {
    return null;
  }
}
