import { createWorkerAction } from '../lib';
import { isMobile, isTablet } from 'shared/selectors/form-factor';

export const logFedopsAction = createWorkerAction('LOG_FEDOPS');

export const LOGGER_FNS = {
  INTERACTION_START: 'interaction_start',
  INTERACTION_END: 'interaction_end',
  APP_LOADED: 'app-loaded',
};

const getLoggerName = state => {
  if (isMobile(state)) {
    return MOBILE_WIDGET;
  }
  if (isTablet(state)) {
    return TABLET_WIDGET;
  }
  return DESKTOP_WIDGET;
};

export function createLoggerActions(_loggerName) {
  const createAction = (logFn, interactionName) => () => (
    dispatch,
    getState,
  ) => {
    const loggerName = _loggerName || getLoggerName(getState());
    dispatch(logFedopsAction({ loggerName, logFn, interactionName }));
  };

  const appLoaded = createAction(LOGGER_FNS.APP_LOADED);
  const createInteraction = interactionName => ({
    start: createAction(LOGGER_FNS.INTERACTION_START, interactionName),
    end: createAction(LOGGER_FNS.INTERACTION_END, interactionName),
  });
  return { appLoaded, createInteraction };
}

export const BASE_LOGGER = 'BASE';

export const MOBILE_OVERLAY = 'MOBILE_OVERLAY';
export const MOBILE_WIDGET = 'MOBILE_WIDGET';

export const DESKTOP_OVERLAY = 'DESKTOP_OVERLAY';
export const DESKTOP_WIDGET = 'DESKTOP_WIDGET';
export const TABLET_WIDGET = 'TABLET_WIDGET';
