import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { NAMES as CHANNEL_VIDEOS_LIST } from 'shared/redux/server/actions/channel/videos/list-public';
import { APP_SETTINGS_UPDATE } from 'widget/redux/client/actions/app-settings';
import { getChannelId } from 'widget/utils/app-settings';

const defaultState = null;
let oldChannelId = null;

function resetOnChannelIdChange(state, { payload }) {
  const channelId = getChannelId(payload);
  if (channelId && oldChannelId && oldChannelId !== channelId) {
    oldChannelId = channelId;
    return defaultState;
  }
  return state;
}

function saveVideoId(state, { payload }) {
  if (state) {
    return state;
  }
  return _.get(payload, 'response.data.result[0]', null);
}

export default handleActions(
  {
    [CHANNEL_VIDEOS_LIST.SUCCESS]: saveVideoId,
    [APP_SETTINGS_UPDATE]: resetOnChannelIdChange,
  },
  defaultState,
);
