import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import { canShowFullInfo } from 'shared/selectors/app-settings';
import { canShareVideo } from 'shared/selectors/video-share';

@connect(state => ({
  canShowVideoInfoButton: canShowFullInfo(state),
  canShowShareButton: canShareVideo(state),
}))
export default class PlayerBlocksVisibility extends React.Component {
  static propTypes = {
    canShowVideoInfoButton: PropTypes.bool.isRequired,
    canShowShareButton: PropTypes.bool.isRequired,

    children: PropTypes.func.isRequired,
  };

  render() {
    const { canShowVideoInfoButton, canShowShareButton, children } = this.props;

    return children({
      canShowVideoInfoButton,
      canShowShareButton,
    });
  }
}
