import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import getResizedImageUrl from '@wix/wix-vod-shared/dist/src/common/utils/get-resized-image-url';
import classnames from 'classnames';
import { getNormalizedCachedDimensions } from '@wix/wix-vod-shared/dist/src/common/utils/normalize-image-dimensions';
import { withStyles } from 'shared/utils/withStyles';
import styles from './resized-image.styl';

@withStyles(styles)
export default class ResizedImage extends React.Component {
  static propTypes = {
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    params: PropTypes.object,
    style: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node,
    dataHook: PropTypes.string,
    withFallbackColor: PropTypes.bool,
    backgroundFit: PropTypes.bool,
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    params: {},
    style: {},
  };

  getResizedImageUrl() {
    const { src, width, height, params, backgroundFit } = this.props;

    const dimensions = getNormalizedCachedDimensions(src, {
      containerWidth: width,
      containerHeight: height,
    });

    const mode = backgroundFit ? 'fit' : undefined;

    return getResizedImageUrl({
      url: src,
      width: dimensions[0],
      height: dimensions[1],
      params,
      mode,
    });
  }

  render() {
    const {
      className,
      width,
      height,
      style,
      children,
      dataHook,
      withFallbackColor,
      backgroundFit,
      isMobile,
    } = this.props;

    const inlineStyle = {
      width: width && _.round(width),
      height: height && _.round(height),
      ...style,
    };

    const resizedThumbnailUrl = this.getResizedImageUrl();

    if (resizedThumbnailUrl) {
      inlineStyle.backgroundImage = `url(${resizedThumbnailUrl})`;
    }

    const classNames = classnames(className, styles['resized-image'], {
      [styles['with-fallback-color']]:
        withFallbackColor && !resizedThumbnailUrl,
      [styles['background-contain']]: backgroundFit,
      [styles.mobile]: isMobile,
    });

    return (
      <div
        className={classNames}
        style={inlineStyle}
        data-hook={dataHook}
        role="presentation"
      >
        {children}
      </div>
    );
  }
}
