import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export default function formatMessageWithDate(id, expirationDate) {
  const date = (
    <FormattedDate value={expirationDate} month="short" day="numeric" />
  );

  return <FormattedMessage id={id} defaultMessage={id} values={{ date }} />;
}
