import { handleActions } from 'redux-actions';

import { NAMES as VIDEO_FULL_SCREEN_ACTIONS } from 'widget/redux/client/actions/player/set-fullscreen-status';
import { NAMES as VIDEO_SOUND_ACTIONS } from 'widget/redux/client/actions/player/sound';
import { NAMES as AUTOPLAYED_ACTIONS } from 'widget/redux/client/actions/player/set-autoplayed';
import { NAMES as SET_OVERLAY_CONTENT_ACTIONS } from 'widget/redux/client/actions/player/set-overlay-content';

const defaultState = {
  isInFullScreen: false,
  muted: false,
  isVolumeChangedByUser: false,
  autoPlayed: false,
  overlayContent: null,
};

const setFullScreenStatus = (state, { payload }) => ({
  ...state,
  isInFullScreen: payload,
});

const setMuted = (state, { payload }) => ({
  ...state,
  muted: payload,
});

const volumeChangedByUser = state => ({
  ...state,
  isVolumeChangedByUser: true,
});

const setAutoPlayed = (state, { payload }) => ({
  ...state,
  autoPlayed: payload,
});

const setOverlayContent = (state, { payload }) => ({
  ...state,
  overlayContent: payload || null,
});

export default handleActions(
  {
    [VIDEO_FULL_SCREEN_ACTIONS.SET]: setFullScreenStatus,
    [VIDEO_SOUND_ACTIONS.SET_MUTED]: setMuted,
    [VIDEO_SOUND_ACTIONS.SET_VOLUME_CHANGED_BY_USER]: volumeChangedByUser,
    [AUTOPLAYED_ACTIONS.SET]: setAutoPlayed,
    [SET_OVERLAY_CONTENT_ACTIONS.SET]: setOverlayContent,
  },
  defaultState,
);
