import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DesktopView } from 'widget/views/desktop/desktop';

import { CHANNEL_LAYOUT_VALUES } from '@wix/wix-vod-constants/dist/app-settings/channel-layout-values';

import strip from 'widget/layouts/strip/strip';
import classic from 'widget/layouts/classic/classic';
import compact from 'widget/layouts/compact/compact';
import slider from 'widget/layouts/slider/slider';
import grid from 'widget/layouts/grid/grid';

const layoutLoaderMap = {
  [CHANNEL_LAYOUT_VALUES.STRIP]: strip,
  [CHANNEL_LAYOUT_VALUES.CLASSIC]: classic,
  [CHANNEL_LAYOUT_VALUES.COMPACT]: compact,
  [CHANNEL_LAYOUT_VALUES.SLIDER_SMALL]: slider,
  [CHANNEL_LAYOUT_VALUES.SLIDER_BIG]: slider,
  [CHANNEL_LAYOUT_VALUES.GRID]: grid,
};

const getLayoutComponent = id => layoutLoaderMap[id];

function getLayoutId(props) {
  return _.get(props.appSettings, 'numbers.channelLayout');
}

@connect(state => ({
  appSettings: state.appSettings,
}))
class LayoutSwitcher extends React.Component {
  static propTypes = {
    children: PropTypes.element,
    appSettings: PropTypes.object,
  };

  render() {
    const layoutId = getLayoutId(this.props);
    const Layout = getLayoutComponent(layoutId);
    return (
      <DesktopView>
        <Layout />
      </DesktopView>
    );
  }
}

export default LayoutSwitcher;
