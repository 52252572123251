import _ from 'lodash';
import { VIDEOS_ASPECT_RATIO } from 'widget/constants/videos-aspect-ratio';
import { WIDTH_CONSTRAINTS } from 'widget/constants/thumbnail-sizes';

export const getItemWidthForSliderHeight = (sliderHeight, contentHeight) => {
  const thumbnailHeight = sliderHeight - contentHeight;
  const itemWidth = Math.floor(thumbnailHeight * VIDEOS_ASPECT_RATIO);
  return _.clamp(itemWidth, ...WIDTH_CONSTRAINTS);
};

export const getActionBarHeightForWidgetHeight = widgetHeight => {
  /* eslint-disable curly */
  if (widgetHeight < 500) return 31;
  return 36;
};

const SLIDER_PADDINGS = {
  275: 31,
  400: 36,
  700: 52,
};

export const getSliderHorizontalPadding = (itemWidth, isArrowsOutside) => {
  /* eslint-disable curly */
  if (!isArrowsOutside) {
    return 0;
  }

  if (itemWidth >= 700) return SLIDER_PADDINGS[700];
  if (itemWidth >= 400) return SLIDER_PADDINGS[400];

  return SLIDER_PADDINGS[275];
};
