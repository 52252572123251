import {
  getUid,
  getCompId,
} from 'widget/redux/client/hydrated-data/hydrated-data';
import { createProxy } from 'shared/worker/lib';

import { pubSubPublish } from 'shared/worker/actions/pub-sub/publish';

export const REFRESH_EVENT = 'refreshOnLoginEvents';

export const storeForReload = createProxy(
  'storeForReload',
  data => (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);
    const rootCompId = getCompId(state);

    dispatch(
      pubSubPublish(
        REFRESH_EVENT,
        {
          uid,
          stored: data,
          rootCompId,
        },
        true,
      ),
    );
  },
);
