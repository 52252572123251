import * as channelVideosModule from '@wix/wix-vod-api/dist/src/public/channel-videos/channel-videos';
import * as channelModule from 'api/public/channel/channel';
import * as videoCardsModule from 'api/public/video-cards/video-cards';
import * as videosModule from '@wix/wix-vod-api/dist/src/public/video/video';
import { fetchAdapter } from '@wix/wix-vod-api-site-based/dist/src/utils/axios-fetch-adapter';
import { setSiteBasedUrls } from 'services/site-based-urls';
import { setInstanceGetter } from '@wix/wix-vod-shared/dist/src/common/utils/get-auth-header';
// import { withContexts } from '@wix/wix-vod-shared/dist/src/common/utils/context';

const configureService = ({ service, instance }) => {
  const axiosInstance = service.getAxiosInstance
    ? service.getAxiosInstance()
    : service;

  axiosInstance.defaults.adapter = fetchAdapter;

  // if (__OOI__) {
  //   axiosInstance.defaults.headers.common = {
  //     ...axiosInstance.defaults.headers.common,
  //     'X-WIX-INSTANCE-TOKEN': instance,
  //   };
  //   // https://github.com/axios/axios/issues/1522
  // }

  return service;
};

export const configureAxios = ({ instance }) => {
  setSiteBasedUrls();

  const modules = [
    channelVideosModule,
    channelModule,
    videoCardsModule,
    videosModule,
  ];

  const services = modules.map(module => {
    const service = module.service;
    // const service = module.createService();
    configureService({ service, instance });
    // module.ctx.provide(undefined); //cleaning default context to notice an error
    // return { val: service, ctx: module.ctx };
  });

  setInstanceGetter(() => instance);
};
