import _ from 'lodash';
import { createAction, handleActions } from 'redux-actions';
import { parseHydratedData } from './parsers';

/* actions */

export const SET_HYDRATED_DATA = 'CLIENT.HYDRATED_DATA.SET';
export const CLEAR_HYDRATED_DATA = 'CLIENT.HYDRATED_DATA.CLEAR';

export const setHydratedData = createAction(SET_HYDRATED_DATA);
export const clearHydratedData = createAction(CLEAR_HYDRATED_DATA);

export const setHydratedDataFromSource = source => dispatch => {
  const parsedData = parseHydratedData(source);
  dispatch(setHydratedData(parsedData));
};

const defaultState = {
  isFetching: false,
  data: {},
};

const merge = (state, { payload }) => ({
  ...state,
  data: _.merge({}, state.data, payload),
});

const clear = state => ({
  ...state,
  data: _.omit(state.data, ['channelVideos', 'memberInfo', 'singleVideo']),
});

/* reducer */
export const reducer = handleActions(
  {
    [SET_HYDRATED_DATA]: merge,
    [CLEAR_HYDRATED_DATA]: clear,
  },
  defaultState,
);

/* selectors */

export const getHydratedData = state => state.hydratedData.data;

const getKey = key => state => _.get(state.hydratedData.data, key);

export const getCurrentPageId = getKey('currentPageId');
export const getInstance = getKey('instance');
export const getSiteOwnerId = getKey('siteOwnerId');
export const getUid = getKey('currentSiteUser.id');

export const getBiToken = getKey('biToken');
export const getLocale = getKey('locale');
export const getMetaSiteId = getKey('metaSiteId');
export const getInstanceId = getKey('instanceId');

/* protocol + domain + path + query: https://testsite.com/basepath?query=true */
export const getFullSiteUrl = getKey('fullSiteUrl');

/* protocol + domain + path: https://testsite.com/basepath */
export const getSiteUrl = getKey('siteUrl');
export const getCompId = getKey('compId');
