import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { REFRESH_EVENT, storeForReload } from 'widget/utils/reload';
import { connect } from 'react-redux';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import { withPubSubEvents } from 'widget/containers/pub-sub-events';

const RELOAD_STORAGE_READY = 'reloadStorageReady';

// TODO: enhance this to look like real storage with get/set/remove methods

/*
 * Since after UOU login or logout, whole site rebuilds, and there is no nice solution in WixSDK for this case.
 * This is workaround, that use persistent events in Wix.PubSub. Anytime we let user login or logout, we also
 * stores data, that we want to path into "next state" of widget after it's reloaded. But since we use
 * persistent events, they are stored in same sequence as we publish them until site is closed or manually refreshed.
 * But we need only last one, that's why we use @debounce for method `processRetrievedData`
 */

@connect(
  state => ({ compId: getCompId(state) }),
  { storeForReload },
)
@withPubSubEvents
export class PersistentEvents extends React.Component {
  static propTypes = {
    onEvent: PropTypes.func,
  };

  static defaultProps = {
    onEvent: _.noop,
  };

  componentDidMount() {
    this.props.storeForReload(RELOAD_STORAGE_READY);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.pubSubEvents) {
      this.props.pubSubEvents[REFRESH_EVENT].forEach(this.saveEventData);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  lastEventData = {};
  lastHandledEventData = {};

  unsubscribe() {
    this.props.storeForReload(null);
  }

  saveEventData = ({ data }) => {
    if (!data || data.stored !== RELOAD_STORAGE_READY) {
      this.lastEventData = data || {};
    }

    this.retrieveDataAfterReload();
  };

  retrieveDataAfterReload = _.debounce(() => {
    const { stored, rootCompId } = this.lastEventData;

    if (
      rootCompId === this.props.compId &&
      stored &&
      !_.isEqual(this.lastHandledEventData, this.lastEventData)
    ) {
      this.props.onEvent(stored);
      this.lastHandledEventData = this.lastEventData;
    }

    this.lastEventData = {};
  }, 300);

  render() {
    return null;
  }
}
