import _ from 'lodash';
import { getVODApiUrl } from '@wix/wix-vod-shared/dist/src/common/settings/get-vod-api-url';
import { isSiteBased } from '@wix/wix-vod-shared/dist/src/common/settings/settings';

import { service as widgetsService } from '@wix/wix-vod-api/dist/src/private/widgets/widgets';
import { service as settingsService } from '@wix/wix-vod-api/dist/src/private/settings/settings';

const SITE_BASED_URL = '//vod.wix.com/api/v2/';

export const SERVICES = [widgetsService, settingsService];

const setBaseUrl = baseUrl => {
  _.forEach(SERVICES, service => {
    const axiosInstance = service.getAxiosInstance
      ? service.getAxiosInstance()
      : service;
    axiosInstance.defaults.baseURL = getVODApiUrl(baseUrl);
  });
};

export const setSiteBasedUrls = () => {
  if (isSiteBased()) {
    setBaseUrl(SITE_BASED_URL);
  }
};
