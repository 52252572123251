/* eslint-disable react/prop-types */
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { isPreviewEnabled } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/preview';
import { isScheduledLive } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/live';

/**
 * Is open video without preview button visible
 * @param props {Object}
 * @param props.video {Object}
 * @return {Boolean}
 */
export function isVisible({ video }) {
  return !isPreviewEnabled(video) && !isScheduledLive(video);
}

/**
 * Open video without preview button text
 * @param props {Object}
 * @param props.video {Object}
 * @param props.translation {Object} default sharedTranslations.startWatching
 * @return {React.Component}
 */
export default function renderOpenVideoWithoutPreviewButtonText(props) {
  if (!isVisible(props)) {
    return null;
  }
  const translation =
    props.translation || 'widget.overlay-buttons.start-watching';
  return i18n.t(translation);
}
