import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updatePremiumIntent } from 'shared/utils/premium-intent';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { getVodSettings } from 'shared/redux/server/actions/vod-settings/get';
import { getExperiments } from 'shared/redux/server/actions/experiments/get';

@connect(
  state => ({
    vodSettings: state.vodSettings,
    experiments: state.experiments,
    isEditor: viewModeSelectors.isEditorMode(state),
  }),
  {
    getVodSettings,
    getExperiments,
  },
)
export default class PremiumIntentUpdater extends React.Component {
  static propTypes = {
    vodSettings: PropTypes.object,
    experiments: PropTypes.object,
    getVodSettings: PropTypes.func,
    getExperiments: PropTypes.func,
    isEditor: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { getVodSettings, getExperiments, isEditor } = this.props;
    if (isEditor) {
      getVodSettings();
      getExperiments();
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { vodSettings, experiments } = nextProps;
    const { isPremiumIntentUpdated } = this;

    if (
      isPremiumIntentUpdated ||
      _.isEmpty(experiments) ||
      _.isEmpty(vodSettings)
    ) {
      return;
    }

    const { biUserUploadedFiles } = vodSettings;

    updatePremiumIntent({
      hasUploadedVideos: biUserUploadedFiles,
    });

    this.isPremiumIntentUpdated = true;
  }

  isPremiumIntentUpdated = false;

  render() {
    return null;
  }
}
