import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isPlayInFrame } from 'shared/selectors/app-settings';
import { isVideoPlayingOptimistic } from 'widget/selectors/video-playback-status';
import { getMainVideo } from 'widget/selectors/get-video';

import PlayerBlocksVisibility from 'widget/containers/player-blocks-visibility';
import PlayerActionCallbacks from 'widget/layouts/compact/actions/player-action-callbacks';

@connect(state => ({
  channelData: getChannelForWidget(state),
  mainVideo: getMainVideo(state),
  isPlayInFrame: isPlayInFrame(state),
  isVideoPlaying: isVideoPlayingOptimistic(state),
}))
export default class Player extends React.Component {
  static propTypes = {
    channelData: PropTypes.object.isRequired,
    isPlayInFrame: PropTypes.bool.isRequired,
    isVideoPlaying: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    mainVideo: PropTypes.object,
    PlayerComponent: PropTypes.func,
    isPortableDevice: PropTypes.bool,
  };

  render() {
    const {
      channelData,
      isPlayInFrame,
      isVideoPlaying,
      mainVideo,
      width,
      height,
      PlayerComponent,
      isPortableDevice,
    } = this.props;

    if (!PlayerComponent) {
      return null;
    }

    const playerProps = {
      videoItem: mainVideo,
      channelData,
      width,
      height,
      paused: !isVideoPlaying,
    };

    const callbackProps = {
      channelId: channelData.id,
      videoId: mainVideo.id,
    };

    if (isPortableDevice || isPlayInFrame) {
      return (
        <PlayerBlocksVisibility>
          {({ canShowVideoInfoButton, canShowShareButton }) => (
            <PlayerActionCallbacks {...callbackProps}>
              <PlayerComponent
                {...playerProps}
                showPoster={false}
                showInitialOverlay={false}
                canShowFullInfo={canShowVideoInfoButton}
                canShareVideo={canShowShareButton}
              />
            </PlayerActionCallbacks>
          )}
        </PlayerBlocksVisibility>
      );
    }

    return null;
  }
}
