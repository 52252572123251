import { handleActions } from 'redux-actions';
import { NAMES } from 'widget/redux/client/actions/full-screen-modal';

export default handleActions(
  {
    [NAMES.OPEN_FULL_SCREEN_MODAL_VIDEO]: () => ({ lightbox: true }),
    [NAMES.CLOSE_FULL_SCREEN_MODAL_VIDEO]: () => ({ lightbox: false }),
  },
  {},
);
