import _ from 'lodash';

const regex = /(auto|scroll|overlay)/;

const parents = (node, ps) => {
  if (node.parentNode === null) {
    return ps;
  }

  return parents(node.parentNode, ps.concat([node]));
};

const style = (node, prop) =>
  getComputedStyle(node, null).getPropertyValue(prop);

const overflow = node =>
  style(node, 'overflow') +
  style(node, 'overflow-y') +
  style(node, 'overflow-x');

const scroll = node => regex.test(overflow(node));

export default function getScrollParent(node) {
  if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
    return;
  }

  return (
    _.find(parents(node.parentNode, []), scroll) ||
    document.scrollingElement ||
    document.documentElement
  );
}
