import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PaymentEvents from 'shared/components/payment-events/payment-events';
import {
  clearVideoUrlsCacheForAll,
  clearVideoUrlsCacheForItem,
} from 'widget/redux/client/actions/video-urls/clear';

@connect(
  null,
  {
    clearVideoUrlsCacheForAll,
    clearVideoUrlsCacheForItem,
  },
)
class ClearVideoUrlsCacheOnPayment extends React.Component {
  static propTypes = {
    clearVideoUrlsCacheForAll: PropTypes.func,
    clearVideoUrlsCacheForItem: PropTypes.func,
  };

  clearVideoUrlsCacheForAll = _.debounce(() => {
    this.props.clearVideoUrlsCacheForAll();
  }, 300);

  clearVideoUrlsCacheForItem = data => {
    this.props.clearVideoUrlsCacheForItem(data.itemId);
  };

  render() {
    return (
      <PaymentEvents
        onSubscription={this.clearVideoUrlsCacheForAll}
        onSubscriptionCancel={this.clearVideoUrlsCacheForAll}
        onRent={this.clearVideoUrlsCacheForItem}
        onSale={this.clearVideoUrlsCacheForItem}
      />
    );
  }
}

export default ClearVideoUrlsCacheOnPayment;
