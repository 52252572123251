let counter = 0;

function getOrder() {
  return ++counter;
}

const subscribers = [];

const initialStyles = [];

function addInitialStyle(st) {
  initialStyles.push(st);
  subscribers.forEach(sub => {
    sub(st);
  });
}

function subscribeGlobalStyles(cb) {
  subscribers.push(cb);
  initialStyles.forEach(st => {
    cb(st);
  });
}

module.exports = { getOrder, addInitialStyle, subscribeGlobalStyles };
