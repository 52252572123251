import {
  sides,
  popoutPositions,
  trianglePositions,
} from 'shared/constants/popout';

import invertSide from './invert-side';

const POPOUT_OFFSET = 12;
const POPOUT_OVERSHOOT = 23;

const defaultPositioning = {
  triangleSide: sides.TOP,
  popoutSide: sides.BOTTOM,
  popoutPosition: popoutPositions.CENTER,
  trianglePosition: trianglePositions.CENTER,
};

export function byPopoutSide(
  referenceElement,
  popoutElement,
  positioning = defaultPositioning,
) {
  if (!referenceElement || !popoutElement) {
    return { left: 0, top: 0, offsetX: 0 };
  }

  const { popoutSide, popoutPosition, trianglePosition } = {
    ...defaultPositioning,
    ...positioning,
  };

  const referenceRect = referenceElement.getBoundingClientRect();
  const popoutRect = popoutElement.getBoundingClientRect();

  const {
    top: referenceTop,
    left: referenceLeft,
    width: referenceWidth,
    height: referenceHeight,
  } = referenceRect;

  const { width: popoutWidth, height: popoutHeight } = popoutRect;

  const { innerWidth: windowWidth, pageXOffset: windowScrollX } = window;

  let top = referenceTop;
  let left = referenceLeft + windowScrollX;

  if (popoutSide === sides.TOP) {
    top -= popoutHeight + POPOUT_OFFSET;
  } else if (popoutSide === sides.LEFT) {
    top -= popoutHeight / 2 - referenceHeight / 2;
    left -= popoutWidth + POPOUT_OFFSET;
  } else if (popoutSide === sides.RIGHT) {
    top -= popoutHeight / 2 - referenceHeight / 2;
    left += referenceWidth + POPOUT_OFFSET;
  } else if (popoutSide === sides.BOTTOM) {
    top += referenceHeight + POPOUT_OFFSET;
  }

  if (popoutSide === sides.TOP || popoutSide === sides.BOTTOM) {
    if (popoutPosition === popoutPositions.RIGHT) {
      left += referenceWidth - popoutWidth + POPOUT_OVERSHOOT;
    } else if (popoutPosition === popoutPositions.LEFT) {
      left -= POPOUT_OVERSHOOT;
    } else {
      left -= popoutWidth / 2 - referenceWidth / 2;
    }
  }

  let offsetX = 0;
  if (
    trianglePosition === trianglePositions.CENTER &&
    windowScrollX + windowWidth < left + popoutWidth
  ) {
    offsetX =
      left + popoutWidth + POPOUT_OFFSET - (windowScrollX + windowWidth);
    left -= offsetX;
  }

  // remove crop from left side
  if (left < POPOUT_OFFSET) {
    offsetX = -POPOUT_OFFSET + left;
    left = POPOUT_OFFSET;
  }

  return { top, left, offsetX };
}

export function byTriangleSide(
  referenceElement,
  popoutElement,
  positioning = defaultPositioning,
) {
  positioning = {
    ...positioning,
    popoutSide: invertSide(positioning.triangleSide),
  };
  return byPopoutSide(referenceElement, popoutElement, positioning);
}
