import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { setWidgetHeight } from 'shared/worker/actions/resize/set-widget-height';
import { resizeWindow } from 'shared/worker/actions/resize/resize-window';
import { resizeComponent } from 'shared/worker/actions/resize/resize-component';
import {
  MAX_WIDGET_WIDTH,
  MIN_WIDGET_WIDTH,
} from 'widget/layouts/compact/compact';

@connect(
  state => ({
    windowSize: state.windowSize,
    isEditor: viewModeSelectors.isEditorMode(state),
  }),
  {
    setWidgetHeight,
    resizeWindow,
    resizeComponent,
  },
)
export default class ResizeWidgetByContent extends React.Component {
  static propTypes = {
    windowSize: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    children: PropTypes.element,
    isEditor: PropTypes.bool.isRequired,
    resizeWindow: PropTypes.func.isRequired,
    setWidgetHeight: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.interval = setInterval(this.updateSize, 300);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  interval = null;
  containerEl = null;

  get containerSize() {
    const width = _.clamp(
      _.get(this.containerEl, 'clientWidth', 0),
      MIN_WIDGET_WIDTH,
      MAX_WIDGET_WIDTH,
    );
    const height = _.get(this.containerEl, 'clientHeight', 0);
    return {
      width: Math.ceil(width),
      height: Math.ceil(height),
    };
  }

  updateSize = () => {
    const newSize = this.containerSize;
    const {
      windowSize,
      isEditor,
      resizeWindow,
      setWidgetHeight,
      resizeComponent,
    } = this.props;

    if (
      !newSize.width ||
      !newSize.height ||
      !windowSize.width ||
      _.isEqual(windowSize, newSize)
    ) {
      return;
    }

    if (isEditor) {
      // editor
      resizeComponent(newSize);
    } else {
      // viewer
      resizeWindow(newSize.width, newSize.height);
    }
    // fix TPA bug. TODO remove
    setWidgetHeight(newSize.height, newSize.width, { overflow: false });
  };

  render() {
    return (
      <div ref={node => (this.containerEl = node)}>{this.props.children}</div>
    );
  }
}
