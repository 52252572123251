import React from 'react';
import _ from 'lodash';
import { FormattedNumber, FormattedMessage } from 'react-intl';

export default function formatMessageWithPrice(id, info) {
  const minimumFractionDigits = _.get(
    info,
    'digits',
    _.isInteger(info.price) ? 0 : 2,
  );
  return (
    <FormattedNumber
      value={info.price}
      style="currency"
      currency={info.currency}
      minimumFractionDigits={minimumFractionDigits}
    >
      {price => (
        <FormattedMessage id={id} defaultMessage={id} values={{ price }} />
      )}
    </FormattedNumber>
  );
}
