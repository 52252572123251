import _ from 'lodash';
import { createSelector } from 'reselect';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import {
  isNavigationArrowsOutside,
  getContainerMargins,
  isPlayInFrame,
} from 'shared/selectors/app-settings';
import { getMainVideo } from 'widget/selectors/get-video';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import {
  FORCED_MARGIN_WIDTH,
  MAX_MARGIN_WIDTH,
} from 'widget/layouts/slider/constants';

export const getSliderHorizontalMargin = createSelector(
  getContainerMargins,
  isNavigationArrowsOutside,
  (horizontalMargin, isArrowsOutside) =>
    _.clamp(
      horizontalMargin,
      isArrowsOutside ? FORCED_MARGIN_WIDTH : 0,
      MAX_MARGIN_WIDTH,
    ),
);

export const canRenderPlayer = createSelector(
  getChannelForWidget,
  getMainVideo,
  getCurrentSiteUser,
  isPlayInFrame,
  (channel, video, user, isInFrame) =>
    Boolean(video && isInFrame && canPlayFullOrPreview(channel, video, user)),
);
