import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { logBi, logWidgetSystem } from 'shared/worker/actions/bi';
import { requestLogin, logOutCurrentMember } from 'shared/utils/auth';
import {
  openFullScreenOverlay,
  openFullScreenChannelOverlay,
  openFullScreenSubscribeOverlay,
  openFullScreenShareOverlay,
} from 'shared/utils/open-overlay';
import { notForPreview } from 'shared/utils/not-for-preview';
import paymentsHelper from 'shared/utils/payments';
import {
  getSiteUrl,
  getInstance,
} from 'widget/redux/client/hydrated-data/hydrated-data';

@connect(
  state => ({
    siteUrl: getSiteUrl(state),
    instance: getInstance(state),
  }),
  {
    logBi,
    logWidgetSystem,
    requestLogin,
    logOutCurrentMember,
    openFullScreenShareOverlay,
    openFullScreenChannelOverlay,
    openFullScreenSubscribeOverlay,
    openFullScreenOverlay,
  },
)
export default class ChannelActionsContainer extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    channelId: PropTypes.string,
    videoId: PropTypes.string,
    siteUrl: PropTypes.string,
    instance: PropTypes.string,

    onPageRefresh: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onPageRefresh: _.noop,
  };

  showChannelInfoAtOverlay = () => {
    this.props.openFullScreenChannelOverlay(this.props.channelId);
  };

  showChannelShareAtOverlay = notForPreview(() => {
    const { videoId, channelId } = this.props;
    this.props.openFullScreenShareOverlay(channelId, videoId);
  });

  openAccountInfoAtOverlay = notForPreview(() => {
    this.props.openFullScreenOverlay(`#/account-info/${this.props.channelId}`);
  });

  showChannelSubscriptionInfoAtOverlay = notForPreview(() => {
    const { channelId } = this.props;
    this.props.logBi('widget.subscription.clicked', { channelID: channelId });
    this.props.openFullScreenSubscribeOverlay(channelId, () => {
      this.props.logBi('widget.subscriptionPopUp.Completed', {
        result: 'canceled',
        errorMsg: '',
      });
    });
  });

  cancelChannelSubscription = notForPreview(() => {
    const { siteUrl, instance, channelId } = this.props;
    this.props.logWidgetSystem('channel.unsubscribe.requested');
    paymentsHelper.cancelSubscription(channelId, siteUrl, instance);
  });

  showLoginForm = notForPreview(() => {
    this.props.logBi('widget.signIn.clicked', {
      channelID: this.props.channelId,
    });
    this.props.requestLogin().then(this.props.onPageRefresh);
  });

  logOut = () => {
    this.props.logOutCurrentMember();
    this.props.onPageRefresh();
  };

  render() {
    return this.props.children({
      showInfo: this.showChannelInfoAtOverlay,
      showAccountInfo: this.openAccountInfoAtOverlay,
      subscribe: this.showChannelSubscriptionInfoAtOverlay,
      cancelSubscription: this.cancelChannelSubscription,
      logOut: this.logOut,
      logIn: this.showLoginForm,
      showShare: this.showChannelShareAtOverlay,
    });
  }
}
