import _ from 'lodash';
import CSSModules from './react-css-modules';

export const VODCSSModules = styles => WrappedComponent => {
  if (!styles) {
    return _.identity;
  }

  return CSSModules(WrappedComponent, styles, {
    allowMultiple: true,
    handleNotFoundStyleName: 'ignore',
  });
};
