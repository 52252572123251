import _ from 'lodash';
import { handleActions } from 'redux-actions';

import { NAMES as GET_EXPERIMENTS } from 'shared/redux/server/actions/experiments/get';

const defaultExperiments = {};

export default handleActions(
  {
    [GET_EXPERIMENTS.SUCCESS]: (state, { payload: { response } }) => ({
      ...defaultExperiments,
      ...response,
    }),
    [GET_EXPERIMENTS.FAIL]: _.constant(defaultExperiments),
  },
  null,
);
