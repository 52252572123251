import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Measure from './measure';

function childrenKeys(props) {
  return React.Children.map(props.children, child => child.key);
}

// FIXME: looks like it doesn't work at all, and I don't see a good way to fix it.
// Need to talk with Product Owner and redesign all places that related for this logic
export default class ElementSwitcher extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    maxWidth: PropTypes.number,
    shouldAlwaysMeasure: PropTypes.bool,
  };

  state = {
    width: null,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Correct solution would be to check width of prev and next children but
    // to simplify and to speed up keys are used instead
    const nextKeys = childrenKeys(nextProps).join('++');
    const prevKeys = childrenKeys(this.props).join('++');

    if (nextProps.maxWidth !== this.props.maxWidth || nextKeys !== prevKeys) {
      this.setState({ width: null });
    }
  }

  onMeasure = dimensions => {
    const width = _.get(dimensions, 'width');
    if (width !== 0 && width !== this.state.width) {
      this.setState({ width });
    }
  };

  render() {
    const { maxWidth, children, shouldAlwaysMeasure } = this.props;
    const width = this.state.width;
    const [normal, small] = React.Children.toArray(children);

    return (
      <Measure
        onMeasure={this.onMeasure}
        shouldMeasure={shouldAlwaysMeasure || width === null}
      >
        {width && width > maxWidth ? small : normal}
      </Measure>
    );
  }
}
