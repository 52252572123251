import React from 'react';
import getDisplayName from 'shared/utils/get-component-display-name';

export default function decorate(Wrapper) {
  return function(DecoratedComponent) {
    return class extends React.Component {
      static displayName = `${getDisplayName(Wrapper)}(${getDisplayName(
        DecoratedComponent,
      )})`;

      render() {
        return (
          <Wrapper {...this.props}>
            <DecoratedComponent {...this.props} />
          </Wrapper>
        );
      }
    };
  };
}
