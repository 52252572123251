import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getChannelCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/cover';

import { notForPreview } from 'shared/utils/not-for-preview';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import {
  isClassicLayoutHeaderVisible,
  isClassicLayoutSearchBarVisible,
  isSearchInputVisible,
  isSignInVisible,
  isCategoriesDropdownVisible,
} from 'shared/utils/layout-helpers';
import { VODCSSModules } from 'shared/utils/wix-connect';
import { storeForReload } from 'widget/utils/reload';

import { openFullScreenChannelOverlay } from 'shared/utils/open-overlay';
import {
  openFullScreenMemberOnlyOverlay,
  openFullScreenPurchaseOverlay,
  openFullScreenSubscribeOverlay,
  openFullScreenRentOverlay,
} from 'widget/utils/open-overlay';
import {
  playerSize,
  listThumbnailSize,
  classicLayoutWidgetHeight,
  MIN_WIDGET_WIDTH,
  MAX_WIDGET_WIDTH,
} from 'widget/utils/videos-sizes/videos-sizes';

import { selectVideo } from 'widget/redux/client/actions/select-video';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import {
  searchByString,
  searchByCategory,
  searchByTag,
} from 'widget/redux/client/actions/search';
import { goToLazyVideosPageIndex } from 'widget/redux/client/lazy-channel-videos/actions';

import { getMainVideo } from 'widget/selectors/get-video';
import { showAutoPlay } from 'widget/selectors/layout';
import {
  getAllSettings,
  getNumberOfRows,
  isMainItemChannelCover,
} from 'shared/selectors/app-settings';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import {
  isVideoPlayingOptimistic,
  isVideoPausedOptimistic,
  isVideoEnded,
} from 'widget/selectors/video-playback-status';
import { getIsSearching, getSearchQuery } from 'shared/selectors/search';
import { logBi, logWidgetSystem } from 'shared/worker/actions/bi';
import { isSubscriptionButtonVisible } from 'shared/components/overlay-texts/channel/subscribe-button-text-utils';
import { isNumberOfRowsEnabled } from 'shared/selectors/experiments';
import { logWidgetVidClick } from 'shared/utils/bi/widget-common-events';
import { sendBiEvent } from 'shared/bi/send-bi-event';
import PlayerBlocksVisibility from 'widget/containers/player-blocks-visibility';
import { requestPlayVideo } from 'widget/redux/client/actions/request-play-video';

import ShareOverlay from 'widget/containers/share-overlay/share-overlay';
import OpenShareOverlay from 'widget/containers/open-share-overlay/open-share-overlay';
import ShareButton from 'widget/components/share-button/share-button';
import AccountButton from 'widget/components/account/account-button/account-button';
import ChannelInfoButton from 'widget/components/channel-actions/channel-info-button';
import ChannelSubscriptionButton from 'widget/components/channel-actions/channel-subscription-button';
import Categories from 'widget/components/categories/categories';
import Tags from 'widget/containers/tags/tags';
import SearchBar from 'widget/components/search-bar/search-bar';
import Videos from 'widget/layouts/classic/components/videos/videos';
import ClassicPlayerOverlay from 'widget/components/player-overlay/classic/classic';
import ComingSoon from 'widget/components/player-overlay/partials/coming-soon';
import { getCurrentChannelId } from 'widget/selectors/channel-info';

import { WidgetScrollbarWrapper } from 'shared/components/scrollbar-wrapper/scrollbar-wrapper';
import EmptySearchResults from 'widget/components/empty-search-results/empty-search-results';
import DeeplinkPopups from 'widget/components/deeplink-popups/deeplink-popups';
import AccessibleVideosContainer from 'widget/components/accessible-videos-container/accessible-videos-container';

import PaymentEvents from 'shared/components/payment-events/payment-events';
import LiveStartHandler from 'widget/components/live-start-handler/live-start-handler';
import { setSavedTime } from 'widget/redux/client/actions/player/set-saved-time';
import {
  getVideosPerPageCount,
  getVideoIdsByPageNumber,
  getVideoIds,
  getCurrentPageIndex,
  getIsFetching,
  getCurrentVideoIndex,
  hasNextVideo,
} from 'widget/redux/client/lazy-channel-videos/selectors';
import { getIsSearchResultEmpty } from 'widget/selectors/search-results';
import NoVideosContent from 'widget/components/no-content/no-videos-content/no-videos-content';
import AutoPlayVideo from 'shared/components/autoplay-video/autoplay-video';

/* containers */
import { WidgetPerformanceLoggers } from 'widget/containers/performance-loggers/performance-loggers';
import ChannelActionsContainer from 'shared/containers/channel-actions/channel-actions';
import { withPlayerModuleLoader } from 'widget/data-components/player-module-loader';

import { withStyles } from 'shared/utils/withStyles';
import styles from './classic.styl';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { setWidgetHeight } from 'shared/worker/actions/resize/set-widget-height';
import { resizeWindow } from 'shared/worker/actions/resize/resize-window';
import { resizeComponent } from 'shared/worker/actions/resize/resize-component';
import { fitIntoView } from 'shared/worker/actions/fit-into-view';

const DEFAULT_STATE = {
  isTagFocused: false,
  isSearchInputFocused: false,
  searchLayout: false,
  currentCategory: null,
};

@connect(
  state => {
    const lazyLoadedVideoIds = getVideoIds(state);
    const currentIdIndex = getCurrentVideoIndex(state);
    const nextVideoId = hasNextVideo(state)
      ? lazyLoadedVideoIds[currentIdIndex + 1]
      : null;

    return {
      isSite: viewModeSelectors.isSiteMode(state),
      isEditor: viewModeSelectors.isEditorMode(state),
      windowSize: state.windowSize,
      mainVideoId: getMainVideoId(state),
      mainVideo: getMainVideo(state),
      nextVideoId,
      selectedVideoId: state.selectedVideoId,
      isVideoPlaying: isVideoPlayingOptimistic(state),
      isVideoPaused: isVideoPausedOptimistic(state),
      isVideoEnded: isVideoEnded(state),
      isNumberOfRowsEnabled: isNumberOfRowsEnabled(state),
      currentChannelId: getCurrentChannelId(state),
      canShowChannelCover: !state.isVideosTouched,
      appSettings: getAllSettings(state),
      currentSiteUser: getCurrentSiteUser(state),
      firstChannelVideoId: state.firstChannelVideoId,
      numberOfRows: getNumberOfRows(state),
      videoByIds: getVideosGroupedByIds(state),
      isSignInVisible: isSignInVisible(state.appSettings),
      isSearchBarVisible: isClassicLayoutSearchBarVisible(
        state.appSettings,
        getChannelForWidget(state),
      ),
      isSearchInputVisible: isSearchInputVisible(
        state.appSettings,
        getChannelForWidget(state),
      ),
      isCategoriesDropdownVisible: isCategoriesDropdownVisible(
        state.appSettings,
        getChannelForWidget(state),
      ),
      searchQuery: getSearchQuery(state),
      channelData: getChannelForWidget(state),
      videosPerPage: getVideosPerPageCount(state),
      videoIdsByPageNumber: getVideoIdsByPageNumber(state),
      currentVideosPageNumber: getCurrentPageIndex(state),
      isVideosFetching: getIsFetching(state),
      isSearching: getIsSearching(state),
      isSearchResultEmpty: getIsSearchResultEmpty(state),
      isMainItemChannelCover: isMainItemChannelCover(state),
      showAutoPlay: showAutoPlay(state),
    };
  },
  {
    selectVideo,
    searchByString,
    searchByCategory,
    searchByTag,
    setSavedTime,
    goToLazyVideosPageIndex,
    requestPlayVideo,
    logBi,
    logWidgetSystem,
    logWidgetVidClick,
    storeForReload,
    sendBiEvent,
    openFullScreenMemberOnlyOverlay,
    openFullScreenPurchaseOverlay,
    openFullScreenSubscribeOverlay,
    openFullScreenRentOverlay,
    setWidgetHeight,
    resizeWindow,
    resizeComponent,
    openFullScreenChannelOverlay,
    fitIntoView,
  },
)
@withStyles(styles)
@VODCSSModules(styles)
export class ClassicView extends React.Component {
  static displayName = 'ClassicView';

  static propTypes = {
    resizeWindow: PropTypes.func.isRequired,
    setWidgetHeight: PropTypes.func.isRequired,
    isSite: PropTypes.bool.isRequired,
    isEditor: PropTypes.bool.isRequired,
    currentSiteUser: PropTypes.object,
    currentChannelId: PropTypes.string,
    mainVideoId: PropTypes.string,
    mainVideo: PropTypes.object,
    selectVideo: PropTypes.func.isRequired,
    canShowChannelCover: PropTypes.bool.isRequired,
    nextVideoId: PropTypes.string,
    channelData: PropTypes.object.isRequired,
    videoByIds: PropTypes.object.isRequired,
    appSettings: PropTypes.object.isRequired,
    windowSize: PropTypes.object.isRequired,
    isVideoEnded: PropTypes.bool,
    isSearchBarVisible: PropTypes.bool,
    isSearchInputVisible: PropTypes.bool,
    isCategoriesDropdownVisible: PropTypes.bool,
    isSignInVisible: PropTypes.bool,
    isNumberOfRowsEnabled: PropTypes.bool,
    isSearching: PropTypes.bool,
    videosPerPage: PropTypes.number,
    searchQuery: PropTypes.string,
    numberOfRows: PropTypes.number,
    setSavedTime: PropTypes.func,
    goToLazyVideosPageIndex: PropTypes.func.isRequired,
    currentVideosPageNumber: PropTypes.number.isRequired,
    videoIdsByPageNumber: PropTypes.array.isRequired,
    isVideosFetching: PropTypes.bool.isRequired,
    searchByString: PropTypes.func.isRequired,
    searchByTag: PropTypes.func.isRequired,
    searchByCategory: PropTypes.func.isRequired,
    isSearchResultEmpty: PropTypes.bool.isRequired,
    isMainItemChannelCover: PropTypes.bool.isRequired,
    requestPlayVideo: PropTypes.func.isRequired,
    fitIntoView: PropTypes.func.isRequired,
    selectedVideoId: PropTypes.string,
    isVideoPlaying: PropTypes.bool.isRequired,
    showAutoPlay: PropTypes.bool,

    PlayerComponent: PropTypes.func,
    resizeComponent: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...DEFAULT_STATE,
      searchBarValue: props.searchQuery,
      isInitialVideosLoaded: false,
    };

    this.initialRowsCount = null;
    this.setUtmostWidgetSize = _.debounce(this._setUtmostWidgetSize, 300);
  }

  componentDidMount() {
    this.setUtmostWidgetSize();
    this.sendSubscriptionDisplayedOnWidget();
    this.props.goToLazyVideosPageIndex(0);
  }

  componentDidUpdate(prevProps) {
    const {
      appSettings,
      channelData,
      windowSize,
      isNumberOfRowsEnabled,
    } = this.props;

    if (
      appSettings.numbers.videosInRow !==
        prevProps.appSettings.numbers.videosInRow ||
      appSettings.numbers.videoInfoPosition !==
        prevProps.appSettings.numbers.videoInfoPosition ||
      !_.isEqual(appSettings.booleans, prevProps.appSettings.booleans) ||
      channelData !== prevProps.channelData ||
      !_.isEqual(windowSize, prevProps.windowSize) ||
      (isNumberOfRowsEnabled &&
        appSettings.numbers.numberOfRows !==
          prevProps.appSettings.numbers.numberOfRows)
    ) {
      this.setUtmostWidgetSize();
    }

    const isSelectedVideoChanged =
      prevProps.selectedVideoId !== this.props.selectedVideoId;
    if (isSelectedVideoChanged) {
      focus(this.playerOverlayContainerRef);
    }
  }

  componentWillUnmount() {
    this.setUtmostWidgetSize.cancel();
  }

  sendSubscriptionDisplayedOnWidget() {
    const { channelData } = this.props;
    if (isSubscriptionButtonVisible({ channel: channelData })) {
      this.props.sendBiEvent('widget.subscription.displayed', {
        whereDisplayed: 'widget',
      });
    }
  }

  playerOverlayContainerRef = null;

  saveRef = (name, ref) => {
    this[name] = ref;
  };

  get listThumbnailSize() {
    const { appSettings, windowSize } = this.props;
    const videosInRow = appSettings.numbers.videosInRow;
    return listThumbnailSize(windowSize.width, videosInRow);
  }

  _setUtmostWidgetSize = () => {
    const {
      appSettings,
      channelData,
      isEditor,
      isSite,
      setWidgetHeight,
      resizeWindow,
      resizeComponent,
      windowSize,
    } = this.props;

    if (!appSettings || !channelData) {
      return;
    }

    if (this.initialRowsCount && isSite) {
      // in viewer, we do only single resize right after we know how much of videos we have in channel.
      return;
    }

    const allVideosCount = channelData.videosCount;
    const videosInRow = appSettings.numbers.videosInRow;

    const initialWidth = windowSize.width;
    const initialHeight = windowSize.height;

    const width = Math.min(
      Math.max(initialWidth, MIN_WIDGET_WIDTH),
      MAX_WIDGET_WIDTH,
    );

    const rowsCount = this.getRowsCount({
      videosInRow,
      allVideosCount,
      width,
      height: initialHeight,
    });

    if (isSite) {
      this.initialRowsCount = rowsCount;
    }

    // widget height for this rows count
    const height = Math.ceil(
      classicLayoutWidgetHeight(
        width,
        videosInRow,
        allVideosCount,
        rowsCount,
        appSettings,
        channelData,
      ),
    );

    if (width !== initialWidth || height !== initialHeight) {
      if (isEditor) {
        // editor
        resizeComponent({ width, height });
      } else {
        // viewer
        resizeWindow(width, height);
      }
      // fix TPA bug. TODO remove
      setWidgetHeight(height, width);
    }
  };

  getRowsCount({ videosInRow, allVideosCount }) {
    const { isNumberOfRowsEnabled, numberOfRows, videosPerPage } = this.props;

    if (isNumberOfRowsEnabled) {
      const maxNumberOfRows = Math.ceil(allVideosCount / videosInRow);
      return maxNumberOfRows > numberOfRows ? numberOfRows : maxNumberOfRows;
    }

    const rowsCount = Math.ceil(
      Math.min(videosPerPage, allVideosCount) / videosInRow,
    );

    return rowsCount <= 0 ? 1 : rowsCount;
  }

  reset = () => {
    this.setState({
      ...DEFAULT_STATE,
      searchBarValue: '',
    });

    this.props.goToLazyVideosPageIndex(0);
  };

  handleThumbnailClick = videoItem => {
    const { selectedVideoId, fitIntoView, selectVideo } = this.props;

    if (selectedVideoId === videoItem.id) {
      return;
    }

    fitIntoView();

    this.containerRef.scrollIntoView({ behavior: 'smooth' });

    selectVideo(videoItem.id);
  };

  get tagList() {
    return _.get(this.props, 'channelData.statsInfo.tags') || [];
  }

  get categoriesList() {
    const categoriesStats =
      _.get(this.props, 'channelData.statsInfo.categories') || [];
    return _.map(categoriesStats, 'value');
  }

  searchByTag = tag => {
    this.props.logWidgetSystem('videoList.searchByTag.requested');
    this.setTagsFocusState(false);
    this.props.searchByTag(tag);
    this.setState({
      searchBarValue: tag,
      currentCategory: null,
    });

    this.props.logBi('widget.tags.clicked');
  };

  handleTagsFocus = () => {
    this.setTagsFocusState(true);
  };

  handleTagsBlur = () => {
    this.setTagsFocusState(false);
  };

  setTagsFocusState(isFocused) {
    this.setState({
      isTagFocused: isFocused,
    });

    if (!isFocused) {
      setTimeout(() => this.hideSearchLayout(), 0);
    }
  }

  get tags() {
    const { searchLayout, searchBarValue } = this.state;
    const tags = this.tagList;

    if (!tags.length || !searchLayout || searchBarValue) {
      return null;
    }

    return (
      <div className={styles['tags-wrapper']} data-hook="tags-wrapper">
        <WidgetScrollbarWrapper>
          <Tags
            styleName="tags"
            onTagFocus={this.handleTagsFocus}
            onTagBlur={this.handleTagsBlur}
            onTagClick={this.searchByTag}
            tags={tags}
          />
        </WidgetScrollbarWrapper>
      </div>
    );
  }

  get noSearchResults() {
    const { isSearchResultEmpty } = this.props;
    const { searchBarValue, searchLayout } = this.state;

    if (!isSearchResultEmpty || (searchLayout && !searchBarValue)) {
      return null;
    }

    return <EmptySearchResults className={styles['no-search-results']} />;
  }

  handleSlideToPrev = () => {
    this.props.logWidgetSystem('videoList.changePage.requested');
    const { currentVideosPageNumber, goToLazyVideosPageIndex } = this.props;
    goToLazyVideosPageIndex(currentVideosPageNumber - 1);
  };

  handleSlideToNext = () => {
    this.props.logWidgetSystem('videoList.changePage.requested');
    const { currentVideosPageNumber, goToLazyVideosPageIndex } = this.props;
    goToLazyVideosPageIndex(currentVideosPageNumber + 1);
  };

  get playerSize() {
    return playerSize(this.props.windowSize.width);
  }

  isChannelCoverShown() {
    return this.props.canShowChannelCover && this.props.isMainItemChannelCover;
  }

  handleThumbnailPlayButtonClick = videoItem => {
    const { channelData } = this.props;

    this.playVideo(videoItem);
    this.props.logWidgetVidClick({ videoItem, channelData });
  };

  get videosList() {
    const {
      channelData,
      currentSiteUser,
      videoIdsByPageNumber,
      currentVideosPageNumber,
      videosPerPage,
      isVideosFetching,
      mainVideo,
    } = this.props;

    const { searchLayout, searchBarValue } = this.state;

    const searchBlockShown =
      searchLayout && !searchBarValue && this.tagList.length;
    const noVideosLoaded = !_.get(
      videoIdsByPageNumber[currentVideosPageNumber],
      'length',
    );

    if (!isVideosFetching && channelData.videosCount === 0) {
      return (
        <section styleName="coming-soon" tabIndex="0">
          {i18n.t('widget.this-channel-is-coming-soon')}
        </section>
      );
    }

    // TODO: this is crap, need to show videoList OR noSearchResults on upper level
    if (noVideosLoaded || !mainVideo || searchBlockShown) {
      return null;
    }

    return (
      <Videos
        styleName="videos"
        containerWidth={this.playerSize.width}
        videoIdsByPageNumber={videoIdsByPageNumber}
        currentVideosPageNumber={currentVideosPageNumber}
        onSlideToNext={this.handleSlideToNext}
        onSlideToPrev={this.handleSlideToPrev}
        channelData={channelData}
        currentSiteUser={currentSiteUser}
        videosPerPageCount={videosPerPage}
        onThumbnailClick={this.handleThumbnailClick}
        onPlayRequest={this.handleThumbnailPlayButtonClick}
        onPlayMemberOnlyRequest={this.openMemberOnly}
        thumbnailSize={this.listThumbnailSize}
        isSelectedVideoIndicationDisabled={this.isChannelCoverShown()}
      />
    );
  }

  handleSearchInputFocus = () => {
    this.setSearchInputFocusState(true);
  };

  handleSearchInputBlur = () => {
    this.setSearchInputFocusState(false);
  };

  setSearchInputFocusState(isFocused) {
    this.setState({
      isSearchInputFocused: isFocused,
    });

    if (isFocused) {
      this.showSearchLayout();
    } else {
      setTimeout(() => this.hideSearchLayout(), 0);
    }
  }

  showSearchLayout() {
    this.setState({ searchLayout: true });
  }

  hideSearchLayout() {
    const { isTagFocused, isSearchInputFocused } = this.state;
    if (!isTagFocused && !isSearchInputFocused) {
      this.setState({
        searchLayout: false,
      });
    }
  }

  clearSearch = () => {
    this.setState({ searchBarValue: '' });

    if (!this.state.currentCategory) {
      //if category is selected nothing to reset
      this.props.searchByString('');
    }
  };

  searchByQuery = value => {
    this.props.searchByString(value);
    this.props.logWidgetSystem('videoList.searchByQuery.requested');
    this.setState({ currentCategory: null });
  };

  handleSearchInputChange = searchBarValue => {
    this.setState({ searchBarValue });
  };

  get search() {
    const { isSearchInputVisible, searchQuery } = this.props;
    const { currentCategory } = this.state;

    if (!isSearchInputVisible) {
      return null;
    }

    const value = currentCategory ? '' : searchQuery;

    return (
      <SearchBar
        value={value}
        onFocus={this.handleSearchInputFocus}
        onBlur={this.handleSearchInputBlur}
        onChange={this.handleSearchInputChange}
        onSearch={this.searchByQuery}
        onClose={this.clearSearch}
        className={styles.search}
      />
    );
  }

  handleCategorySelect = category => {
    this.props.searchByCategory(category);
    this.props.logWidgetSystem('videoList.searchByCategory.requested');
    this.setState({
      currentCategory: category,
      searchBarValue: '',
    });
  };

  get categories() {
    const {
      isCategoriesDropdownVisible,
      searchQuery,
      videosPerPage,
      videoByIds,
    } = this.props;
    const { searchLayout, currentCategory, searchBarValue } = this.state;

    if (
      !isCategoriesDropdownVisible ||
      (!currentCategory && (searchBarValue || searchQuery))
    ) {
      return null;
    }

    const className = classnames(styles['categories-container'], {
      [styles.hidden]: searchLayout,
    });

    return (
      // wrapper needed for focusing when switching from search bar to categories by tab
      <div className={className}>
        <Categories
          className={styles.categories}
          maxOptionsVisible={_.size(videoByIds) > videosPerPage ? 5 : 3}
          isFocusable={!searchQuery || Boolean(currentCategory)}
          list={this.categoriesList}
          onCategorySelect={this.handleCategorySelect}
          selectedCategory={currentCategory}
        />
      </div>
    );
  }

  get content() {
    const {
      isSearchBarVisible,
      channelData,
      isVideosFetching,
      videoIdsByPageNumber,
      searchQuery,
      currentVideosPageNumber,
      isSearching,
    } = this.props;

    const styleNames = classnames('content', {
      'no-search-bar': !isSearchBarVisible,
    });

    return (
      <div styleName={styleNames} className="qa-widget-content">
        <section
          className={styles['search-line']}
          aria-label={i18n.t('widget.accessibility.search-videos')}
          data-hook="widget-search-line"
        >
          {this.search}
          {this.categories}
        </section>
        {this.tags}

        <AccessibleVideosContainer
          channelTitle={channelData.title}
          isChannelHasVideos={Boolean(channelData.videosCount)}
          isVideosFetching={isVideosFetching}
          isSearching={isSearching}
          videoIdsByPageNumber={videoIdsByPageNumber}
          searchQuery={searchQuery}
          currentVideosPageNumber={currentVideosPageNumber}
          className={styles['videos-container']}
          dataHook="video-list"
          style={{ height: this.videosListHeight }}
          onRef={memoizedPartial(this.saveRef, 'videosContainerRef')}
        >
          {this.videosList}
          {this.noSearchResults}
        </AccessibleVideosContainer>
      </div>
    );
  }

  get channelTitle() {
    const { channelData, appSettings } = this.props;
    return appSettings.booleans.showChannelTitle ? channelData.title : null;
  }

  showChannelInfo = event => {
    event.preventDefault();
    this.props.openFullScreenChannelOverlay(this.props.channelData.id);
  };

  openMemberOnly = notForPreview(id => {
    this.props.openFullScreenMemberOnlyOverlay(this.props.channelData.id, id);
  });

  storeDataForReload = () => {
    this.props.storeForReload({
      selectedVideoId: this.props.mainVideoId,
    });
  };

  setCurrentVideoFromPayment = paymentEvent => {
    if (paymentEvent.itemId) {
      this.props.selectVideo(paymentEvent.itemId);
    }
  };

  get signInButton() {
    const { isSignInVisible, channelData } = this.props;

    if (!isSignInVisible) {
      return null;
    }

    return (
      <ChannelActionsContainer
        channelId={channelData.id}
        onPageRefresh={this.storeDataForReload}
      >
        {channelActions => (
          <AccountButton
            className={styles.action}
            tooltipSide="bottom"
            channelData={channelData}
            onClick={channelActions.showAccountInfo}
            onLoginClick={channelActions.logIn}
            onLogoutClick={channelActions.logOut}
            onSubscribeClick={channelActions.subscribe}
            onSubscriptionCancelClick={channelActions.cancelSubscription}
          />
        )}
      </ChannelActionsContainer>
    );
  }

  get header() {
    const { appSettings, channelData } = this.props;
    const styleNames = classnames('header', {
      'empty-header': !isClassicLayoutHeaderVisible(appSettings, channelData),
    });

    const channelTitle = this.channelTitle;

    return (
      <header styleName={styleNames} data-hook="widget-header">
        <h2
          className={styles['channel-name']}
          data-hook="channel-title"
          title={channelTitle}
        >
          {channelTitle}
        </h2>
        <OpenShareOverlay>
          {({ toggleShare, ariaLabel }) => (
            <ShareButton
              className={styles.action}
              ariaLabel={ariaLabel}
              onClick={toggleShare}
            />
          )}
        </OpenShareOverlay>
        <ChannelInfoButton
          styleName="action"
          channelData={channelData}
          onClick={this.showChannelInfo}
        />
        <ChannelSubscriptionButton
          styleName="action"
          channelData={channelData}
          onClick={this.openSubscription}
        />
        {this.signInButton}
      </header>
    );
  }

  openSubscription = notForPreview(() => {
    const { currentChannelId } = this.props;
    this.props.logBi('widget.subscription.clicked', {
      channelID: currentChannelId,
    });
    this.props.openFullScreenSubscribeOverlay(currentChannelId, () => {
      this.props.logBi('widget.subscriptionPopUp.Completed', {
        result: 'canceled',
        errorMsg: '',
      });
    });
  });

  openPurchase = notForPreview(() => {
    const { currentChannelId, mainVideoId } = this.props;
    this.props.openFullScreenPurchaseOverlay(currentChannelId, mainVideoId);
  });

  openRent = notForPreview(() => {
    const { currentChannelId, mainVideoId } = this.props;
    this.props.openFullScreenRentOverlay(currentChannelId, mainVideoId);
  });

  renderShareOverlay() {
    const { channelData, mainVideo } = this.props;
    const key = mainVideo ? mainVideo.id : channelData.id;
    return (
      <ShareOverlay
        key={`share-${key}`}
        channelData={channelData}
        videoItem={mainVideo}
      />
    );
  }

  renderMainSceneContent() {
    return (
      <div className={styles['main-scene-container']}>
        {this.player}
        {this.renderShareOverlay()}
      </div>
    );
  }

  get player() {
    const { mainVideo, channelData, isMainItemChannelCover } = this.props;

    if (mainVideo) {
      return this.renderPlayerForCurrentDevice(mainVideo);
    }

    const { width, height } = this.playerSize;

    return (
      <NoVideosContent
        channelCoverUrl={getChannelCoverUrl(channelData)}
        width={width}
        height={height}
        isMainItemChannelCover={isMainItemChannelCover}
      >
        <ComingSoon className={styles['coming-soon']} />
      </NoVideosContent>
    );
  }

  handleOverlayPlayClick = videoItem => {
    const isMemberOnly = videoItem.memberOnly && !this.props.currentSiteUser;

    if (isMemberOnly) {
      this.openMemberOnly(videoItem.id);
    } else {
      this.playVideo(videoItem);
    }
  };

  playVideo = ({ id }) => {
    this.props.requestPlayVideo(id);
  };

  playNextVideo = () => {
    const { nextVideoId: id, channelData, mainVideo } = this.props;

    if (id) {
      this.props.logWidgetVidClick({
        videoItem: mainVideo,
        channelData,
        buttonName: 'next_video',
      });
      this.playVideo({ id });
    }
  };

  renderPlayerForCurrentDevice(videoItem) {
    const {
      isVideoPlaying,
      isVideoEnded,
      canShowChannelCover,
      channelData,
      appSettings,
      nextVideoId,
      mainVideo,
      currentSiteUser,
      showAutoPlay,
      PlayerComponent,
    } = this.props;

    const classicPlayerOverlay = (
      <ClassicPlayerOverlay
        appSettings={appSettings}
        currentSiteUser={currentSiteUser}
        channelData={channelData}
        videoItem={mainVideo}
        showChannelCover={canShowChannelCover}
        nextVideoId={nextVideoId}
        onPlaySelectedVideo={this.handleOverlayPlayClick}
        playNextVideo={this.playNextVideo}
        ended={isVideoEnded}
        saveRef={memoizedPartial(this.saveRef, 'playerOverlayContainerRef')}
      />
    );

    if (!PlayerComponent) {
      return (
        <div className={styles['player-container']}>
          <div className={styles.player} style={this.playerSize}>
            {classicPlayerOverlay}
          </div>
        </div>
      );
    }

    return (
      <div className={styles['player-container']}>
        <PlayerBlocksVisibility>
          {({ canShowVideoInfoButton, canShowShareButton }) => (
            <PlayerComponent
              styleName="player"
              className="qa-widget-player"
              videoItem={videoItem}
              channelData={channelData}
              width={this.playerSize.width}
              height={this.playerSize.height}
              paused={!isVideoPlaying}
              canShowChannelCover={canShowChannelCover}
              onPurchaseClick={this.openPurchase}
              onRentClick={this.openRent}
              onSubscribeClick={this.openSubscription}
              canShowFullInfo={canShowVideoInfoButton}
              canShareVideo={canShowShareButton}
            >
              {classicPlayerOverlay}
            </PlayerComponent>
          )}
        </PlayerBlocksVisibility>
        <DeeplinkPopups />
        {showAutoPlay && <AutoPlayVideo />}
      </div>
    );
  }

  saveContainerRef = ref => {
    this.containerRef = ref;
  };

  render() {
    const {
      channelData,
      isVideoPlaying,
      mainVideoId,
      windowSize,
      isEditor,
    } = this.props;

    return (
      <main
        ref={this.saveContainerRef}
        className={styles.container}
        style={isEditor ? { width: '100%', height: '100%' } : windowSize}
        data-hook="widget-container"
        data-channel-layout="classic"
        aria-label={i18n.t('widget.accessibility.channel-videos-widget', {
          channelTitle: channelData.title,
        })}
        tabIndex={0}
      >
        {this.header}
        {this.renderMainSceneContent()}
        {this.content}

        <PaymentEvents
          onRent={this.setCurrentVideoFromPayment}
          onSale={this.setCurrentVideoFromPayment}
          onSubscription={this.reset}
          onSubscriptionCancel={this.reset}
        />

        <LiveStartHandler
          playVideo={this.playVideo}
          isVideoPlaying={isVideoPlaying}
          selectedVideoId={mainVideoId}
        />

        <WidgetPerformanceLoggers />
      </main>
    );
  }
}

export default withPlayerModuleLoader(ClassicView);
