import { handleActions } from 'redux-actions';
import { SHARE_MODES } from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/share-buttons/constants';

import { SHARE_OVERLAY_ACTIONS } from 'widget/redux/client/actions/share-overlay';

const DEFAULT_STATE = {
  isOpen: false,
  shareMode: null,
};

const toggleShareChannel = state => ({
  ...state,
  isOpen: !state.isOpen,
  shareMode: SHARE_MODES.CHANNEL,
});

const toggleShareVideo = state => ({
  ...state,
  isOpen: !state.isOpen,
  shareMode: SHARE_MODES.VIDEO,
});

export default handleActions(
  {
    [SHARE_OVERLAY_ACTIONS.TOGGLE_SHARE_CHANNEL]: toggleShareChannel,
    [SHARE_OVERLAY_ACTIONS.TOGGLE_SHARE_VIDEO]: toggleShareVideo,
    [SHARE_OVERLAY_ACTIONS.CLOSE]: () => DEFAULT_STATE,
  },
  DEFAULT_STATE,
);
