import React from 'react';
import { connect } from 'react-redux';
import { logBi } from 'shared/worker/actions/bi';

import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import {
  withPubSubEvents,
  consumePubSubEvent,
} from 'widget/containers/pub-sub-events';
import { BI_EVENT } from 'shared/bi/constants';

@withPubSubEvents
@connect(
  state => ({
    currentCompId: getCompId(state),
  }),
  { logBi },
)
export default class BiHandler extends React.Component {
  componentDidUpdate(prevProps) {
    consumePubSubEvent(BI_EVENT, this.sendBiEvent, {
      prevProps,
      nextProps: this.props,
    });
  }

  sendBiEvent = ({ data }) => {
    const { eventName, params, targetCompId } = data;
    if (targetCompId === this.props.currentCompId) {
      this.props.logBi(eventName, params);
    }
  };

  render() {
    return null;
  }
}
