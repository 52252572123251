import _ from 'lodash';

export const APP_STATUSES = {
  LOADING: 'LOADING',
  READY: 'READY',
};

class AppStatus {
  constructor() {
    this.pending = ['load'];
  }

  init() {
    this.root = document.getElementById('root');
    this.status = APP_STATUSES.LOADING;
    this.setStatusAttribute();

    window.addEventListener('load', () => this.setMarkerReady('load'), {
      once: true,
    });
  }

  setLoaded = _.debounce(() => {
    this.status = APP_STATUSES.READY;
    this.setStatusAttribute();
  }, 300);

  setStatusAttribute() {
    this.root.setAttribute('data-loading-status', this.status);
  }

  addMarker(marker) {
    this.pending = _.uniq([...this.pending, marker]);
  }

  setMarkerReady(marker) {
    this.pending = _.reject(
      this.pending,
      pendingMarker => marker === pendingMarker,
    );

    if (!this.pending.length) {
      this.setLoaded();
    }
  }
}

export const appStatus = new AppStatus();
