import { createSelector } from 'reselect';
import SEARCH_QUERY_FIELDS from 'shared/constants/search-query-fields';

export const getSearchQuery = state => state.search.searchQuery;
export const getSearchQueryField = state => state.search.searchQueryField;
export const getCategory = createSelector(
  getSearchQueryField,
  getSearchQuery,
  (field, query) => (field === SEARCH_QUERY_FIELDS.CATEGORY ? query : ''),
);
export const getIsSearching = state => state.search.isSearching;
