import React from 'react';
import _ from 'lodash';

import classnames from 'classnames';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import pubsub from 'shared/utils/pubsub';
import modalTypes from 'shared/constants/modal-types';
import Header from './header/header';
import PropTypes from 'prop-types';

import {
  PrimaryButton,
  SecondaryButton,
} from 'shared/components/button/button';
import HeaderMobile from 'mobile/components/modal/header/header-mobile';

import { DISMISSED } from 'shared/components/modal/utils';
import events from 'shared/constants/events';

import { withStyles } from 'shared/utils/withStyles';
import styles from './modal.styl';
import stylesMobile from './modal-mobile.styl';

const closeOnOverlayClick = false;

function injectButtonComponentsIntoConfig(config, buttonComponents) {
  let { buttons } = config;

  buttons = _.mapValues(buttons, function(value, key) {
    if (value.Button) {
      return value;
    }

    return { ...value, Button: buttonComponents[key] };
  });

  return { ...config, buttons };
}

function buildModalConfig(config, isMobile = false) {
  // Regular Modal
  config = injectButtonComponentsIntoConfig(config, {
    primary: PrimaryButton,
    secondary: SecondaryButton,
  });

  if (isMobile) {
    config = {
      Header: HeaderMobile,
      className: stylesMobile['modal-mobile'],
      windowClassName: stylesMobile['modal-mobile-window'],
      contentClassName: stylesMobile['modal-mobile-content'],
      closeOnOverlayClick: true,
      ...config,
    };
  }

  return config;
}

@withStyles(styles)
@withStyles(stylesMobile)
export default class Modal extends React.Component {
  static propTypes = {
    title: PropTypes.node,
    closeButtonLabel: PropTypes.string,
    content: PropTypes.element,
    className: PropTypes.string,
    windowClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    buttonsClassName: PropTypes.string,
    closeOnOverlayClick: PropTypes.bool,
    useSafeFocus: PropTypes.bool,
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    closeOnOverlayClick,
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      closeOnOverlayClick,
      title: null,
      closeButtonLabel: null,
      content: null,
    };

    this.containerRef = null;
  }

  UNSAFE_componentWillMount() {
    this.openModalUnsubscribe = pubsub.subscribe(
      events.MODAL.OPEN,
      this.openModal,
    );
    this.closeModalUnsubscribe = pubsub.subscribe(
      events.MODAL.CLOSE,
      this.closeModal,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.open && !prevState.open) {
      if (this.props.useSafeFocus) {
        const containerRefHeight = this.containerRef.style.height;
        this.containerRef.style.height = 0;
        this.containerRef.focus();
        this.containerRef.style.height = containerRefHeight;
      } else {
        this.containerRef.focus();
      }
    }
  }

  componentWillUnmount() {
    this.openModalUnsubscribe();
    this.closeModalUnsubscribe();
  }

  saveRef = ref => {
    this.containerRef = ref;
  };

  openModal = config => {
    config = buildModalConfig(config, this.props.isMobile);

    this.setState({
      title: null,
      closeButtonLabel: null,
      content: null,
      Header,
      closeOnOverlayClick,
      className: '',
      windowClassName: '',
      contentClassName: '',
      buttonsClassName: '',
      buttons: {}, // {primary: {label, onClick, Button}, secondary: {...}}

      resolve: _.noop,
      reject: _.noop,

      modalType: modalTypes.POPUP,
      noCloseButton: false,
      ...config,
    });

    setTimeout(() => {
      this.setState({
        open: true,
      });
    }, 0);
  };

  closeModal = () => {
    return new Promise(resolve => {
      this.setState({ open: false }, () => {
        setTimeout(() => {
          this.setState({ content: null }, resolve);
        }, 200);
      });
    });
  };

  handleBackdropClick = event => {
    const { closeOnOverlayClick } = this.state;
    if (!event.isDefaultPrevented() && closeOnOverlayClick) {
      this.$modal.dismiss();
    }
  };

  handleCloseButtonClick = () => {
    this.$modal.dismiss();
  };

  stopEventPropagation(event) {
    event.preventDefault();
  }

  get $modal() {
    const { resolve, reject } = this.state;

    return {
      resolve: data => {
        resolve({ data, $modal: this.$modal });
      },
      reject(reason = DISMISSED) {
        reject(reason);
      },
      close: data => {
        this.closeModal().then(() => resolve(data));
      },
      dismiss: (reason = DISMISSED) => {
        this.closeModal().then(() => reject(reason));
      },
    };
  }

  get buttons() {
    const { buttons, buttonsClassName } = this.state;

    if (!_.keys(buttons).length) {
      return null;
    }

    const keyToDataHookMap = {
      primary: 'confirmation-button',
      secondary: 'cancellation-button',
    };

    return (
      <footer className={classnames(buttonsClassName, styles.buttons)}>
        {_.map(buttons, ({ Button, label, onClick }, key) => (
          <Button
            key={key}
            dataHook={keyToDataHookMap[key]}
            label={label}
            type="button"
            onClick={memoizedPartial(onClick, this.$modal)}
            className={styles.button}
          />
        ))}
      </footer>
    );
  }

  render() {
    const { open, content, modalType } = this.state;

    if (modalType !== modalTypes.POPUP) {
      return null;
    }

    if (!open && !content) {
      return null;
    }

    const {
      Header: InheritHeader,
      title,
      closeButtonLabel,
      className,
      windowClassName,
      contentClassName,
    } = this.state;

    const rootClassName = classnames(className, styles.modal, {
      [styles.open]: open && content,
    });

    return (
      <div
        className={rootClassName}
        onClick={this.handleBackdropClick}
        role="presentation"
      >
        <section
          data-hook="modal-window"
          className={classnames(windowClassName, styles.window)}
          role="dialog"
          aria-modal="true"
          aria-label={title}
          tabIndex={-1}
          ref={this.saveRef}
          onClick={this.stopEventPropagation}
        >
          {InheritHeader && (
            <InheritHeader
              title={title}
              closeButtonLabel={closeButtonLabel}
              onButtonClick={this.handleCloseButtonClick}
              role="presentation"
            />
          )}
          <div
            data-hook="modal-content"
            className={classnames(contentClassName, styles.content)}
            role="presentation"
          >
            {_.isString(content)
              ? content
              : React.cloneElement(content, { $modal: this.$modal })}
          </div>
          {this.buttons}
        </section>
      </div>
    );
  }
}
