import { handleActions } from 'redux-actions';

import SEARCH_QUERY_FIELDS from 'shared/constants/search-query-fields';
import { NAMES as CHANNEL_VIDEOS_LIST } from 'shared/redux/server/actions/channel/videos/list-public';
import { SEARCH_ACTIONS } from 'widget/redux/client/actions/search';

const DEFAULT_STATE = {
  searchQuery: '',
  searchQueryField: SEARCH_QUERY_FIELDS.QUERY,
  isSearching: false,
};

const updateSearch = (state, { payload }) => {
  const { searchQuery, searchQueryField } = payload;
  return {
    ...state,
    searchQuery,
    searchQueryField,
    isSearching:
      searchQuery !== state.searchQuery ||
      searchQueryField !== state.searchQueryField,
  };
};

const markSearchComplete = state => ({
  ...state,
  isSearching: false,
});

export default handleActions(
  {
    [SEARCH_ACTIONS.UPDATE_SEARCH]: updateSearch,
    [SEARCH_ACTIONS.RESET_SEARCH]: () => DEFAULT_STATE,
    [CHANNEL_VIDEOS_LIST.SUCCESS]: markSearchComplete,
    [CHANNEL_VIDEOS_LIST.FAIL]: markSearchComplete,
  },
  DEFAULT_STATE,
);
