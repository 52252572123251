import renderVideoSignInToWatchButtonText, {
  isVisible as isVideoSignInToWatchButtonVisible,
} from '../video/sign-in-to-watch-button-text';

/**
 * Is video sign in to play button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @return {Boolean}
 */
export const isVisible = isVideoSignInToWatchButtonVisible;

/**
 * Video sign in to play button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @param props.translation {Object} default is sharedTranslations.startWatching
 * @return {React.Component}
 */
export default function renderSignInToWatchButtonText(props) {
  props.translation =
    props.translation || 'widget.overlay-buttons.start-watching';
  return renderVideoSignInToWatchButtonText(props);
}
