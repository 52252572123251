import React from 'react';
import { connect } from 'react-redux';

import { notForPreview } from 'shared/utils/not-for-preview';
import { handleLogOutPaymentModal } from 'shared/utils/handle-logout-payment-modal';

import PropTypes from 'prop-types';

import {
  openFullScreenPurchaseOverlay,
  openFullScreenSubscribeOverlay,
  openFullScreenRentOverlay,
} from 'widget/utils/open-overlay';

@connect(
  null,
  {
    openFullScreenPurchaseOverlay,
    openFullScreenSubscribeOverlay,
    openFullScreenRentOverlay,
  },
)
export default class PlayerActionCallbacks extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    channelId: PropTypes.string,
    videoId: PropTypes.string,
  };

  componentDidMount() {
    handleLogOutPaymentModal({
      onRent: this.showRentInfoAtOverlay,
      onPurchase: this.showPurchaseInfoAtOverlay,
      onSubscription: this.showChannelSubscriptionInfoAtOverlay,
    });
  }

  showPurchaseInfoAtOverlay = notForPreview(() => {
    const { channelId, videoId } = this.props;
    this.props.openFullScreenPurchaseOverlay(channelId, videoId);
  });

  showRentInfoAtOverlay = notForPreview(() => {
    const { channelId, videoId } = this.props;
    this.props.openFullScreenRentOverlay(channelId, videoId);
  });

  showChannelSubscriptionInfoAtOverlay = notForPreview(() => {
    this.props.openFullScreenSubscribeOverlay(this.props.channelId);
  });

  render() {
    return React.cloneElement(this.props.children, {
      // TODO: rename to onPurchaseRequest and onSubscriptionRequest
      onPurchaseClick: this.showPurchaseInfoAtOverlay,
      onSubscribeClick: this.showChannelSubscriptionInfoAtOverlay,
      onRentClick: this.showRentInfoAtOverlay,
    });
  }
}
