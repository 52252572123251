import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import _ from 'lodash';
import { connect } from 'react-redux';

import { logBi } from 'shared/worker/actions/bi';
import {
  getBiToken,
  getInstanceId,
  getSiteUrl,
  getFullSiteUrl,
} from 'widget/redux/client/hydrated-data/hydrated-data';
import { SHARE_MODES } from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/share-buttons/constants';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import EmbedSelect from './embed-select/embed-select';
import CustomSize from './custom-size/custom-size';
import CopyContent from './copy-content/copy-content';
import Header from './header/header';

import {
  calculateHeight,
  calculateWidth,
  MIN_WIDTH,
  MIN_HEIGHT,
  EMBED_API_ENDPOINT,
} from './utils';

import { withStyles } from 'shared/utils/withStyles';
import styles from './embed-form.styl';

const SIZES = [
  {
    value: '560x315',
    text: '560x315',
  },

  {
    value: '1280x720',
    text: '1280x720',
  },

  {
    value: '853x480',
    text: '853x480',
  },

  {
    value: '640x360',
    text: '640x360',
  },

  {
    value: '',
    text: i18n.t('share-overlay.embed.custom'),
  },
];

const DEFAULT_SIZE = SIZES[0];

@connect(
  state => ({
    biToken: getBiToken(state),
    instanceId: getInstanceId(state),
    siteUrl: getSiteUrl(state),
    fullSiteUrl: getFullSiteUrl(state),
  }),
  { logBi },
)
@withStyles(styles)
class EmbedForm extends React.Component {
  static propTypes = {
    dataHook: PropTypes.string,
    onBack: PropTypes.func,
    channelId: PropTypes.string,
    videoId: PropTypes.string,
    compId: PropTypes.string,
    sitePageId: PropTypes.string,
    isRTL: PropTypes.bool,
    biToken: PropTypes.string.isRequired,
    instanceId: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    fullSiteUrl: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      customWidth: '',
      customHeight: '',
      size: DEFAULT_SIZE.value,
      websiteUrl: null,
      instanceId: props.instanceId,
      biToken: props.biToken,
      pathToPage: null,
    };
  }

  componentDidMount() {
    const { siteUrl, fullSiteUrl } = this.props;
    const baseUrlLen = siteUrl.length;
    const [pathToPage] = fullSiteUrl.substr(baseUrlLen).split('?');

    this.setState({
      pathToPage: pathToPage ? encodeURIComponent(pathToPage) : '',
    });

    focus(this.containerRef);
  }

  saveRef = ref => {
    this.containerRef = ref;
  };

  generateEmbedUrl() {
    const { width, height } = this.getSize();
    const embedUrl = this.buildEmbedUrl();
    if (!embedUrl) {
      return '';
    }
    return `<iframe width="${width}" height="${height}" src="${embedUrl}" frameborder="0" allowfullscreen></iframe>`;
  }

  buildEmbedUrl() {
    const { pathToPage, instanceId, biToken } = this.state;

    if (pathToPage === null) {
      return '';
    }
    const { channelId, videoId, compId, sitePageId } = this.props;

    // eslint-disable-next-line max-len
    return `${EMBED_API_ENDPOINT}?instanceId=${instanceId}&biToken=${biToken}&pathToPage=${pathToPage}&channelId=${channelId}&videoId=${videoId}&compId=${compId}&sitePageId=${sitePageId}`;
  }

  getSize() {
    const { size, customWidth, customHeight } = this.state;

    if (size) {
      const [width, height] = size.split('x');
      return { width, height };
    } else if (customWidth && customHeight) {
      return { width: customWidth, height: customHeight };
    }

    const [defaultWidth, defaultHeight] = DEFAULT_SIZE.value.split('x');
    return { width: defaultWidth, height: defaultHeight };
  }

  handleBackButtonClick = e => {
    e.preventDefault();
    this.props.onBack();
  };

  saveTextAreaRef = node => {
    this.textAreaRef = node;
  };

  selectCode = () => {
    this.textAreaRef.select();
  };

  updateSize = size => {
    this.setState({ size });
  };

  onWidthBlur = width => {
    const useDefaultValues = !_.gt(width, MIN_WIDTH);
    const customWidth = useDefaultValues ? MIN_WIDTH : width;
    const customHeight = useDefaultValues ? MIN_HEIGHT : calculateHeight(width);

    this.setState({
      customHeight,
      customWidth,
    });
  };

  onHeightBlur = height => {
    const useDefaultValues = !_.gt(height, MIN_HEIGHT);
    const customHeight = useDefaultValues ? MIN_HEIGHT : height;
    const customWidth = useDefaultValues ? MIN_WIDTH : calculateWidth(height);

    this.setState({
      customHeight,
      customWidth,
    });
  };

  setCustomSize = () => {
    const { size } = this.state;
    const customSize = _.last(SIZES).value;

    if (size !== customSize) {
      const [width, height] = size.split('x');
      this.setState({
        size: customSize,
        customHeight: height,
        customWidth: width,
      });
    }
  };

  getOptions() {
    return SIZES;
  }

  stopPropagation = event => {
    event.stopPropagation();
  };

  handleContentCopied = () => {
    const { channelId, videoId } = this.props;
    this.props.logBi('widget.share.embed.copy.clicked', {
      channelID: channelId,
      videoID: videoId,
      shareType: SHARE_MODES.VIDEO,
    });
  };

  getCustomSizeWidth() {
    const { size, customWidth } = this.state;
    return size ? size.split('x')[0] : customWidth;
  }

  getCustomSizeHeight() {
    const { size, customHeight } = this.state;
    return size ? size.split('x')[1] : customHeight;
  }

  render() {
    const { dataHook, isRTL } = this.props;
    const { size } = this.state;

    return (
      <section
        data-hook={dataHook}
        ref={this.saveRef}
        onClick={this.stopPropagation}
        className={styles['embed-form']}
      >
        <Header
          dataHook="header"
          onBackButtonClick={this.handleBackButtonClick}
        />

        <div className={styles['size-picker']}>
          <EmbedSelect
            dataHook="embed-select"
            onChange={this.updateSize}
            selected={size}
            options={this.getOptions()}
            isRTL={isRTL}
            ariaLabel={i18n.t('widget.accessibility.widget-size', {
              size: size.label,
            })}
          />

          <CustomSize
            dataHook="custom-size"
            className={styles['custom-sizes']}
            inactive={Boolean(size)}
            width={this.getCustomSizeWidth()}
            height={this.getCustomSizeHeight()}
            onFocus={this.setCustomSize}
            onWidthBlur={this.onWidthBlur}
            onHeightBlur={this.onHeightBlur}
          />
        </div>

        <CopyContent
          onInit={this.saveTextAreaRef}
          onFocus={this.selectCode}
          onContentCopied={this.handleContentCopied}
          value={this.generateEmbedUrl()}
          isRTL={isRTL}
        />
      </section>
    );
  }
}

export default EmbedForm;
