import React from 'react';
import _ from 'lodash';
import { withStyles } from 'shared/utils/withStyles';
import styles from './grid.styl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import AutoPlayVideo from 'shared/components/autoplay-video/autoplay-video';

/* containers */
import ActionCallbacks from 'widget/containers/action-callbacks/action-callbacks';
import OpenFullscreenModalShare from 'widget/containers/open-fullscreen-modal-share/open-fullscreen-modal-share';
import { WidgetPerformanceLoggers } from 'widget/containers/performance-loggers/performance-loggers';
import PaymentEvents from 'shared/components/payment-events/payment-events';
import LiveStartHandler from 'widget/components/live-start-handler/live-start-handler';
import WithPreviousVideos from 'widget/layouts/grid/containers/with-previous-videos/with-previous-videos';
import Player from 'widget/containers/player/player';
import ShareOverlay from 'widget/containers/share-overlay/share-overlay';
import { withPlayerModuleLoader } from 'widget/data-components/player-module-loader';
import { ViewModeObserver } from 'widget/containers/view-mode-observer';
import { CSSGrid } from '@wix/wix-vod-shared/dist/src/widget/ui-components/css-grid/css-grid';
import { PostSsrRuntimeContent } from '@wix/wix-vod-shared/dist/src/widget/utils/post-ssr-runtime';

/* selectors */
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isVideoPlayingOptimistic } from 'widget/selectors/video-playback-status';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import {
  getIsFetching,
  getCursor,
} from 'widget/redux/client/lazy-channel-videos/selectors';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { getMainVideo } from 'widget/selectors/get-video';
import { showAutoPlay, isPlayerActive } from 'widget/selectors/layout';
import { getCategory } from 'shared/selectors/search';
import {
  getNumberOfRows,
  getStretchToFullWidth,
  getContainerMargins,
  isPlayInFrame,
  getThumbnailSpacing,
  getVideosInRowCount,
} from 'shared/selectors/app-settings';

import {
  getThumbnailWidth,
  getMaxVideosCountInRow,
  getRowSpacing,
} from 'widget/layouts/grid/selectors';

/* utils */
import getThumbnailMinWidthAttribute from '@wix/wix-vod-shared/dist/src/widget/ui-components/slider/get-thumbnail-min-width-attribute';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';

/* actions */
import {
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
} from 'widget/redux/client/actions/full-screen-modal';
import { selectVideo } from 'widget/redux/client/actions/select-video';
import { loadMoreVideoPages } from 'widget/redux/client/lazy-channel-videos/actions';
import { requestPlayVideo } from 'widget/redux/client/actions/request-play-video';
import { resetSearch } from 'widget/redux/client/actions/search';
import { setWidgetHeight } from 'shared/worker/actions/resize/set-widget-height';
import { resizeComponent } from 'shared/worker/actions/resize/resize-component';

/* components */
import VideoThumbnail from 'shared/components/video-thumbnail/video-thumbnail';
import ActionBar from 'widget/components/action-bar/action-bar';
import LoadMoreButton from 'widget/layouts/grid/components/load-more-button/load-more-button';
import BallsLoader from 'widget/layouts/grid/components/balls-loader/balls-loader';
import NoResults from 'widget/layouts/components/no-results/no-results';

/* bi */
import { logWidgetSystem } from 'shared/worker/actions/bi';
import { logWidgetVidClick } from 'shared/utils/bi/widget-common-events';

/* translations */
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

/* constants */
import { FULL_WIDTH_MARGINS } from 'widget/layouts/grid/constants';
import { MAX_WIDGET_WIDTH } from 'widget/utils/videos-sizes/videos-sizes';
import { VIDEOS_ASPECT_RATIO } from 'widget/constants/videos-aspect-ratio';
import { WIDTH_CONSTRAINTS } from 'widget/constants/thumbnail-sizes';
import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';

@connect(
  state => ({
    styleId: getCompId(state),
    windowSize: state.windowSize,
    isEditorMode: viewModeSelectors.isEditorMode(state),
    channel: getChannelForWidget(state),
    currentSiteUser: getCurrentSiteUser(state),
    mainVideo: getMainVideo(state),
    mainVideoId: getMainVideoId(state),
    isFetching: getIsFetching(state),
    itemWidth: getThumbnailWidth(state),
    isVideoPlaying: isVideoPlayingOptimistic(state),
    numberOfRows: getNumberOfRows(state),
    videosInRow: getMaxVideosCountInRow(state),
    gridColumns: getVideosInRowCount(state),
    isFullWidth: getStretchToFullWidth(state),
    containerMargins: getContainerMargins(state),
    rowSpacing: getRowSpacing(state),
    thumbnailSpacing: getThumbnailSpacing(state),
    hasMoreVideos: getCursor(state),
    isPlayInFrame: isPlayInFrame(state),
    selectedCategory: getCategory(state),
    showAutoPlay: showAutoPlay(state),
    isPlayerActive: isPlayerActive(state),
  }),
  {
    selectVideo,
    loadMoreVideoPages,
    resetSearch,
    requestPlayVideo,
    openFullScreenVideoOverlay,
    closeFullScreenVideoOverlay,
    logWidgetSystem,
    logWidgetVidClick,
    setWidgetHeight,
    resizeComponent,
  },
)
@withStyles(styles)
export class GridLayout extends React.Component {
  static propTypes = {
    styleId: PropTypes.string.isRequired,
    isEditorMode: PropTypes.bool.isRequired,
    windowSize: PropTypes.object.isRequired,
    channel: PropTypes.object.isRequired,
    videoIds: PropTypes.array.isRequired,
    videoByIds: PropTypes.object.isRequired,
    currentSiteUser: PropTypes.object,
    selectVideo: PropTypes.func.isRequired,
    mainVideo: PropTypes.object,
    mainVideoId: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    itemWidth: PropTypes.number.isRequired,
    numberOfRows: PropTypes.number.isRequired,
    videosInRow: PropTypes.number.isRequired,
    gridColumns: PropTypes.number.isRequired,
    isFullWidth: PropTypes.bool.isRequired,
    isPlayInFrame: PropTypes.bool.isRequired,
    containerMargins: PropTypes.number.isRequired,
    rowSpacing: PropTypes.number.isRequired,
    thumbnailSpacing: PropTypes.number.isRequired,
    hasMoreVideos: PropTypes.string,
    loadMoreVideoPages: PropTypes.func.isRequired,
    requestPlayVideo: PropTypes.func.isRequired,
    openFullScreenVideoOverlay: PropTypes.func.isRequired,
    closeFullScreenVideoOverlay: PropTypes.func.isRequired,
    isVideoPlaying: PropTypes.bool.isRequired,
    isSearching: PropTypes.bool.isRequired,
    selectedCategory: PropTypes.string,
    resetSearch: PropTypes.func.isRequired,
    showAutoPlay: PropTypes.bool,
    isPlayerActive: PropTypes.bool,
    setWidgetHeight: PropTypes.func.isRequired,
    resizeComponent: PropTypes.func.isRequired,
    PlayerComponent: PropTypes.func,
    isPortableDevice: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      numberOfRows: props.numberOfRows,
    };

    this.forcedWidth = 0;
    this.a11yFocusTimer = null;
    this.thumbnailRefs = {};
    this.playerRef = null;
    this.playerContainerRef = null;
  }

  componentDidMount() {
    this.resizeWidget();
    this.attachPlayerToSelectedThumbnail(this.props.mainVideoId);
  }

  UNSAFE_componentWillReceiveProps({
    numberOfRows,
    isFullWidth,
    isFetching,
    mainVideoId,
  }) {
    if (numberOfRows !== this.props.numberOfRows) {
      this.setState({ numberOfRows });
    }

    if (isFullWidth !== this.props.isFullWidth && !isFullWidth) {
      this.forcedWidth = MAX_WIDGET_WIDTH;
    }

    if (!isFetching && this.props.isFetching && this.contentRef) {
      this.a11yFocusTimer = setTimeout(() => {
        focus(this.contentRef);
      }, 300);
    }

    if (mainVideoId !== this.props.mainVideoId) {
      this.attachPlayerToSelectedThumbnail(mainVideoId);
    }
  }

  componentDidUpdate() {
    if (this.forcedWidth && this.props.windowSize.width === MAX_WIDGET_WIDTH) {
      this.forcedWidth = 0;
    }

    this.resizeWidget();
  }

  componentWillUnmount() {
    clearTimeout(this.a11yFocusTimer);
  }

  attachPlayerToSelectedThumbnail(mainVideoId) {
    const thumbnailRef = this.thumbnailRefs[mainVideoId];

    if (!thumbnailRef || !this.playerRef) {
      return;
    }

    if (thumbnailRef.contains(this.playerRef)) {
      return;
    }

    thumbnailRef.appendChild(this.playerRef);
  }

  reset = ({ editMode }) => {
    const { numberOfRows, mainVideoId } = this.props;

    if (this.state.numberOfRows !== numberOfRows) {
      this.setState({ numberOfRows });
    }

    if (
      this.playerContainerRef &&
      this.playerRef &&
      editMode === VIEW_MODES.EDITOR
    ) {
      this.playerContainerRef.appendChild(this.playerRef);
    }

    if (editMode === VIEW_MODES.PREVIEW) {
      this.attachPlayerToSelectedThumbnail(mainVideoId);
    }
  };

  resizeWidget() {
    const {
      windowSize,
      isFullWidth,
      isEditorMode,
      setWidgetHeight,
      resizeComponent,
    } = this.props;

    const width = this.containerRef.clientWidth;
    const height = this.containerRef.clientHeight;
    const nextWindowSize = { width, height };

    if (_.isEqual(windowSize, nextWindowSize)) {
      return;
    }

    if (isFullWidth) {
      setWidgetHeight(height, width);
      return;
    }

    if (isEditorMode) {
      // Viewer does not support resizeComponent
      resizeComponent({ width, height });
    } else {
      setWidgetHeight(height, width);
    }
  }

  setCurrentVideoFromPayment = ({ itemId } = {}) => {
    if (itemId) {
      this.props.selectVideo(itemId);
    }
  };

  logVideoPlayRequested = videoItem => {
    const { channel } = this.props;
    this.props.logWidgetVidClick({ videoItem, channelData: channel });
  };

  saveThumbnailRef = (videoId, ref) => {
    this.thumbnailRefs[videoId] = ref;
    if (videoId === this.props.mainVideoId) {
      this.attachPlayerToSelectedThumbnail(this.props.mainVideoId);
    }
  };

  renderThumbnail = videoId => {
    const {
      channel,
      isSearching,
      videoByIds,
      currentSiteUser,
      itemWidth,
      rowSpacing,
      thumbnailSpacing,
    } = this.props;

    const video = videoByIds[videoId];

    if (!video) {
      return <div key={videoId} style={{ width: itemWidth }} />;
    }

    return (
      <div
        style={{ marginTop: rowSpacing - 2, marginLeft: thumbnailSpacing }}
        key={videoId}
        ref={memoizedPartial(this.saveThumbnailRef, videoId)}
        className={styles.thumbnail}
      >
        <ActionCallbacks
          channelId={channel.id}
          videoItem={video}
          onPlayRequestedBi={this.logVideoPlayRequested}
        >
          <VideoThumbnail
            videoItem={video}
            isLoading={isSearching}
            dataHook="video-list-thumb-wrapper"
            channelData={channel}
            currentSiteUser={currentSiteUser}
            width={itemWidth}
            isContentFocusable
            useResponsiveThumbnail
          />
        </ActionCallbacks>
      </div>
    );
  };

  renderGridContent() {
    const {
      mainVideo,
      isFetching,
      selectedCategory,
      hasMoreVideos,
      videoIds,
    } = this.props;

    if (!isFetching && selectedCategory && !hasMoreVideos && !videoIds.length) {
      return this.renderEmptySearchState(
        i18n.t('widget.categories.no-videos-in-category'),
      );
    }

    return mainVideo ? this.renderThumbnailsCSSGrid() : this.renderEmptyState();
  }

  saveContentRef = ref => {
    this.contentRef = ref;
  };

  getContentAriaLabel() {
    const { videoIds, selectedCategory, hasMoreVideos } = this.props;

    const options = {
      videosCount: videoIds.length,
    };

    const moreVideosAvailable = hasMoreVideos
      ? i18n.t('widget.a11y.grid.more-videos-available')
      : '';

    const ariaLabel = selectedCategory
      ? i18n.t('widget.a11y.grid.videos-for-category', {
          ...options,
          category: selectedCategory,
        })
      : i18n.t('widget.a11y.grid.videos', options);

    return [ariaLabel, moreVideosAvailable].join(' ');
  }

  renderThumbnailsCSSGrid() {
    const {
      videoIds,
      videosInRow,
      rowSpacing,
      thumbnailSpacing,
      styleId,
    } = this.props;

    const { numberOfRows } = this.state;

    const grid = _.range(0, numberOfRows * videosInRow);
    const gridVideoIds = _(grid)
      .map(value => `fake-item-${value}`)
      .assign(videoIds)
      .value();

    const gridId = 'grid-layout-items';
    const maxRows = Math.ceil(videoIds.length / videosInRow);
    const rows = _.clamp(numberOfRows, 1, maxRows);

    // TODO: maxItemsPerRow

    return (
      <React.Fragment>
        <div
          data-hook="grid-content"
          tabIndex="0"
          aria-label={this.getContentAriaLabel()}
          ref={this.saveContentRef}
          className={styles.content}
        >
          <CSSGrid
            styleId={styleId}
            gridId={gridId}
            style={{
              gridAutoRows: 0,
              overflowY: 'hidden',
              marginTop: -rowSpacing,
              marginLeft: -thumbnailSpacing,
            }}
            rows={rows}
            cols={videosInRow}
            minItemWidth={WIDTH_CONSTRAINTS[0]}
          >
            {_.map(gridVideoIds, this.renderThumbnail)}
          </CSSGrid>
          <PostSsrRuntimeContent>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  (function () {
                    var gridContent = document.querySelector('.${styleId}-${gridId}');
                    var width = gridContent.clientWidth;
                    var itemWidth = ${WIDTH_CONSTRAINTS[0]};
                    var spacing = ${thumbnailSpacing};
                    var takenWidth = 0;
                    var itemsCount = 1;
                    var gridTemplateColumns = [];

                    while (itemsCount <= ${videosInRow}) {
                      itemsCount++;
                      takenWidth = itemsCount * itemWidth + (itemsCount - 1) * spacing;
                      gridTemplateColumns.push('minmax(${
                        WIDTH_CONSTRAINTS[0]
                      }px, 1fr)');
                      if (takenWidth >= width) {
                        break;
                      }
                    }

                    gridContent.style.gridTemplateColumns = gridTemplateColumns.join(' ');
                    gridContent.style.msGridColumns = gridTemplateColumns.join(' ');
                  })();
                `,
              }}
            ></script>
          </PostSsrRuntimeContent>
        </div>
      </React.Fragment>
    );
  }

  renderEmptyState() {
    return (
      <div data-hook="grid-empty" className={styles.empty}>
        {!this.props.isFetching && (
          <div className={styles.emptyContent}>
            {i18n.t('widget.this-channel-is-coming-soon')}
          </div>
        )}
      </div>
    );
  }

  renderActions() {
    const { channel, containerMargins, isFullWidth } = this.props;

    return (
      <ActionBar
        channelData={channel}
        style={{
          padding: isFullWidth && containerMargins < 20 ? '0 20px' : 0,
        }}
        className={styles['action-bar-container']}
      />
    );
  }

  loadMoreVideos = () => {
    const {
      loadMoreVideoPages,
      hasMoreVideos,
      numberOfRows,
      isFetching,
    } = this.props;

    if (isFetching) {
      return;
    }

    this.props.logWidgetSystem('videoList.loadMore.requested');

    const loadPromise = hasMoreVideos
      ? loadMoreVideoPages()
      : Promise.resolve();

    loadPromise.then(() => {
      this.setState({
        numberOfRows: _.clamp(
          this.state.numberOfRows + numberOfRows,
          1,
          this.getNumberOfRows(),
        ),
      });
      this.props.logWidgetSystem('videoList.loadMore.rendered');
    });
  };

  renderLoadMoreButton() {
    const { isFetching } = this.props;

    return (
      <LoadMoreButton
        dataHook="load-more-button"
        isLoading={isFetching}
        onClick={this.loadMoreVideos}
        ariaLabel={i18n.t('widget.load-more')}
      >
        {isFetching ? <BallsLoader /> : i18n.t('widget.load-more')}
      </LoadMoreButton>
    );
  }

  shouldDisplayLoadMoreButton() {
    const { hasMoreVideos } = this.props;
    return hasMoreVideos || this.state.numberOfRows < this.getNumberOfRows();
  }

  getNumberOfRows() {
    const { videoIds, videosInRow } = this.props;
    return Math.ceil(videoIds.length / videosInRow);
  }

  saveContainerRef = ref => {
    this.containerRef = ref;
  };

  playVideo = ({ id }) => {
    const {
      channel,
      requestPlayVideo,
      openFullScreenVideoOverlay,
      closeFullScreenVideoOverlay,
      isPlayInFrame,
      isPortableDevice,
    } = this.props;

    if (isPlayInFrame || isPortableDevice) {
      requestPlayVideo(id);
      return;
    }

    openFullScreenVideoOverlay(
      channel.id,
      id,
      true,
      closeFullScreenVideoOverlay,
    );
  };

  renderEmptySearchState(message) {
    return (
      <NoResults
        className={styles.empty}
        dataHook="grid-empty-search-results"
        message={message}
        onButtonClick={this.resetSearch}
      />
    );
  }

  resetSearch = () => {
    const { resetSearch, numberOfRows } = this.props;

    this.setState({ numberOfRows: numberOfRows });
    resetSearch();
  };

  savePlayerContainerRef = ref => {
    this.playerContainerRef = ref;
  };

  savePlayerRef = ref => {
    this.playerRef = ref;
  };

  renderPlayer() {
    const {
      channel,
      mainVideo,
      itemWidth,
      isPlayerActive,
      showAutoPlay,
      PlayerComponent,
    } = this.props;

    if (!mainVideo) {
      return null;
    }

    return (
      <div
        data-hook="player-wrapper"
        ref={this.savePlayerRef}
        className={classnames(styles.player, {
          [styles.active]: isPlayerActive,
        })}
      >
        <Player
          width={itemWidth}
          height={itemWidth / VIDEOS_ASPECT_RATIO}
          PlayerComponent={PlayerComponent}
        />
        <ShareOverlay
          key={channel.id}
          channelData={channel}
          videoItem={mainVideo}
        />
        {showAutoPlay && <AutoPlayVideo />}
      </div>
    );
  }

  render() {
    const {
      channel,
      isFullWidth,
      containerMargins,
      itemWidth,
      mainVideoId,
      isVideoPlaying,
      isSearching,
      videoIds,
      isPlayInFrame,
    } = this.props;

    const horizontalPadding = isFullWidth
      ? containerMargins
      : FULL_WIDTH_MARGINS;

    const containerStyles = {
      padding: isFullWidth
        ? `${FULL_WIDTH_MARGINS}px ${containerMargins}px`
        : `${FULL_WIDTH_MARGINS}px`,
      minWidth: `${this.forcedWidth || itemWidth + horizontalPadding * 2}px`,
      minHeight: isSearching || !videoIds.length ? '100vh' : 'unset',
    };

    return (
      <main
        className={styles.container}
        style={containerStyles}
        data-thumbnail-min-width={getThumbnailMinWidthAttribute(itemWidth)}
        data-hook="widget-container"
        data-channel-layout="grid"
        ref={this.saveContainerRef}
        aria-label={i18n.t('widget.accessibility.channel-videos-widget', {
          channelTitle: channel.title,
        })}
        tabIndex="0"
      >
        {isPlayInFrame && (
          <div ref={this.savePlayerContainerRef} style={{ display: 'none' }}>
            {this.renderPlayer()}
          </div>
        )}
        {this.renderActions()}
        {this.renderGridContent()}
        {this.shouldDisplayLoadMoreButton() && this.renderLoadMoreButton()}
        <OpenFullscreenModalShare itemWidth={itemWidth} />
        <PaymentEvents
          onRent={this.setCurrentVideoFromPayment}
          onSale={this.setCurrentVideoFromPayment}
        />
        <WidgetPerformanceLoggers />
        <LiveStartHandler
          playVideo={this.playVideo}
          isVideoPlaying={isVideoPlaying}
          selectedVideoId={mainVideoId}
        />
        <ViewModeObserver onChange={this.reset} />
      </main>
    );
  }
}

export default withPlayerModuleLoader(WithPreviousVideos(GridLayout));
