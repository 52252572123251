import { createSelector } from 'reselect';
import {
  getCompId,
  getFullSiteUrl,
  getCurrentPageId,
  getInstanceId,
} from 'widget/redux/client/hydrated-data/hydrated-data';

import getUrlToVideo from '@wix/wix-vod-shared/dist/src/common/utils/get-url-to-video';
import getUrlToChannel from '@wix/wix-vod-shared/dist/src/common/utils/get-url-to-channel';

const getUrlToVideoSafe = (videoItem, params) => {
  if (!videoItem) {
    return null;
  }
  return getUrlToVideo(videoItem, params);
};

export const getShareMode = state => state.shareOverlay.shareMode;
export const isShareOverlayOpen = state => state.shareOverlay.isOpen;
export const getInstanceInfo = createSelector(
  getInstanceId,
  getCompId,
  getCurrentPageId,
  (instanceId, componentId, sitePageId) => ({
    instanceId,
    componentId,
    sitePageId,
  }),
);

export const getVideoUrl = createSelector(
  (state, ownProps) => ownProps.videoItem,
  getCompId,
  getFullSiteUrl,
  (videoItem, compId, siteUrl) =>
    getUrlToVideoSafe(videoItem, { compId, siteUrl }),
);

export const getChannelUrl = createSelector(
  getCompId,
  getFullSiteUrl,
  (compId, siteUrl) => getUrlToChannel({ siteUrl, compId }),
);
