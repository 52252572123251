import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import PaidIcon from 'static/images/paid.svg';

import { withStyles } from 'shared/utils/withStyles';
import styles from '../overlay.styl';

@withStyles(styles)
export default class OverlayPaidAccessButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,

    children: PropTypes.node,
    isFocusable: PropTypes.bool,
    isIconOnly: PropTypes.bool,

    className: PropTypes.string,
    iconClassName: PropTypes.string,

    ariaHidden: PropTypes.bool,
  };

  static defaultProps = {
    isFocusable: true,
    isIconOnly: false,
    ariaHidden: false,
  };

  handleClick = event => {
    event.stopPropagation();
    event.preventDefault();

    this.props.onClick();
  };

  render() {
    const {
      children,
      isIconOnly,
      isFocusable,
      className,
      iconClassName,
      ariaHidden,
    } = this.props;

    const classNames = classnames(styles.button, className, {
      [styles['only-icon']]: isIconOnly,
    });

    return (
      <a
        href="#"
        className={classNames}
        onClick={this.handleClick}
        data-hook="paid-access-button"
        aria-hidden={ariaHidden}
        tabIndex={isFocusable ? 0 : -1}
      >
        <PaidIcon
          className={classnames(
            styles.icon,
            styles['paid-access-icon'],
            iconClassName,
          )}
        />
        {children && <span className={styles.text}>{children}</span>}
      </a>
    );
  }
}
