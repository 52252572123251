import DEVICE_TYPES from 'shared/constants/device-types';

const getHydratedData = state => state.hydratedData.data;

const getFormFactor = state => getHydratedData(state).deviceType;

export const isMobile = state => getFormFactor(state) === DEVICE_TYPES.MOBILE;

export const isTablet = state => getFormFactor(state) === DEVICE_TYPES.TABLET;

export const isPortableDevice = state => isMobile(state) || isTablet(state);
