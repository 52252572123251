import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';

import NavArrow from './images/prev.svg';

import { withStyles } from 'shared/utils/withStyles';
import styles from './nav-button.styl';

const navTranslations = {
  next: 'shared.navigation.next-page',
  prev: 'shared.navigation.previous-page',
};

@withStyles(styles)
class GenericNavButton extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    ariaLabel: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    rounded: false,
  };

  render() {
    const { name, disabled, ariaLabel, onClick } = this.props;

    const classNames = classnames(styles['nav-button'], styles[name], {
      [styles.disabled]: disabled,
    });

    return (
      <button
        className={classNames}
        type="button"
        onClick={disabled ? null : onClick}
        aria-label={ariaLabel}
      >
        <NavArrow className={styles['nav-arrow']} />
        <div className={styles['nav-text']}>
          {i18n.t(navTranslations[name])}
        </div>
      </button>
    );
  }
}

export function PrevButton(props) {
  return (
    <GenericNavButton
      {...props}
      ariaLabel={i18n.t('widget.accessibility.prev-videos')}
      name="prev"
    />
  );
}

export function NextButton(props) {
  return (
    <GenericNavButton
      {...props}
      ariaLabel={i18n.t('widget.accessibility.next-videos')}
      name="next"
    />
  );
}
