import * as React from 'react';
import Spinner from '@wix/wix-vod-shared/dist/src/widget/ui-components/loading-spinner/loading-spinner';

import { withStyles } from 'shared/utils/withStyles';
import styles from './spinner.styl';

export const LayoutSpinner = withStyles(styles)(() => (
  <div className={styles['spinner-container']}>
    <Spinner className={styles.spinner} />
  </div>
));
