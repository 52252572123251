import pixWixProxy from 'api/pix-wix-proxy/pix-wix-proxy';

export const getAuthToken = (userId, metaSiteId) =>
  pixWixProxy
    .get('/annoto/token', {
      params: {
        member_id: userId,
        metasite_id: metaSiteId,
      },
    })
    .then(response => response.data.token);
