import WixSDK from 'shared/utils/wix-sdk';
import parseQueryParams from './get-query-params';

const getRootCompId = () => {
  const { rootCompId } = parseQueryParams(window.location.search);
  return rootCompId;
};

export const getCompId = () =>
  getRootCompId() || WixSDK.Utils.getOrigCompId() || WixSDK.Utils.getCompId();
