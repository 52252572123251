import { isVODChannel } from 'shared/utils/channel-helpers';
import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { normalizeVideo } from 'shared/redux/helpers/normalized';
import { getChannelById } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getYoutubeVideoById as _getYoutubeVideoById } from '@wix/wix-vod-api/dist/src/public/youtube/videos';
import { getChannelVideoById as _getChannelVideoById } from '@wix/wix-vod-api/dist/src/public/channel-videos/channel-videos';
import { getHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
import { createAsyncProxy } from 'shared/worker/lib';

const ACTIONS = createAsyncActions('SERVER.CHANNEL.VIDEO.GET');
export const NAMES = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

export const getPublicVideo = createAsyncProxy(
  'video.getPublicVideo',
  (channelId, videoId) => async (dispatch, getState, { withContext }) => {
    const state = getState();
    const params = { channelId, videoId };
    const channel = getChannelById(state, channelId);
    const { singleVideoId, singleVideo } = getHydratedData(state);

    dispatch(START(params));

    let response;

    const getChannelVideoById = withContext(_getChannelVideoById);
    const getYoutubeVideoById = withContext(_getYoutubeVideoById);

    try {
      if (singleVideoId === videoId) {
        response = await getChannelVideoById(channelId, videoId, singleVideo);
      } else if (channel) {
        response = isVODChannel(channel)
          ? await getChannelVideoById(channelId, videoId)
          : await getYoutubeVideoById(videoId);
      } else {
        try {
          response = await getChannelVideoById(channelId, videoId);
        } catch (error) {
          if (error.status === 404) {
            response = await getYoutubeVideoById(videoId);
          }
        }
      }

      response = {
        ...response,
        data: normalizeVideo(response.data),
      };

      dispatch(SUCCESS(params, response));
      return response;
    } catch (error) {
      dispatch(
        FAIL(params, error, {
          analytics: { type: 'error', name: 'shared.channel.video.get' },
        }),
      );
    }
  },
);
