import { VIDEO_PLAYBACK_STATUSES } from '@wix/wix-vod-constants/dist/common/video-playback-statuses';

export const isVideoPlaying = state =>
  state.player.playback === VIDEO_PLAYBACK_STATUSES.PLAYING;
export const isVideoInInitialState = state =>
  state.player.playback === VIDEO_PLAYBACK_STATUSES.INITIAL;
export const isVideoPaused = state =>
  state.player.playback === VIDEO_PLAYBACK_STATUSES.PAUSED;
export const isVideoPlayRequested = state =>
  state.player.playback === VIDEO_PLAYBACK_STATUSES.PLAY_REQUESTED;
export const isVideoPauseRequested = state =>
  state.player.playback === VIDEO_PLAYBACK_STATUSES.PAUSE_REQUESTED;

export const isVideoEnded = state =>
  state.player.playback === VIDEO_PLAYBACK_STATUSES.ENDED;

export const isVideoPlayingOptimistic = state =>
  isVideoPlaying(state) || isVideoPlayRequested(state);
export const isVideoPausedOptimistic = state =>
  isVideoPaused(state) || isVideoPauseRequested(state);
export const isVideoPlayAborted = state =>
  state.player.playback === VIDEO_PLAYBACK_STATUSES.PLAY_ABORTED;
