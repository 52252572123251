import _ from 'lodash';

const EVENT_NAMES = {
  VIDEO_PLAY_TRAILER: 'VIDEO_PLAY_TRAILER',
  VIDEO_PLAY_COMPLETE: 'VIDEO_PLAY_COMPLETE',
  VIDEO_SHARE: 'VIDEO_SHARE',
  CHANNEL_SHARE: 'CHANNEL_SHARE',
};

const getEvents = ({ ownerId }) => ({
  [EVENT_NAMES.VIDEO_PLAY_TRAILER]: {
    messageId() {
      return Date.now();
    },
    metrics: [
      {
        type: 'video',
        reportMetrics: [{ name: 'view', value: 1 }],
        properties: {
          view_type: 'trailer',
          user_id: ownerId,
          video_id({ videoItem }) {
            return videoItem.baseItemId;
          },
          channel_id({ channel }) {
            return channel.id;
          },
        },
      },
    ],
  },
  [EVENT_NAMES.VIDEO_PLAY_COMPLETE]: {
    messageId() {
      return Date.now();
    },
    metrics: [
      {
        type: 'video',
        reportMetrics: [{ name: 'view', value: 1 }],
        properties: {
          view_type: 'video',
          user_id: ownerId,
          video_id({ videoItem }) {
            // only youtube feed videos have no baseItemId, use externalId instead
            return videoItem.baseItemId || videoItem.externalId;
          },
          channel_id({ channel }) {
            return channel.id;
          },
        },
      },
    ],
  },
  [EVENT_NAMES.VIDEO_SHARE]: {
    messageId() {
      return Date.now();
    },
    metrics: [
      {
        type: 'video',
        reportMetrics: [{ name: 'share', value: 1 }],
        properties: {
          user_id: ownerId,
          video_id({ videoItem }) {
            // only youtube feed videos have no baseItemId, use externalId instead
            return videoItem.baseItemId || videoItem.externalId;
          },
          channel_id({ channel }) {
            return channel.id;
          },
          social({ network }) {
            return network.name;
          },
        },
      },
    ],
  },
  [EVENT_NAMES.CHANNEL_SHARE]: {
    messageId() {
      return Date.now();
    },
    metrics: [
      {
        type: 'channel',
        reportMetrics: [{ name: 'share', value: 1 }],
        properties: {
          user_id: ownerId,
          channel_id({ channel }) {
            return channel.id;
          },
          social({ network }) {
            return network.name;
          },
        },
      },
    ],
  },
});

function fillParams(eventParams, data) {
  return _.mapValues(eventParams, value => {
    if (_.isFunction(value)) {
      return value(data);
    } else if (_.isArray(value)) {
      return _.map(value, item => fillParams(item, data));
    } else if (_.isObject(value)) {
      return fillParams(value, data);
    }

    return value;
  });
}

export function buildReport(eventName, data, { ownerId }) {
  const events = getEvents({ ownerId });
  const event = events[eventName];

  if (!event) {
    return;
  }

  data = _.merge({}, data, { ownerId });

  return fillParams(event, data);
}

export default EVENT_NAMES;
