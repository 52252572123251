import _ from 'lodash';
import axios from 'axios';
import { hosts } from '@wix/wix-vod-shared/dist/src/common/settings/settings';
import { COLLECTOR_SERVICE } from 'shared/constants/stats/endpoints-url';
import { buildReport } from 'shared/constants/stats/events';

import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { getSiteOwnerId } from 'widget/redux/client/hydrated-data/hydrated-data';
import { createAsyncProxy } from 'shared/worker/lib';

const reportUrl = `${hosts.statsCollector}/${COLLECTOR_SERVICE}`;

const ACTIONS = createAsyncActions('SERVER.STATS.REPORT');
export const NAMES = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

export const reportStats = createAsyncProxy('reportStats', function(
  event,
  eventData,
) {
  return function(dispatch, getState) {
    dispatch(START());

    const analytics = {
      analytics: { type: 'error', name: 'widget.stats.report' },
    };

    const state = getState();
    const ownerId = getSiteOwnerId(state);
    const data = buildReport(event, eventData, { ownerId });

    return axios
      .post(reportUrl, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if (!_.get(response, 'data.success')) {
          return Promise.reject(response);
        }

        dispatch(SUCCESS(data, response));
      })
      .catch(error => {
        dispatch(FAIL(data, error, analytics));
      });
  };
});
