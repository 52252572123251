import _ from 'lodash';
import { createSelector } from 'reselect';
import { denormalize } from 'denormalizr';

import { video as videoSchema } from 'shared/schemas';
import {
  isFree as isVideoFree,
  isSaleEnabled,
} from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/pricing';
import { PREVIEW_TYPES } from '@wix/wix-vod-constants/dist/common/preview-types';
import { getDealInfo, getAllowDownload, getMemberOnly } from './vod-settings';

function withDealInfoFromSettings(state, video) {
  if (!video) {
    return video;
  }

  // TODO: move to entities reducer
  video.dealInfo = _.isEmpty(video.dealInfo)
    ? getDealInfo(state)
    : video.dealInfo;

  const memberOnly = _.isUndefined(video.memberOnly)
    ? getMemberOnly(state)
    : video.memberOnly;
  video.memberOnly = isVideoFree(video) ? memberOnly : false;

  const allowDownload = _.isUndefined(video.allowDownload)
    ? getAllowDownload(state)
    : video.allowDownload;
  video.allowDownload =
    isVideoFree(video) || isSaleEnabled(video) ? allowDownload : false;

  if (_.isEmpty(video.previewType)) {
    video.previewType = isVideoFree(video)
      ? PREVIEW_TYPES.NONE
      : PREVIEW_TYPES.FIRST_20_SEC;
  }
  return video;
}

export const getEntities = state => state.entities;

export const getVideoEntities = createSelector(
  getEntities,
  _.property('videos'),
);
const getVideosCount = createSelector(
  getVideoEntities,
  _.size,
);

export function isFetching(state) {
  return state.videos.activeRequests !== 0;
}

export function getVideoIds(state) {
  return state.videos && state.videos.ids;
}

export function getVideoById(state, videoId) {
  return getVideoEntities(state)[videoId];
}
export const hasMoreThanOneVideo = createSelector(
  getVideosCount,
  count => count > 1,
);

/**
 * @param state
 * @param videoId {String}
 * @returns {Object} video
 */
export const getVideoWithChannelDataById = createSelector(
  [state => state, getVideoById],
  (state, videoData) =>
    // TODO remove denormalize. Used to add channel data to usedInLists
    withDealInfoFromSettings(
      state,
      denormalize(videoData, state.entities, videoSchema),
    ),
);

/**
 * @param state
 * @returns {Object} {id: video}
 */
export const getVideosGroupedByIds = createSelector(
  [state => state, getVideoEntities],
  (state, videosByIds) =>
    _.mapValues(videosByIds, _.partial(withDealInfoFromSettings, state)),
);

/**
 * @param state
 * @param ids {String[]}
 * @returns {Object[]} videos list
 */
export const getVideosByIds = createSelector(
  [getVideosGroupedByIds, (state, ids) => ids],
  _.pick,
);

/**
 * @param state
 * @param ids {String[]}
 * @param currentVideoId {String}
 * @returns {Object[]} videos list
 */
export const getVideosByIdsWithoutCurrent = createSelector(
  [getVideosByIds, (state, ids, currentVideoId) => currentVideoId],
  _.omit,
);
