import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentUserEmail } from 'shared/selectors/current-site-user';
import {
  canShowChannelShare,
  getCanShowChannelInfo,
  getCanShowSignIn,
  canShowChannelTitle,
  isMenuPreview,
  isActionBarPreview,
  isRTL,
} from 'shared/selectors/app-settings';

import {
  canShowCancelSubscription,
  canShowSubscription,
} from 'widget/selectors/subscription';
import { logOutCurrentMember } from 'shared/utils/auth';
import EVENTS from 'shared/constants/events';
import WixSDK from 'shared/utils/wix-sdk';

import SubscriptionCancelHandler from 'widget/components/account/account-button/subscription-cancel-handler';
import MenuItem from 'widget/components/action-bar/menu/menu-item/menu-item';
import ChannelSubscriptionLabel from 'shared/components/channel-subscription-label/channel-subscription-label';
import Popover from '@wix/wix-vod-shared/dist/src/widget/ui-components/popover/popover';
import Title from 'widget/components/action-bar/menu/title/title';
import MenuIcon from 'widget/components/action-bar/menu/icons/menu.svg';
import SignInIcon from 'widget/components/action-bar/menu/icons/sign-in.svg';
import { withStyles } from 'shared/utils/withStyles';
import styles from './menu.styl';

import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { logBi } from 'shared/worker/actions/bi';
import {
  withPubSubEvents,
  consumePubSubEvent,
} from 'widget/containers/pub-sub-events';

@withPubSubEvents
@connect(
  state => ({
    isMenuOpen: isMenuPreview(state) || isActionBarPreview(state),
    currentSiteUserEmail: getCurrentUserEmail(state),
    canShowChannelTitle: canShowChannelTitle(state),
    canShowSignIn: getCanShowSignIn(state),
    canShowChannelInfo: getCanShowChannelInfo(state),
    canShowChannelShare: canShowChannelShare(state),
    canShowSubscription: canShowSubscription(state),
    canShowCancelSubscription: canShowCancelSubscription(state),
    isRTL: isRTL(state),
  }),
  { logBi, logOutCurrentMember },
)
@withStyles(styles)
export default class Menu extends React.Component {
  static propTypes = {
    canShowChannelTitle: PropTypes.bool.isRequired,
    canShowSignIn: PropTypes.bool.isRequired,
    canShowChannelInfo: PropTypes.bool.isRequired,
    canShowChannelShare: PropTypes.bool.isRequired,
    canShowSubscription: PropTypes.bool.isRequired,
    canShowCancelSubscription: PropTypes.bool.isRequired,
    currentSiteUserEmail: PropTypes.string.isRequired,
    isMenuOpen: PropTypes.bool.isRequired,
    isRTL: PropTypes.bool,

    channel: PropTypes.object.isRequired,
    onInfoRequest: PropTypes.func.isRequired,
    onShareRequest: PropTypes.func.isRequired,
    onSubscriptionRequest: PropTypes.func.isRequired,
    onCancelSubscriptionRequest: PropTypes.func.isRequired,
    onLogInRequest: PropTypes.func.isRequired,
    onShowAccountInfoRequest: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    consumePubSubEvent(EVENTS.SESSION.LOGOUT_USER, this.handleLogoutEvent, {
      prevProps,
      nextProps: this.props,
    });
  }

  componentWillUnmount() {
    this.unsubscribeLogoutEvent();
  }

  handleLogoutEvent = () => {
    this.props.logOutCurrentMember();
    this.unsubscribeLogoutEvent();
  };

  unsubscribeLogoutEvent() {
    WixSDK.PubSub.unsubscribe(
      EVENTS.SESSION.LOGOUT_USER,
      this.handleLogoutEvent,
    );
  }

  handlePopoverToggle = isOpen => {
    if (isOpen) {
      this.logMenuClickedBi();
    }
  };

  logMenuClickedBi = () => {
    const { channel } = this.props;
    this.props.logBi('widget.menu.clicked', { channelID: channel.id });
  };

  handleSubscribeRequest = () => {
    const { onSubscriptionRequest } = this.props;
    onSubscriptionRequest();
  };

  handleChannelInfoRequest = () => {
    const { onInfoRequest, channel } = this.props;
    this.props.logBi('widget.aboutCh.clicked', { channelID: channel.id });
    onInfoRequest();
  };

  renderSubscribe() {
    const { channel } = this.props;

    return (
      <MenuItem
        onClick={this.handleSubscribeRequest}
        key="channel-subscription"
        dataHook="channel-subscription-button"
      >
        <ChannelSubscriptionLabel dealInfo={channel.dealInfo} />
      </MenuItem>
    );
  }

  renderSubscriptionCancel() {
    const { onCancelSubscriptionRequest } = this.props;

    return (
      <MenuItem
        onClick={onCancelSubscriptionRequest}
        key="channel-subscription-cancel"
        dataHook="channel-subscription-cancel"
      >
        {i18n.t('widget.payments.cancel-subscription')}
      </MenuItem>
    );
  }

  renderShare() {
    return (
      <MenuItem
        onClick={this.props.onShareRequest}
        key="channel-share"
        dataHook="share-overlay-button"
      >
        {i18n.t('widget.accessibility.share')}
      </MenuItem>
    );
  }

  renderSignIn(hasOtherItems) {
    const {
      currentSiteUserEmail,
      onShowAccountInfoRequest,
      onLogInRequest,
    } = this.props;
    const className = classnames(styles.account, {
      [styles.withSeparator]: hasOtherItems,
    });

    if (currentSiteUserEmail) {
      return (
        <MenuItem
          onClick={onShowAccountInfoRequest}
          className={className}
          key="channel-account-info"
          icon={<SignInIcon className={styles.signIn} />}
          dataHook="channel-account-info"
        >
          {currentSiteUserEmail}
        </MenuItem>
      );
    }

    return (
      <MenuItem
        onClick={onLogInRequest}
        className={className}
        key="channel-login"
        dataHook="login-button"
      >
        {i18n.t('widget.account.sign-in')}
      </MenuItem>
    );
  }

  renderChannelInfo() {
    return (
      <MenuItem
        onClick={this.handleChannelInfoRequest}
        key="channel-info"
        dataHook="channel-info-button"
      >
        {i18n.t(
          'widget.compact-view.components.action-bar.channel-info-tooltip',
        )}
      </MenuItem>
    );
  }

  renderContent() {
    const {
      canShowSignIn,
      canShowChannelInfo,
      canShowChannelShare,
      canShowSubscription,
      canShowCancelSubscription,
    } = this.props;

    const restItems = _.compact([
      canShowSubscription && this.renderSubscribe(),
      canShowCancelSubscription && this.renderSubscriptionCancel(),
      canShowChannelShare && this.renderShare(),
      canShowChannelInfo && this.renderChannelInfo(),
    ]);

    return _.compact([
      canShowSignIn && this.renderSignIn(restItems.length),
      ...restItems,
    ]);
  }

  renderTitle() {
    const { canShowChannelTitle, channel } = this.props;

    return canShowChannelTitle ? (
      <div className={styles.title}>
        <Title dataHook="channel-title">{channel.title}</Title>
      </div>
    ) : null;
  }

  renderMenuWithTitle(content) {
    const { channel, isRTL } = this.props;

    return (
      <Popover
        ariaRole="combobox"
        openerClassName={styles.menuContainer}
        ariaLabel={channel.title}
        isOpen={content.length > 0 && this.props.isMenuOpen}
        className={styles.popoverMenu}
        content={content}
        onToggle={this.handlePopoverToggle}
        isRTL={isRTL}
      >
        <div data-hook="menu-button" className={styles.menu}>
          <MenuIcon className={styles.menuButton} />
          {this.renderTitle()}
        </div>
      </Popover>
    );
  }

  render() {
    const content = this.renderContent();

    return (
      <div className={styles.container} data-hook="channel-actions">
        <SubscriptionCancelHandler
          onSubscriptionCancel={this.props.onCancelSubscriptionRequest}
        />
        {_.size(content)
          ? this.renderMenuWithTitle(content)
          : this.renderTitle()}
      </div>
    );
  }
}
