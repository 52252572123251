import { canSubscribeToChannel } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/channel-access';

import getTranslationData from './translation-data';
import getCallbackFromProps from './callback';

export default function getPaidAccessButtonConfig(props) {
  const { channelData } = props;

  if (!canSubscribeToChannel(channelData)) {
    return null;
  }

  const callback = getCallbackFromProps(props);
  const translationData = getTranslationData(channelData);

  return {
    callback,
    translationData,
  };
}
