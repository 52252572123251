import _ from 'lodash';
import { withWidgetProps } from './widget-props';

export const withPubSubEvents = withWidgetProps(({ pubSubEvents }) => ({
  pubSubEvents,
}));

export const consumePubSubEvent = (
  eventName,
  consumer,
  { prevProps, nextProps },
) => {
  if (!prevProps.pubSubEvents) {
    return;
  }

  if (
    _.isEqual(
      prevProps.pubSubEvents[eventName],
      nextProps.pubSubEvents[eventName],
    )
  ) {
    return;
  }

  if (nextProps.pubSubEvents[eventName]) {
    consumer(nextProps.pubSubEvents[eventName]);
  }
};
