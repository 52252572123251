import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  focusSource,
  focusWithin,
} from '@wix/wix-vod-shared/dist/src/common/accessibility/focus';

import {
  getAllSettings,
  getChannelLayout,
} from 'shared/selectors/app-settings';

import ForceSliderHeight from 'widget/layouts/slider/containers/force-slider-height/force-slider-height';

import {
  isCurrentComponentIdInUrl,
  getVideoIdFromUrl,
} from 'widget/selectors/query-params';
import { isVideoPlayingOptimistic } from 'widget/selectors/video-playback-status';
import { openFullScreenVideoOnMount } from 'widget/redux/client/actions/full-screen-video-on-mount';
import PaymentEventProxy from 'shared/components/payment-events/proxy';
import { pauseVideo } from 'widget/redux/client/actions/player/change-playback-status';

import TooltipPopout from 'shared/components/tooltip/tooltip-popout';
import Modal from 'widget/components/modal/modal';

import BiHandler from 'shared/bi/bi-handler';
import { PersistentEventsHandler } from 'widget/containers/persistent-events-handler/persistent-events-handler';
import { LayoutSpinner } from 'widget/layouts/components/spinner/spinner';

import LocaleProvider from 'shared/containers/locale-provider';
import PlayerModuleLoader from 'widget/data-components/player-module-loader';

import WindowSize from 'shared/containers/window-size';
import { EditorObserver } from '../../components/editor-observer/editor-observer';
import { ViewerObserver } from 'shared/components/viewer-observer/viewer-observer';
import { AppSettingsObserver } from 'widget/data-components/app-settings-loader/app-settings-loader';
import { LoginWatcher } from 'widget/containers/login-watcher';
import ReloadChannelPaymentDataOnPayment from 'widget/data-components/reload-channel-payment-data-on-payment';
import ClearVideoUrlsCacheOnPayment from 'widget/data-components/clear-video-urls-cache-on-payment';
import PaymentsBiHandler from 'widget/components/payments/bi-handler';
import PremiumIntentUpdater from '../../components/premium-intent-updater/premium-intent-updater';
import { fitIntoView } from 'shared/worker/actions/fit-into-view';
import { getViewMode, isSiteMode } from 'widget/selectors/view-mode';
import { withStyles } from 'shared/utils/withStyles';
import styles from 'shared/styles/core.styl';
import SEOStructuredData from '../seo/seo';

@connect(
  state => ({
    appSettings: getAllSettings(state),
    videoIdFromUrl: getVideoIdFromUrl(state),
    viewMode: getViewMode(state),
    layout: getChannelLayout(state),
    isCompIdInUrl: isCurrentComponentIdInUrl(state),
    isVideoPlaying: isVideoPlayingOptimistic(state),
    windowSize: state.windowSize,
    isSiteMode: isSiteMode(state),
  }),
  {
    pauseVideo,
    fitIntoView,
    openFullScreenVideoOnMount,
  },
)
@withStyles(styles)
export class DesktopView extends React.Component {
  static propTypes = {
    windowSize: PropTypes.object.isRequired,
    children: PropTypes.node,
    videoIdFromUrl: PropTypes.string,
    appSettings: PropTypes.object,
    viewMode: PropTypes.string,
    isSiteMode: PropTypes.bool.isRequired,
    layout: PropTypes.number,
    isCompIdInUrl: PropTypes.bool,
    singleVideoId: PropTypes.string,
    isVideoPlaying: PropTypes.bool,
    pauseVideo: PropTypes.func,
    fitIntoView: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // TODO: remove experiments fetching after opening video embed to all users
    this.focusWithinHandler = focusWithin();
    this.scrollToIfNecessary();
    this.props.openFullScreenVideoOnMount();
  }

  componentWillUnmount() {
    this.focusWithinHandler.disengage();
    focusSource.disengage(this.containerRef);
  }

  scrollToIfNecessary() {
    const { videoIdFromUrl, isCompIdInUrl, fitIntoView } = this.props;

    if (isCompIdInUrl || videoIdFromUrl) {
      fitIntoView();
      this.containerRef.scrollIntoView({ behavior: 'smooth' });
    }
  }

  saveContainerRef = ref => {
    focusSource.engage(ref);
    this.containerRef = ref;
  };

  render() {
    const {
      children,
      appSettings,
      viewMode,
      layout,
      windowSize,
      isVideoPlaying,
      pauseVideo,
      isSiteMode,
    } = this.props;

    return (
      <WindowSize>
        <SEOStructuredData />
        <div
          style={
            isSiteMode ? { height: '100%' } : { height: windowSize.height }
          }
          ref={this.saveContainerRef}
          data-layout={layout}
          data-app-version={__CI_APP_VERSION__}
          className={styles.root}
          data-view-mode={viewMode}
        >
          <BiHandler />
          <PaymentEventProxy />
          <PaymentsBiHandler />
          {isSiteMode || windowSize.width ? (
            <PlayerModuleLoader>
              <React.Fragment>
                <LocaleProvider>
                  <ForceSliderHeight>{children}</ForceSliderHeight>
                </LocaleProvider>
                <PersistentEventsHandler />
              </React.Fragment>
            </PlayerModuleLoader>
          ) : (
            <LayoutSpinner />
          )}
          <AppSettingsObserver />
          <EditorObserver />
          <ViewerObserver
            isVideoPlaying={isVideoPlaying}
            pauseVideo={pauseVideo}
          />
          <ReloadChannelPaymentDataOnPayment />
          <ClearVideoUrlsCacheOnPayment />
          <LoginWatcher />

          <Modal settings={appSettings} />
          <TooltipPopout />
          <PremiumIntentUpdater />
        </div>
      </WindowSize>
    );
  }
}
